import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  tag: null,
});

export const ozonTag = {
  namespaced: true,
  state: initState,
  getters: {
    // tag: (state) => state.tag,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setTagMain: (state, tag) => (state.tag = tag),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async tagMain({ commit }) {
      const res = await api.tagMain({ marketplace: 'ozon' });
      if (res) {
        commit('setTagMain', res);
      }
    },
  },
};
