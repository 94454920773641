import { serverWhite } from '../server-white';

async function wbStock(data) {
  const res = await serverWhite.post('/admin/analytics/wb/stock', data);
  return res;
}

async function getWbStockFiltersData() {
  return await serverWhite.post('/admin/analytics/wb/stock_available', {});
}

async function ozonStock(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/stock', data);
  return res;
}
async function getOzonStockFiltersData() {
  return await serverWhite.post('/admin/analytics/ozon/stock_available', {});
}

export { wbStock, ozonStock, getWbStockFiltersData, getOzonStockFiltersData };
