<template>
  <div>
    <AnaliticEmpty v-if="!isSuccessOzon" :market-name="'Ozon'" />

    <div v-if="isSuccessOzon" class="analitica">
      <div class="d-flex pt-4">
        <v-tabs v-model="tab" class="pl-8" height="44" background-color="transparent">
          <v-tabs-slider color="primary" class="tab"></v-tabs-slider>
          <v-tab v-for="(tab, i) of tabs" :key="'vertical-' + i" :ripple="false" class="text-none analitic__tabs__link">
            <div class="text-subtitle-1 font--lh18">{{ tab.name }}</div>
          </v-tab>
          <i class="line"></i>
        </v-tabs>
      </div>
      <v-container fluid class="px-8 pt-8 analitica__container">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(tab, i) of tabs" :key="'vertical-' + i" class="tabs">
            <component :is="tab.component" class="page"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </div>
  </div>
</template>

<script>
import AnaliticEmpty from '@/components/analitic/AnaliticEmpty';

import InfoTab from '@/components/analitic/ozon/info/InfoTab';
import StockTab from '@/components/analitic/ozon/stock/StockTab';
import OrdersTab from '@/components/analitic/ozon/orders/OrdersTab';
import FinancesTab from '@/components/analitic/ozon/finances/FinancesTab';
import ProductTab from '@/components/analitic/ozon/products/ProductTab';

import * as filterMutations from '@/store/modules/analitic/mutations/filter';
import * as pagingMutations from '@/store/modules/analitic/mutations/paging';
import { mapGetters } from 'vuex';

export default {
  name: 'AnaliticOzon',
  components: {
    AnaliticEmpty,
    InfoTab,
    OrdersTab,
    ProductTab,
    StockTab,
    FinancesTab,
  },
  provide: {
    filterMutations,
    pagingMutations,
  },
  data: () => ({
    tabs: [
      {
        component: 'InfoTab',
        name: 'Общая информация',
      },
      {
        component: 'ProductTab',
        name: 'Товары',
      },
      {
        component: 'OrdersTab',
        name: 'Заказы',
      },
      {
        component: 'FinancesTab',
        name: 'Финансы',
      },
      {
        component: 'StockTab',
        name: 'Остатки по складам',
      },
    ],
    tab: 0,
  }),
  computed: {
    ...mapGetters('analitic', ['isSuccessOzon']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main';
.theme--light.v-tabs-items {
  background-color: transparent;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: white !important;
}

.analitica,
.analitica__container {
  background-color: #fff;
  min-height: 400px;
}

.analitica__container .v-item-group {
  padding: 0;
}

.v-tabs-slider-wrapper {
  height: 3px !important;
}

.v-slide-group__prev {
  display: none;
}

.tabs .analitic__tabs__link {
  background-color: #ffff !important;
  color: #222733;
  padding: 0;
  margin-right: 32px;
}

.analitic__tabs__link.v-tab:before {
  background-color: transparent !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #222733 !important;
}

.analitic__tabs__link {
  min-width: 2px !important;
}

.v-tab--active {
  color: #4971d0 !important;
}

.line {
  width: 100%;
  height: 0;
  border-bottom: 2px solid #e6e7e9;
  float: left;
  margin: 0;
  position: absolute;
  z-index: 0;
  top: 95%;
  right: 0;
  left: 0;
}
</style>
