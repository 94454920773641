import * as api from '@/api';
import { createGridModule } from '@/store/modules/analitic/features/grid';
import * as gridMutations from '@/store/modules/analitic/mutations/grid';
import * as pagingMutations from '@/store/modules/analitic/mutations/paging';
import { filter } from '@/mixins/filterDate.js';

export function createWbProductsModule({ ...config } = {}) {
  return createGridModule({
    filter,
    state: {
      // productToken: null,
    },
    getters: {
      sdate: (state) => state.sdate,
      edate: (state) => state.edate,
    },

    mutations: {
      set: (state, payload) => (state[payload.type] = payload.value),
      setStartDate: (state, sdate) => (state.sdate = sdate),
      setEndDate: (state, edate) => (state.edate = edate),
      // setToken: (state, value) => (state.productToken = value),
    },

    actions: {
      async wbProducts({ commit, getters: { filter, search_query, pageSize, page, sort } }) {
        if (search_query && search_query.length <= 2) {
          return;
        }
        let data = {
          filter,
          search_query,
          pageSize,
          page,
          sort,
          // marketplace: 'wb',
        };
        const res = await api.wbProducts(data);

        // if (res.products === 'disable') {
        //   commit('setToken', true);
        // }

        if (res) {
          commit(gridMutations.SET_GRID, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
      },
    },
    ...config,
  });
}
