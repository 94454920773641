<template>
  <div>
    <v-card class="mx-auto font--black1" max-width="420">
      <h3 class="text-center pt-10 font--black1">Вход в МаркетМетрику</h3>
      <v-sheet class="px-12 py-8 mb-6" rounded>
        <FBRLoginInit v-if="isInit" v-model="formValid" @init="init" />
        <FBRLoginConfirm
          v-if="isConfirm"
          :resend-delay="resendDelay"
          :masked-phone="maskedPhone"
          @confirm="confirm"
          @refresh="refresh"
        />
      </v-sheet>
    </v-card>
    <v-snackbar v-model="resendAlert" top color="error" right>
      Повторная отправка кода подтверждения возможна через {{ resendDelay }}с.
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="resendAlert = false"> Закрыть </v-btn>
      </template>
    </v-snackbar>

    <div class="d-flex justify-center">
      <v-btn text @click="$emit('registration', true)"
        ><span class="text-caption font-weight-medium font--graphite3">Впервые здесь? зарегистрироваться</span></v-btn
      >
    </div>
  </div>
</template>

<script>
import * as autorization from '@/api/autorization';
import FBRLoginInit from './FBRLoginInit';
import FBRLoginConfirm from './FBRLoginConfirm';
import { mapMutations, mapState } from 'vuex';
import { extensionLogIn } from '@/utils/chrome.js';

const INIT = 'init';
const CONFIRM = 'confirm';

export default {
  name: 'FBRLogin',

  components: { FBRLoginInit, FBRLoginConfirm },
  data() {
    return {
      smsid: null,
      phone: null,
      stage: INIT,
      resendDelay: 180,
      resendAlert: false,
      formValid: false,
    };
  },
  computed: {
    ...mapState('profile', ['login']),

    isInit() {
      return this.stage === INIT;
    },
    isConfirm() {
      return this.stage === CONFIRM;
    },
    maskedPhone() {
      return this.phone ? '+7***' + this.phone.substring(8) : '';
    },
  },

  methods: {
    ...mapMutations('common', ['auth']),

    async init(login) {
      const res = await autorization.init(login);
      if (res) {
        this.smsid = res.smsid;
        this.stage = CONFIRM;
        this.resendDelay = res.resend_delay || 180;

        if (res.resend_delay > 0) {
          this.resendAlert = true;
        }
      }
    },

    async confirm(code) {
      const res = await autorization.login({ login: this.login, code, smsid: this.smsid });

      if (res) {
        this.auth(res.tnx);

        if (localStorage.getItem('extension') === 'Y') {
          extensionLogIn();
          this.$store.dispatch('common/addAlert', { msg: 'Отправка данных в расширение', type: 'success' });
        }
      }
    },

    async refresh() {
      const res = await autorization.init(this.login);

      if (res) {
        this.smsid = res.smsid;
      }
    },
  },
};
</script>
