<template>
  <div class="mt-6">
    <v-simple-table class="query_tag__table" height="240px" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="min-width: 360px">Запрос</th>
            <th class="text-left">Частота</th>
            <th class="text-left">Индекс</th>
            <th class="text-left">Есть</th>
            <th class="text-left">Бренд</th>
            <th class="text-left">Название</th>
            <th class="text-left">Цвет</th>
            <th class="text-left">Состав</th>
            <th class="text-left">Описание</th>
            <th class="text-left">Параметры</th>
            <th class="text-left">Комплектация</th>
          </tr>
        </thead>
        <tbody>
          <td v-if="!list || !list.length" colspan="8" class="text-center pt-16 font--graphite3">
            Не указаны ключевые запросы для поиска
          </td>

          <tr v-for="(item, i) in list" :key="i">
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.tag) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.tag !== null ? item.tag : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.frequency) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.frequency !== null ? item.frequency : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.index) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.index !== null ? item.index : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.thereIs) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.thereIs !== null ? item.thereIs : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.brand) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.brand !== null ? item.brand : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.name) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.name !== null ? item.name : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.color) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.color !== null ? item.color : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.composit) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.composit !== null ? item.composit : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.descr) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.descr !== null ? item.descr : '-' }}
            </td>
            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.params) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.params !== null ? item.params : '-' }}
            </td>

            <td
              class="font--lh21"
              :style="
                item.index !== null
                  ? { 'background-color': itemAllClass(item.contents) }
                  : { 'background-color': itemIndexClass(item.index) }
              "
            >
              {{ item.contents !== null ? item.contents : '-' }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'QueryTagCardTable',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('wbProductCard', ['keywordsResult']),
  },
  methods: {
    itemIndexClass(item) {
      if (item === null) {
        return '#FFE9E9';
      }
      return 'white';
    },
    itemAllClass(item) {
      if (item === 'Все') {
        return '#F2F6FF';
      }
      if (item === 'Полное' || item == '+') {
        return '#d0f5e0';
      }
      return 'white';
    },
  },
};
</script>

<style lang="scss" scoped>
table > thead > tr > th:nth-child(1) {
  z-index: 9999;
}

.theme--light.v-data-table.query_tag__table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
  background-color: #f5f5f6 !important;
}
.v-data-table.query_tag__table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-right: thin solid rgba(0, 0, 0, 0.12);
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.v-data-table.query_tag__table > .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
