<template>
  <vue-horizontal scroll :button="false" class="horizontal__scroll">
    <div class="table__container">
      <v-simple-table fixed-header class="table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-caption text--secondary history__th">Номер задания</th>
              <th class="text-caption text--secondary history__th">Создано</th>
              <th class="text-caption text--secondary history__th">Статус</th>
              <th class="text-caption text--secondary history__th">Статус клиента</th>
              <th class="text-caption text--secondary history__th pr-16">Товар</th>
              <th class="text-caption text--secondary history__th pr-14">Цена</th>
              <th class="text-caption text--secondary history__th">Артикул поставщика</th>
              <th class="text-caption text--secondary history__th">Размер</th>
              <th class="text-caption text--secondary history__th">Тип доставки</th>
              <th class="text-caption text--secondary history__th">Склад</th>
              <th class="text-caption text--secondary history__th">Адрес</th>
              <th class="text-caption text--secondary history__th">Штрихкод</th>
              <th class="text-caption text--secondary history__th">Артикул МП</th>
            </tr>
          </thead>
          <tbody v-for="item in orderList" :key="item.id">
            <tr>
              <td>{{ item.orderUID }}</td>
              <td>{{ item.dt_create === null ? '-' : formatDate(item.dt_create) }}</td>
              <td>{{ item.sts }}</td>
              <td>{{ item.stsUser }}</td>
              <td class="py-2">{{ item.name }}</td>
              <td>{{ makeRub(item.totalPrice, 2) }}</td>
              <td>{{ item.article === null ? '-' : item.article }}</td>
              <td>{{ item.size === (null || undefined) ? '-' : `${item.size} см` }}</td>
              <td>{{ item.deliveryType === null ? '-' : item.deliveryType }}</td>
              <td>{{ item.scOfficesNames === null ? '-' : item.scOfficesNames }}</td>
              <td>{{ item.officeAddress === null ? '-' : item.officeAddress }}</td>
              <td>{{ item.barcode === null ? '-' : item.barcode }}</td>
              <td>{{ item.sku === null ? '-' : item.sku }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </vue-horizontal>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { format } from 'date-fns';
import VueHorizontal from 'vue-horizontal';

export default {
  name: 'AssemblyExpandTable',
  components: { VueHorizontal },
  mixins: [makeRub],
  props: {
    orderList: {
      type: Array,
    },
  },
  methods: {
    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },
  },
};
</script>

<style scoped>
.history__th {
  height: 30px;
}
td {
  border-bottom: 1px solid rgb(125, 125, 125, 0.3);
}
</style>
