<template>
  <div class="chart-container">
    <div class="d-flex align-center justify-center">
      <div v-for="(item, index) in legendItems" :key="index" class="d-flex align-center mr-4">
        <v-checkbox
          v-model="item.display"
          color="success"
          :label="item.label"
          class="mr-1"
          @click="onLegendClicked(index)"
        ></v-checkbox>
        <div style="width: 10px; height: 10px" :style="{ 'background-color': item.backgroundColor }"></div>
      </div>
    </div>
    <line-chart ref="chartRef" :chart-data="productGraph" :options="options"></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/common/graph/LinesChart.vue';

export default {
  name: 'ProductGraph',
  components: {
    LineChart,
  },
  props: {
    product_card: {
      // eslint-disable-line
      type: [Object, null],
    },
  },
  data() {
    return {
      productGraph: {},
      legendItems: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },

        scales: {
          yAxes: [
            {
              id: 'sales',
              type: 'linear',
              position: 'left',
              display: true,
              ticks: {
                fontColor: 'rgba(252, 37, 37)',
              },
            },
            {
              id: 'income',
              type: 'linear',
              position: 'left',
              display: false,
              ticks: {
                fontColor: 'rgba(166, 110, 240)',
              },
            },
            {
              id: 'quantity',
              type: 'linear',
              position: 'right',
              display: true,
              ticks: {
                fontColor: 'rgba(75,192,192)',
              },
            },
            {
              id: 'price',
              type: 'linear',
              position: 'right',
              display: false,
              ticks: {
                fontColor: 'rgba(229, 240, 110)',
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    card() {
      const array = this.product_card?.sales ? this.product_card.sales : [];
      return array.reverse();
    },

    label() {
      const formattedDates = this.card.map((res) => new Date(res?.date).toLocaleDateString('ru-RU'));
      return formattedDates;
    },
  },
  watch: {
    card() {
      this.lineGraph();
    },
  },

  methods: {
    onLegendClicked(index) {
      const ci = this.$refs.chartRef.$data._chart;

      var meta = ci.getDatasetMeta(index);

      meta.hidden = !this.legendItems[index].display;

      // отображаем/скрываем данные на графике
      if (meta.hidden) {
        ci.options.scales.yAxes[index].display = false;
      } else if (meta.hidden === null && (index === 1 || index === 3)) {
        ci.options.scales.yAxes[index].display = false;
      } else if (meta.hidden === null && (index === 0 || index === 2)) {
        ci.options.scales.yAxes[index].display = true;
      } else {
        ci.options.scales.yAxes[index].display = true;
      }
      ci.update();
    },
    lineGraph() {
      this.productGraph = {
        labels: this.label,
        datasets: [
          {
            label: 'Продажи',
            data: this.card.map((res) => res?.sales),
            fill: true,
            borderColor: 'rgba(252, 37, 37)',
            backgroundColor: 'rgba(252, 37, 37, 0.3)',
            yAxisID: 'sales',
            hidden: false,
          },
          {
            label: 'Выручка',
            data: this.card.map((res) => res?.income),
            fill: true,
            borderColor: 'rgba(166, 110, 240)',
            backgroundColor: 'rgba(166, 110, 240, 0.3)',
            yAxisID: 'income',
            hidden: true,
          },
          {
            label: 'Остаток',
            data: this.card.map((res) => res?.quantity),
            fill: true,
            borderColor: 'rgba(75,192,192)',
            backgroundColor: 'rgba(75,192,192,0.3)',
            yAxisID: 'quantity',
            hidden: false,
          },
          {
            label: 'Цена',
            data: this.card.map((res) => res?.price),
            fill: true,
            borderColor: 'rgba(229, 240, 110)',
            backgroundColor: 'rgba(229, 240, 110, 0.3)',
            yAxisID: 'price',
            hidden: true,
          },
        ],
      };

      const ci = this.$refs.chartRef.$data._chart;
      const isEmpty = ci.config.data.datasets.length === 0;

      if (isEmpty) {
        this.legendItems = this.productGraph.datasets.map((e) => {
          return {
            label: e.label,
            display: !e.hidden,
            backgroundColor: e.borderColor,
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  max-height: 500px;
}
</style>
