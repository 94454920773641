import * as api from '@/api';
import { createGridModule } from '@/store/modules/analitic/features/grid';
import * as gridMutations from '@/store/modules/analitic/mutations/grid';
import * as pagingMutations from '@/store/modules/analitic/mutations/paging';

export function createOzonStockModule({ ...config } = {}) {
  return createGridModule({
    state: {
      filtersData: {},
    },

    mutations: {
      set: (state, payload) => (state[payload.type] = payload.value),
      setFilters: (state, filtersData) => (state.filtersData = filtersData),
    },

    actions: {
      async ozonStock({ commit, getters: { filter, search_query, pageSize, page, sort } }) {
        if (search_query && search_query.length <= 2) {
          return;
        }
        let data = {
          search_query,
          pageSize,
          page,
          sort,
          // marketplace: 'wb',
        };
        if (filter && filter.length) {
          data.filter = filter;
        }
        const res = await api.ozonStock(data);

        if (res) {
          commit(gridMutations.SET_GRID, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
          commit('setFilters', res.filters);
        }
      },
    },
    ...config,
  });
}
