<template>
  <div class="px-5" style="padding-bottom: 120px">
    <div class="d-flex justify-center mt-2 mb-6">
      <img src="img/marketplace_wb.png" alt="Name" />
    </div>
    <v-card elevation="0" color="transparent">
      <div class="text-subtitle-1 font-weight-medium">Комплекс сервисных услуг и тарифов для селлеров</div>
      <div class="text-subtitle-1 my-6">
        Широкий выбор возможностей для начинающего предпринимателя и масштабирования своего бизнеса:
      </div>
      <p class="d-flex align-center text-subtitle-1 mb-4">
        <svg width="20" height="20">
          <use xlink:href="#markers_type"></use>
        </svg>
        <span class="ml-3">Вывод выручки с площадок без комиссии</span>
      </p>
      <p class="d-flex align-center text-subtitle-1 mb-4">
        <svg width="20" height="20">
          <use xlink:href="#markers_type"></use>
        </svg>
        <span class="ml-3">Наличные без ограничений</span>
      </p>
      <p class="d-flex align-center text-subtitle-1 mb-4">
        <svg width="20" height="20">
          <use xlink:href="#markers_type"></use>
        </svg>
        <span class="ml-3">Инструменты продвижения</span>
      </p>
      <p class="d-flex align-center text-subtitle-1 mb-11">
        <svg width="20" height="20">
          <use xlink:href="#markers_type"></use>
        </svg>
        <span class="ml-3">Курсы для успешного старта</span>
      </p>

      <div class="mb-8 d-flex align-center">
        <svg width="20" height="20">
          <use xlink:href="#share"></use>
        </svg>
        <a
          href="https://modulbank.ru/fs/files/market_seller.pdf"
          target="_blank"
          style="cursor: pointer"
          class="ml-3 text-decoration-underline"
          >Тарифы для селлеров</a
        >
      </div>
      <div class="d-flex align-center">
        <svg width="20" height="20">
          <use xlink:href="#share"></use>
        </svg>
        <a
          href="https://partner.modulbank.ru/files/marketplace_services.pdf"
          target="_blank"
          style="cursor: pointer"
          class="ml-3 text-decoration-underline"
          >Услуги для селлеров</a
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'WidgetNotConnectedMobile',
  methods: {
    ...mapMutations('common', ['setMobileMainTab']),
  },
};
</script>
