<template>
  <div>
    <v-row>
      <v-col cols="6">
        <div class="text-subtitle-2 my-6">Информация о товаре</div>
        <div v-if="product.card.selling.brand_name">
          <span class="text-caption font-weight-medium font--graphite3 font--lh14">Бренд</span>
          <v-text-field
            hide-details
            class="card_field pb-2"
            height="44"
            outlined
            :value="product.card.selling.brand_name"
            readonly
          ></v-text-field>
        </div>

        <div v-if="product.card.imt_name">
          <span class="text-caption font-weight-medium font--graphite3 font--lh14">Название товара</span>
          <v-text-field
            outlined
            hide-details
            class="card_field pb-2"
            :value="product.card.imt_name"
            readonly
          ></v-text-field>
        </div>

        <div v-if="product.card.nm_colors_names">
          <span class="text-caption font-weight-medium font--graphite3 font--lh14">Цвет</span>
          <v-text-field
            outlined
            hide-details
            class="card_field pb-2"
            :value="product.card.nm_colors_names"
            readonly
          ></v-text-field>
        </div>

        <div v-if="product.card.compositions">
          <span class="text-caption font-weight-medium font--graphite3 font--lh14">Состав</span>
          <v-text-field
            outlined
            hide-details
            class="card_field pb-2"
            :value="compositions(product.card.compositions)"
            readonly
          >
          </v-text-field>
        </div>

        <div v-if="product.card.kinds">
          <span class="text-caption font-weight-medium font--graphite3 font--lh14">Пол</span>
          <v-text-field
            outlined
            hide-details
            class="card_field pb-2"
            :value="product.card.kinds"
            readonly
          ></v-text-field>
        </div>

        <div v-if="product.card.contents">
          <span class="text-caption font-weight-medium font--graphite3 font--lh14">Комплектация</span>
          <v-text-field
            hide-details
            class="card_field pb-2"
            height="44"
            outlined
            :value="product.card.contents"
            readonly
          ></v-text-field>
        </div>

        <div v-if="product.card.description">
          <span class="text-caption font-weight-medium font--graphite3 font--lh14">Описание</span>
          <v-textarea
            outlined
            min-height="200"
            hide-details
            class="pb-2 pt-0"
            :value="product.card.description"
            readonly
          ></v-textarea>
        </div>

        <a
          v-if="product.card.grouped_options"
          color="primary"
          :ripple="false"
          class="text-decoration-underline d-flex justify-end pt-4 pb-4 text-subtitle-1"
          @click="addMoreOptions()"
          >{{ showOptions ? 'Скрыть дополнительные атрибуты' : 'Показать все атрибуты' }}</a
        >
      </v-col>

      <v-col cols="6">
        <div class="text-subtitle-2 my-6">Проверочный текст</div>
        <v-card color="#F5F5F6" min-height="400" class="pa-6" elevation="0">
          <v-form ref="form" v-model="valid">
            <v-card-text class="pa-0 font--graphite3">По этим фразам будет произведена проверка</v-card-text>
            <v-textarea
              v-model="tags"
              hide-details
              min-height="300"
              solo
              class="my-4"
              :rules="[rules.required('')]"
              label="Введите ключевые запросы"
            ></v-textarea>
            <v-card-text class="pa-0 text-caption font--graphite3">Каждое значение вводится с новой строки</v-card-text>
            <v-card-text class="pa-0 text-caption font--graphite3">Не более 5000 строк</v-card-text>
            <v-btn
              :disabled="!valid"
              color="primary"
              :ripple="false"
              depressed
              width="70%"
              class="mx-16 mt-5"
              @click="searchTags()"
              >Проверить</v-btn
            >
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="showOptions" class="pb-16">
      <div v-for="option in product.card.grouped_options" :key="option.group_name" class="pt-6">
        <span class="text-subtitle-2"> {{ option.group_name }}</span>
        <v-row class="pa-0 pt-4">
          <v-col v-for="opt in option.options" :key="opt.name" cols="6" class="py-0">
            <span class="text-caption font-weight-medium font--graphite3 font--lh14">{{ opt.name }} </span>
            <v-text-field outlined hide-details class="card_field pb-2" :value="opt.value" readonly></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>

    <ProductSeoCards id="targetElement" />
    <ProductSeoTabs :close-panel="closePanel" :show-slider="showSlider" />
    <v-btn
      v-if="showExcel"
      color="primary"
      class="ml-2 px-4"
      outlined
      :loading="fileLoading"
      style="border-width: 2px"
      @click="exportFile()"
    >
      <div class="d-flex align-center">
        <svg width="18" height="18" class="icon mr-1">
          <use xlink:href="#download"></use>
        </svg>
        <span class="text-caption font-weight-bold"> Cкачать ключевые запросы</span>
      </div>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProductSeoTabs from '@/components/metric/wb/product/ProductSeoTabs';
import ProductSeoCards from '@/components/metric/wb/product/ProductSeoCards';

export default {
  name: 'ProductInfo',

  components: { ProductSeoCards, ProductSeoTabs },
  props: {
    closePanel: {
      type: Boolean,
    },
    showSlider: {
      type: Boolean,
    },
  },

  data() {
    return {
      showOptions: false,
      showExcel: false,
      valid: false,
      rules: {
        required: (msg) => (v) => !!v || msg,
      },
    };
  },

  computed: {
    ...mapState('wbProductCard', ['product', 'keywords', 'seoTab', 'fileLoading']),

    tags: {
      get() {
        return this.keywords;
      },
      set(value) {
        this.setKeywords(value);
      },
    },
    formatedKeywords() {
      return this.tags.split('\n');
    },
    seoType() {
      return this.seoTab === 0 ? 'query' : 'words';
    },
  },

  watch: {
    closePanel(v) {
      if (!v) {
        this.setKeywords('');
        this.setKeywordResults({});
        this.showOptions = false;
        this.valid = false;
      }
    },
  },

  methods: {
    ...mapMutations('wbProductCard', ['setKeywords', 'setKeywordResults']),
    ...mapActions('wbProductCard', ['checkKeywords', 'getExportListSEOTags']),

    // скролл до результатов проверки
    scrollToBottom() {
      const targetElement = document.getElementById('targetElement');
      targetElement.scrollIntoView({ behavior: 'smooth' });
    },

    addMoreOptions() {
      this.showOptions = !this.showOptions;
    },
    compositions(value) {
      return value
        .map((item) => {
          return item.name;
        })
        .join(', ');
    },

    async searchTags() {
      let data = {
        pid: this.product.pid,
        keywords: this.formatedKeywords,
      };
      const res = this.checkKeywords(data);
      if (res) {
        this.scrollToBottom();
        this.showExcel = true;
      }
    },

    exportFile() {
      this.getExportListSEOTags(this.seoType);
    },
  },
};
</script>

<style lang="scss">
.card_field > .v-input__control > .v-input__slot {
  min-height: 44px !important;
}
</style>
