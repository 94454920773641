<template>
  <div>
    <tags-modul-filter-form
      v-if="tagsFilters"
      ref="filterForm"
      :module-name="moduleName"
      :columns="filterColumns"
      :fast-date="fastDate"
      @update:filters="updateFilters"
      @reset:filters="resetFilters"
    />

    <div v-else class="modul-filters d-flex">
      <modul-date-filter
        v-if="fastDate"
        :height="height"
        :module-name="moduleName"
        @update:date-filter="updateDateFilter"
      />
      <v-btn
        color="dark"
        :height="height"
        outlined
        class="product-filter-dialog__button"
        @click="showFilters = !showFilters"
      >
        <v-icon>mdi-filter-outline</v-icon>Фильтры
      </v-btn>
      <SidePanel v-model="showFilters" :width="600">
        <modul-filter-form
          ref="filterForm"
          :module-name="moduleName"
          :columns="filterColumns"
          :fast-date="fastDate"
          @update:filters="updateFilters"
          @reset:filters="resetFilters"
        ></modul-filter-form>
      </SidePanel>
    </div>
  </div>
</template>

<script>
import ModulFilterForm from './modul-filter-form';
import TagsModulFilterForm from './tags-modul-filter-form';
import ModulDateFilter from '@/components/common/modul-grid/components/modul-date-filter';
import SidePanel from '@/components/common/SidePanel';

export default {
  name: 'ModulFilter',
  components: {
    ModulDateFilter,
    'modul-filter-form': ModulFilterForm,
    'tags-modul-filter-form': TagsModulFilterForm,
    SidePanel,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      required: true,
    },
    fastDate: {
      type: Boolean,
      default: false,
    },
    tagsFilters: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: '36',
    },
  },
  data() {
    return {
      // dialogVisible: false,
      showFilters: false,
    };
  },
  computed: {
    filterColumns() {
      const _columns = this.columns.filter((col) => !!col.type);
      return _columns;
    },
    oldFilters() {
      return this.$store.getters[`${this.moduleName}/localFilters`];
    },
  },
  methods: {
    updateFilters(e) {
      this.$emit('update:filters', e);
      this.showFilters = false;
    },
    updateDateFilter(e) {
      this.$emit('update:date-filter', e);
    },
    resetFilters() {
      this.$emit('reset:filters');
      this.showFilters = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
