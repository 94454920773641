import { serverWhite } from '../server-white';

async function wbWeekly(data) {
  const res = await serverWhite.post('/admin/analytics/wb/weekly_report', data);
  return res;
}

async function ozonWeekly(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/report', data);
  return res;
}

async function getOzonWeeklyFiltersData() {
  return await serverWhite.post('/admin/analytics/ozon/report_available', {});
}

async function wbWeeklyDetails(data) {
  const res = await serverWhite.post('/admin/analytics/wb/weekly_report_detail', data);
  return res;
}

async function ozonWeeklyDetails(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/reportDetail', data);
  return res;
}

async function wbTape(data) {
  const res = await serverWhite.post('/admin/analytics/wb/sales_tape', data);
  return res;
}

async function ozonTape(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/sales', data);
  return res;
}

async function getOzonTapeFiltersData() {
  return await serverWhite.post('/admin/analytics/ozon/sales_available', {});
}

async function wbTapeDetails(data) {
  const res = await serverWhite.post('/admin/analytics/wb/sales_tape_detail', data);
  return res;
}

async function ozonTapeDetails(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/salesDetail', data);
  return res;
}

export {
  wbWeekly,
  wbWeeklyDetails,
  getOzonWeeklyFiltersData,
  getOzonTapeFiltersData,
  wbTape,
  wbTapeDetails,
  ozonWeekly,
  ozonWeeklyDetails,
  ozonTape,
  ozonTapeDetails,
};
