<template>
  <div>
    <div class="d-flex main-filter mb-4 mb-sm-6 flex-wrap px-4 mt-4">
      <div v-show="viewLabel === 'Таблица'">
        <div class="d-flex main-filter__tab">
          <div class="d-flex main-filter__tab--day mr-4">
            <div class="font--p2 main-filter__tab--text mr-2">Выберите период:</div>

            <v-card class="font main-filter__tab--card" flat color="#FFF">
              <div class="text-center main-filter__tab--group">
                <v-btn-toggle v-model="days" class="ma-0" group color="primary" @change="selectedDays($event)">
                  <v-btn value="7" class="main-filter__tab--btn ma-0 mr-2" :class="{ 'disable-events': days == '7' }"
                    >7 дн.</v-btn
                  >
                  <v-btn value="14" class="main-filter__tab--btn ma-0 mr-2" :class="{ 'disable-events': days == '14' }"
                    >14 дн.</v-btn
                  >
                  <v-btn value="30" class="main-filter__tab--btn ma-0" :class="{ 'disable-events': days == '30' }"
                    >30 дн.</v-btn
                  >
                </v-btn-toggle>
              </div>
            </v-card>
          </div>

          <SalesTypeToogle class="main-filter__tab--sales" @change="changeType($event)" />
        </div>
      </div>

      <v-switch
        :label="viewLabel"
        dense
        inset
        hide-details
        class="ma-0 mt-2 ml-2 pa-0 flex align-center switch-catalog"
        @click="toggleView"
      ></v-switch>
    </div>

    <CatalogTable v-if="isTableView" :items="items" class="mx-4" @cat:change="$emit('cat:change', $event)" />
    <v-row v-else class="mx-4 mb-5">
      <v-col v-for="(item, i) in items" :key="i" cols="12" md="6" lg="4" class="pa-1">
        <v-expansion-panels :active-class="`grey lighten-${item.lvl}`">
          <CatalogPanel :item="item" @cat:change="$emit('cat:change', $event)" />
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import CatalogPanel from '@/components/metric/wb/catalog/CatalogPanel';
import CatalogTable from '@/components/metric/wb/catalog/CatalogTable';
import SalesTypeToogle from '@/components/common/SalesTypeToogle';
import { INIT_SALES_TYPE } from '@/constants.js';

export default {
  name: 'CatalogMain',

  components: { CatalogPanel, CatalogTable, SalesTypeToogle },

  data() {
    return {
      isTableView: true,
      days: '7',
      types: INIT_SALES_TYPE,
    };
  },

  computed: {
    ...mapState({
      items: (state) => state.wbCatalog.catalog,
    }),
    ...mapGetters('wbMainTabs', ['tabs']),

    viewLabel() {
      return this.isTableView ? 'Таблица' : 'Панель';
    },
  },

  mounted() {
    // диспатчим метод каталога
    this.$store.dispatch(this.tabs[0].action);
  },

  methods: {
    ...mapMutations('wbCatalog', ['setPeriod', 'setCatalogSalesType']),
    ...mapActions('wbCatalog', ['catalogList']),

    toggleView() {
      this.isTableView = !this.isTableView;
    },

    selectedDays(e) {
      this.setPeriod(e);
      this.catalogList();
    },
    changeType(e) {
      this.setCatalogSalesType(e);
      this.catalogList();
    },
  },
};
</script>

<style lang="scss">
.switch-catalog.v-input--switch--inset .v-input--selection-controls__input {
  width: 40px;
}
.switch-catalog .v-label {
  color: #222733;
}
</style>
