<template>
  <div>
    <div v-if="!isActive && isLoaded">
      <div v-if="isMobile">
        <WidgetNotConnectedMobile />
      </div>
      <v-row v-if="!isMobile">
        <v-col xs="12" sm="12" md="12" lg="9">
          <SpecialOffer />
          <WidgetNotConnected />
        </v-col>

        <v-col xs="12" sm="12" md="12" lg="3">
          <!-- <WidgetAddToken class="mb-2 flex-grow-1" /> -->
          <v-row>
            <v-col cols="12" md="6" lg="12">
              <WidgetSupport />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div v-if="isActive && isLoaded">
      <v-row class="pa-0 ma-0" :class="isMobile ? 'd-flex flex-column' : ''">
        <v-col lg="3" md="6" xs="12" :class="alignCards">
          <div class="d-flex flex-column" style="height: 100%">
            <WidgetSellers :class="isMobile ? 'mobile_widget' : 'widget mb-6'" />
            <div v-if="!isMobile">
              <WidgetAnalytics v-if="showAnalytics" class="widget d-flex flex-column fill-height" />
              <WidgetAnalyticsEmpty v-else class="widget d-flex flex-column fill-height" />
            </div>
          </div>
        </v-col>

        <v-col lg="3" md="6" xs="12" :class="alignCards"
          ><WidgetManagers v-if="showManagers" :class="isMobile ? 'mobile_widget' : 'widget'"
        /></v-col>
        <v-col lg="3" md="6" xs="12" :class="alignCards">
          <WidgetGreen
            v-if="showGreen"
            :class="isMobile ? 'mobile_widget' : 'widget'"
            :style="isMobile ? 'margin-bottom: 80px' : ''"
          />
        </v-col>
        <v-col v-if="!isMobile" lg="3" md="6" xs="12" :class="alignCards">
          <WidgetBestProduct v-if="bestProduct" class="widget"
        /></v-col>
      </v-row>
      <v-row v-if="!isMobile" class="pa-0 ma-0">
        <v-col lg="3" md="6" xs="12" :class="alignCards"> <WidgetInstitute class="widget" /></v-col>
        <v-col lg="3" md="6" xs="12" :class="alignCards"> <WidgetSertification class="widget" /></v-col>
        <v-col lg="3" md="6" xs="12" :class="alignCards"> <WidgetShippers class="widget" /></v-col>
        <v-col lg="3" md="6" xs="12" :class="alignCards"> <WidgetStudio class="widget" /></v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import WidgetSellers from '@/components/products/WidgetSellers';
import WidgetAnalyticsEmpty from '@/components/products/WidgetAnalyticsEmpty';
import WidgetAnalytics from '@/components/products/WidgetAnalytics';
import WidgetManagers from '@/components/products/WidgetManagers';
import WidgetGreen from '@/components/products/WidgetGreen';
import WidgetBestProduct from '@/components/products/WidgetBestProduct';

import WidgetInstitute from '@/components/products/WidgetInstitute';
import WidgetSertification from '@/components/products/WidgetSertification';
import WidgetShippers from '@/components/products/WidgetShippers';
import WidgetStudio from '@/components/products/WidgetStudio';

import WidgetNotConnected from '@/components/products/WidgetNotConnected';
import WidgetNotConnectedMobile from '@/components/products/WidgetNotConnectedMobile';
import WidgetSupport from '@/components/products/WidgetSupport';
import { mapGetters } from 'vuex';
import SpecialOffer from '@/components/common/SpecialOffer';

export default {
  name: 'Products',
  components: {
    WidgetSellers,
    WidgetAnalytics,
    WidgetAnalyticsEmpty,
    WidgetManagers,
    WidgetGreen,
    WidgetBestProduct,
    WidgetInstitute,
    WidgetSertification,
    WidgetShippers,
    WidgetStudio,
    WidgetNotConnected,
    WidgetNotConnectedMobile,
    WidgetSupport,
    SpecialOffer,
  },
  data() {
    return {
      banner: false,
      componentName: 'products',
    };
  },
  computed: {
    // FIXME грин будем получать в каждом табе свой
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('products', [
      'isActive',
      'isLoaded',
      'showNotifications',
      'showInfo',
      'showManagers',
      'showGreen',
      'bestProduct',
      'showAnalytics',
    ]),

    alignCards() {
      return this.isMobile ? 'd-flex flex-column align-center' : 'pa-0 pr-6 pb-6';
    },
  },
};
</script>
<style lang="scss" scoped>
.widget {
  width: 290px;
}
.mobile_widget {
  width: 100%;
}
</style>
