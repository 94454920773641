<template>
  <v-card height="100%" class="pa-6 elevation-0">
    <div class="font-weight-medium font--fs20 mb-5">Какие документы нужны?</div>
    <div class="description mb-2 font--lh20 font--fs16">
      Хотите продавать товары на маркетплейсах, но не знаете какие документы на товар может потребовать платформа?
    </div>
    <div class="description mb-6 font--lh20 font--fs16">
      Введите наименование товара и мы подскажем какие документы нужно оформить.
    </div>

    <v-card-text class="text-caption font-weight-medium font--graphite3 font--lh14 pa-0 mb-1"
      >Наименование товара</v-card-text
    >
    <v-autocomplete
      ref="autocomplete"
      v-model="selectedValue"
      :no-data-text="nodataText"
      dense
      :items="listDocs"
      loading="isLoading"
      :search-input.sync="search"
      clearable
      hide-details
      item-text="category"
      label="Введите наименование товара"
      outlined
      single-line
      return-object
      no-filter
      @click:clear="searchCertificate"
    >
      <template v-slot:item="{ item }">
        <v-card color="#f5f5f500" class="pa-2 d-flex align-center">{{ item.category }} </v-card>
      </template>
    </v-autocomplete>

    <v-btn class="mt-5" color="primary" @click="setIsDocs(true)">сформировать список </v-btn>

    <div class="font--fs16 mt-7 font-weight-medium">Необходимые документы</div>

    <div v-if="!docs || !isDocs" class="text-center mt-16 mb-4">
      <svg width="36" height="36" class="mb-3">
        <use xlink:href="#search"></use>
      </svg>
      <div class="description font--lh16 font--fs14">Укажите товар, чтобы получить список документов</div>
    </div>

    <div v-if="docs && isDocs" class="mt-5 mb-4">
      <div v-for="item of docs" :key="item">
        <div class="alert-app pa-4 mb-3">{{ item }}</div>
      </div>
    </div>
  </v-card>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'CertificateSearch',

  data: () => ({
    search: null,
    certificate: [],
  }),

  computed: {
    ...mapState('certificate', ['listDocs', 'isDocs', 'selectedDocs']),

    isLoading() {
      return !!this.$store.state.common.loading;
    },
    nodataText() {
      return this.search && this.search.length >= 3 ? 'Нет результатов' : 'Введите минимум 3 символа для поиска';
    },
    docs() {
      return this.listDocs.length === 1 ? this.listDocs[0].docs : null;
    },
    selectedValue: {
      get() {
        return this.selectedDocs;
      },
      set(value) {
        this.set({ type: 'selectedDocs', value });
      },
    },
  },

  watch: {
    search(current) {
      if (!current || current.length < 3 || (!!this.value && this.value === current)) return;
      this.debouncedSearch(this.search);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchCertificate, 500);
  },

  methods: {
    ...mapActions('certificate', ['searchCertification']),
    ...mapMutations('certificate', ['setIsDocs', 'set']),

    async searchCertificate(query) {
      await this.searchCertification({ query: query });
    },
  },
};
</script>

<style scoped>
.description {
  color: #777c87;
}
.alert-app {
  background: #f5f5f6;
}
</style>
