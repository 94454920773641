import { serverGate } from '../server-gate';

async function getOptionList() {
  const res = await serverGate.get('/mp/option/get');
  return res;
}

async function addOption(data) {
  const res = await serverGate.post('/mp/option/key', data);
  return res;
}

async function deleteOption(data) {
  const res = await serverGate.post('/mp/option/diskey', data);
  return res;
}

export { getOptionList, addOption, deleteOption };
