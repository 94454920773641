<template>
  <div>
    <v-card class="font pa-6">
      <div>
        <v-img width="85" src="img\not_working.png"></v-img>
      </div>
      <div>
        <v-card-text class="font--fs18 font-weight-medium font--lh24 font--black1"
          >Еще не{{ '\xa0' }}работаете с{{ '\xa0' }}маркетплейсами?</v-card-text
        >
        <v-card-text class="font font--lh21 text-subtitle-1"
          >Наша команда сертифицированных специалистов поможет вам начать работу!</v-card-text
        >
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn class="btn font-weight-bold" color="primary" outlined :href="chat" target="_blank"
            >Поговорить с поддержкой</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>
<script>
import { CHAT_URL } from '@/constants.js';
export default {
  name: 'WidgetSupport',
  data() {
    return {
      chat: CHAT_URL,
    };
  },
};
</script>

<style scoped>
.btn {
  border: 2px solid;
  font-size: 12px;
}
</style>
