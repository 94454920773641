import { TYPES } from '@/components/common/modul-grid/_utils/filters';

export const GRID_HEADERS_SUB_TABLE = [
  {
    text: 'Название',
    value: 'name',
    type: TYPES.STRING,
  },
  {
    text: 'Товаров',
    value: 'products',
    type: TYPES.NUMBER,
  },
  {
    text: 'Товаров со скидкой',
    value: 'productsPromo',
    type: TYPES.NUMBER,
  },
  {
    text: 'Комментарии',
    value: 'comments',
    type: TYPES.NUMBER,
    name: 'Количество отзывов',
  },
  {
    text: 'Рейтинг',
    value: 'rating',
    type: TYPES.NUMBER,
  },
  // в старых float

  {
    text: 'Выручка,\xa0₽',
    value: 'income',
    width: '100px',
    type: TYPES.NUMBER,
    name: 'Доход',
  },
  {
    text: 'Продажи',
    value: 'sales',
    type: TYPES.NUMBER,
  },
  {
    text: 'Ср.Цена,\xa0₽',
    value: 'priceAvg',
    subType: true,
  },
  {
    text: 'Cр.Выручка,\xa0₽',
    value: 'incomeAvg',
    subType: true,
  },
  {
    text: 'Кол-во продавцов',
    value: 'sellers',
    subType: true,
    categoryType: true,
  },
  {
    text: 'Кол-во брендов',
    value: 'brands',
    subType: true,
    categoryType: true,
  },
];

export const GRID_HEADERS_SUB_PRODUCT_LIST = [
  { text: 'Название', value: 'name', type: TYPES.STRING },
  { text: '', value: 'imagePreview', sortable: false },
  { text: 'SKU', value: 'extid', type: TYPES.STRING },
  { text: 'Продавец', value: 'seller', width: '150px', type: TYPES.STRING },
  { text: 'Бренд', value: 'brand', width: '150px', type: TYPES.STRING },
  { text: 'Страна', value: 'country' },
  // в старых фильтрах float
  { text: 'Цена,\xa0₽', value: 'price', hideCountry: true, type: TYPES.NUMBER },
  // в старых фильтрах float
  { text: 'Цена со скидкой,\xa0₽', value: 'priceWithSale', hideCountry: true, type: TYPES.NUMBER },
  { text: 'Количество отзывов', value: 'comments', hideCountry: true, type: TYPES.NUMBER },
  { text: 'Рейтинг', value: 'rating', hideCountry: true, type: TYPES.NUMBER },
  { text: 'Остаток', value: 'quantity', hideCountry: true, type: TYPES.NUMBER },
  { text: 'Продажи', value: 'sales', hideCountry: true, type: TYPES.NUMBER },
  // в старых фильтрах float
  { text: 'Выручка,\xa0₽', value: 'income', hideCountry: true, type: TYPES.NUMBER, name: 'Доход' },
  // в старых фильтрах float
  {
    text: 'Упущенная выручка,\xa0₽',
    value: 'incomeLost',
    description: 'LP',
    name: 'Упущенный доход',
    hideCountry: true,
    type: TYPES.NUMBER,
  },
  { text: 'График продаж', value: 'salesSchedule', sortable: false, width: '200px' },
];
