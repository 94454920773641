<template>
  <div>
    <SearchCountry v-model="countryModal" dense @cat:change="$emit('cat:change', $event)" />
  </div>
</template>
<script>
import SearchCountry from '@/components/metric/wb/country/SearchCountry';

export default {
  name: 'CountryMain',
  components: {
    SearchCountry,
  },

  data: () => ({
    countryModal: null,
  }),
  computed: {},

  watch: {
    countryModal(current) {
      if (current) {
        this.name = current.name;
        this.id = current.id;
      } else {
        this.name = '';
        this.id = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
