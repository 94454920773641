// Динамический модуль таба с гридом товаров каталога или категории
import { createGridModule } from './grid';
import * as gridMutations from '@/store/modules/metric/wb/mutations/grid';
import * as pagingMutations from '@/store/modules/metric/wb/mutations/paging';
import * as api from '@/api';
import { filter } from '@/mixins/filterDate.js';
import { downloadFile } from '@/store/helpers/download-file';
import Vue from 'vue';

/**
 * Создание модуля для списка товаров
 */
export function createCategoryModule({ cid, typeId, ...config } = {}) {
  return createGridModule({
    filter,
    mutations: {
      setFilterExport: (state, payload) => (state.filterExport = payload),
      setFileLoading: (state, payload) => Vue.set(state, 'fileLoading', payload),
    },
    getters: {
      filterExport: (state) => {
        const filterObj = {};
        if (state.filterExport) {
          filterObj.filter = state.filterExport.filter.filter;
          filterObj.page = state.filterExport.paging.page;
          filterObj.pageSize = state.filterExport.paging.pageSize;
          filterObj.search_query = state.filterExport.filter.search_query;
          filterObj.sort = state.filterExport.filter.sort;
          filterObj.sales = state.filterExport.filter.sales;
        }
        console.log('filterObj', filterObj);
        return filterObj;
      },
    },

    actions: {
      async getProductList({ commit, getters: { filter, search_query, pageSize, page, sort, sales } }) {
        if (search_query && search_query.length <= 2) {
          return;
        }

        const requestPayload = { filter, search_query, pageSize, page, sort, salesType: sales, marketplace: 'wb' };
        // категория, подкатегория
        if (typeId === 'cid') {
          requestPayload.cid = cid;
        }
        // продавцы
        if (typeId === 'sid') {
          requestPayload.sid = cid;
        }
        // бренды
        if (typeId === 'bid') {
          requestPayload.bid = cid;
        }

        // страна
        if (typeId === 'ctid') {
          requestPayload.ctid = cid;
        }
        // КЛЮЧЕВИКИ
        if (typeId === 'tid') {
          requestPayload.tid = cid;
        }

        requestPayload.marketplace = 'wb';

        const res = await api.getProductList(requestPayload);
        if (res) {
          commit(gridMutations.SET_GRID, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
        return res;
      },

      async getExportList({ commit, dispatch, getters: { filterExport } }, data) {
        commit('setFileLoading', true);

        const requestPayload = filterExport;
        // категория, подкатегория
        if (data.typeId === 'cid') {
          requestPayload.cid = data.cid;
        }
        // продавцы
        if (data.typeId === 'sid') {
          requestPayload.sid = data.cid;
        }
        // ключевики
        if (data.typeId === 'tid') {
          requestPayload.tid = data.cid;
        }
        // бренды
        if (data.typeId === 'bid') {
          requestPayload.bid = data.cid;
        }

        requestPayload.marketplace = 'wb';

        const res = await api.getExportListHidden(requestPayload);

        if (res.data.status === 'ok') {
          const fileid = res.data.response.fileid;
          // выгрузка файла
          const fileResponse = await api.fileGetHidden(fileid);

          if (fileResponse) {
            downloadFile(fileResponse);
          }
        } else if (res.data.status == 'err') {
          dispatch('common/addAlert', { type: 'error', msg: res.data.errdesc }, { root: true });
        }

        commit('setFileLoading', false);
      },

      // таб поиск товара
      async findProduct({ commit, getters: { search_query, pageSize, page, sort } }) {
        if (search_query && search_query.length <= 2) {
          return;
        }
        // TODO потестить тут нужнен marketplace или нет?
        const requestPayload = { search_query, pageSize, page, sort };

        const res = await api.getProductList(requestPayload);
        if (res) {
          commit(gridMutations.SET_GRID, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
      },
    },
    ...config,
  });
}
