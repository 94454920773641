import { IS_DEVELOPMENT } from '@/constants';
import { mapState } from 'vuex';

const analiticMixin = {
  data() {
    return {
      shouldLoadList: false,
    };
  },

  computed: {
    ...mapState('common', ['loading']),

    options: {
      get() {
        const page = this.$store.getters[`${this.productModule}/page`];
        const itemsPerPage = this.$store.getters[`${this.productModule}/pageSize`];
        const sort = this.$store.getters[`${this.productModule}/sort`];
        const sortBy = !sort ? [] : [sort[0].field];
        const sortDesc = !sort ? [] : [sort[0].dir === 'asc'];
        return {
          page,
          itemsPerPage,
          sortBy,
          sortDesc,
        };
      },
      set({ itemsPerPage, page, sortBy, sortDesc }) {
        const sortDir = sortDesc[0] === false ? 'desc' : 'asc';

        if (page) {
          this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE}`, page);
        }
        if (itemsPerPage) {
          this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE_SIZE}`, itemsPerPage);
        }
        if (sortBy && sortBy.length > 0) {
          this.$store.commit(`${this.productModule}/${this.filterMutations.SET_SORT}`, {
            field: sortBy[0],
            dir: sortDir,
          });
        }
      },
    },

    total() {
      return this.$store.getters[`${this.productModule}/total`];
    },

    grid() {
      return this.$store.getters[`${this.productModule}/grid`];
    },

    search: {
      get() {
        return this.$store.getters[`${this.productModule}/search_query`];
      },
      set(query) {
        this.$store.commit(`${this.productModule}/${this.filterMutations.SET_SEARCH_QUERY}`, query);
        this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE}`, 1);
        this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE_SIZE}`, 10);
        this.$store.commit(`${this.productModule}/${this.filterMutations.RESET_SORT}`);
        this.debouncedSearch(query);
      },
    },
  },

  methods: {
    onUpdateOptions(e) {
      if (IS_DEVELOPMENT) {
        console.warn('onUpdateOptions: ', e);
      }
      const currentSearch = this.$store.getters[`${this.productModule}/search_query`];

      if (e.sortBy.length === 0 && e.sortDesc.length === 0) {
        // Последняя проверка для предотвращения загрузки при первом открытии страницы поиска
        this.$store.commit(`${this.productModule}/${this.filterMutations.RESET_SORT}`);
      }

      // в поиске товара пустой запрос не кидать
      if (!currentSearch && this.productModule == ('category' || 'ozonCategory' || 'wbCategory')) return;

      if (IS_DEVELOPMENT) {
        console.log('=======from analiticMixin=====');
      }
      // при первой загрузке не вызывать
      if (this.shouldLoadList) {
        this.debouncedSearch();
      }
      this.shouldLoadList = true;
    },
  },
};

export { analiticMixin };
