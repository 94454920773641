import { serverGate } from '../server-gate';

async function getPackage() {
  const res = await serverGate.get('/mp/services/package');
  return res;
}

async function getOneTime() {
  const res = await serverGate.get('/mp/services/onetime');
  return res;
}

async function saveServices(data) {
  const res = await serverGate.post('/mp/services/save', data);
  return res;
}

export { getPackage, getOneTime, saveServices };
