import { createResetMutation } from '@/store/helpers/reset';
const initState = () => ({
  tabs: [],
});

export const ozonCategorySubTabs = {
  namespaced: true,
  state: initState,
  getters: {
    tabs: ({ tabs }) => tabs,
    hasTab:
      ({ tabs }) =>
      (id) =>
        tabs.some((tab) => tab.id === id),
  },
  mutations: {
    addTab: (state, tab) => state.tabs.push(tab),
    removeTab: (state, id) => (state.tabs = state.tabs.filter(({ id }) => id !== id)), // eslint-disable-line
    reset: createResetMutation(initState),
  },
};
