<template>
  <div class="brand-container">
    <v-col v-for="(item, index) in filterObjectsByName" :key="item.id + index" class="brand-col pa-0">
      <v-card class="brand-card d-flex flex-nowrap text-body-2 font--lh16" @click="onBrandClick(item)">
        <div>{{ item.name }}</div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'BrandTop',

  computed: {
    ...mapGetters('wbMetricTabs', ['hasTab']),
    ...mapGetters('wbBrand', ['brands']),

    filterObjectsByName() {
      return this.brands ? this.brands.filter((obj) => obj.name !== undefined && obj.name.trim() !== '') : [];
    },
  },

  created() {
    this.getTopBrand();
  },

  methods: {
    ...mapMutations('common', ['setMetricAdditional']),
    ...mapActions('wbMetricTabs', ['addTab']),
    ...mapActions('wbBrand', ['getTopBrand']),

    onBrandClick(item) {
      if (!this.hasTab(item.id)) {
        this.addTab({
          id: item.id,
          name: item.name,
          component: `BrandTabs`,
          action: `wbMetricModule/${item.id}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.id);
      }

      const moduleName = `wbMetricModule/${item.id}`;

      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'bid', cid: item.id }));
      }

      this.setMetricAdditional(true);
    },
  },
};
</script>

<style lang="scss">
.brand-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); // Adjust the min width as needed
  gap: 16px 20px; // 16px vertical and 20px horizontal gap
}

.brand-card {
  box-shadow: 0px 6px 16px 0px rgba(34, 39, 51, 0.12) !important;
  padding: 12px;
  display: flex;
  height: 100%;
  min-height: 64px;
  width: 100%;
}

@media (max-width: 648px) {
  .brand-container {
    grid-template-columns: repeat(2, 1fr); // 2 columns for small screens
  }

  .brand-card {
    border-radius: 4px !important;
    height: auto;
  }
}
</style>
