<template>
  <v-text-field
    :error-messages="msg"
    outlined
    dense
    class="ag-mask-text"
    :rules="localRules"
    v-bind="$props"
    v-on="$listeners"
  />
</template>

<script>
import { mask } from 'vue-the-mask';
import { VTextField } from 'vuetify/lib';

export default {
  name: 'MaskedTextField',
  props: {
    ...VTextField.options.props,
    pattern: RegExp,
    rules: Array,
  },
  directives: { mask },
  data() {
    return {
      localRules: [
        ...this.rules,
        (v) =>
          (!!v && v.split('').every((char) => /[А-Яа-яёЁ-]/.test(char))) ||
          this.label + ' может содержать только буквы кириллицы',
        (v) => (!!v && v.length <= 20) || this.label + ' не может содержать больше 20 букв',
      ],
      msg: '',
    };
  },
};
</script>

<style lang="scss">
.ag-mask-text.v-text-field .v-text-field__details {
  position: absolute;
  top: 44px;
}

.ag-mask-text > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
</style>
