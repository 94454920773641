<template>
  <v-card min-height="182px" class="font mx-auto pa-4" color="#F2F6FF">
    <v-list-item three-line class="pa-0">
      <v-list-item-content>
        <div>
          <svg width="49" height="40">
            <use xlink:href="#token"></use>
          </svg>
        </div>

        <v-list-item-title class="text-body-2 font-weight-medium mb-3"> Где найти токен в ОЗОН?</v-list-item-title>

        <span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 1</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              Зайдите в личный кабинет
              <span>
                <a target="_blank" href="https://seller.ozon.ru/app/main">ОЗОН</a>
              </span></v-card-text
            >
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 2</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1"> Выберите "Настройки > API-ключи" </v-card-text>
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 3</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              В ЛК ОЗОН выберите тип токена "Администратор", придумайте название ключа и нажмите "Создать"
            </v-card-text>
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 4</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              Скопируйте полученный ключ и вставьте его в поле "Токен" в ЛК банка
            </v-card-text>
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 5</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              Скопируйте код из поля "Client ID" и вставьте в ЛК Банка в поле "Клиент ID"
            </v-card-text>
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 6</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1"> Нажмите кнопку "Добавить" </v-card-text>
          </span>
        </span>
      </v-list-item-content>
    </v-list-item>

    <v-btn
      class="text-caption font-weight-bold"
      width="100%"
      outlined
      color="primary"
      style="border: 2px solid"
      :href="'https://modulbank.ru/fs/files/market_seller.pdf'"
      target="_blank"
    >
      Поговорить с поддержкой
    </v-btn>
  </v-card>
</template>
<script>
export default {
  name: 'OzonTokenInfo',
};
</script>
