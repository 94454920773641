import * as api from '@/api';
import { createGridModule } from '@/store/modules/analitic/features/grid';
import * as gridMutations from '@/store/modules/analitic/mutations/grid';
import * as pagingMutations from '@/store/modules/analitic/mutations/paging';
import { filter } from '@/mixins/filterDate.js';

export function createOzonWeeklyModule({ ...config } = {}) {
  return createGridModule({
    filter,
    state: {
      filtersData: {},
    },
    getters: {
      sdate: (state) => state.sdate,
      edate: (state) => state.edate,
    },

    mutations: {
      set: (state, payload) => (state[payload.type] = payload.value),
      setStartDate: (state, sdate) => (state.sdate = sdate),
      setEndDate: (state, edate) => (state.edate = edate),
      setFilters: (state, filtersData) => (state.filtersData = filtersData),
    },

    actions: {
      async ozonWeekly({ commit, getters: { filter, search_query, pageSize, page, sort } }) {
        if (search_query && search_query.length <= 2) {
          return;
        }
        let data = {
          filter,
          search_query,
          pageSize,
          page,
          sort,
          // marketplace: 'ozon',
        };
        const res = await api.ozonWeekly(data);

        if (res) {
          if (res.list) {
            let items = res.list.map((item) => {
              return {
                details: [],
                ...item,
              };
            });
            commit(gridMutations.SET_GRID, items);
            commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
            commit('setFilters', res.filters);
          }
          if (!res.list) {
            commit(gridMutations.SET_GRID, res.list);
            commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
            commit('setFilters', res.filters);
          }
        }
      },
    },
    ...config,
  });
}
