import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  brands: null,
});

export const wbBrand = {
  namespaced: true,
  state: initState,
  getters: {
    brands: (state) => state.brands,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setTopBrand: (state, brand) => (state.brands = brand),
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async getTopBrand({ commit }) {
      const res = await api.getTopBrand({ marketplace: 'wb' });
      if (res) {
        commit('setTopBrand', res);
      }
    },
  },
};
