<template>
  <div>
    <div class="d-flex flex-wrap justify-space-between mb-11 mt-6">
      <div class="mb-2">
        <modul-grid-filters
          ref="grid-filters"
          :module-name="`${productModule}`"
          :action="`${action}`"
          :columns="headers"
          :filters="true"
          :footer-props="footerProps"
          :fast-date="true"
        >
        </modul-grid-filters>
      </div>
      <v-text-field
        v-model="search"
        dense
        single-line
        hide-details
        height="44"
        class="mb-2"
        style="max-width: 400px"
        outlined
        placeholder="Введите номер продажи"
        clearable
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>
    <v-data-table
      :mobile-breakpoint="100"
      class="tape-table"
      :headers="headers"
      :items="grid"
      item-key="gnumber"
      :no-data-text="noDataText"
      fixed-header
      :items-per-page="50"
      :footer-props="footerProps"
      show-expand
      :server-items-length="total"
      :options.sync="options"
      @update:options="onUpdateOptions($event)"
      @item-expanded="loadDetails"
    >
      <template #[`item.gnumber`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>

      <template #[`item.dateSale`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
      </template>

      <template #[`item.forpay`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
      </template>

      <template #[`item.quantity`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
      </template>

      <template #[`item.sumPrice`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 0 !important">
          <TapeExpandTable :key="item.gnumber" :tape-list="item.details" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import { TYPES } from '@/components/common/modul-grid/_utils/filters';
import { analiticMixin } from '@/mixins/analiticMixin';
import { createWbTapeModule } from '@/store/modules/analitic/wb/finances/tape';

import { makeRub } from '@/mixins/makeRub';
import { format } from 'date-fns';
import TapeExpandTable from '@/components/analitic/wb/finances/tape/TapeExpandTable';
import * as api from '@/api';
import { mapState } from 'vuex';

export default {
  name: 'TapeTable',
  components: { TapeExpandTable, ModulGridFilters },
  inject: ['filterMutations', 'pagingMutations'],

  mixins: [makeRub, analiticMixin],
  props: {
    productModule: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        { text: 'Номер продажи', value: 'gnumber', width: '213px', type: TYPES.EQ_STRING },
        { text: 'Дата продажи', value: 'dateSale', width: '126px' },
        { text: 'Выплата', value: 'forpay', width: '126px', type: TYPES.NUMBER },
        { text: 'Количество', value: 'quantity', width: '126px', type: TYPES.NUMBER },
        { text: 'Выручка', value: 'sumPrice', width: '126px', type: TYPES.NUMBER },
      ],
    };
  },

  computed: {
    ...mapState('wbTapeFinances', ['filtersData']),
  },

  created() {
    if (!this.$store.hasModule(this.productModule)) {
      this.$store.registerModule(this.productModule, createWbTapeModule());
    }
    this.debouncedSearch = debounce(this.getTape, 800);
  },

  methods: {
    getTape() {
      this.$store.dispatch(`${this.action}`);
    },
    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },

    async loadDetails({ item }) {
      if (!Object.keys(item.details).length) {
        const res = await api.wbTapeDetails({ gNumber: item.gnumber });
        if (res) {
          item.details = res;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table.tape-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.theme--light.v-data-table.tape-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}
</style>
