<template>
  <v-dialog v-model="show" width="480">
    <v-card class="pa-8">
      <div class="d-flex justify-space-between pb-0">
        <p class="text-h6 font-weight-regular pa-0">Подтверждение</p>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <p class="text-subtitle-1">{{ text }}</p>
      <div class="mt-8">
        <v-btn color="primary" class="mr-2" @click="$emit('delete')">Удалить</v-btn>
        <v-btn text color="black" @click="show = false">Отмена</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,

    text: {
      type: String,
      required: true,
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
