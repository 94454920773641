<template>
  <div class="px-4">
    <SearchCountry v-model="countryModal" dense class="search-country" @cat:change="$emit('cat:change', $event)" />
  </div>
</template>

<script>
import SearchCountry from '@/components/metric/wb/country/SearchCountry';

export default {
  name: 'CountryMain',

  components: {
    SearchCountry,
  },

  data: () => ({
    countryModal: null,
  }),
};
</script>

<style lang="scss">
.search-country {
  width: 320px;
  background-color: white;

  @media (max-width: 960px) {
    width: 100%;
  }
}
</style>
