<template>
  <SearchSeller
    v-model="sellerModal"
    dense
    style="max-width: 320px"
    class="px-4"
    @cat:change="$emit('cat:change', $event)"
  />
</template>

<script>
import SearchSeller from '@/components/metric/wb/seller/SearchSeller';

export default {
  name: 'SellerMain',

  components: {
    SearchSeller,
  },

  data: () => ({
    sellerModal: null,
  }),

  watch: {
    sellerModal(current) {
      if (current) {
        this.name = current.name;
        this.id = current.id;
      } else {
        this.name = '';
        this.id = '';
      }
    },
  },
};
</script>
