<template>
  <div class="pa-6 font">
    <div class="text-h6 font--black1 mb-6">Добавить контрагента</div>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12">
          <CompanySearch
            v-model="company"
            hide-details
            placeholder="Наименование, ИНН или ОГРН контрагента"
            :rules="[rules.required('')]"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="inn"
            dense
            :rules="[rules.required('Введите ИНН'), rules.inn]"
            single-line
            outlined
            placeholder="ИНН"
          ></v-text-field
        ></v-col>

        <v-col cols="6">
          <v-text-field
            v-model="ogrn"
            dense
            :rules="[rules.required('Введите ОГРН'), rules.ogrn]"
            single-line
            outlined
            placeholder="ОГРН"
          ></v-text-field
        ></v-col>
      </v-row>
      <div style="float: right">
        <v-btn class="mt-6 mr-4" outlined @click="close">Отмена</v-btn>
        <v-btn class="mt-6" color="primary" @click="addContragent">Добавить</v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import { validateInn, validateOgrn } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import CompanySearch from '@/components/common/green/CompanySearch.vue';
import { getCadd } from '@/api';

export default {
  name: 'GreenLimitCreateContragent',
  components: {
    CompanySearch,
  },

  props: {
    closePanel: {
      type: Boolean,
    },
  },
  data() {
    return {
      inn: '',
      ogrn: '',
      company: null,
      rules: {
        required: (msg) => (v) => !!v || msg,
        inn: (v) => (!!v && !!validateInn(v)) || 'Введите корректный ИНН',
        ogrn: (v) => (!!v && !!validateOgrn(v)) || 'Введите корректный ОГРН',
      },
      valid: false,
    };
  },

  watch: {
    closePanel(v) {
      if (!v) {
        this.close();
      }
    },
    company(company) {
      if (company) {
        this.inn = company.inn;
        this.ogrn = company.ogrn;
      } else {
        this.inn = '';
        this.ogrn = '';
      }
    },
  },
  methods: {
    ...mapMutations('common', ['showGreenSidePanel']),
    ...mapMutations('green', ['setContragents']),

    // добавление контрагентов, отправка сразу в api
    async addContragent() {
      let valid = this.$refs.form.validate();

      if (valid) {
        const res = await getCadd(this.inn);

        if (res.contragents) {
          this.setContragents(res.contragents);
        }
        this.close();
      }
    },
    resetFields() {
      this.inn = '';
      this.ogrn = '';
      this.company = null;
    },
    close() {
      this.showGreenSidePanel(false);

      setTimeout(() => {
        this.resetFields();
        this.$refs.form.resetValidation();
      }, 200);
    },
  },
};
</script>

<style scoped></style>
