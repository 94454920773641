<template>
  <div style="width: 97%">
    <v-row v-if="inProgress || isDisabled || isSuccess">
      <v-col lg="6" md="6" sm="12" xs="12">
        <MomentMain v-if="isSuccess" />
        <MomentEmpty v-if="inProgress || isDisabled" />
      </v-col>
      <v-col lg="6" md="6" sm="12" xs="12">
        <InDevelopment :title="'Полученные выплаты'" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import InDevelopment from '@/components/common/InDevelopment';
import MomentEmpty from '@/components/moment/MomentEmpty';
import MomentMain from '@/components/moment/MomentMain';
import { mapGetters } from 'vuex';

export default {
  name: 'Moment',

  components: {
    InDevelopment,
    MomentEmpty,
    MomentMain,
  },

  data: () => ({
    empty: false,
    showInfoPopup: false,
  }),
  computed: {
    ...mapGetters('moment', ['isSuccess', 'inProgress', 'isDisabled']),
  },
  mounted() {
    this.$store.dispatch('moment/getMoment');
  },
  methods: {},
};
</script>
