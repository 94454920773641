<template>
  <div>
    <MetricTabs v-if="metricAdditional" />

    <Wb v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Wb from '@/components/metric/Wb.vue';
import MetricTabs from '@/components/mainVerticalTabs/MetricTabs.vue';

// import Ozon from '@/components/metric/Ozon.vue';

export default {
  name: 'Metric',

  components: {
    // Ozon,
    Wb,
    MetricTabs,
  },

  computed: {
    ...mapState('common', ['metricAdditional']),
  },

  mounted() {
    document.title = 'МаркетМетрика';
  },
};
</script>
<style>
.tab_act .v-tab--active {
  background-color: #d2ddfa !important;
}
.tabs .tabs_tab:nth-child(2) {
  margin-left: 0px;
}
</style>
