<template>
  <v-card class="pa-4">
    <div class="text-subtitle-2">Аналитика по моим магазинам</div>

    <v-img src="img/analytics.png" max-height="50" width="42" class="mx-auto mb-2 mt-6"></v-img>
    <div class="text-subtitle-2 font-weight-regular font--graphite3 text-center font--lh20 pb-12">
      Здесь может быть аналитика по вашему магазину, для этого нужно ввести продуктовый ключ
    </div>
    <v-card-actions class="justify-center"
      ><v-btn class="pa-0 widget__btn" absolute bottom outlined color="#464C59" @click="setTab()"
        ><span class="text-wrap">Добавить магазин</span></v-btn
      ></v-card-actions
    >
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'WidgetAnalyticsEmpty',
  computed: {
    ...mapGetters('common', ['isMobile']),
  },
  methods: {
    ...mapMutations('common', ['setVerticalTab', 'setMobileMainTab']),
    setTab() {
      this.isMobile ? this.setMobileMainTab('tab-0') : this.setVerticalTab(5);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget__btn.v-btn.v-size--default {
  font-size: 12px !important;
  min-width: 90%;
}
.widget__btn >>> span {
  width: 100%;
}
</style>
