<template>
  <v-autocomplete
    ref="autocomplete"
    :no-data-text="nodataText"
    :value="value"
    :items="brands"
    :loading="isLoading"
    :search-input.sync="search"
    :dense="dense"
    clearable
    hide-details
    :item-text="itemText"
    label="Введите название бренда"
    outlined
    single-line
    return-object
    no-filter
    append-icon=""
    class="brand-search"
    @input="$emit('input', $event)"
    @click:clear="clearSearchResult"
  >
    <template v-slot:item="{ item }">
      <v-btn color="#f5f5f500" @click="createBrandModule(item)">
        {{ item.name
        }}<v-img v-if="item.logo" max-height="50" max-width="50" :src="`http:${item.logo}`" class="ml-3"></v-img>
      </v-btn>
    </template>
    <template v-slot:prepend-inner="">
      <svg width="20" height="20" fill="#717D83" class="mr-1 mt-1">
        <use xlink:href="#search"></use>
      </svg>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce';
import { searchBrand } from '@/api/metric/brand';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';

export default {
  name: 'SearchBrand',

  props: {
    value: {
      type: Object,
      default: null,
    },
    dense: Boolean,
  },

  data: () => ({
    search: null,
    brands: [],
  }),

  computed: {
    ...mapGetters('wbMetricTabs', ['hasTab']),

    isLoading() {
      return !!this.$store.state.common.loading;
    },

    nodataText() {
      return this.search && this.search.length >= 2 ? 'Нет результатов' : 'Введите минимум 2 символа для поиска';
    },
  },

  watch: {
    search(current) {
      if (!current || current.length < 2 || (!!this.value && this.itemText(this.value) === current)) return;
      this.debouncedSearch(this.search);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchBrand, 500);
  },

  methods: {
    ...mapMutations('products', ['setBrand', 'setMetricAdditional']),
    ...mapActions('wbMetricTabs', ['addTab']),

    itemText(item) {
      return item.name;
    },

    async searchBrand(query) {
      const res = await searchBrand({ search_query: query, marketplace: 'wb' });

      if (res.length) {
        this.brands = res;
      } else {
        this.brands = [];
      }
    },

    // регистрация/создание модуля по выбраному бренду
    createBrandModule(item) {
      this.$refs.autocomplete.blur();
      this.clearSearchResult();

      if (item?.id) {
        if (!this.hasTab(item.id)) {
          this.addTab({
            id: item.id,
            name: item.name,
            component: 'BrandTabs',
            action: `wbMetricModule/${item.id}/getProductList`,
          });
        } else {
          this.$emit('cat:change', item.id);
        }

        const moduleName = `wbMetricModule/${item.id}`;

        if (!this.$store.hasModule(moduleName)) {
          this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'bid', cid: item.id }));
        }

        this.setMetricAdditional(true);
      }
    },

    clearSearchResult() {
      this.brands = [];
    },
  },
};
</script>

<style lang="scss">
.brand-search .v-label {
  top: 12px !important;
}

.brand-search .v-input__slot {
  height: 44px;
}
</style>
