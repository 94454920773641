import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  statuses: [],
  ozonStatus: null,
  wbStatus: null,
});

export const analitic = {
  namespaced: true,
  state: initState,
  getters: {
    isSuccessOzon: (state) => state.ozonStatus?.worked,
    isSuccessWb: (state) => state.wbStatus?.worked,

    isLoaded: (state) => state.statuses.length,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setStatuses: (state, value) => {
      state.statuses = value;
    },
    setOzon: (state, value) => {
      state.ozonStatus = value;
    },
    setWb: (state, value) => {
      state.wbStatus = value;
    },
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async getAnalitic({ commit }) {
      const res = await api.getAnalitic();
      if (res) {
        commit('setStatuses', res.keys);
        commit(
          'setOzon',
          res.keys.find((status) => status.type === 'ozon')
        );
        commit(
          'setWb',
          res.keys.find((status) => status.type === 'wb')
        );
      }
    },
  },
};
