import { serverWhite } from '../server-white';

/**
 * Бренд
 * @param {string} search_query
 * @returns {Promise<SellerSearchResponse>}
 */

async function searchSeller(data) {
  let res = await serverWhite.post('/admin/mp/seller/get', data);
  return res;
}

/**
 * Список товаров
 * @param {Object} data
 * @param {string} data.page
 * @param {string} data.search_query
 * @param {string} data.pageSize
 * @param {Array} data.filter
 * @returns {Promise<SellerResponse>}
 */
async function getSellerSubTab(data) {
  const res = await serverWhite.post('/admin/mp/metrics/seller', data);
  return res;
}

export { searchSeller, getSellerSubTab };
