import { serverGate } from '../server-gate';

async function getGreen() {
  const res = await serverGate.get('mp/greenlimit/get');
  return res;
}

async function searchGreenLimit(q) {
  const res = await serverGate.post('mp/greenlimit/search', { q });
  return res;
}

async function getCadd(inn) {
  const res = await serverGate.post('mp/greenlimit/cadd', { inn });
  return res;
}

export { getGreen, searchGreenLimit, getCadd };
