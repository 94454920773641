// Модуль пагинации

import * as pagingMutations from '@/store/modules/analitic/mutations/paging';

const DEFAULT_CONFIG = {
  page: 1,
  pageSize: 50,
  total: null,
};

/**
 * Создание модуля пагинации
 *
 * @param {Object} config
 * @param {Number} config.page номер страницы
 * @param {Number} config.pageSize размер страницы
 */
export function createPagingModule(config = {}) {
  const initState = () => ({
    ...DEFAULT_CONFIG,
    ...config,
  });

  return {
    state: initState,
    getters: {
      page: ({ page }) => page,
      pageSize: ({ pageSize }) => pageSize,
      total: ({ total }) => total,
    },
    mutations: {
      [pagingMutations.SET_PAGE]: (state, payload) => (state.page = payload),
      [pagingMutations.SET_PAGE_SIZE]: (state, payload) => (state.pageSize = payload),
      [pagingMutations.SET_TOTAL]: (state, payload) => (state.total = payload),
      [pagingMutations.RESET_PAGING]: (state) => {
        state.page = DEFAULT_CONFIG.page;
        state.pageSize = DEFAULT_CONFIG.pageSize;
        state.total = DEFAULT_CONFIG.total;
        // FIXME не отрабатывает
        // state = initState();
      },
    },
  };
}
