<template>
  <div v-if="list">
    <v-card
      v-for="item in list"
      :key="item.id"
      min-height="72px"
      class="font d-flex justify-md-space-between flex-wrap pa-4 mb-2 align-center"
      @click="openSidePanel(item.id)"
    >
      <div class="d-flex flex-column mr-2">
        <p
          class="mr-1 ma-0 mb-2"
          :class="
            getStatusColor(item.state) == 'rejection'
              ? 'font--graphite3 text-body-2'
              : 'text-subtitle-1 font--lh24 font--black1'
          "
        >
          <span class="mr-2">{{ item.name }}</span>
          <StatusChip :value="getStatusColor(item.state) || item.state" class="px-2" />
        </p>
        <div v-if="item.inn" class="text-subtitle-1 font--graphite3 mr-2">ИНН {{ item.inn }}</div>
      </div>
      <div v-if="item.money" class="font-weight-medium mr-2">
        {{ makeRub(item.money) }}
      </div>
      <div
        v-if="item.payment"
        class="font-weight-bold text-caption font--primary pa-3"
        style="background-color: #f2f6ff; border-radius: 4px"
      >
        <svg width="18" height="18" style="fill: #4971d0">
          <use xlink:href="#refill"></use>
        </svg>
        {{ item.payment.toUpperCase() }}
      </div>
      <div>
        <v-icon v-if="item.comment" color="#4971D0">mdi-comment-processing-outline</v-icon>

        <v-tooltip v-if="item.req === 1" left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">mdi-alert-circle-outline</v-icon>
          </template>
          <span>Есть незаполненные реквизиты</span>
        </v-tooltip>
      </div>
    </v-card>
  </div>
</template>

<script>
import StatusChip from '@/components/common/StatusChip';
import { makeRub } from '@/mixins/makeRub';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'CardList',
  components: { StatusChip },
  mixins: [makeRub],

  props: {
    list: {
      type: Array,
    },
    supplier: {
      type: Boolean,
    },
  },
  data() {
    return {
      statusColor: {
        одобрен: 'approved',
        'на проверке': 'check',
        отказ: 'rejection',
        добавлен: 'added',
      },
    };
  },
  methods: {
    ...mapActions('credit', ['editSupplier']),
    ...mapMutations('credit', ['setSid']),
    getStatusColor(status) {
      return this.statusColor[status.toLowerCase()];
    },
    openSidePanel(id) {
      if (this.supplier) {
        this.editSupplier(id);
        this.setSid(id);
      }
    },
  },
};
</script>

<style scoped></style>
