<template>
  <v-row class="align-center font">
    <v-col cols="2">
      <v-img width="100%" :src="product_card.imagePreview" style="cursor: pointer" @click="dialog = true"></v-img
    ></v-col>

    <v-dialog v-model="dialog" class="image" width="unset">
      <v-card>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon color="#464C59">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <span><img style="height: 80vh" :src="`${product_card.imageZoom}`" /></span>
      </v-card>
    </v-dialog>

    <v-col v-if="product_card" cols="12" xs="12" sm="10" md="10" lg="10" class="d-flex">
      <v-row dense>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
          <v-card style="background-color: #f2f6ff; border-radius: 2px; height: 100%" class="pa-3 mr-4" flat>
            <div class="font-weight-bold mb-5">Описание товара</div>
            <div v-if="product_card.extid != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">SKU</div>
              <a
                target="_blank"
                :href="`${product_card.extlink}`"
                class="text-decoration-none text-body-2 font-weight-bold"
                >{{ product_card.extid }}</a
              >
            </div>

            <div v-if="product_card.brand != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Бренд</div>
              <a
                class="text-body-2 font-weight-bold"
                style="cursor: pointer; text-align: right"
                @click="closeSlideCardBrand"
                >{{ product_card.brand }}</a
              >
            </div>

            <div v-if="product_card.seller != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled mr-2">Продавец</div>
              <a
                class="text-body-2 font-weight-bold"
                style="cursor: pointer; text-align: right"
                @click="closeSlideCardSeller"
                >{{ product_card.seller }}</a
              >
            </div>

            <div v-if="product_card.country != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Страна</div>
              <a class="text-body-2 font-weight-bold" style="cursor: pointer" @click="closeSlideCardCountry">{{
                product_card.country
              }}</a>
            </div>

            <div v-if="product_card.price != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Цена</div>
              <div class="text-body-2 font-weight-bold">{{ makeRub(product_card.price) }}</div>
            </div>

            <div v-if="product_card.priceWithSale != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex font text-caption font-weight-medium text-uppercase text--disabled">
                Цена со скидкой
              </div>
              <div class="text-body-2 font-weight-bold">{{ makeRub(product_card.priceWithSale) }}</div>
            </div>

            <div v-if="product_card.rating != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Рейтинг</div>
              <div class="text-body-2 font-weight-bold">{{ product_card.rating }}</div>
            </div>

            <div v-if="product_card.comments != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Комментарии</div>
              <div class="text-body-2 font-weight-bold">{{ product_card.comments.toLocaleString() }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
          <v-card style="background-color: #f2f6ff; border-radius: 2px; height: 100%" class="pa-3" flat>
            <div class="font-weight-bold mb-5">Метрика</div>

            <div v-if="product_card.dadd != null" cols="12" class="d-flex justify-space-between mb-2">
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Впервые добавлен</div>
              <span class="text-body-2 font-weight-bold">{{
                new Date(product_card.dadd).toLocaleDateString('ru-RU')
              }}</span>
            </div>

            <div
              v-if="product_card.metrics && product_card.metrics.quantity != null"
              cols="12"
              class="d-flex justify-space-between mb-2"
            >
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Остаток</div>
              <div class="text-body-2 font-weight-bold">{{ product_card.metrics.quantity.toLocaleString() }}</div>
            </div>

            <div
              v-if="product_card.metrics && product_card.metrics.sales != null"
              cols="12"
              class="d-flex justify-space-between mb-2"
            >
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Продажи</div>
              <div class="text-body-2 font-weight-bold">{{ product_card.metrics.sales.toLocaleString() }}</div>
            </div>

            <div
              v-if="product_card.metrics && product_card.metrics.income != null"
              cols="12"
              class="d-flex justify-space-between mb-2"
            >
              <div class="d-flex text-caption font-weight-medium text-uppercase text--disabled">Выручка</div>
              <div class="text-body-2 font-weight-bold">{{ makeRub(product_card.metrics.income) }}</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';

export default {
  name: 'ProductDescription',

  mixins: [makeRub],
  props: {
    product_card: Object, // eslint-disable-line
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters('wbMetricTabs', ['hasTab']),
  },

  methods: {
    ...mapActions('wbMetricTabs', ['addTab']),
    ...mapMutations('common', ['showCardWbModal']),
    ...mapActions('wbProductCard', ['isCardEditing']),
    ...mapMutations('common', ['setMetricAdditional']),

    closeSlideCardSeller() {
      this.onSellerClick(this.product_card);
      this.showCardWbModal(false);
      this.isCardEditing();
    },

    closeSlideCardBrand() {
      this.onBrandClick(this.product_card);
      this.showCardWbModal(false);
      this.isCardEditing();
    },

    closeSlideCardCountry() {
      this.onCountryClick(this.product_card);
      this.showCardWbModal(false);
      this.isCardEditing();
    },

    // регистрация/создание нового модуля. таб бренды
    onBrandClick(item) {
      if (!this.hasTab(item.bid)) {
        this.addTab({
          id: item.bid,
          name: item.brand,
          component: `BrandTabs`,
          action: `wbMetricModule/${item.bid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.bid);
      }

      const moduleName = `wbMetricModule/${item.bid}`;

      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'bid', cid: item.bid }));
      }

      this.setMetricAdditional(true);
    },

    // регистрация/создание нового модуля. таб продавцы
    onSellerClick(item) {
      if (!this.hasTab(item.sid)) {
        this.addTab({
          id: item.sid,
          name: item.seller,
          component: `SellerTabs`,
          action: `wbMetricModule/${item.sid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.sid);
      }

      const moduleName = `wbMetricModule/${item.sid}`;

      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'sid', cid: item.sid }));
      }

      this.setMetricAdditional(true);
    },

    // регистрация/создание нового модуля. таб страна
    onCountryClick(item) {
      if (!this.hasTab(item.ctid)) {
        this.addTab({
          id: item.ctid,
          name: item.country,
          component: `Country`,
          action: `wbMetricModule/${item.ctid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.ctid);
      }

      const moduleName = `wbMetricModule/${item.ctid}`;

      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'ctid', cid: item.ctid }));
      }

      this.setMetricAdditional(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
