<template>
  <div>
    <modul-date-filter module-name="wbAnaliticInfo" @update:date-filter="updateDateFilter" />
    <InfoCards />
    <MainInfoGraph :info_graph="info_graph" class="py-4" />
    <!-- <SalesGraph :value="product_graph"></SalesGraph> -->
  </div>
</template>

<script>
import InfoCards from '@/components/analitic/wb/info/InfoCards';
import MainInfoGraph from '@/components/analitic/wb/info/MainInfoGraph';
// import SalesGraph from '@/components/analitic/wb/info/SalesGraph';
import { mapActions, mapMutations, mapState } from 'vuex';
import ModulDateFilter from '@/components/common/modul-grid/components/modul-date-filter';

export default {
  name: 'InfoTab',
  components: {
    InfoCards,
    ModulDateFilter,
    MainInfoGraph,
    // SalesGraph,
  },
  computed: {
    ...mapState('wbAnaliticInfo', ['analitic']),

    // product_graph() {
    //   return this.analitic?.graph;
    // },
    info_graph() {
      return this.analitic && this.analitic != true ? this.analitic : null;
    },
  },

  methods: {
    ...mapMutations('wbAnaliticInfo', ['setStartDate', 'setEndDate']),
    ...mapActions('wbAnaliticInfo', ['wbAnalitic']),

    updateDateFilter(dates) {
      this.setStartDate(dates.value[0]);
      this.setEndDate(dates.value[1]);
      this.$store.dispatch('wbAnaliticInfo/wbAnalitic');
    },
  },
};
</script>

<style lang="scss" scoped></style>
