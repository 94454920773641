import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  packageList: [],
  oneTimeList: [],
  clear: false,
});

export const services = {
  namespaced: true,
  state: initState,
  getters: {
    showServiceTable: (state) => state.oneTimeList.length,
    showPackList: (state) => state.packageList.length,
    clear: (state) => state.clear,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setPackage: (state, value) => {
      state.packageList = value;
    },
    setOneTime: (state, value) => {
      state.oneTimeList = value;
    },
    resetTable: (state, value) => {
      state.clear = value;
    },
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async getPackage({ commit }) {
      const res = await api.getPackage();
      if (res) {
        commit('setPackage', res);
      }
    },

    async getOneTime({ commit }) {
      const res = await api.getOneTime();
      if (res) {
        commit('setOneTime', res);
      }
    },
  },
};
