import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  isCertificate: false,
  brif_good_name: '',
  brif_buy_good_name: '',
  brif_tnved: '',
  brif_good_material: '',
  brif_short_desc_link: '',
  listDocs: [],
  isDocs: false,
  selectedDocs: null,
});

export const certificate = {
  namespaced: true,
  state: initState,
  getters: {},
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setCertificate: (state, payload) => (state.isCertificate = payload),
    setListDoc: (state, payload) => (state.listDocs = payload),
    setIsDocs: (state, payload) => (state.isDocs = payload),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    clearNewBid({ commit }) {
      commit('set', { type: 'brif_good_name', value: '' });
      commit('set', { type: 'brif_buy_good_name', value: '' });
      commit('set', { type: 'brif_tnved', value: '' });
      commit('set', { type: 'brif_good_material', value: '' });
      commit('set', { type: 'brif_short_desc_link', value: '' });
      commit('set', { type: 'selectedDocs', value: null });
    },

    async createCertificate({
      state: { brif_good_name, brif_buy_good_name, brif_tnved, brif_good_material, brif_short_desc_link },
      commit,
      dispatch,
    }) {
      const data = {
        brif_good_name,
        brif_buy_good_name,
        brif_tnved,
        brif_good_material,
        brif_short_desc_link,
      };
      const res = await api.createCertification(data);

      if (res) {
        dispatch('common/addAlert', { type: 'success', msg: 'Заявка прошла успешно!' }, { root: true });
        commit('setCertificate', false);
        commit('setIsDocs', false);
        commit('setListDoc', []);
        dispatch('clearNewBid');
      }
    },

    async searchCertification({ commit }, query) {
      const res = await api.searchCertification(query);

      if (res.length > 0) {
        commit('setListDoc', res);
      } else {
        commit('setIsDocs', false);
        commit('setListDoc', []);
      }
      return res;
    },
  },
};
