<template>
  <v-card min-height="182px" class="font mx-auto pa-4" color="#F2F6FF">
    <v-list-item three-line class="pa-0">
      <v-list-item-content>
        <div>
          <svg width="49" height="40">
            <use xlink:href="#token"></use>
          </svg>
        </div>

        <v-list-item-title class="text-body-2 font-weight-medium mb-3"> Что такое токен?</v-list-item-title>
        <div class="text-body-2 font--lh21">
          Токен - это электронный «ключ», позволяющий получить данные о продажах на маркетплейсах. Он нужен для расчета
          кредитного лимита и аналитики ваших товаров.
        </div>

        <div class="text-body-2 font-weight-medium font--lh21 my-4">
          Выберите маркетплейс из списка и следуйте инструкции по установке токена.
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-btn
      class="text-caption font-weight-bold"
      width="100%"
      outlined
      color="primary"
      style="border: 2px solid"
      :href="chat"
      target="_blank"
    >
      Поговорить с поддержкой
    </v-btn>
  </v-card>
</template>
<script>
import { CHAT_URL } from '@/constants.js';

export default {
  name: 'TokenInfo',

  data() {
    return {
      chat: CHAT_URL,
    };
  },
};
</script>
