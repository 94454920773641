import { createTabsModule } from './features/tabs';

export const wbMainTabs = createTabsModule({
  state: {
    tabs: [
      { name: 'Категории', component: 'CatalogMain', action: 'wbCatalog/catalogList' },
      {
        name: 'Продавцы',
        component: 'SellerMain',
      },
      {
        name: 'Бренды',
        component: 'BrandMain',
      },
      {
        name: 'Поиск по стране',
        component: 'CountryMain',
      },
      {
        name: 'Поиск по SKU',
        component: 'SearchSKUMain',
      },
      {
        name: 'Ключевики',
        component: 'TagList',
      },
    ],

    tab: 0,
  },
});
