import { serverGate } from '../server-gate';

// создание заявки
async function createCertification(data) {
  const res = await serverGate.post('/mp/certification/request', data);
  return res;
}

// поиск по полю "Наименование товара"
async function searchCertification(query) {
  const res = await serverGate.post('/mp/certification/list', query);
  return res;
}

export { createCertification, searchCertification };
