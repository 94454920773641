<template>
  <div>
    <h2 class="text-h5 font--lh28 mt-2 mb-6">Лимиты на вывод выручки</h2>

    <v-row class="d-flex flex-wrap">
      <v-col md="5" lg="4" sm="12">
        <v-card min-height="308px" class="font mx-auto px-4 py-6">
          <WidgetGreenLimit
            :limit-cta="limitCta"
            :limit-mp="limitMp"
            :limit-nal="limitNal"
            :rate-nal="rateNal"
            :rate-mp="rateMp"
            :rate-cta="rateCta"
            class="mb-4"
          />
        </v-card>
      </v-col>
      <v-col md="4" lg="4" sm="12"><GreenLimitList class="mb-4" /> </v-col>
      <v-col md="3" lg="4" sm="12">
        <GreenLimitInfo />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WidgetGreenLimit from '@/components/common/WidgetGreenLimit';
import GreenLimitInfo from '@/components/common/cards/GreenLimitInfo';

import GreenLimitList from '@/components/greenLimit/GreenLimitList';
import { mapState } from 'vuex';

export default {
  name: 'GreenLimitMain',

  components: {
    GreenLimitList,
    WidgetGreenLimit,
    GreenLimitInfo,
  },
  computed: {
    ...mapState('green', ['limitCta', 'limitMp', 'limitNal', 'rateNal', 'rateMp', 'rateCta']),
  },
  methods: {},
};
</script>

<style scoped></style>
