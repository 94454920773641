<template>
  <div class="font mx-auto">
    <div class="font--black1 font-weight-medium mb-4">Ваши контрагенты</div>
    <v-btn
      outlined
      class="text-caption font-weight-bold mb-4 pr-7"
      style="border: 2px solid"
      color="primary"
      @click="showGreenSidePanel(true)"
      >Добавь контрагента</v-btn
    >

    <CardList :list="contragents" />
  </div>
</template>

<script>
import CardList from '@/components/common/CardList';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'GreenLimitList',

  components: { CardList },

  computed: {
    ...mapState('green', ['contragents']),
  },
  methods: {
    ...mapMutations('common', ['showGreenSidePanel']),
  },
};
</script>

<style scoped></style>
