<template>
  <div ref="containter">
    <v-autocomplete
      v-model="company"
      no-filter
      class="mb-2"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :items="searchResult"
      :loading="isLoading"
      :search-input.sync="query"
      clearable
      hide-details
      item-text="name"
      return-object
      outlined
      prepend-inner-icon="mdi-magnify"
      :filter="filterCompany"
      :menu-props="{ maxWidth: '600px' }"
      dense
    >
      <template v-slot:no-data>
        <div v-if="!query || query.length < 2" class="pa-4">
          <p class="text-center">Введите минимум два символа для поиска</p>
        </div>
        <div v-else class="pa-4">
          <p class="text-center">Мы старались, но не нашли вашу организацию ☹️</p>
          <div class="text-center"></div>
        </div>
      </template>

      <template v-slot:item="{ item }">
        <div class="search-result">
          <h4 class="font text-body-2 font-lh16 mb-2">{{ item.name }}</h4>
          <p class="font text-caption font--gray7">
            ИНН: {{ item.inn }}, ОГРН: {{ item.ogrn }}
            <br />
            <template>
              Адрес: {{ item.address }}
              <br />
            </template>
            Дата регистрации: {{ new Date(item.dt_create).toLocaleDateString() }}
          </p>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { searchGreenLimit } from '@/api';

export default {
  props: {
    label: String,
    placeholder: String,
    rules: Array,
    value: Object,
  },
  data: () => ({
    query: null,
    searchResult: [],
  }),

  computed: {
    company: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    isLoading() {
      return !!this.$store.state.common.loading;
    },
  },

  watch: {
    query(current) {
      if (!current || current.length < 2 || (this.company && current === this.company.name)) return;
      this.debouncedSearch(this.query);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchCompany, 500);
  },

  mounted() {
    if (this.value) {
      this.searchResult = [this.value];
    }
  },

  methods: {
    filterCompany(item, queryText) {
      const q = queryText.toLowerCase();
      return (
        item.name.toLowerCase().includes(q) || item.inn.toLowerCase().includes(q) || item.ogrn.toLowerCase().includes(q)
      );
    },

    async searchCompany(query) {
      try {
        const response = await searchGreenLimit(query);
        if (response) {
          if (response.company && Array.isArray(response.company)) {
            this.searchResult = response.company;
          } else {
            this.searchResult = [];
          }
        }
      } catch (e) {
        null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.choosen {
  background-color: #f5f5f6;
}
</style>
