import { isMultiple, isTextField, canHaveRange } from './filterConditions';
import { LOGIC_OPERATOR_VALUES, NUMBER_OPERATOR_VALUES } from '../_const/filterConstants';

/*
 * rawFilterFactory - makes a filter that is lightweight and easy to render
 * gets object with {type, text (column header), value (column value), options (if enum or multiple)},
 * range with fields (min and max) if slider and defaultOperator if one-side filter (not more or not less);
 * returns object
 *
 * */

const rawFilterFactory = ({ type, text, name, value: column, rangeLimits, defaultOperator }) => {
  let operator = undefined;
  if (rangeLimits || defaultOperator) {
    operator = defaultOperator || NUMBER_OPERATOR_VALUES.GREATER_OR_EQUALS;
  }
  const rangeActive = !!rangeLimits;
  const rangeOperator = rangeLimits ? NUMBER_OPERATOR_VALUES.LESS_OR_EQUALS : undefined;
  const value = rangeLimits?.min || undefined;
  const rangeValue = rangeLimits?.max || undefined;
  const filter = {
    type, // тип фильтра
    name, // имя фильтра
    text, // текст колонки
    column, // значение колонки
    active: false, // включен ли фильтр
    value, // основное значения фильтра
    // ...(isSelect(type) && { options }), // Опции для селектов/
    // Опции выносятся в отдельные поля стора
    ...(isMultiple(type) && { not: false }), // включен ли реверс фильтра
    ...(isTextField(type) && { operator }), // оператор (больше/меньше/равно...)
    ...(canHaveRange(type, defaultOperator) && {
      range: {
        // для фильтров типа number существует возможность задать диапазон,
        active: rangeActive, // включен ли диапазон
        value: rangeValue, // второе значение диапазона
        operator: rangeOperator, // оператор (больше/меньше/равно...)
        logic: LOGIC_OPERATOR_VALUES.AND, // логика (и/или)
      },
    }),
    ...(rangeLimits && { rangeLimits }), // ограничения сверху/снизу для слайдера
  };
  return filter;
};

export default rawFilterFactory;
