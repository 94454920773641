// import * as api from '@/api/user.js';

const initState = () => ({
  newphone: '',
  process: 'init',
  smsid: '',
  code: '',
  showPhone: false,
});

export const user = {
  namespaced: true,
  state: initState,
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    reset: (state) => {
      const initialState = initState();
      Object.keys(initialState).forEach((key) => (state[key] = initialState[key]));
    },
  },
  actions: {
    // Вызывается 2 раза:
    // 1) Когда введет пароль и новый телефон по нажатию на кнопку сохранить
    // 2) Когда введен код из смс (тест 1488) по вводу последнего символа
    // async changeLogin({ state, commit }) {
    //   const res = await api.changeLogin(state);
    //   if (res) {
    //     if (state.process === 'init' && res.smsid) {
    //       commit('set', { type: 'process', value: 'apply' });
    //       commit('set', { type: 'smsid', value: res.smsid });
    //     } else {
    //       commit('profile/set', { type: 'phone', value: state.newphone }, { root: true });
    //       commit('set', { type: 'showPhone', value: false });
    //       commit('set', { type: 'process', value: 'init' });
    //       commit('set', { type: 'pass', value: '' });
    //       commit('set', { type: 'newphone', value: '' });
    //       commit('set', { type: 'smsid', value: '' });
    //       commit('set', { type: 'code', value: '' });
    //     }
    //   }
    // },
    resetPhoneChange({ commit }) {
      commit('set', { type: 'showPhone', value: false });
      commit('set', { type: 'process', value: 'init' });
      commit('set', { type: 'pass', value: '' });
      commit('set', { type: 'newphone', value: '' });
      commit('set', { type: 'smsid', value: '' });
      commit('set', { type: 'code', value: '' });
    },
  },
};
