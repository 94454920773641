<template>
  <v-autocomplete
    ref="autocomplete"
    :no-data-text="nodataText"
    :value="value"
    :dense="dense"
    :items="skuItems"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-details
    :item-text="itemText"
    label="Введите SKU или ссылку на товар"
    outlined
    single-line
    return-object
    no-filter
    class="sku-search"
    append-icon=""
    @input="$emit('input', $event)"
    @click:clear="clearSearchResult"
  >
    <template v-slot:item="{ item }">
      <v-card color="#f5f5f500" class="pa-2 d-flex align-center" @click="openCard"
        >{{ item.name }} <img style="width: 7vh" class="ml-2" :src="item.imageZoom"
      /></v-card>
    </template>
    <template v-if="icon" v-slot:prepend-inner="">
      <svg width="20" height="20" fill="#717D83" class="mr-1 mt-1">
        <use xlink:href="#search"></use>
      </svg>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce';
import { getProductSKU } from '@/api/metric/category';
import { mapActions, mapState } from 'vuex';
import { today, thirtyDaysBefore } from '@/mixins/filterDate';

export default {
  name: 'SearchSKU',

  mixins: [today, thirtyDaysBefore],

  props: {
    value: {
      type: Object,
      default: null,
    },
    dense: Boolean,
    icon: Boolean,
    selectedTab: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    search: null,
    skuItems: [],
  }),

  computed: {
    ...mapState('wbProductCard', ['product']),

    isLoading() {
      return !!this.$store.state.common.loading;
    },

    nodataText() {
      return this.search && this.search.length >= 2 ? 'Нет результатов' : 'Введите минимум 2 символа для поиска';
    },
  },

  watch: {
    search(current) {
      if (current != null && current.length == 0) {
        this.clearSearchResult();
      }
      if (!current || current.length < 2 || (!!this.value && this.itemText(this.value) === current)) return;
      this.debouncedSearch(this.search);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchSKU, 500);
  },

  methods: {
    ...mapActions('common', ['showCardWbModal']),

    openCard() {
      this.clearSearchResult();
      this.$refs.autocomplete.blur();
      this.$store.commit('wbProductCard/setStartDate', thirtyDaysBefore);
      this.$store.commit('wbProductCard/setEndDate', today);
      this.$store.commit('wbProductCard/setPid', this.product?.pid);
      this.$store.commit('common/showCardWbModal', true);
    },

    itemText(item) {
      return item.name;
    },

    async searchSKU(query) {
      const res = await getProductSKU({
        sku: query,
        sdate: thirtyDaysBefore,
        edate: today,
        marketplace: 'wb',
      });

      // проверка на ответ от сервера, что данные приходят
      if (res?.name || res?.extid) {
        this.$store.commit('wbProductCard/setProduct', res);
        this.skuItems = [res];
      }
    },

    clearSearchResult() {
      this.skuItems = [];
    },
  },
};
</script>

<style>
.sku-search .v-label {
  top: 12px !important;
}

.sku-search .v-input__slot {
  height: 44px;
}
</style>
