import { serverWhite } from '../server-white';

async function wbAnalitic(data) {
  const res = await serverWhite.post('/admin/analytics/wb/main', data);
  return res;
}

async function ozonAnalitic(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/main', data);
  return res;
}

export { wbAnalitic, ozonAnalitic };
