<template>
  <div class="font my-10 d-flex justify-space-around">
    <div style="width: 50%">
      <div class="graph text-body-2 font-weight-medium">По выручке</div>
      <pie-chart class="graph__pie" :chart-data="income" :options="options"></pie-chart>
    </div>

    <div style="width: 50%">
      <div class="graph text-body-2 font-weight-medium">По продажам</div>
      <pie-chart class="graph__pie" :chart-data="sales" :options="options"></pie-chart>
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/common/graph/PieChart.vue';

export default {
  name: 'IncomeSalesGraph',
  components: {
    PieChart,
  },
  props: {
    graphIncome: {
      type: Array,
      default: () => [],
    },
    graphSales: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    income() {
      const data = this.graphIncome.map((res) => res.income || 0);
      const total = data.reduce((acc, value) => acc + value);
      const labels = this.graphIncome.map((res) => res.name + ' ' + this.calculatePercentage(res.income, total) + '%');
      const unit = ' ₽';
      return this.makeDataSet(labels, data, unit);
    },
    sales() {
      const data = this.graphSales.map((res) => res.sales || 0);
      const total = data.reduce((acc, value) => acc + value);
      const labels = this.graphSales.map((res) => res.name + ' ' + this.calculatePercentage(res.sales, total) + '%');
      const unit = ' шт';
      return this.makeDataSet(labels, data, unit);
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'right',
          labels: {
            fontSize: 10,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              let currentValue = dataset.data[tooltipItem.index];
              return ' ' + currentValue.toLocaleString('ru-RU') + dataset.unit;
            },
          },
        },
      };
    },
  },
  methods: {
    calculatePercentage(value, total) {
      return Math.floor((value / total) * 100 + 0.5);
    },
    makeDataSet(labels, data, unit) {
      return {
        labels: labels,
        datasets: [
          {
            label: 'Data One',
            data: data,
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
              'rgb(116, 82, 171)',
              'rgb(84, 135, 157)',
              'rgb(146, 201, 97)',
              'rgb(206, 17, 141)',
              'rgb(141, 137, 197)',
              'rgb(250, 98, 30)',
              'rgb(204, 153, 67)',
            ],
            unit: unit,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.graph {
  &__pie {
    height: 230px;
  }
}
</style>
