<template>
  <v-tooltip v-if="header.description" :top="top" :left="left" :max-width="width">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        {{ header.text }}
        <v-icon small> mdi-help-circle-outline </v-icon>
      </span>
    </template>
    <span>{{ header.description }}</span>
  </v-tooltip>

  <span v-else>
    {{ header.text }}
  </span>
</template>

<script>
export default {
  name: 'Headers',

  props: {
    header: {
      type: Object,
      require: true,
    },

    top: {
      type: Boolean,
      default: true,
    },

    left: {
      type: Boolean,
      default: false,
    },

    width: {
      type: Number,
      default: 330,
    },
  },
};
</script>
