import {
  DATE_OPERATOR_VALUES,
  EQUALITY_OPERATOR_VALUES,
  LOGIC_OPERATOR_VALUES,
  TYPES,
} from '../_const/filterConstants';

import { stringFilterNeedsValue, rangeOpen } from './filterConditions';

import { formatISO } from 'date-fns';

// factories for different types of fetch filters

// подготовка фильтров перед отправкой
const enumFetchFilterFactory = ({ column, not, value }) => ({
  field: column,
  filters: [
    {
      operator: not ? EQUALITY_OPERATOR_VALUES.NOT_EQUALS : EQUALITY_OPERATOR_VALUES.EQUALS,
      value,
    },
  ],
});

// оператор постоянный 'eq'
const equalStringFetchFilterFactory = ({ column, value }) => ({
  field: column,
  filters: [
    {
      operator: 'eq',
      value,
    },
  ],
});

const multipleFetchFilterFactory = ({ column, not, value }) => {
  if (value.length === 1) return enumFetchFilterFactory({ column, not, value: value[0] });
  else
    return {
      field: column,
      logic: not ? LOGIC_OPERATOR_VALUES.AND : LOGIC_OPERATOR_VALUES.OR,
      filters: value.map((v) => ({
        operator: not ? EQUALITY_OPERATOR_VALUES.NOT_EQUALS : EQUALITY_OPERATOR_VALUES.EQUALS,
        value: v,
      })),
    };
};

const stringFetchFilterFactory = ({ column, operator, value }) => ({
  field: column,
  filters: [
    {
      operator,
      ...(stringFilterNeedsValue(operator) && { value }),
    },
  ],
});

const numberFetchFilterFactory = ({ column, range, operator, value }) => {
  if (rangeOpen(range, operator)) {
    return {
      field: column,
      logic: range.logic,
      filters: [
        {
          operator,
          value,
        },
        {
          operator: range.operator,
          value: range.value,
        },
      ],
    };
  } else
    return {
      field: column,
      filters: [
        {
          operator,
          value,
        },
      ],
    };
};

const dateFetchFilterFactory = ({ column, value }) => {
  const _value = [...value];
  _value.sort();
  return {
    field: column,
    logic: LOGIC_OPERATOR_VALUES.AND,
    filters: [
      {
        operator: DATE_OPERATOR_VALUES.GREATER_OR_EQUALS,
        value: formatISO(new Date(_value[0]), { representation: 'date' }),
      },
      {
        operator: DATE_OPERATOR_VALUES.LESS_OR_EQUALS,
        value: formatISO(new Date(_value[_value.length === 2 ? 1 : 0]), { representation: 'date' }),
        // if only one value - same filter
      },
    ],
  };
};

// fetch filter factories dispatcher

const fetchFilterFactory = ({ type, column, active, not, value, operator, range }) => {
  if (active) {
    switch (type) {
      case TYPES.ENUM: {
        return enumFetchFilterFactory({ column, not, value });
      }
      case TYPES.EQ_STRING: {
        return equalStringFetchFilterFactory({ column, value });
      }
      case TYPES.MULTIPLE: {
        return multipleFetchFilterFactory({ column, not, value });
      }
      case TYPES.STRING: {
        return stringFetchFilterFactory({ column, operator, value });
      }
      case TYPES.NUMBER: {
        return numberFetchFilterFactory({ column, operator, range, value });
      }
      case TYPES.DATE: {
        return dateFetchFilterFactory({ column, value });
      }
    }
  }
};

export default fetchFilterFactory;
