<template>
  <div>
    <modul-date-filter module-name="wbAnaliticInfo" @update:date-filter="updateDateFilter" />
    <InfoCards />
    <MainInfoGraph :info_graph="info_graph" class="py-4" />
  </div>
</template>

<script>
import InfoCards from '@/components/analitic/ozon/info/InfoCards';
import MainInfoGraph from '@/components/analitic/ozon/info/MainInfoGraph';
import ModulDateFilter from '@/components/common/modul-grid/components/modul-date-filter';

import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'InfoTab',
  components: {
    InfoCards,
    ModulDateFilter,
    MainInfoGraph,
    // SalesGraph,
  },
  computed: {
    ...mapState('ozonAnaliticInfo', ['analitic']),
    // product_graph() {
    //   return this.analitic?.graph;
    // },
    info_graph() {
      return this.analitic && this.analitic != true ? this.analitic : null;
    },
  },

  methods: {
    ...mapMutations('ozonAnaliticInfo', ['setStartDate', 'setEndDate']),
    ...mapActions('ozonAnaliticInfo', ['ozonAnalitic']),

    updateDateFilter(dates) {
      this.setStartDate(dates.value[0]);
      this.setEndDate(dates.value[1]);
      this.$store.dispatch('ozonAnaliticInfo/ozonAnalitic');
    },
  },
};
</script>

<style lang="scss" scoped></style>
