<template>
  <div>
    <CreditEmpty v-if="supplierExists" />
    <CreditMain v-else />

    <SidePanel v-model="showSupplier" :width="600" :title="titlePanel || 'Добавить поставщика'">
      <CreditCreateSupplier :close-panel="showSupplier" @title="title" />
    </SidePanel>

    <SidePanel v-model="showAddPayment" :width="600">
      <CreditCreatePaymentSupplier :close-panel="showAddPayment" />
    </SidePanel>
  </div>
</template>

<script>
import CreditEmpty from '@/components/credit/CreditEmpty';
import CreditMain from '@/components/credit/CreditMain';
import CreditCreatePaymentSupplier from '@/components/credit/CreditCreatePaymentSupplier';
import SidePanel from '@/components/common/SidePanel';
import CreditCreateSupplier from '@/components/credit/CreditCreateSupplier';
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'Credit',

  components: { CreditEmpty, CreditMain, SidePanel, CreditCreatePaymentSupplier, CreditCreateSupplier },

  data() {
    return {
      supplierExists: false,
      titlePanel: '',
    };
  },
  computed: {
    ...mapState('credit', ['openSupplier', 'openPayment']),

    showSupplier: {
      get() {
        return this.openSupplier;
      },
      set(value) {
        this.showCreditSidePanelSupplier(value);
      },
    },

    showAddPayment: {
      get() {
        return this.openPayment;
      },
      set(value) {
        this.showCreditSidePanelPayment(value);
      },
    },
  },
  methods: {
    ...mapMutations('credit', ['showCreditSidePanelSupplier', 'showCreditSidePanelPayment']),
    title(e) {
      this.titlePanel = e;
    },
  },
};
</script>

<style scoped></style>
