<template>
  <svg :height="height" :width="width" :viewBox="type == 'wb' ? '0 0 529.333 142.667' : '0 0 74 16'">
    <use :xlink:href="img"></use>
  </svg>
</template>

<script>
export default {
  name: 'LogoMarket',
  props: {
    type: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number,
      default: 55,
    },
  },
  data() {
    return {
      imgType: {
        wb: '#wb-logo',
        'wb.card': '#wb-logo',
        yandex: '#yandex-with-market-logo',
        ozon: '#ozon-logo',
      },
    };
  },
  computed: {
    img() {
      return this.imgType[this.type];
    },
  },
};
</script>
