import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';
import { INIT_SALES_TYPE } from '@/constants.js';
import Vue from 'vue';

const initState = () => ({
  isCatalogLoaded: false,
  catalog: [],
  children: {},
  period: '7',
  catalogSalesType: INIT_SALES_TYPE,
  icons: {
    Женщинам: {
      icon: 'mdi-human-female',
    },
    Мужчинам: {
      icon: 'mdi-human-male',
    },
    Premium: {
      icon: 'mdi-crown-outline',
    },
    Игрушки: {
      icon: 'mdi-duck ',
    },
    Продукты: {
      icon: 'mdi-food-variant',
    },
    Здоровье: {
      icon: 'mdi-needle',
    },
    Магнит: {
      icon: 'mdi-cart-variant',
    },
    'Новый год': {
      icon: 'mdi-pine-tree',
    },
    'Бытовая техника': {
      icon: 'mdi-fridge-outline',
    },
    Спорт: {
      icon: 'mdi-soccer',
    },
    'Спорт и отдых': {
      icon: 'mdi-soccer',
    },
    Электроника: {
      icon: 'mdi-monitor-cellphone',
    },
    'Дом и сад': {
      icon: 'mdi-home-outline',
    },
    'Строительство и ремонт': {
      icon: 'mdi-hammer-wrench',
    },
    Мебель: {
      icon: 'mdi-sofa-single-outline',
    },
    Автотовары: {
      icon: 'mdi-car-hatchback',
    },
    'Красота и здоровье': {
      icon: 'mdi-auto-fix',
    },
    'Детские товары': {
      icon: 'mdi-baby-carriage',
    },
    'Туризм, рыбалка, охота': {
      icon: 'mdi-spear',
    },
    'Продукты питания': {
      icon: 'mdi-cart-variant',
    },
    'Ozon Express': {
      icon: 'mdi-truck-delivery',
    },
    'Товары для животных': {
      icon: 'mdi-rabbit',
    },
    Аптека: {
      icon: 'mdi-pill',
    },
    'Хобби и творчество': {
      icon: 'mdi-palette-outline',
    },
    'Бытовая химия и гигиена': {
      icon: 'mdi-toothbrush-paste',
    },
    'Канцелярские товары': {
      icon: 'mdi-pencil',
    },
    'Игры и консоли': {
      icon: 'mdi-gamepad-variant-outline',
    },
    Аксессуары: {
      icon: 'mdi-watch-variant',
    },
    Книги: {
      icon: 'mdi-book-open-page-variant-outline',
    },
    Одежда: {
      icon: 'mdi-tshirt-crew-outline',
    },
    Обувь: {
      icon: 'mdi-shoe-sneaker',
    },
    'Товары для взрослых': {
      icon: 'mdi-heart-plus-outline',
    },
    'Ювелирные украшения': {
      icon: 'mdi-diamond-stone',
    },
    'Цифровые товары': {
      icon: 'mdi-tray-arrow-down',
    },
    'Антиквариат и коллекционирование': {
      icon: 'mdi-menorah-fire',
    },
    'Уценённые товары': {
      icon: 'mdi-percent-outline',
    },
    'Электронные сигареты и товары для курения': {
      icon: 'mdi-smoke',
    },
    'Музыка и видео': {
      icon: 'mdi-music-box-multiple-outline',
    },
    'Ozon Услуги': {
      icon: 'mdi-account-wrench-outline',
    },
    'Алкогольная продукция': {
      icon: 'mdi-glass-tulip',
    },
    'Автомобили и мототехника': {
      icon: 'mdi-wiper',
    },
  },
});

export const ozonCatalog = {
  namespaced: true,
  state: initState,
  getters: {
    children: (state) => state.children,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setCatalog: (state, catalog) => (state.catalog = catalog),
    setCatalogLoaded: (state, isLoaded) => (state.isCatalogLoaded = isLoaded),
    setPeriod: (state, period) => (state.period = period),
    setCatalogSalesType: (state, catalogSalesType) => (state.catalogSalesType = catalogSalesType),
    // push children: id: [children]
    setChildren: (state, child) => Vue.set(state.children, child.id, child.res),
    childrenClear: (state, child) => (state.children = child),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async catalogList({ commit, state: { isCatalogLoaded, period, catalogSalesType }, getters: { children } }, id) {
      // загрузка каталога без id
      if (!id) {
        const res = await api.catalogList({ period, salesType: catalogSalesType, marketplace: 'ozon' });
        if (res.length) {
          commit('setCatalog', res);
          commit('childrenClear', {});

          if (!isCatalogLoaded) {
            commit('setCatalogLoaded', true);
          }
        }
      }

      // добавление детей в каталоог
      if (id) {
        if (!(id in children)) {
          const res = await api.catalogList({ period, salesType: catalogSalesType, parent: id, marketplace: 'ozon' });
          commit('setChildren', { id, res });
          return res;
        } else {
          return children[id];
        }
      }
    },
  },
};
