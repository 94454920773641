<template>
  <div class="metrica">
    <v-card class="mx-auto font">
      <div class="d-flex align-center justify-space-between">
        <div style="max-width: 74%">
          <v-tabs v-model="currentTab" class="ml-sm-5 ml-0" height="64px">
            <v-tab
              v-for="({ name, id, icon, disabled }, i) of tabs"
              :key="id || name"
              :ripple="false"
              :disabled="disabled"
              class="mt-tab text-none pa-0 mx-3"
            >
              <v-divider
                v-if="id && i == 2"
                class="mt-6 pr-6"
                style="min-height: 50%; max-height: 60%; left: 0; position: absolute"
                vertical
              ></v-divider>
              <div class="text-body-2 font-weight-medium font--lh16">
                <v-icon if="icon" small color="primary">{{ icon }}</v-icon>
                {{ name }}
              </div>
              <v-btn v-if="id" x-small icon class="ml-2" style="margin-bottom: 3px" @click="closeTab(id)">
                <v-icon color="#464C59"> mdi-close </v-icon>
              </v-btn>
            </v-tab>
          </v-tabs>
        </div>
        <div class="mx-8" style="width: 300px">
          <SearchSKU v-model="skuModal" :selected-tab="tab" dense icon />
        </div>
      </div>

      <v-divider></v-divider>

      <v-main>
        <v-container
          fluid
          class="pt-4 metrica__container"
          :class="{
            'px-4': currentTab === 3,
            'px-8': currentTab !== 3,
            'd-flex justify-center align-center': currentTab === 3,
          }"
        >
          <v-tabs-items v-model="currentTab">
            <v-tab-item v-for="{ component, id, name } of tabs" :key="id || name">
              <component :is="component" :id="id" @cat:change="onCatChange"></component>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-main>
    </v-card>

    <SidePanel v-model="showCard" :title="'Описание товара'">
      <ProductCard v-if="product" :close-panel="showCard" @cat:change="onCatChange" />
    </SidePanel>
  </div>
</template>

<script>
import CatalogTabsMain from '@/components/metric/wb/catalog/CatalogTabsMain';
import ProductCard from '@/components/metric/wb/product/ProductCard';
import SellerMain from '@/components/metric/wb/seller/SellerMain';
import SellerTabs from '@/components/metric/wb/seller/SellerTabs';
import Category from '@/components/metric/wb/category/Category';
import BrandTabs from '@/components/metric/wb/brands/BrandTabs';
import Country from '@/components/metric/wb/country/Country';
import TagList from '@/components/metric/wb/tag/TagList.vue';
import SearchSKU from '@/components/metric/wb/SearchSKU';
import TagTabs from '@/components/metric/wb/tag/TagTabs';
import SidePanel from '@/components/common/SidePanel';

// import SearchMain from '@/components/metric/wb/searchProduct/SearchMain';

import * as filterMutations from '@/store/modules/metric/wb/mutations/filter';
import * as pagingMutations from '@/store/modules/metric/wb/mutations/paging';
import * as gridMutations from '@/store/modules/metric/wb/mutations/grid';
import * as tabMutations from '@/store/modules/metric/wb/mutations/tabs';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'Wb',

  provide: {
    filterMutations,
    pagingMutations,
    gridMutations,
  },

  components: {
    CatalogTabsMain,
    ProductCard,
    SellerMain,
    SellerTabs,
    SearchSKU,
    BrandTabs,
    SidePanel,
    Category,
    TagList,
    TagTabs,
    Country,
    // SearchMain,
  },

  data() {
    return {
      skuModal: null,
    };
  },

  computed: {
    ...mapGetters('wbMainTabs', ['tabs', 'tab']),
    ...mapState('wbProductCard', ['product']),
    ...mapGetters({ categorySubTabs: 'wbCategorySubTabs/tabs' }),

    showCard: {
      get() {
        return this.$store.state.common.showCardWb;
      },
      set(value) {
        this.showCardWbModal(value);
      },
    },

    currentTab: {
      get() {
        return this.tab;
      },
      set(value) {
        this[tabMutations.SET_TAB](value);
      },
    },
  },

  watch: {
    showCard(value) {
      let url = new URL(location.href);
      const sku = url.searchParams.has('openSku') ? url.searchParams.get('openSku') : false;

      if (!value && sku) {
        const newURL = window.location.origin + '/';
        history.pushState(null, '', newURL); // Изменяем URL без перезагрузки страницы
      }
    },

    skuModal(current) {
      if (current) {
        this.name = current.name;
      } else {
        this.name = '';
      }
    },
  },

  methods: {
    ...mapMutations('wbMainTabs', [tabMutations.SET_TAB]),
    ...mapMutations('common', ['showCardWbModal']),

    onCatChange(id) {
      const index = this.tabs.findIndex((tab) => tab.id === id);
      if (index !== -1) {
        this[tabMutations.SET_TAB](index);
      }
    },

    closeTab(id) {
      console.log('closeTab id', id);
      this.$store.commit(`wbMainTabs/${tabMutations.REMOVE_TAB}`, id);
      this.$store.unregisterModule(`wbCategory/${id}`);

      // удаление categorySubTabs
      this.categorySubTabs
        .filter((tab) => id === tab.categoryId)
        .forEach((tab) => {
          this.$store.commit('wbCategorySubTabs/removeTab', tab.id);
          this.$store.unregisterModule(tab.modulName);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/main';
.theme--light.v-tabs-items {
  background-color: transparent;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: white !important;
}

.metrica,
.metrica__container {
  background-color: #fff;
  min-height: 400px;
}

.metrica__container .v-item-group {
  padding: 0;
}
</style>
