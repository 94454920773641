import * as api from '@/api';
import { createGridModule } from './features/grid';
import { createResetMutation } from '@/store/helpers/reset';
import { downloadFile } from '@/store/helpers/download-file';
import * as gridMutations from '@/store/modules/metric/wb/mutations/grid';
import * as pagingMutations from '@/store/modules/metric/wb/mutations/paging';
import Vue from 'vue';

const initState = () => ({
  list: null,
});
export function createWbTagsModule({ ...config } = {}) {
  return createGridModule({
    state: initState,
    mutations: {
      set: (state, payload) => (state[payload.type] = payload.value),
      setTagList: (state, list) => (state.list = list),
      setFileLoading: (state, payload) => Vue.set(state, 'fileLoading', payload),
      setFilterExport: (state, payload) => (state.filterExport = payload),

      // метдод для сброса стейта
      reset: createResetMutation(initState),
    },
    getters: {
      filterExport: (state) => {
        const filterObj = {};
        filterObj.filter = state.filterExport.filter.filter;
        filterObj.page = state.filterExport.paging.page;
        filterObj.pageSize = state.filterExport.paging.pageSize;
        filterObj.search_query = state.filterExport.filter.search_query;
        filterObj.sort = state.filterExport.filter.sort;
        console.log('filterObj', filterObj);
        return filterObj;
      },
    },
    actions: {
      async wbTagList({ getters: { filter, pageSize, page, sort, search_query }, commit }) {
        if (search_query === '' && search_query.length <= 2) {
          return;
        }

        let data = {
          search_query,
          pageSize,
          page,
          filter,
          sort,
          marketplace: 'wb',
        };

        const res = await api.tagList(data);

        if (res) {
          commit(gridMutations.SET_GRID, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
      },

      async getExportListTags({ commit }, tag) {
        commit('setFileLoading', true);

        let my_data = {
          tag: tag,
          marketplace: 'wb',
        };

        const { data } = await api.getExportTagsHidden(my_data);

        if (data.response.fileid) {
          const fileid = data.response.fileid;

          // Запрос на получение файла
          const fileResponse = await api.fileGetHidden(fileid);

          if (fileResponse) {
            fileResponse.text = 'tags_wb_';
            downloadFile(fileResponse);
          }
        }

        // Сброс состояния загрузки файла
        commit('setFileLoading', false);
      },
    },
    ...config,
  });
}
