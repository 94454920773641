<template>
  <div class="mt-10">
    <v-tabs ref="tabs" v-model="currentTab" class="seo__tabs">
      <v-tabs-slider color="primary" class="tab"></v-tabs-slider>
      <v-tab :ripple="false" class="query__tab pl-0 font-weight-regular text-none font--p2 font--black1">
        Запросы
      </v-tab>
      <v-tab :ripple="false" class="words__tab font-weight-regular text-none font--p2 font--black1"> Слова </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="currentTab" class="seo__tabs">
      <v-tab-item>
        <QueryTagCardTable :list="keywordsResult.query" />
      </v-tab-item>
      <v-tab-item>
        <WordsTagCardTable :list="keywordsResult.words" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import QueryTagCardTable from '@/components/metric/wb/product/QueryTagCardTable';
import WordsTagCardTable from '@/components/metric/wb/product/WordsTagCardTable';

export default {
  name: 'ProductSeoTabs',

  components: { QueryTagCardTable, WordsTagCardTable },

  props: {
    closePanel: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState('wbProductCard', ['keywordsResult', 'showSlider', 'seoTab']),

    currentTab: {
      get() {
        return this.seoTab;
      },

      set(value) {
        this.setSeoTab(value);
      },
    },
  },

  watch: {
    closePanel(v) {
      if (!v) {
        this.tab = 0;
        this.setSlider(false);
      }
    },
    // так как компонент не обновляется, слайдер тоже не хочет грузится, принудительно устанавливаем
    showSlider(v) {
      if (v) {
        this.$refs.tabs.onResize();
      }
    },
  },

  methods: {
    ...mapMutations('wbProductCard', ['setSlider', 'setSeoTab']),
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active).words__tab,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active).query__tab {
  color: #222733 !important;
}
</style>
