<template>
  <v-data-table
    :items="flattenItems"
    :headers="headers"
    hide-default-footer
    :items-per-page="-1"
    dense
    class="ma-5 catalog-grid"
    fixed-header
    height="70vh"
  >
    <!-- <template v-slot:item="{ item: { id, lvl, parent, name, child, products, brands, sellers } }"> -->
    <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ headers }">
      <v-tooltip v-if="h.description" :key="h.value" top>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ h.text }} <v-icon size="14">mdi-help-circle-outline </v-icon></span>
        </template>
        <span>{{ h.description }}</span>
      </v-tooltip>
      <span v-else :key="h.value">{{ h.text }}</span>
    </template>

    <template
      v-slot:item="{
        item: {
          id,
          lvl,
          name,
          child,
          products,
          brands,
          sellers,
          averageSm,
          sales,
          income,
          incomeLost,
          sps,
          spg,
          available,
        },
      }"
    >
      <tr>
        <td class="pa-3">
          <span :class="'ml-' + lvl * 4"></span>
          <v-icon v-if="child" class="ml-n4 mr-1" color="#464C59" @click="expand(id, lvl, name)">
            {{ id in expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
          <v-btn v-if="lvl >= 1 && id && available == 1" small text @click="onCatalogClick({ lvl, id, name })">
            <v-icon class="mr-5">{{ getIcon({ name, lvl }) }}</v-icon> {{ name }}
          </v-btn>
          <span v-else class="text-body-1 mx-3">
            <v-icon color="#464C59" class="mr-5">{{ getIcon({ name, lvl }) }}</v-icon> {{ name }}
          </span>
        </td>
        <td>
          {{ numberFormat(products) }}
        </td>
        <td>
          {{ numberFormat(brands) }}
        </td>
        <td>
          {{ numberFormat(sellers) }}
        </td>
        <td>
          {{ numberFormat(averageSm) }}
        </td>
        <td>
          {{ numberFormat(sales) }}
        </td>
        <td>
          {{ numberFormat(income) }}
        </td>
        <td>
          {{ incomeLost == null ? '-' : numberFormat(incomeLost) }}
        </td>
        <td style="min-width: 120px">
          {{ sps }}
        </td>
        <td style="min-width: 120px">
          {{ spg }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';
import { mapActions, mapGetters, mapState } from 'vuex';
import { numberFormat } from '@/mixins/numberFormat';
import { GRID_HEADERS } from './constants';

export default {
  name: 'CatalogTable',

  mixins: [numberFormat],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      expanded: {
        root: true,
      },
      headers: GRID_HEADERS,
      // TODO: Вместо flattenItems лучше сюда сплайсом добавлять / удалять элементы там 18000 штук получается, такое
      openedItems: this.items,
    };
  },

  watch: {
    period() {
      this.expanded = { root: true };
    },
    catalogSalesType() {
      this.expanded = { root: true };
    },
  },

  computed: {
    ...mapState('wbCatalog', ['icons', 'period', 'catalogSalesType']),
    ...mapGetters('wbCatalog', ['children']),
    ...mapGetters('wbMainTabs', ['tabs', 'hasTab']),

    flattenItems() {
      return this.flatItems(this.items, ['root']); // FIXME тут очень много элементов получается
    },
  },

  methods: {
    ...mapActions('wbMainTabs', ['addTab']),
    ...mapActions('wbCatalog', ['catalogList']),
    getIcon({ name, lvl }) {
      if (lvl > 1) return;
      const icon = this.icons[name]?.icon;
      return icon ?? null;
    },
    flatItems(items, parent) {
      let res = items.flatMap((item) => {
        if (item.id in this.children && this.children[item.id]?.length && item.id in this.expanded) {
          return [item, ...this.flatItems(this.children[item.id], [...parent, item.id])];
        }
        return [item];
      });
      return res;
    },
    onCatalogClick(item) {
      if (item.lvl >= 1 && item.id) {
        if (!this.hasTab(item.id)) {
          this.addTab({
            id: item.id,
            name: item.name,
            component: 'Category',
            action: `wbCategory/${item.id}/getProductList`,
          });
        } else {
          this.$emit('cat:change', item.id);
        }
        const moduleName = `wbCategory/${item.id}`;
        if (!this.$store.hasModule(moduleName)) {
          this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'cid', cid: item.id }));
        }
      }
    },

    // клик: спускаемся вниз по id. идем за данными children (catalogList)
    async expand(id, lvl) {
      if (id in this.expanded) {
        this.$delete(this.expanded, id);
      } else {
        await this.catalogList(id);
        this.$set(this.expanded, id, lvl);
      }
    },
  },
};
</script>

<style lang="scss">
.text-body-1 {
  line-height: 130%;
  font-size: 0.9rem !important;
  color: #464c59;
}

.catalog-grid {
  th {
    border-right: none !important;
    vertical-align: top;
  }
  td {
    min-width: 110px;
  }
}
</style>
