<template>
  <v-autocomplete
    ref="bank"
    v-model="bank"
    :no-data-text="nodataText"
    :dense="dense"
    :rules="rules"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-details
    hide-selected
    :disabled="disabled"
    :item-text="itemText"
    label="БИК или наименование банка получателя"
    outlined
    single-line
    return-object
    prepend-inner-icon="mdi-magnify"
    no-filter
    @input="$emit('input', $event)"
    @click:clear="clearSearchResult"
  >
    <template v-slot:item="{ item }" class="mb-2">
      <div class="search-result">
        <h4 class="font text-body-2 font-lh16">{{ item.bank }}</h4>
        <div class="font text-caption font--gray7">
          БИК:
          {{ item.bic }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce';
import { searchCreditBank } from '@/api';

export default {
  name: 'BankSearch',

  props: {
    rules: Array,
    dense: Boolean,
    value: Object,
    disabled: Boolean,
  },

  data: () => ({
    search: null,
    items: [],
  }),

  computed: {
    bank: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    // FIXME: Вынести в пропс
    isLoading() {
      return !!this.$store.state.common.loading;
    },
    nodataText() {
      return this.search && this.search.length >= 3 ? 'Нет результатов' : 'Введите минимум 3 символа для поиска банка';
    },
  },

  watch: {
    value(current) {
      // fix: не отображается значение, если его нет в результатах поиска, например при первоначальном
      // отображении банка из стейта или при сбросе значения к первоначальному
      if (!!current && !this.items.some((bank) => bank.bic === current.bic)) {
        this.items = [current];
      }
    },
    search(current) {
      // При установке банка обнавляется значение поисковой строки, в этом случае поиск не производим
      // также не ищем, если в поисковой строке меньше 3 символов.
      if (!current || current.length < 3 || (!!this.bank && this.itemText(this.bank) === current)) return;
      this.debouncedSearch(this.search);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchBank, 500);
  },

  mounted() {
    if (this.value) {
      this.items = [this.value];
    }
  },

  methods: {
    itemText(item) {
      if (item && item.bic && item.bank && item !== null) {
        return item.bic + ' ' + item.bank;
      } else return '';
    },

    async searchBank(query) {
      const res = await searchCreditBank(query);
      if (res?.results) {
        this.items = res.results;
      }
    },

    clearSearchResult() {
      this.items = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.nodata {
  padding: 16px;
}
</style>
