export const BUILD = 'metric'; // prod test metric

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_PROD = !IS_DEVELOPMENT;
export const DEV_TOKEN_COMPANY = '532dc640-e3d2-45e9-b135-a8be02499a8f';
export const IS_METRIC = BUILD === 'metric';

const configuration = {
  metric: {
    url: 'https://modulmetric.aml.tech/',
    white_server_url: 'https://modulmetric.aml.tech/',
    developer: false,
  },
};

export const URL = configuration[BUILD].url;
export const WHITE_SERVER_URL = configuration[BUILD].white_server_url;

export const IS_DEVELOPER = configuration[BUILD].developer;

export const CHAT_URL = URL + '#/goto/finance?action=openChat&text=Здравствуйте,';
export const OFFER_URL =
  URL +
  '#/goto/finance?action=openChat&text=Здравствуйте! Расскажите подробнее про кредитование.&product=Credit&group={}';
export const TARIFF_URL = URL + '#/settings?tab=tariff&subtab=tariffForSeller';
export const OFERTA_URL = 'http://momentum.rest/legal/';
export const INIT_SALES_TYPE = 'fbo';
