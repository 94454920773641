import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  isLoaded: false,
  limitCta: null,
  limitMp: null,
  limitNal: null,
  rateNal: null,
  rateMp: null,
  rateCta: null,
  contragents: [],
});

export const green = {
  namespaced: true,
  state: initState,
  getters: {
    hideGreenLimit: (state) =>
      state.limitCta === null &&
      state.limitMp === null &&
      state.limitNal === null &&
      !state.rateMp &&
      !state.rateNal &&
      !state.rateCta &&
      !state.contragents.length,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setContragents: (state, value) => {
      if (value) {
        state.contragents = value;
      }
    },
    setLimitCta: (state, value) => {
      if (value) {
        state.limitCta = value;
      }
    },
    setLimitMp: (state, value) => {
      if (value) {
        state.limitMp = value;
      }
    },
    setLimitNal: (state, value) => {
      if (value) {
        state.limitNal = value;
      }
    },
    setLoaded: (state) => {
      state.isLoaded = true;
    },
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async getGreen({ commit }) {
      const res = await api.getGreen();
      if (res) {
        // TODO Проследить что приходит
        commit('setContragents', res.contragents);
        commit('setLimitCta', res.limitCta);
        commit('setLimitMp', res.limitMp);
        commit('setLimitNal', res.limitNal);
        commit('set', { type: 'rateMp', value: res.rateMp });
        commit('set', { type: 'rateCta', value: res.rateCta });
        commit('set', { type: 'rateNal', value: res.rateNal });
        commit('setLoaded');
      }
    },
  },
};
