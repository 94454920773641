<template>
  <div>
    <BrandTop @cat:change="$emit('cat:change', $event)" />
  </div>
</template>

<script>
import BrandTop from '@/components/metric/wb/brands/BrandTop';

export default {
  name: 'BrandMain',

  components: {
    BrandTop,
  },
};
</script>
