import { serverWhite } from '../server-white';

async function searchTag(data) {
  let res = await serverWhite.post('/admin/mp/tag/get', data);
  return res;
}

async function tagList(data) {
  let res = await serverWhite.post('/admin/mp/tag/list', data);
  return res;
}

export { searchTag, tagList };
