<template>
  <div>
    <v-tabs v-model="tab" class="tabs">
      <v-tabs-slider color="transparent"></v-tabs-slider>
      <v-tab v-for="{ name, icon } of tabs" :key="name" :ripple="false" class="text-none tabs__link">
        <v-icon class="mr-1" color="primary">{{ icon }}</v-icon
        ><span class="text-subtitile-1 font-weight-regular">{{ name }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ component, name, prop } of tabs" :key="name">
        <component :is="component" v-bind="prop" @cat:change="$emit('cat:change', $event)"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import WeeklyTable from '@/components/analitic/ozon/finances/weekly/WeeklyTable';
import TapeTable from '@/components/analitic/ozon/finances/tape/TapeTable';

export default {
  name: 'FinancesTab',
  components: { WeeklyTable, TapeTable },

  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'Недельные отчеты',
          prop: {
            action: 'ozonWeeklyFinances/ozonWeekly',
            productModule: 'ozonWeeklyFinances',
          },
          component: 'WeeklyTable',
        },
        {
          name: 'Лента продаж',
          prop: {
            action: 'ozonTapeFinances/ozonTape',
            productModule: 'ozonTapeFinances',
          },
          component: 'TapeTable',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
// Табы начинаются с index 2
.tabs .tabs__link {
  border: 1px solid #cbcdd1;
  height: 44px;
  color: #222733 !important;
}
.tabs .tabs__link:nth-child(2) {
  border-right-color: transparent;
}
.v-tab--active .v-icon {
  color: #ffff !important;
}
.tabs .tabs__link.v-tab--active {
  color: #4971d0 !important;
  background-color: #f2f6ff !important;
  border: 2px solid #4971d0 !important;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(50%);
  opacity: 0;
  transition: all 0.8s ease;
}
</style>
