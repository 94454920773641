import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      sort: 'mdi-arrow-down', // For proper arrow directions (all sorts are descending-first)
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#4971d0',
      },
    },
  },
});
