<template>
  <v-card height="100%" class="pa-4">
    <div class="text-subtitle-2 pb-4 font--black1">Лимиты на вывод выручки</div>

    <WidgetGreenLimit
      :limit-cta="greenLimit.limitCta"
      :limit-mp="greenLimit.limitMp"
      :limit-nal="greenLimit.limitNal"
      :rate-nal="greenLimit.rateNal"
      :rate-mp="greenLimit.rateMp"
      :rate-cta="greenLimit.rateCta"
      :product-type="true"
    />
    <v-card-actions v-if="!isMobile" class="pt-8">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn-multiline" @click="setTab()">
        <span class="text-wrap">Посмотреть подробнее </span></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import WidgetGreenLimit from '@/components/common/WidgetGreenLimit';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: 'WidgetGreen',
  components: { WidgetGreenLimit },

  computed: {
    ...mapState('products', ['greenLimit']),
    ...mapGetters('common', ['isMobile']),
  },
  methods: {
    ...mapMutations('common', ['setVerticalTab', 'setMobileMainTab']),
    setTab() {
      this.setVerticalTab(6);
    },
  },
};
</script>
<style scoped>
.btn-multiline >>> span {
  width: 100%;
  font-size: 12px;
}
</style>
