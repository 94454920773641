<template>
  <v-snackbar v-model="openSnackbar" timeout="10000" color="#D4F296" light left width="421">
    <div class="d-flex align-start">
      <v-icon color="#5E8C00" class="pr-3" size="20">mdi-check-circle-outline</v-icon>

      <div class="text-subtitle-2 font-weight-regular">
        {{ text }}
      </div>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',

  props: {
    text: String,
    open: Boolean,
  },
  computed: {
    openSnackbar: {
      get: function () {
        return this.open;
      },
      set: function (newValue) {
        this.$emit('update:open', newValue);
      },
    },
  },
};
</script>
