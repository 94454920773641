<template>
  <div>
    <div class="d-flex flex-wrap justify-space-between mb-11 mt-6">
      <div class="mb-2">
        <modul-grid-filters
          ref="grid-filters"
          :module-name="`${productModule}`"
          :action="`${action}`"
          :columns="headers"
          :filters="true"
          :footer-props="footerProps"
          :fast-date="true"
        >
        </modul-grid-filters>
      </div>
      <v-text-field
        v-model="search"
        dense
        single-line
        hide-details
        height="44"
        class="mb-2"
        style="max-width: 400px"
        outlined
        placeholder="Введите номер задания"
        clearable
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>

    <vue-horizontal scroll class="horizontal__scroll" snap="none">
      <div class="table__container">
        <v-data-table
          :mobile-breakpoint="100"
          class="ozon__assembly-table"
          :headers="headers"
          :items="indexedGrid"
          item-key="id"
          :no-data-text="noDataText"
          fixed-header
          :items-per-page="50"
          :footer-props="footerProps"
          :server-items-length="total"
          :options.sync="options"
          show-expand
          @item-expanded="loadDetails"
          @update:options="onUpdateOptions($event)"
        >
          <template #[`item.postingNumber`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.sdate`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
          </template>

          <template #[`item.edate`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
          </template>

          <template #[`item.sts`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.price`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
          </template>

          <template #[`item.quantity`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
          </template>

          <template #[`item.deliveryType`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.warehouse`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.region`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.deliveringDate`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 0 !important">
              <AssemblyTaskExpandTable
                :key="item.id"
                :task-products="item.details.products"
                :task-costs="item.details.costs"
              />
            </td>
          </template>
        </v-data-table>
      </div>
    </vue-horizontal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import { TYPES } from '@/components/common/modul-grid/_utils/filters';
import { analiticMixin } from '@/mixins/analiticMixin';
import { createOzonAssemblyTasksModule } from '@/store/modules/analitic/ozon/orders/assemblyTasks';
import VueHorizontal from 'vue-horizontal';
import * as api from '@/api';
import AssemblyTaskExpandTable from '@/components/analitic/ozon/orders/assemblyTasks/AssemblyTaskExpandTable';
import { getOzonAssemblyFiltersData } from '@/api/analitic/order';

import { format } from 'date-fns';
import { makeRub } from '@/mixins/makeRub';
import { mapState } from 'vuex';

export default {
  name: 'AssemblyTasksTable',
  components: { AssemblyTaskExpandTable, ModulGridFilters, VueHorizontal },
  inject: ['filterMutations', 'pagingMutations'],
  mixins: [makeRub, analiticMixin],
  props: {
    productModule: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        { text: 'Номер задания', value: 'postingNumber', width: '126px', type: TYPES.EQ_STRING },
        { text: 'Дата создания', value: 'sdate', width: '126px' },
        { text: 'Дата, до которой необходимо собрать заказ', value: 'edate', width: '126px' },
        { text: 'Статус', value: 'sts', width: '126px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Цена', value: 'price', width: '126px', type: TYPES.NUMBER },
        { text: 'Количество', value: 'quantity', width: '126px', type: TYPES.NUMBER },
        { text: 'Тип доставки', value: 'deliveryType', width: '126px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Склад', value: 'warehouse', width: '126px', type: TYPES.EQ_STRING },
        { text: 'Регион', value: 'region', width: '126px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Дата передачи заказа в доставку', value: 'deliveringDate', width: '126px' },
      ],
    };
  },

  computed: {
    ...mapState('ozonAssemblyOrders', ['filtersData']),
    indexedGrid() {
      return this.grid.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
  },

  created() {
    if (!this.$store.hasModule(this.productModule)) {
      this.$store.registerModule(this.productModule, createOzonAssemblyTasksModule());
    }
    this.debouncedSearch = debounce(this.getAssemblyOrders, 800);
  },

  async mounted() {
    const statesAndTypes = await getOzonAssemblyFiltersData();
    this.setOptions({ field: 'sts', options: statesAndTypes?.sts });
    this.setOptions({ field: 'region', options: statesAndTypes?.region });
    this.setOptions({ field: 'deliveryType', options: statesAndTypes?.deliveryType });
  },

  methods: {
    getAssemblyOrders() {
      this.$store.dispatch(`${this.action}`);
    },
    setOptions({ field, options }) {
      this.$store.commit(`${this.productModule}/setOptions`, { field, options });
    },

    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },

    async loadDetails({ item }) {
      if (!Object.keys(item.details).length) {
        const res = await api.ozonAssemblyDetails({ postingNumber: item.postingNumber });
        if (res) {
          item.details = res;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table.ozon__assembly-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.theme--light.v-data-table.ozon__assembly-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.table__container {
  padding-top: 20px;
}

.horizontal__scroll::v-deep .v-hl-btn {
  top: -40px;
  align-items: start;
}
.horizontal__scroll::v-deep .v-hl-btn-next {
  right: 22px;
}

.horizontal__scroll::v-deep .v-hl-btn-prev {
  left: 22px;
}
</style>
