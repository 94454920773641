import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';
import { createGridModule } from './features/grid';
import * as gridMutations from '@/store/modules/metric/wb/mutations/grid';
import * as pagingMutations from '@/store/modules/metric/wb/mutations/paging';
const initState = () => ({
  list: null,
});
export function createWbTagsModule({ ...config } = {}) {
  return createGridModule({
    state: initState,
    mutations: {
      set: (state, payload) => (state[payload.type] = payload.value),
      setTagList: (state, list) => (state.list = list),

      // метдод для сброса стейта
      reset: createResetMutation(initState),
    },
    actions: {
      async wbTagList({ getters: { filter, pageSize, page, sort, search_query }, commit }) {
        if (search_query === '' && search_query.length <= 2) {
          return;
        }
        let data = {
          search_query,
          pageSize,
          page,
          filter,
          sort,
          marketplace: 'wb',
        };

        const res = await api.tagList(data);
        if (res) {
          commit(gridMutations.SET_GRID, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
      },
    },
    ...config,
  });
}
