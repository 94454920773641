<template>
  <v-card min-height="182px" class="font mr-1 py-4 px-6" color="#F2F6FF">
    <v-list-item three-line class="pa-0">
      <v-list-item-content class="pa-0 font--gray10">
        <div class="mb-4">
          <v-img width="124" src="img/green_limit.png"></v-img>
        </div>

        <div class="text-subtitle-1 font--lh22 font--black1 font-weight-medium mb-4">
          Выводите выручку или снимайте наличные с опцией «Зелёный лимит»
        </div>
        <div class="text-subtitle-1 font--lh24 mb-2">
          Зелёный лимит – опция, позволяющая переводить деньги по реквизитам со счёта ИП или ООО на счета физлиц любого
          банка без ограничений:
        </div>
        <span class="text-subtitle-1 font--lh24 mb-4">
          <li class="text-subtitle-1 font--lh24 ml-2">С комиссией от 0%</li>
          <li class="text-subtitle-1 font--lh24 ml-2">С защитой от блокировок по 115–ФЗ</li>
        </span>
        <div class="text-subtitle-1 font--lh24 mb-4">
          Лимит динамический, рассчитывается индивидуально, не сгорает, и включается, когда бесплатный лимит по тарифу
          израсходован. Накопленную сумму можно вывести в любой момент, даже через несколько месяцев.
        </div>

        <div class="text-subtitle-1 font--lh24 mb-2">Выводите выручку на тарифе:</div>

        <span>
          <li class="text-subtitle-1 font--lh24 ml-2">«Селлер» до 1 млн ₽ – 0%, далее — 0,5%</li>
          <li class="text-subtitle-1 font--lh24 ml-2">«Селлер Про» до 5 млн ₽ – 0%, далее — 0,5%</li>
          <li class="text-subtitle-1 font--lh24 ml-2">«Селлер Топ» – 0%</li>
        </span>
      </v-list-item-content>
    </v-list-item>
    <div class="text-subtitle-1 font--lh24 my-4">
      Комиссия от 0,75% от суммы перевода платежей от проверенных контагентов
    </div>

    <v-btn
      class="text-caption font-weight-bold"
      :width="width"
      outlined
      color="primary"
      style="border: 2px solid"
      :href="'https://modulbank.ru/fs/files/market_seller.pdf'"
      target="_blank"
    >
      Узнать больше
    </v-btn>
  </v-card>
</template>
<script>
export default {
  name: 'GreenLimitInfo',
  props: {
    width: {
      type: String,
      default: '100%',
    },
  },
};
</script>
