<template>
  <div v-if="tags">
    <div class="font text-h6 font--lh28">Запросы и позиции по ним</div>
    <v-simple-table height="300px" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Название</th>
            <th class="text-left">Частота</th>
            <th v-for="(item, i) in tableHeadersDate" :key="i" class="text-center">
              {{ new Date(item).toLocaleDateString('ru-RU') }}
            </th>
          </tr>
        </thead>

        <tbody v-for="(pos, i) in array" :key="i">
          <tr class="name">
            <td>
              {{ pos.text }}
            </td>
            <td>
              {{ pos.frequency }}
            </td>
            <td v-for="headerDate in tableHeadersDate" :key="headerDate" class="text-center">
              {{ extractPosition(pos, headerDate) || '-' }}
            </td>
          </tr>
          <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'ProductTag',

  props: {
    product_card: Object, // eslint-disable-line
  },

  computed: {
    tags() {
      return this.product_card && this.product_card?.tags;
    },
    array() {
      return this.tags.slice().sort(function (a, b) {
        return b.frequency - a.frequency;
      });
    },

    tableHeadersDate() {
      return this.product_card.dates?.map((elem) => elem.date);
    },

    tableValues() {
      const headersDate = this.tableHeadersDate;
      const values = this.tags;

      // имя -> (дата -> позиция)
      const tableDataMap = new Map();

      for (let i = 0; i < values.length; i++) {
        const graph = values[i].graph;

        if (graph) {
          // дата -> позиция
          const valuesMap = new Map();
          for (let headerIndex = 0; headerIndex < headersDate.length; headerIndex++) {
            const headerValue = headersDate[headerIndex];

            const graphValue = graph[headerValue];
            valuesMap.set(headerValue, graphValue);
          }
          tableDataMap.set(values[i].text, valuesMap);
        }
      }
      return tableDataMap;
    },
  },

  methods: {
    extractPosition(pos, headerDate) {
      return this.tableValues?.get(pos.text)?.get(headerDate);
    },
  },
};
</script>

<style lang="scss" scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background: white;
}
</style>
