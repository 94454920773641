<template>
  <v-card height="100%" class="pa-4">
    <v-row class="d-flex align-center">
      <v-col cols="7">
        <div class="text-subtitle-2 font--black1 font--lh19 font-weight-medium mb-2">Новинки недели</div>
      </v-col>
      <v-col cols="5" class="pt-0">
        <v-card width="72" height="32" elevation="3" class="pa-2">
          <LogoMarket :type="'wb'" />
        </v-card>
      </v-col>
    </v-row>
    <div v-for="item in bestProduct" :key="item.pid">
      <div class="text-body-2 my-4 text-truncate" :title="item.name">{{ item.name }}</div>

      <div class="d-flex flex-inline-block">
        <v-img :src="item.imagePreview" alt="Product" class="align-end" height="100" width="76"></v-img>
        <v-card-text class="pt-0">
          <div class="d-flex text-caption font-weight-medium mb-1 align-center">
            <v-icon size="13" class="mr-1">mdi-source-branch</v-icon>
            SKU:
            <a target="_blank" :href="`${item.extlink}`" class="text-decoration-none text-body-2 font--lh21 ml-1">{{
              item.extid
            }}</a>
          </div>
          <div class="d-flex flex-wrap align-center text-caption font-weight-medium mb-1">
            <v-icon size="16" class="mr-1">mdi-tag-outline</v-icon>
            Бренд:
            <div class="text-body-2 font--lh21 ml-1">{{ item.brand }}</div>
          </div>
          <div class="d-flex flex-wrap align-center text-caption font-weight-medium mb-1">
            <v-icon size="16" class="mr-1">mdi-account-outline</v-icon>
            Продавец:
            <div class="text-body-2 font--lh21 ml-1">{{ item.seller }}</div>
          </div>
          <div class="text-caption font-weight-medium mb-1">Выручка: {{ makeRub(item.income) }}</div>
          <div class="text-caption font-weight-medium">Продажи: {{ item.sales }}</div>
        </v-card-text>
      </div>
    </div>
    <v-card-actions class="pt-8">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn-multiline" @click="setTab()">
        <span class="text-wrap">Посмотреть подробнее </span></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations } from 'vuex';
import LogoMarket from '@/components/common/LogoMarket';

export default {
  name: 'WidgetBestProduct',
  components: { LogoMarket },
  mixins: [makeRub],

  computed: {
    ...mapGetters('products', ['bestProduct']),
    ...mapGetters('common', ['isMobile']),
  },
  methods: {
    ...mapMutations('common', ['setVerticalTab', 'setMobileMainTab']),
    setTab() {
      this.isMobile ? this.setMobileMainTab('tab-3') : this.setVerticalTab(2);
    },
  },
};
</script>
<style scoped>
.btn-multiline >>> span {
  width: 100%;
  font-size: 12px;
}
</style>
