<template>
  <v-dialog v-model="show" width="480">
    <v-card class="pa-8">
      <div class="d-flex justify-space-between pb-0">
        <p class="text-h6 font-weight-regular pa-0">Подтверждение</p>
        <v-btn icon @click.stop="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <p class="text-subtitle-1">
        При удалении токена функционал сервиса будет частично ограничен, пожалуйста, добавьте новый ключ.
      </p>
      <div class="mt-8">
        <v-btn color="primary" class="mr-4" @click.stop="addNewToken">Добавить новый токен</v-btn>

        <v-btn text color="black" @click="$emit('del')">Удалить</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapMutations('common', ['showOptionSidePanel']),

    addNewToken() {
      this.showOptionSidePanel(true);
      this.show = false;
    },
  },
};
</script>
