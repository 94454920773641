<template>
  <div class="px-4">
    <SearchBrand v-model="brandModal" dense class="input-brand mb-6" />
    <BrandTop @cat:change="$emit('cat:change', $event)" />
  </div>
</template>

<script>
import SearchBrand from '@/components/metric/wb/brands/SearchBrand';
import BrandTop from '@/components/metric/wb/brands/BrandTop';

export default {
  name: 'BrandMain',

  components: {
    SearchBrand,
    BrandTop,
  },

  data() {
    return {
      brandModal: null,
    };
  },
};
</script>

<style lang="scss">
.input-brand {
  width: 320px;
  background-color: white;

  @media (max-width: 960px) {
    width: 100%;
  }
}
</style>
