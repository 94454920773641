export const numberFormat = {
  methods: {
    /**
     * @param {number | string | null} value
     */
    numberFormat(value, digits = 0) {
      const rubs = typeof value === 'number' ? value : Number(value);
      return rubs.toLocaleString('ru-RU', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    },
  },
};
