import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';
import { INIT_SALES_TYPE } from '@/constants.js';
import Vue from 'vue';

const initState = () => ({
  isCatalogLoaded: false,
  catalog: [],
  children: {},
  period: '7',
  catalogSalesType: INIT_SALES_TYPE,
  icons: {
    Женщинам: {
      icon: 'mdi-human-female',
    },
    Мужчинам: {
      icon: 'mdi-human-male',
    },
    Детям: {
      icon: 'mdi-baby-carriage',
    },
    Premium: {
      icon: 'mdi-crown-outline',
    },
    Спорт: {
      icon: 'mdi-soccer',
    },
    Игрушки: {
      icon: 'mdi-duck ',
    },
    Канцтовары: {
      icon: 'mdi-pen',
    },
    'Для ремонта': {
      icon: 'mdi-hammer-wrench',
    },
    'Товары для взрослых': {
      icon: 'mdi-heart-plus-outline',
    },
    Обувь: {
      icon: 'mdi-shoe-sneaker',
    },
    Электроника: {
      icon: 'mdi-monitor-cellphone',
    },
    'Бытовая техника': {
      icon: 'mdi-fridge-outline',
    },
    Книги: {
      icon: 'mdi-book-open-variant ',
    },
    Красота: {
      icon: 'mdi-auto-fix',
    },
    Аксессуары: {
      icon: 'mdi-watch-variant',
    },
    Продукты: {
      icon: 'mdi-food-variant',
    },
    Зоотовары: {
      icon: 'mdi-rabbit',
    },
    Автотовары: {
      icon: 'mdi-car-hatchback',
    },
    Здоровье: {
      icon: 'mdi-needle',
    },
    Дом: {
      icon: 'mdi-home-outline',
    },
    'Ювелирные изделия': {
      icon: 'mdi-diamond-stone ',
    },
    'Экспресс-доставка': {
      icon: 'mdi-truck-delivery',
    },
    Магнит: {
      icon: 'mdi-cart-variant',
    },
    Фармацевтика: {
      icon: 'mdi-pill',
    },
    Алкоголь: {
      icon: 'mdi-glass-tulip',
    },
    'Новый год': {
      icon: 'mdi-pine-tree',
    },
  },
});

export const wbCatalog = {
  namespaced: true,
  state: initState,
  getters: {
    children: (state) => state.children,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setCatalog: (state, catalog) => (state.catalog = catalog),
    setCatalogLoaded: (state, isLoaded) => (state.isCatalogLoaded = isLoaded),
    setPeriod: (state, period) => (state.period = period),
    setCatalogSalesType: (state, catalogSalesType) => (state.catalogSalesType = catalogSalesType),
    // push children: id: [children]
    setChildren: (state, child) => Vue.set(state.children, child.id, child.res),
    childrenClear: (state, child) => (state.children = child),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async catalogList({ commit, state: { isCatalogLoaded, period, catalogSalesType }, getters: { children } }, id) {
      // загрузка каталога без id
      if (!id) {
        const res = await api.catalogList({ period, salesType: catalogSalesType, marketplace: 'wb' });
        if (res.length) {
          commit('setCatalog', res);
          commit('childrenClear', {});

          if (!isCatalogLoaded) {
            commit('setCatalogLoaded', true);
          }
        }
      }

      // добавление детей в каталоог
      if (id) {
        if (!(id in children)) {
          const res = await api.catalogList({ period, salesType: catalogSalesType, parent: id, marketplace: 'wb' });
          commit('setChildren', { id, res });
          return res;
        } else {
          return children[id];
        }
      }
    },
  },
};
