// Модуль фильтрации списков

import * as filterMutations from '@/store/modules/metric/ozon/mutations/filter';
import { INIT_SALES_TYPE } from '@/constants.js';
import { isDate } from '@/components/common/modul-grid/_utils/filters';

const defaultConfig = {
  localFilters: [],
  filter: [],
  search_query: '',
  sort: null,
  sales: INIT_SALES_TYPE,
};

/**
 * Создание модуля фильтров
 *
 * @param {Object} config
 * @param {Number} config.filter массив фильтров
 * @param {Number} config.search_query поисковая фраза
 */
export function createFilterModule(config = {}) {
  const initState = () => ({
    ...defaultConfig,
    ...config,
  });

  return {
    state: initState,
    getters: {
      dateFilter: (state) => {
        const filter = state.filter.find((f) => f.field === 'dadd');
        if (filter) {
          const result = {};
          // 'gte' - дата начала периода, 'lte' - дата конца периода
          const start = filter.filters.find((f) => f.operator === 'gte');
          const end = filter.filters.find((f) => f.operator === 'lte');
          if (start) {
            result.start = start.value;
          }
          if (end) {
            result.end = end.value;
          }
          return result;
        }
        return null;
      },
      filter: (state) => state.filter,
      localFilters: (state) => state.localFilters,
      localDateFilter: (state) => state.localFilters.find((filter) => isDate(filter)),

      // filter: ({ filter }) => filter,
      search_query: ({ search_query }) => search_query,
      sort: ({ sort }) => sort,
      sales: ({ sales }) => sales,
    },
    mutations: {
      /**
       * Установка фильтра по дате
       *
       * @param {Object} state
       * @param {Object} payload
       * @param {String} [payload.start] дата начала периода
       * @param {String} [payload.end] дата конца периода
       * @param {String} [payload.fieldName] ключ поля с датой
       */
      [filterMutations.SET_FILTER_BY_DATE]: (state, payload) => {
        // убираем из стейта фильтр по дате
        state.filter = state.filter.filter(({ field }) => field !== 'dadd');

        // заготовка фильтра по дате
        const daddFilter = {
          field: 'dadd',
          logic: 'and',
          filters: [],
        };

        // установка начала периода
        if (payload.start) {
          daddFilter.filters.push({
            operator: 'gte',
            value: payload.start,
          });
        }

        // установка конца периода
        if (payload.end) {
          daddFilter.filters.push({
            operator: 'lte',
            value: payload.end,
          });
        }

        // добавляем фильтр в стейт при наличии хотя бы одной границы периода
        if (daddFilter.filters.length > 0) {
          state.filter.push(daddFilter);
        }
      },

      /**
       * Установка фильтра по имени поля с заменой
       *
       * @param {Object} state
       * @param {Object} payload
       * @param {String} payload.value значение поля
       * @param {String} payload.fieldName ключ поля
       */
      [filterMutations.SET_FILTER_BY]: (state, payload) => {
        const { value, fieldName } = payload;
        // убираем из стейта фильтр если он есть
        state.filter = state.filter.filter(({ field }) => field !== fieldName);

        if (value) {
          state.filter.push({
            field: fieldName,
            logic: 'and',
            filters: [
              {
                operator: 'eq',
                value,
              },
            ],
          });
        }
      },

      [filterMutations.SET_SEARCH_QUERY]: (state, payload) => {
        state.search_query = payload;
      },

      setFilters: (state, filters) => {
        state.filter = filters;
      },
      setLocalDateFilter: (state, dateFilter) => {
        // Need to check outside, is there a dadd column at all
        const _dateFilter = { ...dateFilter };
        _dateFilter.active = true;
        const _filters = [...state.localFilters];
        const oldDateIndex = _filters.findIndex((filter) => isDate(filter));
        if (oldDateIndex !== -1) _filters.splice(oldDateIndex, 1, _dateFilter);
        else _filters.push(dateFilter);
        state.localFilters = _filters;
      },
      setLocalFilters: (state, localFilters) => (state.localFilters = localFilters),
      resetFilters: (state, filters, localFilters) => {
        state.filter = filters || [];
        state.localFilters = localFilters || [];
      },

      [filterMutations.SET_PRODUCT_FILTER]: (state, payload) => {
        const newFilters = state.filter.filter((el) => el.field === 'dadd');
        payload.forEach((f) => {
          const filter_obj = {
            field: f.field,
            filters: f.utilities.diapasonOpened ? f.filters : [f.filters[0]],
            logic: f.logic,
          };
          newFilters.push(filter_obj);
        });
        console.log('pushing new filters');
        state.filter = newFilters;
      },

      [filterMutations.RESET_FILTER]: (state) => {
        state.filter = state.filter.filter((f) => f.field === 'dadd');
      },

      [filterMutations.SET_SORT]: (state, payload) => {
        console.log('setting sort', state.sort, payload);
        state.sort = [payload];
      },
      [filterMutations.RESET_SORT]: (state) => {
        console.log('resetting sort', state.sort);
        state.sort = null;
      },
      [filterMutations.SET_SALES]: (state, payload) => (state.sales = payload),
      [filterMutations.RESET_SALES]: (state) => (state.sales = INIT_SALES_TYPE),
    },
  };
}
