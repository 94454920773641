// Динамический модуль таба для грида с пагинацией и фильтрацией

import { createFilterModule } from './filtering';
import { createPagingModule } from './paging';
import * as gridMutations from '@/store/modules/metric/ozon/mutations/grid';
import { createResetMutation } from '@/store/helpers/reset';

const defaultState = {
  grid: [],
};

/**
 * Создание модуля грида
 */
export function createGridModule({
  filter = {},
  paging = {},
  state = {},
  getters = {},
  mutations = {},
  actions = {},
  modules = {},
} = {}) {
  const initState = () => ({
    ...defaultState,
    ...state,
  });

  return {
    namespaced: true,
    state: initState,
    getters: {
      grid: (state) => state.grid,
      ...getters,
    },
    modules: {
      filter: createFilterModule(filter),
      paging: createPagingModule(paging),
      ...modules,
    },
    mutations: {
      [gridMutations.SET_GRID]: (state, grid = []) => (state.grid = grid),
      ...mutations,
      reset: createResetMutation(initState),
    },
    actions,
  };
}
