// Мутации фильтрация
export const SET_FILTER_BY_DATE = 'setFilterByDate';
export const SET_FILTER_BY = 'setFilterBy';
export const SET_SEARCH_QUERY = 'setSearchQuery';
export const SET_PRODUCT_FILTER = 'setProductFilter';
export const RESET_FILTER = 'resetFilter';
export const SET_SORT = 'setSort';
export const RESET_SORT = 'resetSort';
export const SET_SALES = 'setSales';
export const RESET_SALES = 'resetSales';
