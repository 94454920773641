<template>
  <v-card min-height="500" class="d-flex flex-column px-6 py-8" elevation="0" height="100%">
    <div class="d-flex justify-space-between">
      <div class="text-h6 font-weight-regular pa-0 font--lh24">{{ title }}</div>
    </div>
    <section>
      <div class="d-flex justify-center">
        <v-img src="img/in_development.png" max-width="108" max-height="108"></v-img>
      </div>
      <div class="pa-0 mb-3 mt-9 text-center text-h6 font-weight-regular font--lh24">Этот раздел еще в разработке</div>
      <div class="pa-0 text-center text-subtitle-1 font--lh24 font--graphite3">
        Мы можем отправить вам уведомление, когда раздел будет готов
      </div>
    </section>
    <!-- <div>
      <v-btn class="text-caption font-weight-bold" color="primary">Прислать уведомление</v-btn>
    </div> -->
  </v-card>
</template>

<script>
export default {
  name: 'InDevelopment',
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style scoped>
section {
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
