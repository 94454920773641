<template>
  <div class="mx-6 my-2">
    <v-row>
      <v-col cols="12">
        <ProductDescription :product_card="product_card" @cat:change="$emit('cat:change', $event)"
      /></v-col>
      <v-col cols="12">
        <div class="d-flex pt-4" style="width: fit-content">
          <v-tabs v-model="tab" class="tabs tab_act ml-8" hide-slider background-color="#F5F5F6" height="44px">
            <v-tab :ripple="false" rounded class="text-none tabs__link"> Метрики </v-tab>
            <v-tab :ripple="false" rounded class="text-none tabs__link" @click="setSlider(true)"> SEO </v-tab>
          </v-tabs>
        </div>
        <v-container fluid class="px-8 pt-8 product_card__container">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="d-flex flex-column">
                <div class="d-flex">
                  <DateFilter
                    :card-datapicker="true"
                    :value="{ start, end }"
                    :height="44"
                    @change-date="setByDate"
                    @change="applyDate"
                  />
                  <div>
                    <SalesTypeToogle class="mr-4" :card-type="salesType" @change="changeType($event)" />
                  </div>
                  <v-menu offset-y open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn width="220" outlined v-bind="attrs" height="44px" class="fb-btn" v-on="on">
                        <v-progress-circular v-if="fileLoading" indeterminate color="primary"></v-progress-circular>
                        <div v-else>Выгрузить метрики</div>
                      </v-btn>
                    </template>
                    <v-list v-for="(item, index) in items" :key="index">
                      <v-list-item @click="exportFile(item.export)">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div>
                  <ProductGraph :product_card="product_card" />
                </div>
                <div>
                  <ProductGraphTable :product_card="product_card" />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-menu offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn width="220" outlined v-bind="attrs" class="fb-btn" v-on="on">
                    <v-progress-circular v-if="fileLoading" indeterminate color="primary"></v-progress-circular>
                    <div v-else>Выгрузить метрики</div>
                  </v-btn>
                </template>
                <v-list v-for="(item, index) in items" :key="index">
                  <v-list-item @click="exportFile(item.export)">
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <ProductInfo :show-slider="showSlider" :product_card="product_card" :close-panel="closePanel" />
              <v-row>
                <v-col cols="12"
                  ><ProductPosition
                    class="mt-10"
                    :product_card="product_card"
                    @cat:change="$emit('cat:change', $event)"
                /></v-col>
                <v-col cols="12"> <ProductTag :product_card="product_card" /></v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductDescription from '@/components/metric/wb/product/ProductDescription';
import ProductGraphTable from '@/components/metric/wb/product/ProductGraphTable';
import ProductPosition from '@/components/metric/wb/product/ProductPosition';
import ProductGraph from '@/components/metric/wb/product/ProductGraph';
import ProductInfo from '@/components/metric/wb/product/ProductInfo';
import ProductTag from '@/components/metric/wb/product/ProductTag';
import DateFilter from '@/components/common/DateFilter';

import { INIT_SALES_TYPE } from '@/constants.js';
import SalesTypeToogle from '@/components/common/SalesTypeToogle';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'ProductCard',

  components: {
    ProductDescription,
    ProductGraphTable,
    ProductPosition,
    SalesTypeToogle,
    ProductGraph,
    ProductInfo,
    DateFilter,
    ProductTag,
  },

  props: {
    closePanel: {
      type: Boolean,
    },
  },

  data() {
    return {
      items: [
        { export: 'categories', text: 'Категории' },
        { export: 'tags', text: 'Запросы' },
      ],

      tab: 0,
      showSlider: false,
    };
  },

  computed: {
    ...mapState('wbProductCard', ['product', 'fileLoading']),
    ...mapGetters('wbProductCard', ['sdate', 'edate', 'pid', 'salesType']),

    start: {
      get() {
        return this.sdate;
      },
      set(v) {
        this.setStartDate(v);
      },
    },

    end: {
      get() {
        return this.edate;
      },
      set(v) {
        this.setEndDate(v);
      },
    },

    product_card() {
      return this.product;
    },
  },

  watch: {
    product() {
      this.product_card;
    },

    closePanel(v) {
      if (!v) {
        this.tab = 0;
        this.setSalesType(INIT_SALES_TYPE);
      }
    },
  },

  methods: {
    ...mapMutations('wbProductCard', ['setStartDate', 'setEndDate', 'setSalesType', 'setSlider']),
    ...mapActions('wbProductCard', ['getExportListCategories', 'getExportListTags']),

    setByDate(dates) {
      this.setStartDate(dates.start);
      this.setEndDate(dates.end);
    },

    applyDate({ start, end }) {
      this.setByDate({ start, end });
      this.$store.dispatch('wbProductCard/getCard');
    },

    changeType(e) {
      this.setSalesType(e);
      this.$store.dispatch('wbProductCard/getCard');
    },

    exportFile(e) {
      if (e == 'categories') {
        this.getExportListCategories();
      } else {
        this.getExportListTags();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  border-radius: 4px;
  height: 44px !important;

  &__link {
    height: 36px;
    font-size: 14px;
    color: #777c87 !important;
    font-weight: 400;
    margin: 4px 4px 0px !important;
    padding: 0 12px;
    letter-spacing: 0 !important;
  }

  &__link.v-tab--active {
    background-color: #4971d0 !important;
    color: #ffffff !important;
    border-radius: 4px;
    border: none;
    height: 36px;
  }
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #777c87 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  color: black !important;
  background: white;
  opacity: 1;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: inherit !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border: 1px solid black !important;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:nth-child(1) {
  border-right-color: transparent !important;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:nth-child(2) {
  border-right-color: transparent !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background-color: #e4e4e4;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  cursor: pointer;
}
.v-list {
  padding: 0 !important;
}
</style>
