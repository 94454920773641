<template>
  <v-menu v-model="showDateFilter" offset-y :close-on-content-click="false" transition="scale-transition">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" :height="height" class="mr-2 date-filter__button" outlined v-on="on">
        <v-icon class="mr-md-1 date-filter__icon">mdi-calendar</v-icon>
        <span class="d-none d-md-inline">{{ dateFilterLabel }}</span>
      </v-btn>
    </template>
    <div class="date-filter font">
      <!-- <div class="date-filter__option" @click="setToday">За сегодня</div> -->
      <!-- <div class="date-filter__option" @click="setThisWeek">За неделю</div> -->
      <!-- <div class="date-filter__option" @click="setLast7Days">За последние 7 дней</div> -->
      <div class="date-filter__option" @click="setLast30Days">За последние 30 дней</div>
      <!-- <div class="date-filter__option" @click="setLast60Days">За последние 60 дней</div> -->
      <div class="date-filter__option" @click="setLast90Days">За последние 90 дней</div>
      <div class="date-filter__option" @click="setLast180Days">За последние 180 дней</div>
      <div class="date-filter__option" @click="setLast360Days">За последние 360 дней</div>
      <!--      <div v-if="showAll" class="date-filter__option" @click="setAllTime">За всё время</div>-->
      <div class="date-filter__filter d-flex">
        <v-menu v-model="startDateMenu" offset-y transition="scale-transition">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="startDateFormatted"
              class="date-filter__date-field mr-2"
              dense
              outlined
              hide-details
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              readonly
              v-on="on"
              @click:prepend-inner="startDateMenu = !startDateMenu"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start"
            class="date-filter__picker"
            :allowed-dates="isBeforeDate"
            :first-day-of-week="1"
            locale="ru-RU"
            no-title
            @input="startDateMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-menu v-model="endDateMenu" offset-y transition="scale-transition">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="endDateFormatted"
              class="date-filter__date-field mr-2"
              dense
              outlined
              hide-details
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              readonly
              v-on="on"
              @click:prepend-inner="endDateMenu = !endDateMenu"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end"
            class="date-filter__picker"
            :allowed-dates="isAfterDate"
            :first-day-of-week="1"
            locale="ru-RU"
            no-title
            @input="endDateMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-btn color="primary" elevation="0" @click="setArbitraryPeriod">ok</v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
import {
  isSameDay,
  isMonday,
  isSunday,
  isThisWeek,
  isToday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isThisMonth,
  isAfter,
  isBefore,
  // isEqual,
  formatISO,
  subDays,
  startOfWeek,
  endOfWeek,
  setDate,
  format,
  // endOfMonth,
  // startOfMonth
} from 'date-fns';

import { mapMutations, mapState } from 'vuex';

export default {
  name: 'DateFilter',
  props: {
    value: {
      type: [Object, null],
      default: null,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    cardDatapicker: {
      type: Boolean,
      default: false,
    },

    height: {
      type: [String, Number],
      default: '36',
    },
  },

  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      showDateFilter: false,
    };
  },

  computed: {
    ...mapState('common', ['sdate', 'edate']),
    start: {
      get() {
        return (this.value && this.value.start) || '';
      },
      set(value) {
        const newStartDate = new Date(value);
        const today = new Date();
        let newEndDate = new Date(this.end);
        // Проверяем, отстоит ли конечная дата на 29 дней или больше
        const startPlus29 = setDate(newStartDate, newStartDate.getDate() + 29);
        if (isAfter(newEndDate, startPlus29)) {
          //  Не позже ли сегодняшнего дня дата на 29 дней больше. Да - ставим сегодняшний. Нет - ставим  + 29 дней
          if (isAfter(startPlus29, today)) newEndDate = today;
          else newEndDate = startPlus29;
        }
        const newEndValue = format(newEndDate, 'yyyy-MM-dd');
        this.$emit('change-date', { start: value, end: newEndValue });
      },
    },

    end: {
      get() {
        return (this.value && this.value.end) || '';
      },
      set(value) {
        this.$emit('change-date', { start: this.start, end: value });
      },
    },

    startDateFormatted() {
      return this.formatDate(this.start);
    },

    endDateFormatted() {
      return this.formatDate(this.end);
    },

    dateFilterLabel() {
      console.log(!this.start && !this.end);
      // Фильтр по дате не назначен
      let label = '';

      if (!this.start && !this.end) {
        label = 'за все время';
      }

      // Фильтр установлен на период
      if (this.start && this.end) {
        const startDate = new Date(this.start);
        const endDate = new Date(this.end);

        // Сегодня
        if (isToday(startDate) && isToday(endDate)) {
          label = 'За сегодня';
        }

        // Эта неделя
        if (isMonday(startDate) && isSunday(endDate) && isThisWeek(startDate)) {
          label = 'За неделю';
        }

        // Этот месяц
        if (isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate) && isThisMonth(startDate)) {
          label = 'За месяц';
        }

        // Один день
        if (isSameDay(startDate, endDate)) {
          label = this.formatDate(this.start);
        }

        // Произвольная дата
        label = `c ${this.formatDate(this.start)} по ${this.formatDate(this.end)}`;
      }

      // Фильтр по дате установлен только на один день "с" или "до"
      if (!this.end) {
        label = `c ${this.formatDate(this.start)}`;
      }

      if (!this.start) {
        label = `по ${this.formatDate(this.end)}`;
      }

      return label;
    },
  },
  mounted() {
    // устанавливаем фиксированные даты из common
    this.start = this.sdate;
    this.end = this.edate;
  },
  methods: {
    ...mapMutations('common', ['setStartDate', 'setEndDate']),
    saveDate({ start, end }) {
      this.setStartDate(start);
      this.setEndDate(end);
    },
    applyDateFilter({ start, end }) {
      this.saveDate({ start, end });
      this.$emit('change', { start, end });
      this.showDateFilter = false;
    },
    // первая дата
    isBeforeDate(val) {
      const date = new Date(val);
      const today = new Date();
      const endDay = new Date(this.end);
      return isBefore(date, endDay) && isBefore(date, today);
    },
    // вторая дата
    isAfterDate(val) {
      const date = new Date(val);
      const today = new Date();
      const maxEndDay = new Date(this.start);
      const startDay = new Date(this.start);
      maxEndDay.setDate(maxEndDay.getDate() + 30);
      // больше даты начала на 29 дней и меньше сегодня и больше даты начала

      if (!this.cardDatapicker) {
        return isBefore(date, maxEndDay) && isBefore(startDay, date) && isBefore(date, today);
      } else {
        return isBefore(startDay, date) && isBefore(date, today);
      }
    },

    setToday() {
      const today = this.formatDateISO(new Date());
      this.applyDateFilter({ start: today, end: today });
      this.saveDate({ start: today, end: today });
    },
    setThisWeek() {
      const today = new Date();
      const start = this.formatDateISO(startOfWeek(today, { weekStartsOn: 1 }));
      const end = this.formatDateISO(endOfWeek(today, { weekStartsOn: 1 }));
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setLast7Days() {
      const today = new Date();
      const start = this.formatDateISO(subDays(today, 6));
      const end = this.formatDateISO(today);
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setLast30Days() {
      const today = new Date();
      const start = this.formatDateISO(subDays(today, 29));
      const end = this.formatDateISO(today);
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setLast60Days() {
      const today = new Date();
      const start = this.formatDateISO(subDays(today, 59));
      const end = this.formatDateISO(today);
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setLast90Days() {
      const today = new Date();
      const start = this.formatDateISO(subDays(today, 89));
      const end = this.formatDateISO(today);
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setLast180Days() {
      const today = new Date();
      const start = this.formatDateISO(subDays(today, 179));
      const end = this.formatDateISO(today);
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setLast360Days() {
      const today = new Date();
      const start = this.formatDateISO(subDays(today, 359));
      const end = this.formatDateISO(today);
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setAllTime() {
      const today = new Date();
      const start = this.formatDateISO(new Date(2021, 0, 1)); // c 1 января 2021 начнем отсчет
      const end = this.formatDateISO(today);
      this.applyDateFilter({ start, end });
      this.saveDate({ start, end });
    },
    setArbitraryPeriod() {
      // выбранные даты уже находятся в стейте (и здесь через геттер)
      this.applyDateFilter({ start: this.start, end: this.end });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    formatDateISO(date) {
      return formatISO(date, { representation: 'date' });
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filter {
  background-color: #ffffff;

  &__button.v-btn:not(.v-btn--round).v-size--default {
    @media (max-width: 960px) {
      min-width: 0;
      padding: 0 8px;
    }
  }

  &__button .date-filter__icon {
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  &__picker {
    & .theme--light.v-btn.v-btn--disabled {
      color: #dadada !important;
    }
  }

  &__date {
    padding: 10px;
    border: 1px solid #cbcdd1;
    border-radius: 3px;
  }

  &__filter {
    padding: 16px;
  }

  &__date-field {
    font-size: 14px;
    width: 125px;

    &.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 36px;
    }

    &.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {
      margin-top: 6px;
    }

    & i.v-icon {
      font-size: 20px;
    }
  }

  &__option {
    cursor: pointer;
    padding: 12px 16px;
    border-bottom: 1px solid #e6e7e9;

    &:hover {
      background-color: #eeeeee;
    }
  }
}
</style>
