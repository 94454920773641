<template>
  <v-dialog v-model="show" max-width="800">
    <v-card class="px-8 py-4">
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>

        <v-btn icon @click.stop="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="text-h5 font--lh34 mb-6 mt-n6">Как работают Моментальные выплаты</div>
      <div class="d-flex mb-6">
        <v-avatar color="#D2DDFA" size="32"> <span class="primary--text text-subtitle-2">1</span> </v-avatar>
        <v-card-text class="text-subtitle-1 pa-0 ml-5 font--black1"
          >Получайте доход от маркетплейсов на счёт в Модульбанке.</v-card-text
        >
      </div>
      <div class="d-flex mb-6">
        <v-avatar color="#D2DDFA" size="32"> <span class="primary--text text-subtitle-2">2</span> </v-avatar>
        <v-card-text class="text-subtitle-1 pa-0 ml-5 font--black1"
          >Размер комиссии всего 2% от суммы выплаты.</v-card-text
        >
      </div>
      <div class="d-flex mb-6">
        <v-avatar color="#D2DDFA" size="32"> <span class="primary--text text-subtitle-2">3</span> </v-avatar>
        <v-card-text class="text-subtitle-1 pa-0 ml-5 font--black1"
          >Срок предоставления заёмных средств по каждой из выплат 20 календарных дней.</v-card-text
        >
      </div>
      <div class="d-flex mb-6">
        <v-avatar color="#D2DDFA" size="32"> <span class="primary--text text-subtitle-2">4</span> </v-avatar>
        <v-card-text class="text-subtitle-1 pa-0 ml-5 font--black1"
          >В день получения выручки на счёт спишем сумму выданной выплаты и начисленную комиссию.</v-card-text
        >
      </div>
      <div class="d-flex mb-6">
        <v-avatar color="#D2DDFA" size="32"> <span class="primary--text text-subtitle-2">5</span> </v-avatar>
        <v-card-text class="text-subtitle-1 pa-0 ml-5 font--black1"
          >Для досрочного погашения напишите своему маркет менеджеру.</v-card-text
        >
      </div>
      <div class="d-flex mb-6">
        <v-avatar color="#D2DDFA" size="32"> <span class="primary--text text-subtitle-2">6</span> </v-avatar>
        <v-card-text class="text-subtitle-1 pa-0 ml-5 font--black1"
          >В случае невозврата выплаты начислим неустойку 1% на сумму задолженности.</v-card-text
        >
      </div>
      <div class="d-flex mb-6">
        <v-avatar color="primary" size="32">
          <svg width="14" height="18" class="mx-2">
            <use xlink:href="#doc"></use>
          </svg>
        </v-avatar>
        <v-card-text class="text-subtitle-1 pa-0 ml-5"
          >Посмотрите
          <a class="text-decoration-underline" target="_blank" :href="oferta"> полные условия</a>
          оферты.</v-card-text
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { OFERTA_URL } from '@/constants.js';

export default {
  name: 'InfoMomentPopup',
  props: {
    value: Boolean,
  },
  data() {
    return {
      oferta: OFERTA_URL,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>
.theme--light.v-card > .v-card__text {
  color: rgba(0, 0, 0, 1);
}
</style>
