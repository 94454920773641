<template>
  <div>
    <div class="d-flex flex-wrap justify-space-between mb-11">
      <div class="mb-2">
        <modul-grid-filters
          ref="grid-filters"
          :module-name="`${productModule}`"
          :action="`${action}`"
          :columns="headers"
          :filters="true"
          :footer-props="footerProps"
        >
        </modul-grid-filters>
      </div>
      <v-text-field
        v-model="search"
        dense
        single-line
        hide-details
        height="44"
        class="mb-2"
        style="max-width: 400px"
        outlined
        placeholder="Введите наименование"
        clearable
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>
    <vue-horizontal scroll class="horizontal__scroll" snap="none">
      <div class="table__container">
        <v-data-table
          :mobile-breakpoint="100"
          class="stocklist-table"
          :headers="headers"
          :items="indexedGrid"
          item-key="id"
          :no-data-text="noDataText"
          fixed-header
          :items-per-page="50"
          :footer-props="footerProps"
          :server-items-length="total"
          :options.sync="options"
          @update:options="onUpdateOptions($event)"
        >
          <template #[`item.name`]="{ value }">
            <span class="two-lines" :title="value" style="min-width: 60px; max-width: 156px">{{
              value === null ? '-' : value
            }}</span>
          </template>

          <template #[`item.primaryImage`]="{ value }">
            <img width="50" class="my-1 ml-4" :src="value" />
          </template>

          <template #[`item.article`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.sku`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.warehouse`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.forSale`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
          </template>

          <template #[`item.reserved`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
          </template>

          <template #[`item.promised`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
          </template>

          <template #[`item.barcode`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.category`]="{ value }">
            <span>{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.discounted`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>
        </v-data-table>
      </div>
    </vue-horizontal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import { TYPES } from '@/components/common/modul-grid/_utils/filters';
import { analiticMixin } from '@/mixins/analiticMixin';
import { createOzonStockModule } from '@/store/modules/analitic/ozon/stock';
import VueHorizontal from 'vue-horizontal';
import { getOzonStockFiltersData } from '@/api/analitic/stock';

import { makeRub } from '@/mixins/makeRub';
import { mapState } from 'vuex';

export default {
  name: 'StockTable',
  components: { ModulGridFilters, VueHorizontal },
  mixins: [makeRub, analiticMixin],
  inject: ['filterMutations', 'pagingMutations'],

  data() {
    return {
      productModule: 'ozonAnaliticStock',
      action: 'ozonAnaliticStock/ozonStock',
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        { text: 'Наименование', value: 'name', type: TYPES.EQ_STRING, name: 'Название' },
        { text: '', value: 'primaryImage', width: '126px', sortable: false },
        { text: 'Артикул поставщика', value: 'article', width: '126px', type: TYPES.EQ_STRING },
        { text: 'SKU', value: 'sku', width: '154px', type: TYPES.EQ_STRING },
        { text: 'Склад', value: 'warehouse', width: '148px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Доступно к продаже на Ozon', value: 'forSale', width: '160px', type: TYPES.NUMBER },
        {
          text: 'Зарезервировано для покупки, возврата и перевозки между складами',
          value: 'reserved',
          width: '160px',
          type: TYPES.NUMBER,
        },
        { text: 'Указано в подтверждённых будущих поставках', value: 'promised', width: '154px', type: TYPES.NUMBER },
        { text: 'Штрихкод', value: 'barcode', width: '126px', type: TYPES.EQ_STRING },
        { text: 'Категория', value: 'category', width: '250px', type: TYPES.MULTIPLE, options: [] },
      ],
    };
  },

  computed: {
    ...mapState('ozonAnaliticStock', ['filtersData']),
    indexedGrid() {
      return this.grid.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
  },

  created() {
    if (!this.$store.hasModule(this.productModule)) {
      this.$store.registerModule(this.productModule, createOzonStockModule());
    }
    this.debouncedSearch = debounce(this.getOzonStock, 800);
  },

  async mounted() {
    const statesAndTypes = await getOzonStockFiltersData();
    this.setOptions({ field: 'category', options: statesAndTypes?.category });
    this.setOptions({ field: 'warehouse', options: statesAndTypes?.warehouse });

    // нет фильтра даты, поэтому вызов вручную
    this.getOzonStock();
  },

  methods: {
    getOzonStock() {
      this.$store.dispatch(`${this.action}`);
    },
    setOptions({ field, options }) {
      this.$store.commit(`${this.productModule}/setOptions`, { field, options });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  opacity: 1 !important;
  background-color: transparent;
}

.v-data-table.stocklist-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.theme--light.v-data-table.stocklist-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.table__container {
  padding-top: 20px;
}

.horizontal__scroll::v-deep .v-hl-btn {
  top: -40px;
  align-items: start;
}
.horizontal__scroll::v-deep .v-hl-btn-next {
  right: 22px;
}

.horizontal__scroll::v-deep .v-hl-btn-prev {
  left: 22px;
}
</style>
