<template>
  <v-card
    style="background-color: #f2f6ff; border-radius: 2px; height: 100%"
    class="d-flex flex-column justify-space-between pa-4 rounded-lg"
    elevation="0"
  >
    <div>
      <div class="text-subtitle-2 mb-5">Склады</div>
      <div v-if="!showStocks && isLoaded" cols="12" class="d-flex justify-space-between mb-2">
        <div
          class="d-flex align-center text-uppercase font--graphite3"
          style="font-size: 0.625rem !important; letter-spacing: 0.1em"
        >
          Товары на всех складах
        </div>
        <div class="text-subtitle-2">{{ sumQuantity }} шт</div>
      </div>

      <div v-if="showStocks" cols="12" class="mb-2">
        <div v-for="warehouse in analitic.stock" :key="warehouse.warehouseName" class="d-flex justify-space-between">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            {{ warehouse.warehouseName }}
          </div>
          <div class="text-subtitle-2">{{ warehouse.fullQuantity }} шт</div>
        </div>
      </div>
    </div>
    <v-btn text color="primary" class="mt-4 info__btn" @click="stocksClick"
      >{{ !showStocks ? 'Подробнее' : 'Свернуть' }}
      <v-icon class="ml-1">{{ !showStocks ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'StockInfo',
  data: () => ({
    showStocks: false,
  }),
  computed: {
    ...mapState('wbAnaliticInfo', ['analitic', 'isLoaded']),
    ...mapGetters('wbAnaliticInfo', ['sumQuantity']),
  },

  methods: {
    stocksClick() {
      this.analitic.stock.length > 0 ? (this.showStocks = !this.showStocks) : (this.showStocks = false);
    },
  },
};
</script>

<style scoped>
.info__btn.v-btn:not(.v-btn--round).v-size--default {
  justify-content: flex-start;
  padding: 0;
}
</style>
