<template>
  <div>
    <v-row class="d-flex mb-1 align-center justify-space-between">
      <v-col lg="6" md="7" sm="12" class="mt-0">
        <div style="width: fit-content">
          <v-tabs v-model="tab" class="tabs" background-color="#F5F5F6" height="44px">
            <v-tabs-slider color="transparent"></v-tabs-slider>
            <v-tab v-for="{ name } of tabs" :key="name" :ripple="false" class="text-none tabs__link">
              {{ name }}
            </v-tab>
          </v-tabs>
        </div>
      </v-col>

      <div class="mx-3" style="width: 300px">
        <SearchBrand v-if="tab == '1'" v-model="brandModal" dense @cat:change="$emit('cat:change', $event)" />
      </div>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ component, name, prop } of tabs" :key="name">
        <component :is="component" v-bind="prop" @cat:change="$emit('cat:change', $event)"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SearchBrand from '@/components/metric/wb/brands/SearchBrand';
import CatalogMain from '@/components/metric/wb/catalog/CatalogMain';
import BrandMain from '@/components/metric/wb/brands/BrandMain';
import CountryMain from '@/components/metric/wb/country/CountryMain';

export default {
  name: 'CataloTabsMain',
  components: { CatalogMain, BrandMain, CountryMain, SearchBrand },
  data() {
    return {
      tab: 0,
      brandModal: null,
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'Категории',
          component: 'CatalogMain',
          prop: {
            action: `wbCatalog/catalogList`,
          },
          icon: 'mdi-format-list-bulleted',
        },
        {
          name: 'Бренды',
          component: 'BrandMain',
          icon: 'mdi-tag-outline',
        },
        {
          name: 'Страны',
          component: 'CountryMain',
          icon: 'mdi-flag-outline',
        },
      ];
    },
  },

  watch: {
    brandModal(current) {
      if (current) {
        this.name = current.name;
        this.id = current.id;
      } else {
        this.name = '';
        this.id = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Табы начинаются с index 2
.tabs {
  border-radius: 4px;
  height: 44px !important;

  &__link {
    height: 36px;
    font-size: 14px;
    color: #777c87 !important;
    font-weight: 400;
    margin: 4px 4px 0px !important;
    padding: 0 12px;
    letter-spacing: 0 !important;
  }

  &__link.v-tab--active {
    background-color: #4971d0 !important;
    color: #ffffff !important;
    border-radius: 4px;
    border: none;
    height: 36px;
  }
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #777c87 !important;
}
</style>
