<template>
  <div>
    <v-tabs v-model="tab" class="mb-5">
      <v-tab v-for="{ name, pid } of tabs" :key="pid || name" :ripple="false" class="text-none">{{ name }} </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ component, pid, name, prop, selectedType } of tabs" :key="pid || name">
        <component
          :is="component"
          :id="id"
          :selected-type="selectedType"
          v-bind="prop"
          @cat:change="$emit('cat:change', $event)"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SearchList from '@/components/metric/wb/searchProduct/SearchList';

export default {
  name: 'SearchMain',

  components: {
    SearchList,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      productModule: this.id ? `wbMetricModule/${this.id}` : 'wbMetricModule',
      tab: 0,
    };
  },

  computed: {
    tabs() {
      return [
        {
          pid: 0,
          name: 'Поиск',
          component: 'SearchList',
          action: `${this.productModule}/getProductList`,
          prop: {
            productModule: this.productModule,
          },
        },
      ];
    },
  },
};
</script>
