import * as tabMutations from '@/store/modules/metric/wb/mutations/subTab';
import * as api from '@/api';
import * as pagingMutations from '@/store/modules/metric/wb/mutations/paging';
import { createPagingModule } from './paging';
import { createFilterModule } from './filtering';
import { filter } from '@/mixins/filterDate.js';
import { makeDataForGraph } from '@/utils/helpers';

const defaultConfig = {
  dataArray: [],
};

/**
 * Создание модуля subtab
 */
export function createSubTabModule({ id, type }) {
  const initState = () => ({
    ...defaultConfig,
    id,
    type,
  });
  return {
    namespaced: true,
    state: initState,
    getters: {
      dataArray: (state) => state.dataArray,
      graphSales: (state) => makeDataForGraph(state.dataArray, 'sales'),
      graphIncome: (state) => makeDataForGraph(state.dataArray, 'income'),
    },
    modules: {
      filter: createFilterModule(filter),
      paging: createPagingModule(),
    },
    mutations: {
      [tabMutations.SET_SUB_TAB]: (state, dataArray = []) => (state.dataArray = dataArray),
      setStartDate: (state, sdate) => (state.sdate = sdate),
      setEndDate: (state, edate) => (state.edate = edate),
    },

    actions: {
      async getCategorySubTab({ commit, getters: { filter, search_query, pageSize, page, sort, sales } }) {
        const requestPayload = {
          type: type,
          cid: id,
          filter,
          search_query,
          pageSize,
          page,
          sort,
          salesType: sales,
          marketplace: 'wb',
        };
        const res = await api.getCategorySubTab(requestPayload);
        if (res) {
          commit(tabMutations.SET_SUB_TAB, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
      },

      async getBrandSubTab({ commit, getters: { filter, search_query, pageSize, page, sales, sort } }) {
        const requestPayload = {
          type: type,
          bid: id,
          filter,
          search_query,
          pageSize,
          page,
          salesType: sales,
          marketplace: 'wb',
          sort,
        };
        const res = await api.getBrandSubTab(requestPayload);
        if (res) {
          commit(tabMutations.SET_SUB_TAB, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
      },

      async getSellerSubTab({ commit, getters: { filter, search_query, pageSize, page, sales, sort } }) {
        const requestPayload = {
          type: type,
          sid: id,
          filter,
          search_query,
          pageSize,
          page,
          salesType: sales,
          marketplace: 'wb',
          sort,
        };
        const res = await api.getSellerSubTab(requestPayload);
        if (res) {
          commit(tabMutations.SET_SUB_TAB, res.list);
          commit(pagingMutations.SET_TOTAL, Number(res.total) || 0);
        }
      },
    },
  };
}
