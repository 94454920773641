<template>
  <div class="font">
    <v-card class="pa-4 mb-4 font" min-height="178">
      <v-row dense>
        <v-col cols="12">
          <div class="mx-auto">
            <div class="text-h4 font-hl37 font--black1 font-weight-bold d-flex mb-1">
              <v-icon color="#5E8C00" class="mr-2"> mdi-checkbox-marked-circle-outline</v-icon
              >{{ makeRub(limit || 0, 0) }}
            </div>
            <v-card-subtitle class="text-caption font-hl24 font--graphite3 pa-0 mb-4">
              Доступно для кредитования по всем поставщикам
            </v-card-subtitle>
            <v-card-actions class="pa-0">
              <v-btn color="primary" class="text-caption font-weightbold" @click="showCreditSidePanelPayment(true)">
                оплата поставщику
              </v-btn>
            </v-card-actions>
          </div>
          <div class="pic">
            <v-img src="img/purse.png"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="limit2" class="pa-4" min-height="130">
      <v-row dense>
        <v-col cols="12">
          <div class="mx-auto">
            <div class="text-h4 font-hl37 font-weight-bold font--red d-flex mb-1 align-center">
              <svg width="16" height="20" class="mr-2">
                <use xlink:href="#moneyBox"></use>
              </svg>
              {{ makeRub(-12000, 0) }}
            </div>
            <v-card-subtitle class="text-caption font-hl24 font--graphite3 pa-0 mb-4">
              Общая сумма задолженности по всем поставщикам
            </v-card-subtitle>
          </div>
          <div class="pic">
            <img src="img/money.png" width="65px" alt="Money" class="icon" />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'CreditCardInfoMoney',

  mixins: [makeRub],

  data() {
    return {
      limit2: false,
    };
  },
  computed: {
    ...mapGetters('credit', ['limit']),
  },
  methods: {
    ...mapMutations('credit', ['showCreditSidePanelPayment']),
  },
};
</script>

<style scoped>
.pic {
  float: right; /* Обтекание картинки текстом */
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
