<template>
  <div>
    <v-form v-model="valid" @submit.prevent="initLogin">
      <v-text-field
        v-model="phone"
        v-mask="'+7(9##) ###-##-##'"
        background-color="#ffffff"
        label="Телефон"
        placeholder=" "
        outlined
        autofocus
        :rules="phoneRules"
        type="text"
      ></v-text-field>

      <v-btn :disabled="!valid" x-large block color="primary" type="submit" class="mb-8">Продолжить</v-btn>
    </v-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { required, phone } from '@/utils/rules';
import { clearPhone } from '@/utils/helpers';
import { mapMutations } from 'vuex';

export default {
  name: 'FBRLoginInit',
  directives: { mask },

  props: {
    value: Boolean,
  },

  data() {
    return {
      phoneRules: [required('Введите номер телефона'), phone],
      phone: '',
      // valid: false,
    };
  },

  computed: {
    phoneDigits() {
      return clearPhone(this.phone);
    },
    valid: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapMutations('profile', ['setLogin']),
    initLogin() {
      this.setLogin(this.phoneDigits);
      this.$emit('init', this.phoneDigits);
    },
  },
};
</script>
