<template>
  <v-main>
    <div class="ma-lg-16 ma-md-6 ma-sm-3 ma-xs-2">
      <v-tabs v-model="currentTab" vertical background-color="transparent">
        <v-tabs-slider color="transparent" class="tab"></v-tabs-slider>
        <v-tab v-for="(tab, i) of tabs" :key="i" class="toolbar mx-2 my-1 px-0" :disabled="isTabDisabled(tab)">
          <div class="d-flex flex-column align-center" :class="getIconClass(tab)">
            <svg width="18" :height="tab.iconHeight ? tab.iconHeight : '16'">
              <use :xlink:href="tab.icon"></use>
            </svg>
            <span class="tab__name text-none mt-2">{{ tab.name }}</span>
          </div>
        </v-tab>
        <v-tabs-items v-model="currentTab">
          <v-tab-item v-for="(tab, i) of tabs" :key="i" class="tabs ml-4">
            <div class="d-flex justify-space-between align-center mb-5">
              <h2 class="text-h5 font--lh28">
                {{ tab.name }}
              </h2>

              <v-btn v-if="isLogged" outlined small @click="logout()"> Выход </v-btn>
            </div>

            <component :is="tab.component" class="page"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-main>
</template>

<script>
import Metric from '@/components/mainVerticalTabs/Metric';
import Profile from '@/components/mainVerticalTabs/Profile';

import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';

export default {
  name: 'MetricVerticalTabs',
  components: {
    Metric,
    Profile,
  },
  data: () => ({
    isLink: false,
  }),
  computed: {
    ...mapGetters('products', ['isActive', 'isLoaded']),
    ...mapGetters('common', ['isLogged']),
    ...mapState('common', ['tab']),

    tabs() {
      return [
        // если добавляем/удаляем таб, то надо изменить значения во всех setVerticalTab на +/-1

        {
          icon: '#chart',
          component: 'Metric',
          name: 'Метрика',
        },

        {
          icon: '#options',
          component: 'Profile',
          name: 'Настройки',
        },
      ];
    },
    currentTab: {
      get() {
        return this.tab;
      },

      set(value) {
        this.setVerticalTab(value);
      },
    },
  },

  methods: {
    ...mapMutations('common', ['setVerticalTab']),
    ...mapActions('common', ['logout']),

    getIconClass(tab) {
      let iconClass = 'icon--disabled';
      iconClass = tab.component == 'Metric' || tab.component == 'Profile' ? 'icon' : 'icon--disabled';

      return iconClass;
    },
    isTabDisabled(tab) {
      let tabDisabled = false;
      tabDisabled = tab.component == 'Metric' || tab.component == 'Profile' ? false : true;

      return tabDisabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  background-color: #f5f5f6 !important;
}

.page {
  min-height: 400px;
  background-color: white !important;
}

.toolbar {
  z-index: 10;
  background-color: #f2f6ff;
  max-width: 100px;
  min-width: 76px;
  max-height: 76px;
  box-shadow: 0 5px 12px -3px rgba(123, 123, 134, 0.2);
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 76px;
}

.icon .tab__name {
  color: #4971d0 !important;
}

.icon--disabled .tab__name {
  color: #717d83 !important;
}

.tab__name {
  white-space: normal;
  line-height: 12px;
  letter-spacing: 0.001rem;
  font-size: 10px;
  font-weight: 500;
  padding-right: 2px;
  padding-left: 2px;
}

.v-tab--active {
  background-color: #d2ddfa;
}

.icon {
  fill: #4971d0 !important;
}

.v-tab--active .icon,
.icon:hover {
  fill: #253e77;
}

.icon--disabled {
  fill: #717d83;
}

/* .tabs__wrap >>> .v-main__wrap {
  flex: 0 1 auto !important;
  margin-right: auto !important;
  margin-left: auto !important;
} */
.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
}
</style>
