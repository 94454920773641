<template>
  <div class="mt-3">
    <v-data-table
      v-model="selectedRow"
      :headers="headersFiltered"
      :items="dataArray"
      :footer-props="footerProps"
      :no-data-text="noDataText"
      :server-items-length="total"
      :options.sync="options"
      :items-per-page="50"
      item-key="id"
      height="70vh"
      fixed-header
      class="productlist-table"
      @update:options="updateOp($event)"
    >
      <template v-for="(header, index) in headersFiltered" v-slot:[`header.${header.value}`]="{ headersFiltered }">
        <Headers :key="header.value + index" :header="header" top />
      </template>

      <template #top>
        <div class="mb-6 mt-2 d-flex flex-wrap">
          <modul-grid-filters
            ref="grid-filters"
            :module-name="`${productModule}`"
            :action="`${action}`"
            :columns="headers"
            :filters="true"
            :footer-props="footerProps"
            :fast-date="true"
            :height="44"
          >
          </modul-grid-filters>
          <SalesTypeToogle class="sales" @change="changeType($event)" />
        </div>
      </template>

      <template #[`item.name`]="{ value, item }">
        <a style="cursor: pointer" @click="onCatalogClick(item)">{{ value }} </a>
      </template>

      <template #[`item.products`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.productsPromo`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.comments`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.priceAvg`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.income`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.sales`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.incomeAvg`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.sellers`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.brands`]="{ value }">
        <span>{{ numberFormat(value) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import SalesTypeToogle from '@/components/common/SalesTypeToogle';
import Headers from '@/components/common/Headers.vue';

import { createCategoryModule } from '@/store/modules/metric/wb/features/category';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { GRID_HEADERS_SUB_TABLE } from './constants';
import { numberFormat } from '@/mixins/numberFormat';
import { sortMixin } from '@/mixins/sortMixin';

export default {
  name: 'SubTable',

  components: {
    Headers,
    SalesTypeToogle,
    ModulGridFilters,
  },

  mixins: [numberFormat, sortMixin],
  inject: ['filterMutations', 'pagingMutations'],

  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
    typeId: {
      required: true,
      type: String,
    },
    nameComponent: {
      required: true,
      type: String,
    },
    moduleName: {
      required: true,
      type: String,
    },
    action: {
      required: true,
      type: String,
    },
    subType: {
      type: String,
    },
    categoryType: {
      type: String,
    },
  },

  data() {
    return {
      selectedRow: [],
      sortLoading: false,
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: GRID_HEADERS_SUB_TABLE,
    };
  },

  computed: {
    ...mapGetters('wbMetricTabs', ['hasTab']),

    productModule() {
      return this.moduleName;
    },

    headersFiltered() {
      let filtered = this.headers.filter((h) => {
        // колонки если не подкатегории показываем не все
        return this.subType !== 'подкатегория' ? !h.subType : h;
      });

      let filterCategory = filtered.filter((h) => {
        // колонки если не категория показываем не все
        return this.categoryType !== 'категория' ? !h.categoryType : h;
      });

      console.log('headersFiltered', filterCategory);
      return filterCategory;
    },

    total() {
      return this.$store.getters[`${this.moduleName}/total`];
    },
  },

  methods: {
    ...mapActions('wbMetricTabs', ['addTab']),
    ...mapMutations('common', ['setMetricAdditional']),

    updateOp(e) {
      if (this.dataArray.length >= 1) {
        this.onUpdateOptionsSubTable(e);
      }
    },

    onCatalogClick(item) {
      if (!this.hasTab(item.id)) {
        this.addTab({
          id: item.id,
          name: item.name,
          component: `${this.nameComponent}`,
          action: `wbMetricModule/${item.id}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.id);
      }

      const moduleName = `wbMetricModule/${item.id}`;

      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: this.typeId, cid: item.id }));
      }

      this.setMetricAdditional(true);
    },

    getList() {
      this.$store.dispatch(`${this.action}`);
    },

    changeType(e) {
      this.$store.commit(`${this.moduleName}/${this.filterMutations.SET_SALES}`, e);
      this.getList();
    },
  },
};
</script>

<style lang="scss">
.productlist-table {
  th {
    border-right: none !important;
    text-wrap: nowrap;
  }
  td:not(:first-child) {
    min-width: 110px;
  }

  td:first-child {
    min-width: 50px;
    width: 52px;
  }

  td:nth-child(2) {
    min-width: 176px;
  }
}

.v-data-table__empty-wrapper {
  margin: 80px auto;
}

.sales {
  margin-left: 8px;

  @media (max-width: 648px) {
    margin-left: 0px;
    margin-top: 16px;
  }
}
</style>
