export function downloadFile(fileResponse) {
  // Создание и загрузка файла
  const blob = new Blob([fileResponse.data], {
    type: fileResponse.headers['content-type'],
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileResponse.text ? fileResponse.text : 'list_'}${new Date().getTime()}.xls`;
  link.click();
}
