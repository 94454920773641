<template>
  <div class="metrica">
    <div class="d-flex align-center justify-space-between tabs-metric mb-4">
      <v-tabs v-model="currentTab" height="60px">
        <v-tab
          v-for="({ name, id, disabled }, i) of tabs"
          :key="id || name"
          :ripple="false"
          :disabled="disabled"
          :class="{ 'first-tab': i === 0 }"
          class="mt-tab text-none py-0 tabs-metric__tab"
        >
          <v-divider
            v-if="id && i == 4"
            class="mt-4 mr-12"
            style="min-height: 50%; max-height: 60%; left: 0; position: absolute"
            vertical
          ></v-divider>
          <div class="text-body-2 font-weight-medium font--lh16 font--gray10">
            {{ name }}
          </div>
        </v-tab>
      </v-tabs>
    </div>

    <v-card flat class="mx-auto font card-wb-metric">
      <v-main>
        <v-container fluid class="metrica__container" :class="currentTab === 0 ? 'pa-0' : 'pt-4 pa-0'">
          <v-tabs-items v-model="currentTab">
            <v-tab-item v-for="{ component, id, name } of tabs" :key="id || name">
              <component :is="component" :id="id"></component>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-main>
    </v-card>

    <SidePanel v-model="showCard" :title="'Описание товара'" :stateless="true">
      <ProductCard v-if="product" :close-panel="showCard" />
    </SidePanel>
  </div>
</template>

<script>
import SearchSKUMain from '@/components/metric/wb/searchSku/SearchSKUMain.vue';
import SearchMain from '@/components/metric/wb/searchProduct/SearchMain';
import ProductCard from '@/components/metric/wb/product/ProductCard';
import CountryMain from '@/components/metric/wb/country/CountryMain';
import CatalogMain from '@/components/metric/wb/catalog/CatalogMain';
import BrandMain from '@/components/metric/wb/brands/BrandMain.vue';
import SellerMain from '@/components/metric/wb/seller/SellerMain';
import TagList from '@/components/metric/wb/tag/TagList.vue';
import SidePanel from '@/components/common/SidePanel';

import * as filterMutations from '@/store/modules/metric/wb/mutations/filter';
import * as pagingMutations from '@/store/modules/metric/wb/mutations/paging';
import * as gridMutations from '@/store/modules/metric/wb/mutations/grid';
import * as tabMutations from '@/store/modules/metric/wb/mutations/tabs';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'Wb',

  provide: {
    filterMutations,
    pagingMutations,
    gridMutations,
  },

  components: {
    SearchSKUMain,
    CountryMain,
    ProductCard,
    CatalogMain,
    SearchMain,
    SellerMain,
    BrandMain,
    SidePanel,
    TagList,
  },

  computed: {
    ...mapState('common', ['showCardWb']),
    ...mapState('wbProductCard', ['product']),
    ...mapGetters('wbMainTabs', ['tabs', 'tab']),
    ...mapGetters({ categorySubTabs: 'wbCategorySubTabs/tabs' }),

    showCard: {
      get() {
        return this.showCardWb;
      },
      set(value) {
        this.showCardWbModal(value);
      },
    },

    currentTab: {
      get() {
        return this.tab;
      },

      set(value) {
        this[tabMutations.SET_TAB](value);
      },
    },
  },

  methods: {
    ...mapMutations('wbMainTabs', [tabMutations.SET_TAB]),
    ...mapMutations('common', ['showCardWbModal']),
    ...mapActions('wbBrand', ['getTopBrand']),
  },
};
</script>

<style lang="scss">
@import '@/styles/main';
.theme--light.v-tabs-items {
  background-color: transparent;
}

.metrica .v-slide-group.v-item-group > .v-slide-group__next {
  background-color: #f6f5f6;
}
.metrica .v-slide-group.v-item-group > .v-slide-group__prev {
  background-color: #f6f5f6;
}

.card-wb-metric.v-sheet.v-card {
  border-radius: 8px;
}

.tabs-metric {
  border-radius: 8px 8px 0px 0px;
  .v-slide-group__content {
    background-color: #f6f5f6;
  }

  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
    .v-slide-group__prev {
    display: none;
  }

  .v-tab {
    padding-left: 12px;
    padding-right: 12px;
    justify-content: start;
    min-width: auto !important;
  }

  .v-slide-group__wrapper {
    height: 35px;
  }

  .v-tab--active {
    .text-body-2 {
      color: $primary; // Active tab text color
    }
  }

  .v-tab--active .v-icon {
    color: $gray10 !important;
  }

  .v-tabs {
    border-radius: 8px 0px 0px 0px;
    width: 60%;
    height: 35px;

    .v-tabs-slider-wrapper {
      height: 3px !important;
      padding: 0 12px;
    }

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    background-color: transparent;
    width: 100%;

    .v-tabs {
      background-color: transparent !important;
    }
    .v-slide-group {
      background-color: transparent !important;
    }
    .v-slide-group__content {
      background-color: transparent !important;
    }
  }
}

.metrica,
.metrica__container {
  min-height: 555px;
}

.metrica__container .v-item-group {
  padding: 0;
}

.metrica .theme--light.v-tabs > .v-tabs-bar {
  background-color: #f6f5f6;
}
</style>
