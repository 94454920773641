<template>
  <div class="main-filter">
    <div class="d-flex main-filter__tab">
      <v-card class="font main-filter__tab--card" flat color="#F5F5F6">
        <div class="text-center main-filter__tab--group">
          <v-btn-toggle v-model="types" color="primary" class="ma-0" dense @change="$emit('change', $event)">
            <v-tooltip v-if="all" top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn value="all" v-bind="attrs" class="ma-0 mr-2 main-filter__tab--btn" v-on="on"> Все </v-btn>
              </template>
              <span
                >Продажи по FBS-системе могут быть искусственно завышены самими продавцами. Поэтому анализ рекомендуем
                делать по FBO</span
              >
            </v-tooltip>

            <v-btn value="fbo" class="ma-0 mr-2 text-uppercase main-filter__tab--btn"> FBO </v-btn>
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn value="fbs" class="ma-0 main-filter__tab--btn" v-bind="attrs" v-on="on"> FBS </v-btn>
              </template>
              <span
                >Продажи по FBS-системе могут быть искусственно завышены самими продавцами. Поэтому анализ рекомендуем
                делать по FBO</span
              >
            </v-tooltip>
          </v-btn-toggle>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { INIT_SALES_TYPE } from '@/constants.js';

export default {
  name: 'SalesTypeToogle',

  props: {
    cardType: {
      type: String,
    },
    all: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      types: INIT_SALES_TYPE,
    };
  },

  watch: {
    cardType(e) {
      this.types = e;
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background-color: #f5f5f6 !important;
}
</style>
