<template>
  <v-card class="font sales-group ml-2" flat color="#F5F5F6">
    <div class="text-center sales-group__div">
      <v-btn-toggle
        v-model="types"
        color="primary"
        class="ma-0 sales-group__toogle"
        dense
        @change="$emit('change', $event)"
      >
        <v-tooltip top max-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="all" v-bind="attrs" class="text-capitalize sales-group__toogle--btn ma-0 mr-2" v-on="on">
              Все
            </v-btn>
          </template>
          <span
            >Продажи по FBS-системе могут быть искусственно завышены самими продавцами. Поэтому анализ рекомендуем
            делать по FBO</span
          >
        </v-tooltip>

        <v-btn value="fbo" class="sales-group__toogle--btn ma-0 mr-2 text-uppercase"> fbo </v-btn>
        <v-tooltip top max-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="fbs" class="sales-group__toogle--btn ma-0 text-uppercase" v-bind="attrs" v-on="on">
              fbs
            </v-btn>
          </template>
          <span
            >Продажи по FBS-системе могут быть искусственно завышены самими продавцами. Поэтому анализ рекомендуем
            делать по FBO</span
          >
        </v-tooltip>
      </v-btn-toggle>
    </div>
  </v-card>
</template>
<script>
import { INIT_SALES_TYPE } from '@/constants.js';

export default {
  name: 'SalesTypeToogle',
  props: {
    cardType: {
      type: String,
    },
  },
  data() {
    return {
      types: INIT_SALES_TYPE,
    };
  },
  watch: {
    cardType(e) {
      this.types = e;
    },
    // types(value) {
    //   if (value == 'all' || value == 'fbs') {
    //     this.notFboType = true;
    //   }
    //   if (value == 'fbo') {
    //     this.notFboType = false;
    //   }
    // },
  },
};
</script>

<style lang="scss">
.sales-group {
  border-radius: 4px !important;
  &__div {
    border-radius: 4px;
    background-color: #f5f5f6 !important;
    padding: 4px;
  }

  .v-btn-toggle > .v-btn.v-btn--active {
    color: #4971d0 !important;
    background-color: #4971d0 !important;
  }

  .v-btn:not(.v-btn--outlined).primary:hover {
    background: none !important;
  }

  &__toogle {
    &--btn {
      width: 56px !important;
      height: 36px !important;
      border-radius: 4px !important;
      border: none !important;
      opacity: 1 !important;
    }
    &--btn .v-btn__content {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: inherit;
      color: #777c87 !important;
    }
    &--btn.v-btn--active .v-btn__content {
      font-weight: 500;
      color: #ffffff !important;
    }
  }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background-color: #f5f5f6 !important;
}
</style>
