<template>
  <div>
    <modul-filters
      v-if="filters && localFiltersSet && tagsFilters"
      :key="moduleName"
      :fast-date="fastDate"
      :tags-filters="tagsFilters"
      :module-name="moduleName"
      :columns="columns"
      :height="height"
      @update:date-filter="updateDateFilter"
      @update:filters="updateFiltersValue"
      @reset:filters="resetFiltersToInitialValue"
    />

    <v-row v-if="!tagsFilters" align-items="center" no-gutters dense class="pa-2 pa-sm-0">
      <v-col :cols="6" :sm="fastDate ? 6 : 3">
        <modul-filters
          v-if="filters && localFiltersSet"
          :key="moduleName"
          :fast-date="fastDate"
          :module-name="moduleName"
          :columns="columns"
          :height="height"
          @update:date-filter="updateDateFilter"
          @update:filters="updateFiltersValue"
          @reset:filters="resetFiltersToInitialValue"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ModulFilters from './components/modul-filters';
import { prepareFilters } from '@/components/common/modul-grid/_utils/dataPreporation';
import { fetchFilterFactory } from '@/components/common/modul-grid/_utils/filters';

export default {
  components: {
    'modul-filters': ModulFilters,
  },
  inject: ['filterMutations', 'pagingMutations'],

  props: {
    moduleName: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Boolean,
      default: false,
    },
    fastDate: {
      type: Boolean,
      default: false,
    },
    tagsFilters: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: '36',
    },
  },

  data() {
    return {
      localFiltersSet: false,
    };
  },

  computed: {
    localFilters() {
      return this.$store.getters[`${this.moduleName}/localFilters`];
    },
  },
  mounted() {
    const filtersStr = localStorage.getItem(`modul-filters_${this.moduleName}`);
    if (filtersStr) this.setLocalFilters(JSON.parse(filtersStr));
    else this.setLocalFilters([]);
    this.localFiltersSet = true;
    if (this.localFilters) {
      this.setFilters(this.getFetchFilters(this.localFilters));
    }

    // FIXME watch цепляет и старые фильтры тоже, из-за этого дергает каталог везде где изменяются фильтры
    // this.unwatchFilters = this.$store.watch(
    //   this.getLocalFilters,
    //   (newVal) => this.setFilters(this.getFetchFilters(newVal)),
    //   {
    //     immediate: true,
    //   }
    // );
    // this.unwatchRequestPayload = this.$store.watch(this.getRequestPayload, this.handleRequestPayloadChange, {
    //   deep: true,
    // });
  },
  destroyed() {
    // this.unwatchRequestPayload();
    // this.unwatchFilters();
  },
  methods: {
    getList() {
      this.$store.dispatch(`${this.action}`);
    },

    getLocalFilters() {
      return this.localFilters;
    },

    getFetchFilters(localFilters) {
      return localFilters.map((f) => fetchFilterFactory(f)).filter((f) => !!f);
    },

    resetPage() {
      this.$store.commit(`${this.moduleName}/setPage`, 1);
    },

    setFilters(filters) {
      this.$store.commit(`${this.moduleName}/setFilters`, filters);
      if (filters.length) {
        this.getList();
      }
    },
    setLocalFilters(localFilters) {
      this.$store.commit(`${this.moduleName}/setLocalFilters`, localFilters);
    },
    setLocalDateFilter(dateFilter) {
      this.$store.commit(`${this.moduleName}/setLocalDateFilter`, dateFilter);
    },
    resetFilters() {
      this.$store.commit(`${this.moduleName}/resetFilters`);
    },

    // применить фильтры
    updateFiltersValue(filters) {
      this.resetPage();

      // подготавливаем фильтры к отправке в функции prepareFilters
      this.setLocalFilters(prepareFilters(filters));
      this.saveFiltersToLocalStorage(filters);

      // вместо unwatchRequestPayload
      this.setFilters(this.getFetchFilters(filters));
    },

    // если выбрали новую дату
    updateDateFilter(dateFilter) {
      this.resetPage();
      this.setLocalDateFilter(prepareFilters(dateFilter));
      this.saveFiltersToLocalStorage(this.localFilters);

      // вместо unwatchRequestPayload
      this.setFilters(this.getFetchFilters(this.localFilters));
    },
    resetFiltersToInitialValue() {
      this.resetPage();
      this.resetFilters();
      this.clearLocalStorageFilters();

      // нет фильтра даты в Ключевиках, поэтому вручную вызываем
      if (this.tagsFilters || !this.fastDate) {
        this.getList();
      }
    },

    // cохраняем фильтры в локалсторадж
    saveFiltersToLocalStorage(filters) {
      localStorage.setItem(`modul-filters_${this.moduleName}`, JSON.stringify(filters));
    },
    clearLocalStorageFilters() {
      localStorage.setItem(`modul-filters_${this.moduleName}`, '');
    },
  },
};
</script>

<style>
.item-color {
  background-color: #ef9a9a;
}
</style>
