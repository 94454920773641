import { serverWhite } from './server-white';

// отправка SMS-кода для восстановления пароля
async function init(login) {
  return await serverWhite.post('/landing/users/account/init', { login });
}

// вход
async function login({ login, code, smsid }) {
  return await serverWhite.post('/landing/users/account/login', { login, code, smsid });
}

// регистрация
async function register({ login, code, smsid }) {
  return await serverWhite.post('/landing/users/account/register', { code, login, smsid });
}

export { init, login, register };
