<template>
  <v-main>
    <div class="ma-lg-16 ma-md-6 ma-sm-3 ma-xs-2">
      <v-tabs v-model="currentTab" class="main_tabs" hide-slider stacked>
        <!-- <v-btn text class="align-self-center tab px-4" @click="back()" @mousedown="hover = false">
          <span class="d-flex flex-column align-center" :class="getIconClass()">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="tab__name font-weight-regular text-none mt-2">Банк</span>
          </span>
        </v-btn> -->

        <v-tab v-for="tab in tabs" :key="tab.value" :href="'#tab-' + tab.value">
          <div class="d-flex flex-column align-center">
            <svg width="21" :height="tab.iconHeight ? tab.iconHeight : '19'">
              <use :xlink:href="tab.icon"></use>
            </svg>
            <span class="tab__name font-weight-regular text-none mt-2">{{ tab.name }}</span>
          </div>
        </v-tab>

        <!-- <v-tab href="#tab-3" style="display: none">Метрика</v-tab>
        <v-tab href="#tab-4" style="display: none">Кредит</v-tab> -->

        <!-- <v-menu v-if="menu.length" bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="align-self-center" v-bind="attrs" v-on="on">
              <span class="d-flex flex-column align-center">
                <v-icon>mdi-dots-horizontal</v-icon>
                <span class="tab__name text-none mt-2">Меню</span>
              </span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="item in menu" :key="item.value" @click="addItem(item)">
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu> -->

        <v-tabs-items v-model="currentTab">
          <v-tab-item v-for="tab in tabs" :key="tab.value" :value="'tab-' + tab.value" class="tabs">
            <component :is="tab.component" class="pa-0 mx-auto"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-main>
</template>

<script>
import Products from '@/components/mainVerticalTabs/Products';
import Services from '@/components/mainVerticalTabs/Services';
import Channels from '@/components/mainVerticalTabs/Channels';
import Metric from '@/components/mainVerticalTabs/Metric';
import Credit from '@/components/mainVerticalTabs/Credit';
import Moment from '@/components/mainVerticalTabs/Moment';

import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'MainTabsMobileTabs',
  components: {
    Products,
    Services,
    Channels,
    Metric,
    Credit,
    Moment,
  },
  data: () => ({
    tabs: [
      // если добавляем/удаляем таб, то надо изменить значения во всех setVerticalTab на +/-1
      {
        icon: '#pos',
        component: 'Products',
        name: 'Главная',
        value: 0,
      },
      {
        icon: '#products',
        component: 'Services',
        name: 'Услуги',
        value: 1,
      },

      {
        icon: '#news',
        component: 'Channels',
        name: 'Telegram-каналы',
        value: 2,
      },
    ],
    // menu: [
    //   {
    //     icon: '#pos',
    //     component: 'Metric',
    //     name: 'Метрика',
    //     value: 3,
    //   },
    //   {
    //     icon: '#refill',
    //     component: 'Credit',
    //     name: 'Кредит',
    //     value: 4,
    //   },
    // ],
  }),
  computed: {
    ...mapState('common', ['mobileTab']),
    ...mapGetters('products', ['isActive', 'isLoaded']),
    currentTab: {
      get() {
        return this.mobileTab;
      },

      set(value) {
        this.setMobileMainTab(value);
      },
    },
  },
  methods: {
    ...mapMutations('common', ['setMobileMainTab']),

    // back() {
    //   console.log('back');
    // },
    // addItem(item) {
    //   this.$nextTick(() => {
    //     this.currentTab = 'tab-' + item.value;
    //   });
    // },

    // getIconClass(tab) {
    //   let iconClass = 'icon--disabled';
    //   if (this.isLoaded) {
    //     iconClass = !this.isActive || tab.name == 'Услуги' ? 'icon' : 'icon--disabled';
    //   }
    //   return iconClass;
    // },
    // isTabDisabled(tab) {
    //   let tabDisabled = false;
    //   if (this.isLoaded) {
    //     tabDisabled = !this.isActive || tab.name == 'Услуги' ? false : true;
    //   }
    //   return tabDisabled;
    // },
  },
};
</script>

<style>
.main_tabs .v-tab--active {
  fill: #4971d0 !important;
}

.main_tabs > .v-slide-group .v-slide-group__content .theme--light.v-btn,
.main_tabs .v-tab--active > .icon .tab__name {
  color: #4971d0;
}

.main_tabs .v-tab--active:before {
  background-color: white !important;
}

.main_tabs .icon .tab__name,
.v-btn > .v-btn__content .v-icon,
.v-btn > .v-btn__content .tab__name {
  color: #000000;
}

.main_tabs .icon--disabled,
.main_tabs .icon--disabled .tab__name,
.main_tabs .v-btn--active .icon--disabled .tab__name {
  color: #717d83 !important;
  fill: #717d83 !important;
}

.main_tabs > .v-slide-group .v-slide-group__content .v-btn:before {
  background-color: white !important;
}

.main_tabs > .v-slide-group .v-slide-group__content .v-btn {
  border-radius: 0px !important;
}

.main_tabs > .v-slide-group .v-slide-group__content .v-btn:not(.v-btn--round).v-size--default {
  height: 100% !important;
}

.v-btn > .v-btn__content .v-icon {
  font-size: 19px !important;
}

.tab__name {
  white-space: normal;
  line-height: 12px;
  letter-spacing: 0.001rem;
  font-size: 10px;
  font-weight: 500;
  padding-right: 2px;
  padding-left: 2px;
}

.main_tabs > .v-slide-group {
  position: fixed !important;
  bottom: 0 !important;
  z-index: 10000 !important;
  background-color: white !important;
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.main_tabs > .v-slide-group .v-slide-group__content {
  justify-content: space-between !important;
  margin: 0 0px;
}

.main_tabs .v-tab {
  width: 120px !important;
}

.main_tabs > .v-slide-group .v-slide-group__prev {
  display: none !important;
}

.main_tabs > .v-tabs-bar {
  height: 86px !important;
  padding-bottom: 34px;
  padding-top: 9px;
}
</style>
