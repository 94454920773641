<template>
  <div>
    <div class="pa-0 py-6 text-h5 font--black1 font--lh32 font-weight-medium">
      Специальные тарифы для поставщиков маркетплейсов
    </div>
    <v-row>
      <v-col v-for="tariff in tariffs" :key="tariff.name" cols="12" md="6">
        <v-card
          class="fill-height elevation-0 py-4 px-6 d-flex flex-column justify-space-between"
          style="border: 1px solid #e6e7e9"
        >
          <div>
            <div :class="tariff.pro ? 'd-flex justify-space-between' : ''">
              <v-chip small :color="!tariff.pro ? '#e64d8d' : '#5932cb'" text-color="white">{{ tariff.chip }}</v-chip>
              <span v-if="tariff.pro" class="text-subtitle-1 text-decoration-line-through font--graphite3"
                >4 900 ₽/мес</span
              >
            </div>

            <v-row class="mb-7 d-flex justify-space-between">
              <v-col class="mt-3">
                <span style="white-space: nowrap" class="font-weight-bold font--fs18 font--lh26 font--black1"
                  >{{ tariff.name }}
                </span>
              </v-col>
              <v-col class="ml-2" align="end">
                <div style="white-space: nowrap">
                  <span class="font-weight-bold font--fs32 font--black1">{{ tariff.price }} ₽</span>
                  <span class="text-body-2 font-weight-regular font--graphite3">/мес* </span>
                </div>
              </v-col>
            </v-row>

            <div v-for="step in tariff.steps" :key="step.name" class="mb-2">
              <div class="d-flex">
                <v-icon color="blue darken-2">mdi-check</v-icon>
                <span class="ml-2 font--lh24 text-subtitle-1 font--graphite3">{{ step.name }}</span>
              </div>
            </div>

            <div class="mb-6">
              <span class="text-caption font--lh16 font--black1">*{{ tariff.info }}</span>
            </div>
          </div>
          <v-card-actions class="pa-0">
            <v-btn
              color="primary"
              :outlined="!tariff.pro ? true : false"
              :class="!tariff.pro ? 'btn font-weight-bold' : ''"
              dark
              depressed
              width="157"
              :href="tariffUrl"
              target="_blank"
            >
              Подключить</v-btn
            >

            <v-btn text width="157" class="ml-1" href="https://modulbank.ru/marketplace/" target="_blank"
              >О тарифе</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TARIFF_URL } from '@/constants.js';
import { makeRub } from '@/mixins/makeRub';

export default {
  name: 'Tariffs',
  mixins: [makeRub],
  data() {
    return {
      tariffUrl: TARIFF_URL,
      tariffs: [
        {
          pro: false,
          chip: 'Для начинающих',
          name: 'Тариф «Селлер»',
          price: '0',
          steps: [
            { name: 'Вывод торговой выручки бесплатно до 1 млн руб.' },
            { name: 'Снятие торговой выручки с комиссией 1%' },
            { name: 'Обмен валюты с разницей в 0,5% от биржи' },
            { name: 'МаркетБухгалтер' },
            { name: 'МаркетМетрика' },
          ],
          info: '3 месяца бесплатно, далее 490 ₽/месяц',
        },
        {
          pro: true,
          chip: 'Для опытных селлеров',
          name: 'Тариф «Селлер PRO»',
          price: '2 490',
          steps: [
            { name: 'Вывод торговой выручки' },
            { name: 'Снятие торговой выручки с комиссией 0,75%' },
            { name: 'Обмен валюты с разницей в 0,35% от биржи' },
            { name: 'МаркетМенеджер' },
            { name: 'МаркетБухгалтер' },
            { name: 'МаркетМетрика' },
          ],
          info: 'При заведении торговой выручки ',
        },
      ],
    };
  },
};
</script>

<style scoped>
.btn.v-btn--outlined {
  border: 2px solid #4971d0;
}
</style>
