<template>
  <v-expansion-panel :readonly="!item.child" @click="expand(item.id)">
    <v-expansion-panel-header
      :hide-actions="!item.child"
      class="pa-3"
      :style="[item.lvl === 1 ? { 'background-color': '#F2F6FF' } : { 'background-color': '#FFFF' }]"
    >
      <template>
        <div
          v-if="item.id && item.available == 1"
          :data-id="item.id"
          class="link d-flex align-center font text-body-2"
          @click.stop="onCatalogClick(item)"
        >
          <v-icon v-if="icon" class="mr-5">{{ icon }}</v-icon>
          {{ item.name }}
        </div>

        <span v-else>
          <v-icon v-if="icon" class="mr-5">{{ icon }}</v-icon> {{ item.name }}
        </span>
      </template>
      <template v-slot:actions>
        <v-icon color="#4971D0"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-expansion-panels v-if="item.child" :active-class="`grey lighten-${item.lvl + 1}`">
        <CatalogPanel v-for="(child, i) of children" :key="i" :item="child" @cat:change="$emit('cat:change', $event)" />
      </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';

export default {
  name: 'CatalogPanel',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      children: [],
    };
  },

  computed: {
    ...mapState('wbCatalog', ['icons']),
    ...mapGetters('wbMetricTabs', ['hasTab']),

    name() {
      return this.item && this.item.name;
    },

    iconsSelected() {
      return this.icons[this.name];
    },

    icon() {
      if (this.item.lvl < 2) {
        return this.iconsSelected && this.iconsSelected.icon ? this.iconsSelected.icon : false;
      }
      return false;
    },
  },

  methods: {
    ...mapActions('wbMetricTabs', ['addTab']),
    ...mapActions('wbCatalog', ['catalogList']),
    ...mapMutations('common', ['setMetricAdditional']),

    async expand(id) {
      if (this.children.length === 0) {
        const res = await this.catalogList(id);
        this.children = res;
      }
    },

    onCatalogClick(item) {
      if (item.lvl >= 1 && item.id) {
        if (!this.hasTab(item.id)) {
          this.addTab({
            id: item.id,
            name: item.name,
            component: 'Category',
            action: `wbMetricModule/${item.id}/getProductList`,
          });
        } else {
          this.$emit('cat:change', item.id);
        }

        const moduleName = `wbMetricModule/${item.id}`;

        if (!this.$store.hasModule(moduleName)) {
          this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'cid', cid: item.id }));
        }

        this.setMetricAdditional(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link:hover {
  color: #1976d2 !important;
}
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
  background-color: white !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  margin: 0px;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}
</style>
