<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Маркетплейс</th>
            <th class="text-left">Токен</th>
            <th class="text-left">Статус токена</th>
            <th class="text-left"></th>
            <!-- <th class="text-left"></th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in optionsList" :key="i">
            <td class="font--lh21"><LogoMarket :type="item.type" :width="60" /></td>
            <td class="font--lh21 font--gray10" style="max-width: 100vh !important">
              {{ item.key }}
            </td>
            <td>
              <v-chip small :style="tokenTextColor(item.act)" class="ma-1" :color="tokenColor(item.act)"
                >{{ tokenStatus(item.act) }}
              </v-chip>
            </td>
            <td>
              <!-- FIX на другие данные -->
              <svg
                v-if="item.isClearable"
                width="18"
                height="18"
                style="cursor: pointer"
                @click="deleteProductTokenDialog(item)"
              >
                <use xlink:href="#trash"></use>
              </svg>
              <svg
                v-if="item.act && !item.isClearable"
                width="18"
                height="18"
                style="cursor: pointer"
                @click="deleteTokenDialog(item)"
              >
                <use xlink:href="#trash"></use>
              </svg>
            </td>
          </tr>
        </tbody>
        <DeletePopup
          v-model="dialog"
          :text="'Вы действительно хотите удалить добавленный продуктовый токен?'"
          @delete="deleteProductToken"
        />
        <DeleteTokenPopup v-model="tokenDeleteDialog" @del="deleteToken" />
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LogoMarket from '@/components/common/LogoMarket';
import { deleteOption } from '@/api';
import DeletePopup from '@/components/common/DeletePopup.vue';
import DeleteTokenPopup from '@/components/options/DeleteTokenPopup.vue';

export default {
  name: 'OptionsTableToken',
  components: { LogoMarket, DeletePopup, DeleteTokenPopup },
  data() {
    return {
      dialog: false,
      tokenDeleteDialog: false,
    };
  },
  computed: {
    ...mapState('options', ['optionsList', 'product_token', 'token']),
  },
  methods: {
    tokenStatus(status) {
      return status ? 'Подключен' : 'Отключен';
    },
    tokenColor(status) {
      return status ? '#EEFAD4' : '#FFE9E9';
    },
    tokenTextColor(status) {
      return status ? 'color: #5E8C00' : 'color: #A61D1D';
    },
    deleteProductTokenDialog(product_token) {
      this.$store.commit('options/setProductToken', product_token);
      this.dialog = true;
    },
    deleteTokenDialog(token) {
      this.$store.commit('options/setToken', token);
      this.tokenDeleteDialog = true;
    },
    async deleteProductToken() {
      let data = {
        key: this.product_token.key,
        type: this.product_token.type,
      };
      const ok = await deleteOption(data);
      if (ok) {
        this.$store.dispatch('options/getOptionList');
        this.$store.commit('options/setProductToken', null);
        this.dialog = false;
      }
    },
    async deleteToken() {
      let data = {
        key: this.token.key,
        type: this.token.type,
      };

      if (this.token.type == 'yandex') {
        data = {
          compId: this.token.compId,
          key: this.token.key,
          type: this.token.type,
        };
      }

      const ok = await deleteOption(data);
      if (ok) {
        this.$store.dispatch('options/getOptionList');
        this.$store.commit('options/setToken', null);
        this.tokenDeleteDialog = false;
      }
    },
  },
};
</script>

<style scoped></style>
