<template>
  <v-dialog v-model="show" max-width="800">
    <v-card class="px-8 py-4">
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>

        <v-btn icon @click.stop="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card min-height="400px" class="d-flex flex-column pa-10 font align-center justify-center mt-6" elevation="0">
        <v-img src="img/analitic.png" max-width="198px" max-height="161px" class="mb-1"></v-img>
        <div class="text-h6 my-3 font--lh23 font--black1">Аналитика по магазинам</div>
        <v-card-text class="text-subtitle-1 mb-3 text-center font--graphite3 font--lh24" style="width: 500px">
          Для получения товаров по вашему магазину необходимо ввести продуктовый токен. Вы получите полную информацию о
          товарах, продажах, остатках и многом другом
        </v-card-text>
        <div>
          <v-btn class="text-caption font-weight-bold mt-10" color="primary" @click="setTab()">Добавить магазин</v-btn>
        </div>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'EmptyProductTokenPopup',
  props: {
    value: Boolean,
  },

  computed: {
    ...mapGetters('common', ['isMobile']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapMutations('common', ['setVerticalTab', 'setMobileMainTab']),
    setTab() {
      this.show = false;
      this.isMobile ? this.setMobileMainTab('tab-0') : this.setVerticalTab(7);
    },
  },
};
</script>

<style scoped>
.theme--light.v-card > .v-card__text {
  color: rgba(0, 0, 0, 1);
}
</style>
