export function prettyDate(dates) {
  if (Array.isArray(dates) && dates.length > 0) {
    const _dates = [...dates];
    switch (_dates.length) {
      case 1: {
        const [year, month, day] = _dates[0].split('-');
        return `${day}.${month}.${year}`;
      }
      case 2: {
        _dates.sort();
        const [year_1, month_1, day_1] = _dates[0].split('-');
        const [year_2, month_2, day_2] = _dates[1].split('-');
        return `${day_1}.${month_1}.${year_1} - ${day_2}.${month_2}.${year_2}`;
      }
      default: {
        return '';
      }
    }
  }
  return '';
}
