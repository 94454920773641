import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import * as modules from './modules';
import * as api from '@/api';

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    reset({ commit }) {
      Object.keys(modules).forEach((moduleName) => commit(`${moduleName}/reset`));
    },
  },
  modules,
});

function requestHandler(config) {
  if (
    config.url !== '/landing/users/account/login' &&
    config.url !== '/landing/users/account/register' &&
    config.url !== '/landing/users/account/init'
  ) {
    config.headers.tnx = localStorage.getItem('tnx');
  }
  console.log('Запрос: ', config);
  store.commit('common/startLoading');
  return config;
}

function basicResponseHandler({ data }) {
  store.commit('common/stopLoading');

  // прокидываем нормальный ответ
  if (data?.status === 'ok' && data.response) {
    console.log('Ответ сервера:', data);
    return data.response;
  }

  if (data?.status === 'ok' && data.desc === 'No data response') {
    console.log('Ответ сервера:', data);
    // после обновления токена и удаления уведомления TRUE
    return true;
  }

  if (data.err === 'unauthorized') {
    localStorage.removeItem('tnx');
    // стираем  tnx в адресной строке
    if (window.location.search) {
      window.location.href = window.location.origin;
    } else {
      window.location.reload();
    }

    return null;
  }

  // // обработка ошибок
  // if (data.err === 'Не авторизован') {
  //   // FIXME: а тут точно так???
  //   window.top.location.href = window.top.location.href + '/#/signin';
  //   return null;
  // }

  if (data.errdesc) {
    store.commit('common/setErrdesc', data.errdesc);
    store.dispatch('common/addAlert', { msg: data.errdesc, type: 'error' });
    return null;
  }

  store.dispatch('common/addAlert', { msg: 'Неожиданный ответ сервера', type: 'error' });

  return null;
}

function errorHandler(e) {
  store.commit('common/stopLoading');
  if (e.response) {
    store.dispatch('common/addAlert', { msg: `Ошибка ${e.response.status}`, type: 'error' });
  } else if (e.request) {
    store.dispatch('common/addAlert', { msg: 'Не удалось получить ответ от сервера', type: 'error' });
  } else {
    store.dispatch('common/addAlert', { msg: 'Что-то пошло не так', type: 'error' });
  }
  console.error(e);
}

export const applyBasicGateInterceptors = () => {
  api.addRequestHandler(requestHandler);
  api.addErrorHandler(errorHandler);
  api.addResponseHandler(basicResponseHandler);
};

export default store;
