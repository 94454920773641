var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"catalog-grid",attrs:{"items":_vm.flattenItems,"headers":_vm.headers,"hide-default-footer":"","items-per-page":-1,"dense":"","fixed-header":"","height":"70vh"},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:`header.${h.value}`,fn:function({ headers }){return [(h.description)?_c('v-tooltip',{key:h.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text)+" "),_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-help-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.description))])]):_c('span',{key:h.value},[_vm._v(_vm._s(h.text))])]}}}),{key:"item",fn:function({
      item: {
        id,
        lvl,
        name,
        child,
        products,
        brands,
        sellers,
        averageSm,
        sales,
        income,
        incomeLost,
        sps,
        spg,
        available,
      },
    }){return [_c('tr',[_c('td',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex no-wrap"},[_c('span',{class:'ml-' + lvl * 4}),(child)?_c('v-icon',{staticClass:"ml-n4 mr-1",attrs:{"color":"#464C59"},on:{"click":function($event){return _vm.expand(id, lvl, name)}}},[_vm._v(" "+_vm._s(id in _vm.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")]):_vm._e(),(lvl >= 1 && id && available == 1)?_c('v-btn',{staticClass:"pl-0",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.onCatalogClick({ lvl, id, name })}}},[(!child)?_c('v-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.getIcon({ name, lvl })))]):_vm._e(),_vm._v(" "+_vm._s(name)+" ")],1):_c('span',{staticClass:"text-body-1 d-flex no-wrap mx-3"},[(!child)?_c('v-icon',{staticClass:"mr-5",staticStyle:{"min-width":"24px"},attrs:{"color":"#464C59"}},[_vm._v(_vm._s(_vm.getIcon({ name, lvl })))]):_vm._e(),_vm._v(" "+_vm._s(name)+" ")],1)],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.numberFormat(products))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.numberFormat(brands))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.numberFormat(sellers))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.numberFormat(averageSm))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.numberFormat(sales))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.numberFormat(income))+" ")]),_c('td',[_vm._v(" "+_vm._s(incomeLost == null ? '-' : _vm.numberFormat(incomeLost))+" ")]),_c('td',{staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(sps)+" ")]),_c('td',{staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(spg)+" ")])])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }