<template>
  <div>
    <div class="d-flex flex-wrap justify-space-between mb-11 mt-6">
      <div class="mb-2">
        <modul-grid-filters
          ref="grid-filters"
          :module-name="`${productModule}`"
          :action="`${action}`"
          :columns="headers"
          :filters="true"
          :footer-props="footerProps"
          :fast-date="true"
        >
        </modul-grid-filters>
      </div>
      <v-text-field
        v-model="search"
        dense
        single-line
        hide-details
        height="44"
        class="mb-2"
        style="max-width: 400px"
        outlined
        placeholder="Введите номер отправления"
        clearable
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>
    <v-data-table
      :mobile-breakpoint="100"
      class="tape-table"
      :headers="headers"
      :items="indexedGrid"
      item-key="id"
      :no-data-text="noDataText"
      fixed-header
      :items-per-page="50"
      :footer-props="footerProps"
      show-expand
      :server-items-length="total"
      :options.sync="options"
      @update:options="onUpdateOptions($event)"
      @item-expanded="loadDetails"
    >
      <template #[`item.id`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>

      <template #[`item.operation_date`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
      </template>

      <template #[`item.dt`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
      </template>

      <template #[`item.status`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>

      <template #[`item.quauntity`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
      </template>

      <template #[`item.sumOrders`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
      </template>

      <template #[`item.forpay`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
      </template>

      <template #[`item.commission`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
      </template>

      <template #[`item.costs`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
      </template>

      <template #[`item.typeSales`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>

      <template #[`item.warehouse`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 0 !important">
          <TapeExpandTable :key="item.id" :tape-products="item.details.products" :tape-costs="item.details.costs" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import { TYPES } from '@/components/common/modul-grid/_utils/filters';
import { analiticMixin } from '@/mixins/analiticMixin';
import { createOzonTapeModule } from '@/store/modules/analitic/ozon/finances/tape';
import { getOzonTapeFiltersData } from '@/api/analitic/finance';

import { makeRub } from '@/mixins/makeRub';
import { format } from 'date-fns';
import TapeExpandTable from '@/components/analitic/ozon/finances/tape/TapeExpandTable';
import * as api from '@/api';
import { mapState } from 'vuex';

export default {
  name: 'TapeTable',
  components: { TapeExpandTable, ModulGridFilters },
  inject: ['filterMutations', 'pagingMutations'],

  mixins: [makeRub, analiticMixin],
  props: {
    productModule: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        { text: 'Номер отправления', value: 'id', width: '156px', type: TYPES.EQ_STRING },
        { text: 'Дата операции', value: 'operation_date', width: '126px' },
        { text: 'Дата заказа', value: 'dt', width: '126px' },
        { text: 'Статус', value: 'status', width: '168px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Количество', value: 'quauntity', width: '126px', type: TYPES.NUMBER },
        { text: 'Выручка', value: 'sumOrders', width: '126px', type: TYPES.NUMBER },
        { text: 'Выплата МП', value: 'forpay', width: '150px', type: TYPES.NUMBER },
        { text: 'Комиссия МП', value: 'commission', width: '150px', type: TYPES.NUMBER },
        { text: 'Расходы МП', value: 'costs', width: '150px', type: TYPES.NUMBER },
        { text: 'Тип продажи', value: 'typeSales', width: '150px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Склад', value: 'warehouse', width: '150px', type: TYPES.MULTIPLE, options: [] },
      ],
    };
  },

  computed: {
    ...mapState('ozonTapeFinances', ['filtersData']),
    indexedGrid() {
      return this.grid.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
  },

  created() {
    if (!this.$store.hasModule(this.productModule)) {
      this.$store.registerModule(this.productModule, createOzonTapeModule());
    }
    this.debouncedSearch = debounce(this.getTape, 800);
  },

  async mounted() {
    const statesAndTypes = await getOzonTapeFiltersData();
    this.setOptions({ field: 'typeSales', options: statesAndTypes?.typeSales });
    this.setOptions({ field: 'status', options: statesAndTypes?.status });
    this.setOptions({ field: 'warehouse', options: statesAndTypes?.warehouse });
  },

  methods: {
    getTape() {
      this.$store.dispatch(`${this.action}`);
    },
    setOptions({ field, options }) {
      this.$store.commit(`${this.productModule}/setOptions`, { field, options });
    },

    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },

    async loadDetails({ item }) {
      if (!Object.keys(item.details).length) {
        const res = await api.ozonTapeDetails({ id: item.operation_id });
        if (res) {
          item.details = res;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table.tape-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.theme--light.v-data-table.tape-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}
</style>
