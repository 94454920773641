<template>
  <div>
    <div class="text-h5 mb-9">Документы для сертификации</div>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <CertificateSearch class="mr-lg-5 mr-md-4 mr-sm-0" />
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6"> <CertificateForm class="mr-lg-5 mr-md-4 mr-sm-0" /> </v-col
    ></v-row>
  </div>
</template>

<script>
import CertificateForm from '@/components/certificate/CertificateForm.vue';
import CertificateSearch from '@/components/certificate/CertificateSearch.vue';

export default {
  name: 'CertificateMain',

  components: {
    CertificateForm,
    CertificateSearch,
  },
};
</script>
