<template>
  <div>
    <v-row class="pa-0 ma-0 flex-column">
      <v-col> <WidgetInstitute class="mobile_widget" /></v-col>
      <v-col> <WidgetSertification class="mobile_widget" /></v-col>
      <v-col> <WidgetShippers class="mobile_widget" /></v-col>
      <v-col class="pb-16 mb-8"> <WidgetStudio class="mobile_widget" /></v-col>
    </v-row>
  </div>
</template>

<script>
import WidgetInstitute from '@/components/products/WidgetInstitute';
import WidgetSertification from '@/components/products/WidgetSertification';
import WidgetShippers from '@/components/products/WidgetShippers';
import WidgetStudio from '@/components/products/WidgetStudio';

export default {
  name: 'Channels',
  components: {
    WidgetInstitute,
    WidgetSertification,
    WidgetShippers,
    WidgetStudio,
  },
};
</script>

<style lang="scss" scoped>
.mobile_widget {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
}
</style>
