import { createTabsModule } from './features/tabs';

export const wbMainTabs = createTabsModule({
  state: {
    tabs: [
      { name: 'Каталог', component: 'CatalogTabsMain', action: 'wbCatalog/catalogList' },
      {
        name: 'Продавцы',
        component: 'SellerMain',
      },
      {
        name: 'Ключевики',
        component: 'TagList',
      },
      // {
      //   name: 'Поиск товаров',
      //   component: 'SearchMain',
      // },
    ],
    tab: 0,
  },
});
