import { serverWhite } from './server-white';

/**
 * @param {Object} data
 * @param {string} [data.firstname]
 * @param {string} [data.surname]
 * @param {string} [data.middlename]
 * @param {string} [data.email]
 * @param {string} [data.bic]
 * @param {string} [data.acc]
 * @param {'osn'| 'usn'} [data.tax_type]
 * @param {'FL'| 'UL'} [data.type]
 * @param {'P' | 'F'} [data.reward_type]
 * @param {string} [data.inn]
 * @param {string} [data.ogrn]
 * @param {string} [data.company_name]
 */
async function updateProfile(data) {
  let res = await serverWhite.post('/landing/app/profile/update', data);
  return res;
}

async function getProfile() {
  let res = await serverWhite.get('/landing/app/profile/get');
  return res;
}

async function searchCompany(query) {
  let res = await serverWhite.post('/landing/app/company/search', { query });
  return res;
}

export { updateProfile, getProfile, searchCompany };
