<template>
  <v-row class="mx-7 my-3 d-flex">
    <v-col cols="7">
      <div class="mb-6 d-flex align-center">
        <div class="text-h6 font--lh21">Добавить продуктовый токен</div>
        <v-tooltip right max-width="252">
          <template v-slot:activator="{ on, attrs }">
            <svg width="18" height="18" class="ml-2">
              <use xlink:href="#info-solid" v-bind="attrs" v-on="on"></use>
            </svg>
          </template>
          <span>С помощью токена вы сможете быстро и легко изменить наименование товара</span>
        </v-tooltip>
      </div>
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="key"
          dense
          single-line
          hide-details
          :rules="[rules.required('Введите токен')]"
          outlined
          class="mb-6"
          placeholder="Продуктовый токен"
        ></v-text-field>
        <div v-if="product_token && product_token !== null" class="mb-6 d-flex align-center justify-space-between">
          <div>
            <svg width="14" height="14" class="mr-2">
              <use xlink:href="#check-circle"></use>
            </svg>
            <span style="color: #6ca300">Продуктовый токен добавлен</span>
          </div>
          <svg width="18" height="18" style="cursor: pointer" @click="dialog = true">
            <use xlink:href="#trash"></use>
          </svg>
        </div>

        <v-dialog v-model="dialog" width="480">
          <v-card class="pa-8">
            <div class="d-flex justify-space-between pb-0">
              <p class="text-h6 font-weight-regular pa-0">Подтверждение</p>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <p>Вы действительно хотите удалить добавленный продуктовый токен?</p>
            <div class="mt-8">
              <v-btn color="primary" @click="deleteProductToken">Удалить</v-btn>
              <v-btn text color="black" @click="dialog = false">Отмена</v-btn>
            </div>
          </v-card>
        </v-dialog>
        <v-btn color="primary" :disabled="!valid" @click="addToken">Добавить и подключить</v-btn>
      </v-form>
    </v-col>

    <v-col cols="5">
      <ProductTokenInfo />
    </v-col>
  </v-row>
</template>
<script>
import ProductTokenInfo from '@/components/common/cards/ProductTokenInfo.vue';

import { mapMutations, mapState } from 'vuex';
import { addOption, deleteOption } from '@/api';

export default {
  name: 'CreateProductToken',
  components: {
    ProductTokenInfo,
  },

  data() {
    return {
      key: null,
      dialog: false,
      rules: {
        required: (msg) => (v) => !!v || msg,
      },
      valid: false,
    };
  },
  computed: {
    ...mapState('options', ['product_token']),
  },
  methods: {
    ...mapMutations('common', ['showOptionProductTokenSidePanel']),
    clearFormOption() {
      this.key = null;
    },

    async addToken() {
      let data = {
        key: this.key,
        type: 'wb.card',
      };

      const ok = await addOption(data);
      if (ok) {
        this.$store.commit('options/setProductToken', this.key);
        this.$refs.form.resetValidation();
        this.$store.dispatch('options/getOptionList');
        this.$store.commit('common/showOptionProductTokenSidePanel', false);
        this.clearFormOption();
      }
    },
    async deleteProductToken() {
      let data = {
        key: this.product_token,
      };
      const ok = await deleteOption(data);
      if (ok) {
        this.$store.dispatch('options/getOptionList');
        this.$store.commit('options/setProductToken', null);
        this.$store.commit('common/showOptionProductTokenSidePanel', false);
        this.dialog = false;
        this.clearFormOption();
      }
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
  background-color: #464c59;
}
</style>
