<template>
  <v-card
    class="d-flex flex-column justify-space-between pa-4 rounded-lg"
    style="background-color: #f2f6ff; border-radius: 2px; height: 100%"
    elevation="0"
  >
    <div>
      <div class="text-subtitle-2 mb-5">Продажи</div>
      <div v-for="(item, value, index) in formatedList()" :key="index">
        <div v-if="index < limit_by" cols="12" class="d-flex justify-space-between mb-2">
          <div
            v-if="Object.values(item).filter((element) => element != null).length > 1"
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            {{ item.name }}
          </div>
          <div v-if="item.quOrders != null && item.sum_orders != null" class="text-subtitle-2">
            {{ item.quOrders + ' шт / ' + makeRub(item.sum_orders) }}
          </div>
          <div
            v-if="item.quOrders != null && (item.sum_orders === null || item.sum_orders === undefined)"
            class="text-subtitle-2"
          >
            {{ item.quOrders + ' шт' }}
          </div>
          <div
            v-if="(item.quOrders === null || item.quOrders === undefined) && item.sum_orders != null"
            class="text-subtitle-2"
          >
            {{ makeRub(item.sum_orders) }}
          </div>

          <div v-if="item.quSales != null && item.sumSales != null" class="text-subtitle-2">
            {{ item.quSales + ' шт / ' + makeRub(item.sumSales) }}
          </div>
          <div
            v-if="item.quSales != null && (item.sumSales === null || item.sumSales === undefined)"
            class="text-subtitle-2"
          >
            {{ item.quSales + ' шт' }}
          </div>
          <div
            v-if="(item.quSales === null || item.quSales === undefined) && item.sumSales != null"
            class="text-subtitle-2"
          >
            {{ makeRub(item.sumSales) }}
          </div>

          <div v-if="item.quReturns != null && item.sumRet != null" class="text-subtitle-2">
            {{ item.quReturns + ' шт / ' + makeRub(item.sumRet) }}
          </div>
          <div
            v-if="(item.sumRet === null || item.sumRet === undefined) && item.quReturns != null"
            class="text-subtitle-2"
          >
            {{ item.quReturns + ' шт' }}
          </div>
          <div
            v-if="(item.quReturns === null || item.quReturns === undefined) && item.sumRet != null"
            class="text-subtitle-2"
          >
            {{ makeRub(item.sumRet) }}
          </div>

          <div v-if="item.delivery != null" class="text-subtitle-2">{{ item.delivery + ' шт' }}</div>

          <div v-if="item.quCancel != null" class="text-subtitle-2">{{ item.quCancel + ' шт' }}</div>

          <div v-if="item.pr_ransom != null" class="text-subtitle-2">{{ item.pr_ransom.toFixed(0) + ' %' }}</div>
        </div>
      </div>
    </div>
    <v-btn
      text
      color="primary"
      class="mt-4 info__btn"
      @click="salesClick(default_limit, Object.keys(formatedList()).length)"
      >{{ limit_by === 2 ? 'Подробнее' : 'Свернуть' }}
      <v-icon class="ml-1">{{ limit_by === 2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn
    >
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { makeRub } from '@/mixins/makeRub';

export default {
  name: 'SalesInfo',
  mixins: [makeRub],

  data: () => ({
    showText: '',
    showSales: false,
    default_limit: 2,
    limit_by: 2,
  }),
  computed: {
    ...mapState('wbAnaliticInfo', ['analitic']),
  },

  methods: {
    salesClick(default_limit, filters_length) {
      this.limit_by = this.limit_by === default_limit ? filters_length : default_limit;
    },
    formatedList() {
      let ordersObj = {
        name: 'Заказы',
        quOrders: this.analitic.sales.quOrders,
        sum_orders: this.analitic.sales.sum_orders,
      };
      let salesObj = { name: 'Выкупы', quSales: this.analitic.sales.quSales, sumSales: this.analitic.sales.sumSales };
      let returnObj = {
        name: 'Возвраты',
        quReturns: this.analitic.sales.quReturns,
        sumRet: this.analitic.sales.sumRet,
      };
      let deliveryObj = { name: 'Доставляются', delivery: this.analitic.sales.delivery };
      // let cancelObj = { name: 'Отмена', quCancel: this.analitic.sales.quCancel };
      let percentObj = { name: 'Процент выкупа', pr_ransom: this.analitic.sales.pr_ransom };

      return { ordersObj, salesObj, returnObj, deliveryObj, percentObj };
    },
  },
};
</script>

<style scoped>
.info__btn.v-btn:not(.v-btn--round).v-size--default {
  justify-content: flex-start;
  padding: 0;
}
</style>
