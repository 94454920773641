<template>
  <div>
    <v-btn text :ripple="false" class="pa-0 mb-2 no-hover-effect" small @click="back()">
      <svg class="mr-2" width="16" height="16" fill="#717D83">
        <use xlink:href="#arrow-left"></use>
      </svg>
      <span class="wrap__back font--gray10">НАЗАД</span>
    </v-btn>

    <div class="tab-metric">
      <v-tabs v-model="currentTab">
        <v-tab v-for="({ name, id }, index) in tabs" :key="id" class="mr-2" @click="currentTab = index">
          <div class="tab-content font--p">
            {{ name }}
            <v-btn x-small icon class="ml-2" @click.stop="closeTab(id)">
              <v-icon :color="currentTab === id ? 'white' : '#9B9FA8'">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-tab>
      </v-tabs>
    </div>

    <v-card flat class="mx-auto font card-wb-metric">
      <v-main>
        <v-container fluid class="metrica__container" style="justify-content: flex-start !important">
          <v-tabs-items v-model="currentTab">
            <v-tab-item v-for="{ component, id } in tabs" :key="id">
              <component :is="component" :id="id" @cat:change="onCatChange"></component>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-main>
    </v-card>

    <SidePanel v-model="showCard" :title="'Описание товара'" :stateless="true">
      <ProductCard v-if="product" :close-panel="showCard" />
    </SidePanel>
  </div>
</template>

<script>
import SidePanel from '@/components/common/SidePanel';
import TagTabs from '@/components/metric/wb/tag/TagTabs';
import BrandTabs from '@/components/metric/wb/brands/BrandTabs';
import Country from '@/components/metric/wb/country/Country.vue';
import Category from '@/components/metric/wb/category/Category.vue';
import ProductCard from '@/components/metric/wb/product/ProductCard';
import SellerTabs from '@/components/metric/wb/seller/SellerTabs.vue';

import * as filterMutations from '@/store/modules/metric/wb/mutations/filter';
import * as pagingMutations from '@/store/modules/metric/wb/mutations/paging';
import * as gridMutations from '@/store/modules/metric/wb/mutations/grid';
import * as tabMutations from '@/store/modules/metric/wb/mutations/tabs';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MetricTabs',

  components: {
    TagTabs,
    Country,
    Category,
    BrandTabs,
    SidePanel,
    SellerTabs,
    ProductCard,
  },

  provide: {
    filterMutations,
    pagingMutations,
    gridMutations,
  },

  watch: {
    tabs(e) {
      if (e.length == 0) {
        this.setMetricAdditional(false);
      }
    },
  },

  computed: {
    ...mapState('common', ['showCardWb']),
    ...mapState('wbProductCard', ['product']),
    ...mapState('wbMetricTabs', ['tabs', 'tab']),

    currentTab: {
      get() {
        return this.tab;
      },

      set(value) {
        this[tabMutations.SET_TAB](value);
      },
    },

    showCard: {
      get() {
        return this.showCardWb;
      },
      set(value) {
        this.showCardWbModal(value);
      },
    },
  },

  methods: {
    ...mapMutations('common', ['showCardWbModal']),
    ...mapMutations('common', ['setMetricAdditional']),
    ...mapMutations('wbMetricTabs', [tabMutations.SET_TAB]),

    back() {
      this.setMetricAdditional(false);
    },

    onCatChange(id) {
      const index = this.tabs.findIndex((tab) => tab.id === id);

      if (index !== -1) {
        this[tabMutations.SET_TAB](index);
      }
    },

    closeTab(id) {
      this.$store.commit(`wbMetricTabs/${tabMutations.REMOVE_TAB}`, id);
      this.$store.unregisterModule(`wbMetricModule/${id}`);

      if (this.tabs.length == 0) {
        this.setMetricAdditional(false);
      }
    },
  },
};
</script>

<style lang="scss">
.popap-folder {
  z-index: 999999 !important;
}

.tab-metric {
  .v-slide-group__prev,
  .v-slide-group__next {
    background-color: #f6f5f6 !important;
  }

  .v-tab:hover::before {
    border-radius: 30px;
  }

  .v-tabs-slider-wrapper {
    display: none;
  }
  .v-tab {
    border-radius: 30px;
    height: 32px;
  }
  .v-tabs-bar__content {
    background-color: #f6f5f6 !important;
  }

  .v-tab--active {
    background-color: #4971d0 !important; // primary color
    color: white;

    .tab-content {
      color: white !important;
    }

    .v-btn.v-size--x-small {
      color: white;
    }
  }

  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    background-color: white;
    color: #9b9fa8;
  }

  .tab-content {
    display: flex;
    align-items: center;
  }
}
</style>
