<template>
  <SearchSeller v-model="sellerModal" dense style="max-width: 360px" @cat:change="$emit('cat:change', $event)" />
</template>
<script>
import SearchSeller from '@/components/metric/wb/seller/SearchSeller';

export default {
  name: 'SellerMain',
  components: {
    SearchSeller,
  },

  data: () => ({
    sellerModal: null,
  }),
  computed: {},

  watch: {
    sellerModal(current) {
      if (current) {
        this.name = current.name;
        this.id = current.id;
      } else {
        this.name = '';
        this.id = '';
      }
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
