import { serverWhite } from '../server-white';

// все заказы
async function wbOrders(data) {
  const res = await serverWhite.post('/admin/analytics/wb/orders', data);
  return res;
}
async function ozonOrders(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/orders', data);
  return res;
}

async function getOzonOrderFiltersData() {
  return await serverWhite.post('/admin/analytics/ozon/orders_available', {});
}

async function wbOrderDetails(data) {
  const res = await serverWhite.post('/admin/analytics/wb/orders_detail', data);
  return res;
}

async function ozonOrderDetails(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/ordersDetail', data);
  return res;
}

// заказы по регионам
async function wbOrdersRegion(data) {
  const res = await serverWhite.post('/admin/analytics/wb/orders_reg ', data);
  return res;
}

async function ozonOrdersRegion(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/ordersReg ', data);
  return res;
}

async function getOzonRegionFiltersData() {
  return await serverWhite.post('/admin/analytics/ozon/ordersReg_available', {});
}

// заказы по складам
async function wbOrdersWarehouse(data) {
  const res = await serverWhite.post('/admin/analytics/wb/orders_warehouse ', data);
  return res;
}

async function getWbWarehouseFiltersData() {
  return await serverWhite.post('/admin/analytics/wb/orders_warehouse_available', {});
}

async function ozonOrdersWarehouse(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/ordersWarehouses ', data);
  return res;
}

async function getOzonWarehouseFiltersData() {
  return await serverWhite.post('/admin/analytics/ozon/ordersWarehouses_available', {});
}
// сборочные задания FBS
async function wbOrdersAssembly(data) {
  const res = await serverWhite.post('/admin/analytics/wb/assembly_tasks ', data);
  return res;
}

async function ozonOrdersAssembly(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/tasks ', data);
  return res;
}
async function getOzonAssemblyFiltersData() {
  return await serverWhite.post('/admin/analytics/ozon/tasks_available', {});
}

async function wbAssemblyDetails(data) {
  const res = await serverWhite.post('/admin/analytics/wb/assembly_tasks_detail ', data);
  return res;
}

async function ozonAssemblyDetails(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/tasksDetail ', data);
  return res;
}

export {
  wbOrders,
  ozonOrders,
  wbOrdersRegion,
  ozonOrdersRegion,
  wbOrdersWarehouse,
  ozonOrdersWarehouse,
  wbOrderDetails,
  ozonOrderDetails,
  wbOrdersAssembly,
  ozonOrdersAssembly,
  wbAssemblyDetails,
  ozonAssemblyDetails,
  getWbWarehouseFiltersData,
  getOzonOrderFiltersData,
  getOzonRegionFiltersData,
  getOzonWarehouseFiltersData,
  getOzonAssemblyFiltersData,
};
