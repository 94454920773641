<template>
  <div v-if="position">
    <div class="text-h6 font--lh24">Категории и позиции в них</div>
    <v-simple-table height="300px" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Категории</th>
            <th v-for="(item, i) in tableHeadersDate" :key="i" class="text-center">
              {{ new Date(item).toLocaleDateString('ru-RU') }}
            </th>
          </tr>
        </thead>
        <tbody v-for="(pos, i) in position" :key="i">
          <tr class="name">
            <td style="cursor: pointer" @click="closeSlideCard(pos)">
              {{ pos.name }}
            </td>
            <td v-for="headerDate in tableHeadersDate" :key="headerDate" class="text-center">
              {{ extractPosition(pos, headerDate) || '-' }}
            </td>
          </tr>
          <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';

export default {
  name: 'ProductPosition',
  components: {},
  mixins: [makeRub],
  props: {
    product_card: Object, // eslint-disable-line
  },
  data() {
    return {
      item: null,
    };
  },
  computed: {
    ...mapGetters('wbMainTabs', ['tabs', 'hasTab']),

    position() {
      return this.product_card?.positions;
    },

    tableHeadersDate() {
      return this.product_card.dates?.map((elem) => elem.date);
    },

    tableValues() {
      const headersDate = this.tableHeadersDate;
      const values = this.position;

      // имя -> (дата -> позиция)
      const tableDataMap = new Map();

      for (let i = 0; i < values.length; i++) {
        const graph = values[i].graph;

        if (graph) {
          // дата -> позиция
          const valuesMap = new Map();
          for (let headerIndex = 0; headerIndex < headersDate.length; headerIndex++) {
            const headerValue = headersDate[headerIndex];

            const graphValue = graph[headerValue];
            valuesMap.set(headerValue, graphValue);
          }
          tableDataMap.set(values[i].name, valuesMap);
        }
      }

      return tableDataMap;
    },
  },
  methods: {
    ...mapActions('wbMainTabs', ['addTab']),
    ...mapActions('wbProductCard', ['isCardEditing']),
    ...mapMutations('common', ['showCardWbModal']),

    closeSlideCard(item) {
      this.onCatalogClick(item);
      this.showCardWbModal(false);
      this.isCardEditing();
    },
    extractPosition(pos, headerDate) {
      return this.tableValues?.get(pos.name)?.get(headerDate);
    },
    onCatalogClick(item) {
      if (!this.hasTab(item.cid)) {
        this.addTab({
          id: item.cid,
          name: item.name,
          component: 'Category',
          action: `wbCategory/${item.cid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.cid);
      }
      const moduleName = `wbCategory/${item.cid}`;
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'cid', cid: item.cid }));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.name > td:first-child:hover {
  color: #1976d2;
  background: #e3f2fd;
  border: 1px solid #1976d2;
}
.name > td:first-child {
  border: 1px solid transparent;
}
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background: white;
}
table > thead > tr > th:nth-child(1) {
  z-index: 9999;
}
</style>
