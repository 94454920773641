import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';
import { today, thirtyDaysBefore } from '@/mixins/filterDate';
import Vue from 'vue';

const initState = () => ({
  product: {},
  sdate: thirtyDaysBefore,
  edate: today,
  pid: null,
  keywords: '',
  keywordsResult: null,
});

export const ozonProductCard = {
  namespaced: true,
  state: initState,
  getters: {
    sdate: (state) => state.sdate,
    edate: (state) => state.edate,
    pid: (state) => state.pid,
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setProduct: (state, value) => (state.product = value),
    setKeywords: (state, value) => (state.keywords = value),
    setKeywordResults: (state, value) => (state.keywordsResult = value),
    setStartDate: (state, sdate) => (state.sdate = sdate),
    setEndDate: (state, edate) => (state.edate = edate),
    setPid: (state, pid) => (state.pid = pid),
    setFileLoading: (state, payload) => Vue.set(state, 'fileLoading', payload),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    isCardEditing({ commit }) {
      commit('setProduct', {});
      commit('setPid', null);
      commit('setStartDate', null);
      commit('setEndDate', null);
    },
    async getCard({ commit, getters }) {
      let data = {
        pid: getters.pid,
        sdate: getters.sdate,
        edate: getters.edate,
        marketplace: 'ozon',
      };
      const res = await api.getProduct(data);
      if (res) {
        commit('setProduct', res);
      }
    },

    async checkKeywords({ commit }, data) {
      const res = await api.checkKeywords(data);

      if (res) {
        commit('setKeywordResults', res);
      }
    },

    async getExportListCategories({ commit, getters }) {
      commit('setFileLoading', true);
      let data = {
        pid: getters.pid,
        marketplace: 'ozon',
      };
      const res = await api.getExportCategories(data);

      if (res.fileid) {
        const fileid = await res.fileid;
        // выгрузка файла
        const fileResponse = await api.fileGetHidden(fileid);
        console.log('fileResponse', fileResponse);

        if (fileResponse) {
          const blob = new Blob([fileResponse.data], {
            type: fileResponse.headers['content-type'],
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `categories_ozon_${new Date().getTime()}.xls`;
          link.click();
          commit('setFileLoading', false);
        }
      }
    },

    async getExportListTags({ commit, getters }) {
      commit('setFileLoading', true);

      let data = {
        pid: getters.pid,
        marketplace: 'ozon',
      };

      const res = await api.getExportTags(data);
      if (res.fileid) {
        const fileid = await res.fileid;
        // выгрузка файла
        const fileResponse = await api.fileGetHidden(fileid);
        console.log('fileResponse', fileResponse);

        if (fileResponse) {
          const blob = new Blob([fileResponse.data], {
            type: fileResponse.headers['content-type'],
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `tags_ozon_${new Date().getTime()}.xls`;
          link.click();
          commit('setFileLoading', false);
        }
      }
    },
  },
};
