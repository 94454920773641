<template>
  <v-card class="px-lg-8 py-lg-6 pa-sm-6 pa-xs-6 mb-4">
    <svg width="196" height="40" style="fill: #4971d0">
      <use xlink:href="#logo-product"></use>
    </svg>

    <Tariffs class="mb-3" />

    <v-row>
      <v-col v-for="(item, i) of connected" :key="i" cols="12" md="4">
        <v-card class="pa-2 elevation-0" color="#F5F5F6">
          <div class="text-uppercase d-flex align-center">
            <svg width="18" height="16" class="icon mr-lg-1 mx-md-2">
              <use :xlink:href="item.icon"></use>
            </svg>
            <v-card-text class="font--lh14 font-weight-medium text-caption pl-2 py-4">{{ item.title }}</v-card-text>
          </div>
          <v-card-text class="font--lh20 text-body-2 pt-md-0 font--black1">{{ item.text }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="d-flex justify-space-between align-center">
      <div class="pl-0">
        <v-card-text class="font--fs18 font-weight-medium pb-0 pl-0"> Маркет менеджер </v-card-text>
        <v-card-text class="font--graphite3 text-body-2 pl-0 d-flex flex-wrap align-center"
          ><span class="mr-4">Сертифицированный специалист</span>
          <div class="d-flex flex-row">
            <LogoMarket :type="'ozon'" :height="12" class="mr-4" />
            <LogoMarket :type="'yandex'" :width="83" class="mr-4" />
            <LogoMarket :type="'wb'" />
          </div>
        </v-card-text>
        <v-card-text class="font--graphite3 text-body-2 pt-0 pl-0"
          >Поможет наладить продажи как новичкам, так и опытным продавцам.</v-card-text
        >
      </div>
      <div>
        <v-card class="card mt-6" max-width="240px">
          <div>
            <v-card-text class="font--black1 text-body-2 font-weight-medium pa-3 pb-0">Рыбалко Александра</v-card-text>
            <v-card-text class="font--graphite3 text-caption font--lh16 mb-10 mr-5 pa-3"
              >Добро пожаловать в Маркетплейс. Я ваш персональный ассистент.</v-card-text
            >
          </div>
        </v-card>
        <v-avatar size="100" class="avatar mr-8 mb-8" color="gray">
          <img src="img/profile.png" alt="Name" />
        </v-avatar>
      </div>
    </div>
  </v-card>
</template>

<script>
import LogoMarket from '@/components/common/LogoMarket';
import Tariffs from '@/components/common/Tariffs';

export default {
  name: 'WidgetNotConnected',
  components: { LogoMarket, Tariffs },
  data() {
    return {
      connected: [
        {
          icon: '#pos',
          title: 'Маркет метрика',
          text: 'Помогает выбрать товарную нишу. Показывает продажи в разрезе категорий, брендов и продавцов.',
        },
        {
          icon: '#refill',
          title: 'Кредит под закупку',
          text: 'Помогает выбрать товарную нишу. Показывает продажи в разрезе категорий, брендов и продавцов.',
        },
        {
          icon: '#atm',
          title: 'Моментальный вывод',
          text: 'Помогает выбрать товарную нишу. Показывает продажи в разрезе категорий, брендов и продавцов.',
        },
      ],
    };
  },
};
</script>
<style scoped>
.avatar {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.card {
  z-index: 2;
  margin-right: 90px;
}

.icon {
  fill: #4971d0;
}

.btn {
  font-size: 12px;
}
</style>
