import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';
import { IS_DEVELOPMENT } from '@/constants';

const initState = () => ({
  active: null,
  managers: [],
  servicePack: null,
  greenLimit: null,
  managersPhoto: {
    Бурындина: 'img/buryndina.png',
    Рыбалко: 'img/rybalko.png',
    Каменская: 'img/kamenskaya.png',
    Дмитриева: 'img/dmitrieva.png',
    Ердан: 'img/erdan.png',
    Прокофьев: 'img/prokofiev.png',
    Шушкова: 'img/shushkova.png',
    Ноздрина: 'img/nozdrina.png',
    Мамонтов: 'img/mamontov.png',
    Кузина: 'img/kuzina.png',
    Айтуганов: 'img/aituganov.png',
  },
  marketmetric: null,
  notifications: [],
  info: null,
  analytics: null,
  analyticStatus: null,
});

// Поле active теперь содержит 4 статуса:
// enabled - опция подключена
// disabled - опция отключена
// disable - опция отключается
// enable - опция подключается

export const products = {
  namespaced: true,
  state: initState,
  getters: {
    isActive: (state) => state.active === 'enabled',
    inProgress: (state) => state.active === 'enable',
    isDisabled: (state) => state.active === 'disabled',
    isLoaded: (state) => state.active !== null,
    showPackage: (state) => state.servicePack !== null && Object.keys(state.servicePack).length,
    showAnalytics: (state) => state.analyticStatus === 'enabled',
    showInfo: (state) => state.analytics !== null,
    showManagers: (state) => state.managers.length,
    showGreen: (state) => !!state.greenLimit,
    showNotifications: (state) => state.notifications.length,
    topCatalogs: (state) => {
      let cat = state.marketmetric?.find((w) => w.data.catalogs != null)?.data?.catalogs;
      return cat;
    },
    bestProduct: (state) => {
      let cat = state.marketmetric?.find((w) => w.data.products != null)?.data?.products;
      return cat;
    },
    getManagers: (state) => {
      if (state.managers) {
        state.managers.map((m) => {
          let surname = m.name.split(' ')[0];
          if (state.managersPhoto[surname]) {
            m.src = state.managersPhoto[surname];
          }
        });
        return state.managers;
      }
    },
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setActive: (state, value) => {
      if (IS_DEVELOPMENT) {
        console.log('setActive', value);
      }

      state.active = value;
    },
    deleteNotification: (state, id) => {
      const i = state.notifications.findIndex((item) => item.id == id);
      state.notifications.splice(i, 1);
    },
    setAnalitics: (state, value) => {
      state.analytics = value;
    },
    setAnaliticStatus: (state, value) => {
      state.analyticStatus = value;
    },
    setManagers: (state, value) => {
      state.managers = value;
    },
    setPackage: (state, value) => {
      state.servicePack = value;
    },
    setNotifications: (state, value) => {
      state.notifications = value;
    },
    setMarketmetric: (state, value) => {
      state.marketmetric = value;
    },
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async mainGet({ commit }) {
      const res = await api.mainGet();
      if (res) {
        commit('setActive', res.active);
        if (res.managers) commit('setManagers', res.managers);
        if (res.package) commit('setPackage', res.package);
        if (res.marketmetric) commit('setMarketmetric', res.marketmetric);
        if (res.notifications) commit('setNotifications', res.notifications);
        if (res.greenlimit) commit('set', { type: 'greenLimit', value: res.greenlimit });
        if (res.analytics) {
          commit('setAnalitics', res.analytics);
          commit('setAnaliticStatus', res.analytics.status.active);
        }
      }
    },
    async deleteNotification({ commit }, id) {
      const res = await api.deleteNotification(id);
      if (res) {
        commit('deleteNotification', id);
      }
    },
  },
};
