<template>
  <v-card min-height="182px" class="font mx-auto pa-4" color="#F2F6FF">
    <v-list-item three-line class="pa-0">
      <v-list-item-content>
        <div>
          <svg width="49" height="40">
            <use xlink:href="#token"></use>
          </svg>
        </div>

        <v-list-item-title class="text-body-2 font-weight-medium mb-3">
          Где найти токен в Яндекс Маркете?</v-list-item-title
        >

        <span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 1</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              Зайдите в личный кабинет
              <span>
                <a target="_blank" href="https://partner.market.yandex.ru/">Яндекс Маркета</a>
              </span></v-card-text
            >
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 2</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1"> Выберите "Настройки > Настройки API" </v-card-text>
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 3</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              Скопируйте из поля ID компании (число после дефиса)
            </v-card-text>
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 4</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              В ЛК Банка выберите Маркетплейс "Яндекс Маркет" > вставьте ID в поле "ID компании"> Нажмите кнопку
              "Получить код подтверждения". Вы будете перенаправлены на страницу Яндекса
            </v-card-text>
          </span>
          <span>
            <div class="text-body-2 font-weight-medium text-uppercase">Шаг 5</div>
            <v-card-text class="text-body-2 font--lh21 px-0 py-1">
              Скопируйте код > вставьте его в поле "Код подтверждения" в ЛК Банка > Нажмите кнопку "Добавить"
            </v-card-text>
          </span>
        </span>
      </v-list-item-content>
    </v-list-item>

    <v-btn
      class="text-caption font-weight-bold"
      width="100%"
      outlined
      color="primary"
      style="border: 2px solid"
      :href="'https://modulbank.ru/fs/files/market_seller.pdf'"
      target="_blank"
    >
      Поговорить с поддержкой
    </v-btn>
  </v-card>
</template>
<script>
export default {
  name: 'ProductTokenInfo',
};
</script>
