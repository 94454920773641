<template>
  <div>
    <div
      class="d-flex font--black1 pa-0 mb-3 ml-0"
      :class="productType ? 'product_text font-weight-medium' : 'text-subtitle-2 ml-9'"
    >
      Доступно для вывода с маркетплейсов по реквизитам
    </div>
    <div class="d-flex">
      <span>
        <svg width="22" height="19" class="icon">
          <use xlink:href="#card-refill"></use>
        </svg>
      </span>
      <div class="ml-4">
        <div class="font--black1" :class="productType ? 'product_text font--lh17' : 'text-body-2 font--lh20'">
          Для перевода на карты физических лиц
        </div>
        <div class="font--black1" :class="productType ? 'text-h6 font--lh30' : 'text-h5 sum_weight font--lh34 '">
          {{ formatedLimit(limitMp) }}
        </div>
        <div class="text-caption font--lh17 font--graphite3">{{ rateMp }}</div>
      </div>
    </div>

    <div class="d-flex mt-4">
      <span>
        <svg width="22" height="22" class="icon">
          <use xlink:href="#coins"></use>
        </svg>
      </span>
      <div class="ml-4">
        <div class="font--black1" :class="productType ? 'product_text font--lh17' : 'text-body-2 font--lh20'">
          Снятие наличных
        </div>
        <div class="font--black1" :class="productType ? 'text-h6 font--lh30' : 'text-h5 sum_weight font--lh34'">
          {{ formatedLimit(limitNal) }}
        </div>
        <div class="text-caption font--lh17 font--graphite3">{{ rateNal }}</div>
      </div>
    </div>

    <div
      class="font-weight-medium font--black1 mb-1"
      :class="productType ? 'product_text mt-6 font--lh17' : 'text-body-2 mt-8 font--lh20 ml-9'"
    >
      Доступно для переводов по реквизитам от проверенных контрагентов
    </div>
    <div class="d-flex align-center">
      <svg width="20" height="22" class="icon mr-4">
        <use xlink:href="#user-card"></use>
      </svg>
      <div class="font--black1" :class="productType ? 'text-h6 font--lh30' : 'text-h5 sum_weight font--lh34'">
        {{ formatedLimit(limitCta) }}
        <div class="text-caption font--lh17 font--graphite3">{{ rateCta }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { makeRub } from '@/mixins/makeRub';

export default {
  name: 'WidgetGreenLimit',
  mixins: [makeRub],

  props: {
    limitCta: {
      type: Number,
      default: 0,
    },
    limitMp: {
      type: Number,
      default: 0,
    },
    limitNal: {
      type: Number,
      default: 0,
    },
    rateNal: {
      type: String,
      default: '',
    },
    rateMp: {
      type: String,
      default: '',
    },
    rateCta: {
      type: String,
      default: '',
    },
    productType: {
      type: Boolean,
    },
  },

  methods: {
    formatedLimit(limit) {
      if (limit === 0 || limit === null) {
        return this.makeRub(limit, 0);
      } else return this.makeRub(limit, 2);
    },
  },
};
</script>

<style scoped>
.icon {
  fill: #5e8c00;
}
.text {
  z-index: 999999;
  position: relative;
}

.btn {
  border: 2px solid;
}

.sum_weight {
  font-weight: 600 !important;
}

.product_text {
  font-size: 12px;
}
</style>
