<template>
  <div class="table__container px-4">
    <TagSearch class="mb-2 tagSearch" @query="setQuery" />

    <div class="d-flex flex-column py-4">
      <span class="text-subtitle-2 font--black1 mb-2">Подбор запросов</span>
      <span class="text-caption font--graphite3">Статистика за последние 30 дней</span>
    </div>

    <div class="mb-4">
      <modul-grid-filters
        ref="grid-filters"
        class="px-0"
        :module-name="`${productModule}`"
        :action="`${action}`"
        :columns="headers"
        :filters="true"
        :tags-filters="true"
        :footer-props="footerProps"
        :text="text"
        @export="exportFile"
      >
      </modul-grid-filters>
    </div>

    <v-data-table
      :mobile-breakpoint="100"
      class="tags-table"
      :headers="headers"
      :items="grid"
      item-key="id"
      :no-data-text="noDataText"
      fixed-header
      :items-per-page="50"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="total"
      @update:options="onUpdateOptions($event)"
    >
      <template #[`item.name`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.frequency`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.sales`]="{ value }">
        <span class="text-no-wrap">{{ value === null || !value ? '-' : value }}</span>
      </template>
      <template #[`item.products`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.prodWithSaleAmount`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.income`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : numberFormat(value, 2) }}</span>
      </template>
      <template #[`item.incomeProduct`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : numberFormat(value, 2) }}</span>
      </template>
      <template #[`item.priceAvg`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : numberFormat(value, 2) }}</span>
      </template>
      <template #[`item.sellers`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.sellersWithSaleAmount`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.brands`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.brandsWithSaleAmount`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.prodWithSalePercent`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.sellersWithSalePercent`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
      <template #[`item.brandsWithSalePercent`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import TagSearch from './TagSearch.vue';
import { sortMixin } from '@/mixins/sortMixin';
import { numberFormat } from '@/mixins/numberFormat';
import { mapActions, mapMutations } from 'vuex';
import { createWbTagsModule } from '@/store/modules/metric/wb/tag';
import { TYPES } from '@/components/common/modul-grid/_utils/filters';
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import { NUMBER_OPERATOR_VALUES } from '@/components/common/modul-grid/_const/filterConstants';

export default {
  name: 'TagList',

  components: { ModulGridFilters, TagSearch },
  mixins: [numberFormat, sortMixin],
  inject: ['filterMutations', 'pagingMutations'],
  props: {
    search: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showFilters: false,
      productModule: 'wbTag',
      action: 'wbTag/wbTagList',
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Не указаны ключевые запросы для анализа',
      headers: [
        { text: 'Запрос', value: 'name', width: '148px' },
        {
          text: 'Частота Wb',
          value: 'frequency',
          width: '148px',
          type: TYPES.NUMBER,
          defaultOperator: NUMBER_OPERATOR_VALUES.GREATER_OR_EQUALS,
          name: 'Частота WB не менее',
        },
        { text: 'Продажи', value: 'sales', width: '148px' },
        {
          text: 'Продавцы',
          value: 'sellers',
          width: '148px',
          type: TYPES.NUMBER,
          defaultOperator: NUMBER_OPERATOR_VALUES.LESS_OR_EQUALS,
          name: 'Продавцов не более',
        },
        {
          text: 'Продавцы с продажами',
          value: 'sellersWithSaleAmount',
          width: '148px',
        },
        {
          text: 'Бренды',
          value: 'brands',
          width: '148px',
          type: TYPES.NUMBER,
          defaultOperator: NUMBER_OPERATOR_VALUES.LESS_OR_EQUALS,
          name: 'Брендов не более',
        },
        {
          text: 'Бренды с продажами',
          value: 'brandsWithSaleAmount',
          width: '135px',
        },
        {
          text: 'Товары',
          value: 'products',
          width: '148px',
          type: TYPES.NUMBER,
          defaultOperator: NUMBER_OPERATOR_VALUES.LESS_OR_EQUALS,
          name: 'Товаров не более',
        },
        {
          text: 'Товары с продажами',
          value: 'prodWithSaleAmount',
          width: '130px',
        },
        {
          text: 'Выручка,\xa0₽',
          value: 'income',
          width: '148px',
          type: TYPES.NUMBER,
          defaultOperator: NUMBER_OPERATOR_VALUES.GREATER_OR_EQUALS,
          name: 'Выручка не менее',
        },
        {
          text: 'Выручка на товар,\xa0₽/шт',
          value: 'incomeProduct',
          width: '148px',
        },
        {
          text: 'Ср.цена,\xa0₽',
          value: 'priceAvg',
          width: '148px',
          type: TYPES.NUMBER,
          rangeLimits: { min: 0, max: 400000 },
          name: 'Средняя цена,\xa0₽',
        },
        {
          text: '% товаров с продажами',
          value: 'prodWithSalePercent',
          width: '148px',
          type: TYPES.NUMBER,
          rangeLimits: { min: 50, max: 80 },
          name: '% товаров с продажами',
        },
        {
          text: '% продавцов с продажами',
          value: 'sellersWithSalePercent',
          width: '148px',
          type: TYPES.NUMBER,
          rangeLimits: { min: 50, max: 80 },
          name: '% продавцов с продажами',
        },
        {
          text: '% брендов с продажами',
          value: 'brandsWithSalePercent',
          width: '148px',
          type: TYPES.NUMBER,
          rangeLimits: { min: 50, max: 80 },
          name: '% брендов с продажами',
        },
      ],
      text: '',
      id: '',
      frequency: '',
    };
  },

  computed: {
    total() {
      return this.$store.getters[`${this.productModule}/total`];
    },

    grid() {
      return this.$store.getters[`${this.productModule}/grid`];
    },
  },

  created() {
    if (!this.$store.hasModule(this.productModule)) {
      this.$store.registerModule(this.productModule, createWbTagsModule());
    }

    this.debouncedSearch = debounce(this.getTags, 800);
  },

  methods: {
    ...mapActions('wbTag', ['getExportListTags']),
    ...mapMutations('wbTag', ['setFilterExport']),

    getTags() {
      this.$store.dispatch(`${this.action}`);
    },

    tagSearch(current) {
      if (current) {
        this.text = current.text;
        this.id = current.id;
        this.frequency = current.id;
        this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE}`, 1);
        this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE_SIZE}`, 10);
        this.$store.commit(`${this.productModule}/${this.filterMutations.RESET_SORT}`);
        this.debouncedSearch(current.text);

        this.$store.commit(`${this.productModule}/${this.filterMutations.SET_SEARCH_QUERY}`, current.text);
      } else {
        this.text = '';
        this.id = '';
        this.frequency = '';
      }
    },

    setQuery(e) {
      this.tagSearch(e);
    },

    exportFile() {
      this.setFilterExport(this.$store._modules.root.state['wbTag']);
      this.getExportListTags(this.text);
    },
  },
};
</script>

<style scoped>
.v-data-table.tags-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.tags-table >>> th {
  color: #777c87 !important;
  background-color: #ffffff !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.tags-table {
  width: 100%;
}

table > thead > tr > th:nth-child(1) {
  z-index: 9999;
}
.tags-table >>> td:first-child {
  border-right: thin solid rgba(0, 0, 0, 0.12);
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.tags-table >>> td:nth-child(1),
.tags-table >>> th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background: white;
}

.tagSearch {
  max-width: 300px;
  @media (max-width: 960px) {
    max-width: 100%;
  }
}
</style>
