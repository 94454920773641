<template>
  <div>
    <v-data-table
      :mobile-breakpoint="100"
      class="productlist-table"
      :headers="headers"
      :items="grid"
      item-key="id"
      :server-items-length="total"
      :options.sync="options"
      :footer-props="footerProps"
      :no-data-text="noDataText"
      :items-per-page="50"
      @update:options="onUpdateOptions($event)"
    >
      <template #top>
        <v-text-field
          v-model="search"
          dense
          single-line
          hide-details
          outlined
          placeholder="Введите название товара SKU"
          class="mb-4 ml-sm-auto"
          clearable
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </template>

      <template #[`item.name`]="{ value, item }">
        <span>
          <a style="cursor: pointer" @click="openCard(item.id)">{{ value }}</a>
        </span>
      </template>

      <template #[`item.imagePreview`]="{ value }">
        <img width="50" :src="value" />
      </template>

      <template #[`item.extid`]="{ value, item }">
        <span class="d-flex">
          <v-icon class="mr-2">mdi-source-branch</v-icon>
          <a target="_blank" :href="`${item.extlink}`" class="text-decoration-none">{{ value }}</a>
        </span>
      </template>

      <template #[`item.brand`]="{ value, item }">
        <a style="cursor: pointer" @click="onBrandClick(item)">{{ value }}</a>
      </template>

      <template #[`item.seller`]="{ value, item }">
        <a style="cursor: pointer" @click="onSellerClick(item)">{{ value }}</a>
      </template>

      <!-- <template #[`item.salesSchedule`]="{ value }">
        <SalesGraph :value="value" />
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';
import { today, thirtyDaysBefore } from '@/mixins/filterDate';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { sortMixin } from '@/mixins/sortMixin';
import debounce from 'lodash/debounce';

export default {
  name: 'SearchList',

  mixins: [today, thirtyDaysBefore, sortMixin],
  inject: ['filterMutations', 'pagingMutations', 'gridMutations'],
  props: {
    productModule: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      imageName: null,
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        { text: 'Название', value: 'name' },
        { text: '', value: 'imagePreview', sortable: false },
        { text: 'SKU', value: 'extid' },
        { text: 'Продавец', value: 'seller' },
        { text: 'Бренд', value: 'brand' },
        // { text: 'График продаж', value: 'salesSchedule', sortable: false, width: '200px' },
      ],
    };
  },

  computed: {
    ...mapGetters('wbMetricTabs', ['hasTab']),

    grid() {
      return this.$store.getters[`${this.productModule}/grid`];
    },

    total() {
      return this.$store.getters[`${this.productModule}/total`];
    },

    search: {
      get() {
        return this.$store.getters[`${this.productModule}/search_query`];
      },
      set(query) {
        this.$store.commit(`${this.productModule}/${this.filterMutations.SET_SEARCH_QUERY}`, query);
      },
    },
  },

  watch: {
    search(v) {
      // в поиске товара пустой запрос не кидать
      if (!v) {
        this.resetSearch();
        return;
      }

      // сброс
      this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE}`, 1);
      this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE_SIZE}`, 10);
      this.$store.commit(`${this.productModule}/${this.filterMutations.RESET_SORT}`);

      this.debouncedSearch(v);
    },
  },
  created() {
    this.debouncedSearch = debounce(this.findProduct, 800);
  },

  methods: {
    ...mapActions('wbMetricTabs', ['addTab']),
    ...mapActions('wbProductCard', ['getCard']),
    ...mapMutations('common', ['showCardWbModal']),
    ...mapMutations('common', ['setMetricAdditional']),

    openCard(pid) {
      this.$store.commit('wbProductCard/setStartDate', thirtyDaysBefore);
      this.$store.commit('wbProductCard/setEndDate', today);
      this.$store.commit('wbProductCard/setPid', pid);
      this.getCard();
      this.showCardWbModal(true);
    },

    findProduct() {
      this.$store.dispatch(`${this.productModule}/findProduct`);
    },

    getList() {
      // TODO For proper mixin work needs refactor
      this.findProduct();
    },

    resetSearch() {
      this.$store.commit(`${this.productModule}/${this.gridMutations.SET_GRID}`, []);
      this.$store.commit(`${this.productModule}/${this.filterMutations.RESET_SORT}`);
      this.$store.commit(`${this.productModule}/${this.pagingMutations.RESET_PAGING}`);
    },

    onBrandClick(item) {
      if (!this.hasTab(item.brandid)) {
        this.addTab({
          id: item.brandid,
          name: item.brand,
          component: `BrandTabs`,
          action: `wbMetricModule/${item.brandid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.brandid);
      }

      const moduleName = `wbMetricModule/${item.brandid}`;

      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'bid', cid: item.brandid }));
      }

      this.setMetricAdditional(true);
    },

    // регистрация/создание нового модуля. таб продавцы
    onSellerClick(item) {
      if (!this.hasTab(item.sellerid)) {
        this.addTab({
          id: item.sellerid,
          name: item.seller,
          component: `SellerTabs`,
          action: `wbMetricModule/${item.sellerid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.sellerid);
      }

      const moduleName = `wbMetricModule/${item.sellerid}`;
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'sid', cid: item.sellerid }));
      }
      this.setMetricAdditional(true);
    },

    onCatalogClick(item) {
      if (!this.hasTab(item.cid)) {
        this.addTab({
          id: item.cid,
          name: item.catalog,
          component: `Category`,
          action: `wbMetricModule/${item.cid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.cid);
      }

      const moduleName = `wbMetricModule/${item.cid}`;

      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'cid', cid: item.cid }));
      }

      this.setMetricAdditional(true);
    },
  },
};
</script>
