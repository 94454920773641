import { serverGate } from '../server-gate';

async function getMoment() {
  const res = await serverGate.get('/mp/momentum/get');
  return res;
}

// Отправить заявку на подключение
async function getMomentReg() {
  const res = await serverGate.get('/mp/momentum/reg');
  return res;
}

async function addMomentSum(data) {
  const res = await serverGate.post('/mp/momentum/pay', data);
  return res;
}

export { getMoment, getMomentReg, addMomentSum };
