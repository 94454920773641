<template>
  <div>
    <v-card
      min-height="412px"
      class="d-flex flex-column pa-10 font"
      :class="!isOptionsList ? 'align-center justify-center' : 'justify-left'"
    >
      <div v-if="(isOptionsList && isLoaded) || (!isOptionsList && isLoaded)">
        <div v-if="!isOptionsList" class="text-body-2 mb-6">Здесь будут токены для доступа к вашим маркетплейсам</div>

        <OptionsTableToken v-else class="mb-4" />
      </div>
      <div>
        <v-btn class="text-caption font-weight-bold mr-3" color="primary" @click="showOptionSidePanel(true)"
          >Добавить токен</v-btn
        >
        <v-btn
          v-if="isLoaded && isActiveWbKey"
          class="text-caption font-weight-bold mr-3"
          color="primary"
          @click="showOptionProductTokenSidePanel(true)"
          >Добавить продуктовый токен</v-btn
        >
        <!-- <v-btn class="text-caption font-weight-bold" color="primary" @click="getOptionList()">
          <v-icon class="mr-1">mdi-sync</v-icon>Обновить</v-btn
        > -->
      </div>
    </v-card>

    <SidePanel v-model="showOptionToken">
      <CreateToken :options="{ componentName: 'options' }" />
    </SidePanel>

    <SidePanel v-model="showOptionProductToken">
      <CreateProductToken />
    </SidePanel>
  </div>
</template>
<script>
import SidePanel from '@/components/common/SidePanel';
import CreateToken from '@/components/common/CreateToken';
import OptionsTableToken from '@/components/options/OptionsTableToken';
import CreateProductToken from '@/components/common/CreateProductToken';

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: 'Options',

  components: {
    SidePanel,
    CreateToken,
    OptionsTableToken,
    CreateProductToken,
  },
  computed: {
    ...mapGetters('options', ['isOptionsList', 'isLoaded', 'isActiveWbKey']),
    ...mapState('common', ['openOptionToken', 'openOptionProductToken']),
    showOptionToken: {
      get() {
        return this.openOptionToken;
      },
      set(value) {
        this.showOptionSidePanel(value);
      },
    },
    showOptionProductToken: {
      get() {
        return this.openOptionProductToken;
      },
      set(value) {
        this.showOptionProductTokenSidePanel(value);
      },
    },
  },
  mounted() {
    this.$store.dispatch('options/getOptionList');
  },
  methods: {
    ...mapMutations('common', ['showOptionSidePanel', 'showOptionProductTokenSidePanel']),
    ...mapActions('options', ['getOptionList']),
  },
};
</script>

<style scoped></style>
