<template>
  <v-simple-table fixed-header class="table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-caption text--secondary history__th"></th>
          <th class="text-caption text--secondary history__th">Название</th>
          <th class="text-caption text--secondary history__th">Цена</th>
          <th class="text-caption text--secondary history__th">SKU</th>
          <th class="text-caption text--secondary history__th">Количество</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in taskProducts" :key="item.name">
          <th v-if="i === 0" :rowspan="taskProducts.length" style="border-right: 1px solid rgb(125, 125, 125, 0.3)">
            Продукты
          </th>
          <td>{{ item.name }}</td>
          <td>{{ item.price ? makeRub(item.price) : '-' }}</td>
          <td>{{ item.sku ? item.sku : '-' }}</td>
          <td>{{ item.quantity ? `${item.quantity} шт` : '-' }}</td>
        </tr>
        <tr v-for="(item, i) in taskCosts" :key="item.name">
          <th v-if="i === 0" :rowspan="taskCosts.length" style="border-right: 1px solid rgb(125, 125, 125, 0.3)">
            Расходы
          </th>
          <td>{{ item.name }}</td>
          <td>{{ item.price ? makeRub(item.price) : '-' }}</td>
          <td>{{ item.sku ? item.sku : '-' }}</td>
          <td>{{ item.quantity ? `${item.quantity} шт` : '-' }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';

export default {
  name: 'AssemblyTaskExpandTable',
  mixins: [makeRub],

  props: {
    taskProducts: {
      type: Array,
    },
    taskCosts: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.history__th {
  min-width: 20vh;
}
td {
  border-bottom: 1px solid rgb(125, 125, 125, 0.3);
}
td:last-child {
  width: 50%;
}
</style>
