<template>
  <div class="px-4">
    <SearchSKU v-model="skuModal" dense icon class="search-sku" />
  </div>
</template>

<script>
import SearchSKU from '@/components/metric/wb/searchSku/SearchSKU.vue';

export default {
  name: 'SearchSKUMain',

  components: {
    SearchSKU,
  },

  data: () => ({
    skuModal: null,
  }),
};
</script>

<style lang="scss">
.search-sku.v-text-field .v-label {
  max-width: 100% !important;
}

.search-sku {
  width: 320px;
  background-color: white;

  @media (max-width: 960px) {
    width: 100%;
  }
}
</style>
