<template>
  <div class="services">
    <div class="d-flex pt-4">
      <v-tabs v-model="tab" height="44" background-color="transparent" class="services_tabs" center-active>
        <v-tabs-slider color="primary" class="tab"></v-tabs-slider>

        <v-tab class="text-none services__tabs__link">
          <div class="text-subtitle-2 font--lh18">Пакеты услуг</div>
        </v-tab>
        <v-tab v-for="(tab, i) of oneTimeList" :key="'vertical-' + i" class="text-none services__tabs__link">
          <div class="text-subtitle-2 font--lh18">{{ tab.group }}</div>
        </v-tab>

        <i class="line"></i>
      </v-tabs>
    </div>
    <v-container fluid class="px-0 services__container">
      <v-tabs-items v-model="tab" class="services_tabs">
        <v-tab-item class="tabs">
          <PackServiceTab />
        </v-tab-item>
        <v-tab-item v-for="(tab, i) of oneTimeList" :key="'vertical-' + i" class="tabs">
          <OneServiceTab :list="tab.services" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import PackServiceTab from '@/components/services/package/PackServiceTab';
import OneServiceTab from '@/components/services/onetime/OneServiceTab';
import { mapState } from 'vuex';

export default {
  name: 'ServicesMain',
  components: {
    PackServiceTab,
    OneServiceTab,
  },
  data: () => ({
    tab: 0,
  }),

  computed: {
    ...mapState('services', ['oneTimeList']),
  },
  mounted() {
    this.$store.dispatch('services/getOneTime');
  },
};
</script>

<style lang="scss">
@import '@/styles/main';
.theme--light.v-tabs-items {
  background-color: transparent;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: white !important;
}

.services,
.services__container {
  background-color: #fff;
  min-height: 400px;
}

.services__container .v-item-group {
  padding: 0;
}

.v-tabs-slider-wrapper {
  height: 3px !important;
}

.tabs .services__tabs__link {
  background-color: #ffff !important;
  color: #222733;
  // padding-left: 0;
  // padding: 0;
  // margin-right: 34px;
}

.services__tabs__link.v-tab:before {
  background-color: transparent !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #464c59 !important;
}

.services__tabs__link {
  min-width: 2px !important;
}

.v-tab--active {
  color: #4971d0 !important;
}

.line {
  width: 100%;
  height: 0;
  border-bottom: 2px solid #e6e7e9;
  float: left;
  margin: 0;
  position: absolute;
  z-index: 0;
  top: 95%;
  right: 0;
  left: 0;
}

.services_tabs.v-window {
  overflow: inherit;
}

.services_tabs > .v-slide-group .v-slide-group__content {
  flex-wrap: wrap !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
  margin: 20px 40px;
}
</style>
