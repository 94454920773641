import axios from 'axios';
import { DEV_TOKEN_COMPANY, IS_METRIC } from '@/constants.js';

const axiosConfig = {
  proxyHeaders: false,
  withCredentials: false,
  baseURL: 'https://gate1.modulbank.ru/modulbank/',
  headers: {
    // Authorization: 'Basic YWctdGVzdDo0ZlRTN0VoWk14UTM=',
    'Content-Type': 'application/json',
  },
};

export let serverGate;
export let serverGateHidden;

export const createServerGate = (tokens) => {
  try {
    if (IS_METRIC) {
      // localStorage.clear();

      let stopUtmList = ['opensku', 'extension'];

      let utmList = decodeURIComponent(location.search.substr(1)).split('&');
      utmList.forEach(function (item) {
        let utm = item.split('=');
        let utmName = utm[0];
        let utmValue = utm[1];
        if (utmName && !stopUtmList.includes(utmName.toLowerCase())) {
          axiosConfig.headers[utmName] = utmValue;
          localStorage.setItem(utmName, utmValue);
        }

        // сохраняем tnx их ссылки в куку
        // document.cookie = `${utmName} = ${utmValue}; max-age=0`;
        // Сохраняем в куки xToken на 1 час
        // document.cookie = `${utmName} = ${utmValue}; path = /; SameSite = None; Secure`;
      });
    } else if (tokens) {
      const xToken = tokens['X-TOKEN'] || tokens['x-token'];
      const company = tokens['Company'] || tokens['company'];

      if (xToken) axiosConfig.headers['X-TOKEN'] = xToken;
      if (company) axiosConfig.headers['Company'] = company;
    } else {
      // moduldev
      axiosConfig.headers['Company'] = DEV_TOKEN_COMPANY;
    }

    // FIXME DELETE
    console.log('headers:');
    console.log(axiosConfig.headers);

    serverGate = axios.create(axiosConfig);
    serverGateHidden = axios.create(axiosConfig);
  } catch (e) {
    console.log('Ошибка создания http клиента');
    console.log(e);
  }
};
