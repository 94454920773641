<template>
  <v-form ref="form" class="py-3" lazy-validation @submit.prevent="updateFilters">
    <v-row no-gutters>
      <v-col v-for="filter in filters" :key="filter.column" md="3" sm="4" class="pb-4 pr-6">
        <div class="pl-2 pb-2">
          <span class="text-caption font--graphite3 font-weight-medium">
            {{ filter.name ? filter.name : filter.text }}
          </span>
          <v-text-field
            v-if="!rangeOpen(filter)"
            v-model="filter.value"
            label="Значение"
            outlined
            :disabled="!stringFilterNeedsValue(filter)"
            :type="isNumber(filter) ? 'number' : 'text'"
            :rules="isNumber(filter) ? RULES.NUMBER.MAIN_VALUE(filter) : RULES.STRING.MAIN_VALUE(filter)"
            dense
            hide-details
          ></v-text-field>

          <v-range-slider
            v-if="rangeOpen(filter)"
            :value="[filter.value, filter.range.value]"
            :min="0"
            :max="filter.rangeLimits.max + filter.rangeLimits.max * 0.25"
            thumb-label="always"
            thumb-color="white"
            class="filter__diapason"
            :thumb-size="20"
            @input="
              (event) => {
                filter.value = event[0];
                filter.range.value = event[1];
              }
            "
          />
        </div>
      </v-col>
      <v-col cols="9" align="end">
        <v-btn color="primary" class="mr-2" elevation="0" @click.prevent="updateFilters">
          <v-icon class="mr-2" color="white">mdi-check</v-icon>
          Применить</v-btn
        >

        <v-btn color="dark" text @click.prevent="resetFilters">
          <v-icon class="mr-2">mdi-close</v-icon>

          Сбросить</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  isNumber,
  isDate,
  rangeAvailable,
  rangeOpen,
  stringFilterNeedsValue,
  RULES,
  STRING_OPERATOR_ITEMS,
  NUMBER_OPERATOR_ITEMS,
  LOGIC_OPERATOR_ITEMS,
  rawFilterFactory,
  fetchFilterFactory,
  TYPES,
} from '../_utils/filters';

export default {
  name: 'ModulFilterForm',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      required: true,
    },
    fastDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: [],
      startDateMenu: false,
      endDateMenu: false,
      RULES,
      STRING_OPERATOR_ITEMS,
      NUMBER_OPERATOR_ITEMS,
      LOGIC_OPERATOR_ITEMS,
      valueMulti: [10, 50],
    };
  },
  computed: {
    oldFilters() {
      return this.$store.getters[`${this.moduleName}/localFilters`];
    },
  },
  watch: {
    innerMultyDrop() {
      // чтобы скролл не поднимался вверх
      this.$refs.autocomplete.setMenuIndex(0);
    },

    moduleName: {
      handler() {
        if (this.oldFilters?.length === 1 && this.oldFilters[0].type === TYPES.DATE) {
          const newFilters = this.columns
            .filter((col) => col.type !== TYPES.DATE)
            .map((col) => {
              return rawFilterFactory(col);
            });
          this.filters = [...this.oldFilters, ...newFilters];
        } else if (this.oldFilters?.length) this.filters = this.oldFilters;
        else {
          console.log('no old filters');
          this.filters = this.columns.map((col) => {
            return rawFilterFactory(col);
          });
          this.$emit('update:filters', this.filters);
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    isNumber,
    isDate,
    rangeAvailable({ operator }) {
      return rangeAvailable(operator);
    },

    rangeOpen({ range, operator }) {
      return rangeOpen(range, operator);
    },
    stringFilterNeedsValue({ operator }) {
      return stringFilterNeedsValue(operator);
    },
    getFilterOptions(filter) {
      const { column } = filter;
      const options = this.$store.getters[`${this.moduleName}/getOptions`](column);
      if (column !== 'state') return options;
      // for getting task states assigned to task types
      const typeFilter = this.filters.find((f) => f.column === 'type');
      const isTypeFilterActive = typeFilter?.active;
      const typeValues = typeFilter?.value;
      const typeNot = typeFilter?.not;
      if (isTypeFilterActive && typeValues?.length > 0 && options) {
        if (typeNot) {
          return options.filter((opt) => !typeValues.includes(opt.task_type));
        }
        return options.filter((opt) => typeValues.includes(opt.task_type));
      }
      return options || undefined;
    },
    setStatesDependingOnTypes() {
      const stateFilter = this.filters.find((f) => f.column === 'state');
      const availableStates = this.getFilterOptions(stateFilter).map((opt) => opt.task_type);
      stateFilter.values = stateFilter?.values?.filter((v) => availableStates.includes(v));
    },
    getFetchFilters() {
      return this.filters.map((f) => fetchFilterFactory(f)).filter((f) => !!f);
    },
    updateFilters() {
      // if (this.$refs.form.validate()) {
      if (this.fastDate && Array.isArray(this.oldFilters)) {
        const oldDateFilter = this.oldFilters?.find((filter) => isDate(filter));
        const dateFilterIndex = this.filters?.findIndex((filter) => filter.column === oldDateFilter.column);
        if (oldDateFilter && dateFilterIndex !== -1) this.filters.splice(dateFilterIndex, 1, { ...oldDateFilter });
      }
      this.filters.map((filter) => {
        if (filter.value) {
          filter.active = true;
        }
      });
      this.$emit('update:filters', this.filters);
      // }
    },
    resetFilters() {
      this.$refs.form.reset();
      this.$emit('reset:filters');
      this.filters = this.columns.map((col) => {
        return rawFilterFactory(col);
      });
    },
    updateLocalFilters() {},
  },
};
</script>
<style scoped>
/* .select {
  &::v-deep input {
    display: none;
  }
} */

.filter__diapason >>> .v-slider__thumb-label {
  color: #717d83 !important;
  bottom: 0;
  top: 20px;
}

.filter__diapason >>> .theme--light.v-slider .v-slider__track-background {
  background-color: #cbcdd1 !important;
}

.filter__diapason >>> .theme--light.v-slider .v-slider__track-fill,
.filter__diapason >>> .theme--light.v-slider .v-slider__thumb {
  background-color: #6ca300 !important;
  z-index: 1;
}
</style>
