import { serverGate, serverGateHidden } from '../server-gate';

/**
 * главный первый запрос
 * @returns {Promise<MainResponse>}
 */
async function mainGet() {
  const res = await serverGate.get('mp/main/get');
  return res;
}
/**
 * главный первый запрос втихую
 * @returns {Promise<MainResponse>}
 */
async function mainGetHidden() {
  const res = await serverGateHidden.get('mp/main/get');
  return res;
}

async function deleteNotification(notificationid) {
  const res = await serverGate.post('mp/notification/delete', { notificationid });
  return res;
}

async function addProduct(data) {
  const res = await serverGate.post('mp/option/activate', data);
  return res;
}

export { mainGet, addProduct, deleteNotification, mainGetHidden };
