import * as api from '@/api';

const initState = () => ({
  login: '',
  newphone: '+7',
  phone: '',
  showPhone: false,
  firstname: '',
  surname: '',
  middlename: '',
  ogrn: '',
  inn: '',
  company: null,
});

export const profile = {
  namespaced: true,
  state: initState,

  mutations: {
    setLogin: (state, payload) => (state.login = payload),
    set: (state, payload) => (state[payload.type] = payload.value),
    reset: (state) => {
      const initialState = initState();
      Object.keys(initialState).forEach((key) => (state[key] = initialState[key]));
    },
  },

  actions: {
    // установка данных профиля из ответа сервера
    setProfile({ commit }, res) {
      if (res.firstName) {
        commit('set', { type: 'firstname', value: res.firstName });
      }
      if (res.lastName) {
        commit('set', { type: 'surname', value: res.lastName });
      }
      if (res.patronymic) {
        commit('set', { type: 'middlename', value: res.patronymic });
      }
      if (res.name) {
        commit('set', {
          type: 'company',
          value: { name: res.name, inn: res.inn, ogrn: res.ogrn },
        });
      }
      if (res.phone) {
        commit('set', { type: 'phone', value: res.phone });
      }
    },

    // получение данных профиля с сервера
    async get({ dispatch }) {
      const res = await api.getProfile();
      if (res) {
        dispatch('setProfile', res);
      }
    },

    // общий метод обновления
    async updateProfile({ dispatch }, data) {
      const res = await api.updateProfile(data);
      if (res) {
        dispatch('get');
      }
      return res;
    },
  },
};
