<template>
  <v-card height="100%" class="pa-4">
    <div class="d-flex justify-space-between align-start ma-0">
      <div class="text-subtitle-2 font--black1">Маркет.Сертификация</div>
      <v-img src="img/sert.png" max-height="50" min-height="48" max-width="37"></v-img>
    </div>
    <v-img src="img/QR_sert.jpeg" max-height="72" max-width="72" class="mx-auto mb-2"></v-img>

    <div class="text-subtitle-2 font-weight-regular font--graphite3 text-center font--lh20 pb-5">
      Оформление любых разрешительных документов для ваших товаров. Отказное письмо от 0 ₽
    </div>

    <v-card-actions class="pt-4">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn-multiline" @click="setCertificate(true)"
        ><span class="text-wrap"><a class="text-decoration-none">Заказать документы</a></span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'WidgetSertification',

  methods: {
    ...mapMutations('certificate', ['setCertificate']),
  },
};
</script>

<style scoped>
.btn-multiline >>> span {
  width: 100%;
  font-size: 12px;
}
</style>
