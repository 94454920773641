<template>
  <div class="pa-6 pt-4 font">
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12">
          <CompanySearch
            v-model="company"
            :disabled="disabled"
            placeholder="Наименование, ИНН или ОГРН контрагента"
            :rules="[rules.required('')]"
          />
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="inn"
            dense
            :rules="[rules.required('Введите ИНН'), rules.inn]"
            single-line
            outlined
            :disabled="disabled"
            placeholder="ИНН"
          ></v-text-field
        ></v-col>

        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="ogrn"
            dense
            :rules="[rules.required('Введите ОГРН'), rules.ogrn]"
            single-line
            outlined
            :disabled="disabled"
            placeholder="ОГРН"
          ></v-text-field
        ></v-col>

        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="kpp"
            dense
            :rules="[rules.kpp_number, rules.kpp]"
            single-line
            outlined
            :disabled="disabled"
            placeholder="КПП поставщика"
          ></v-text-field
        ></v-col>

        <v-col cols="12" class="pb-0"> <div class="text-body-2 font--lh21">Банковские реквизиты</div></v-col>
        <v-col cols="12">
          <BankSearch v-model="localBank" dense :disabled="disabledBank" :rules="[rules.required('')]" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="localAcc"
            :rules="[rules.required(''), rules.number, rules.acc, rules.acc20]"
            dense
            single-line
            :disabled="isDisabled && edit"
            label="Cчет получателя"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="pb-0"> <div class="text-body-2 font--lh21">Дополнительная информация</div></v-col>

        <v-col v-if="phoneInputEnabled" cols="6" class="pb-0">
          <v-text-field
            v-if="!noPhone"
            v-model="phone"
            v-mask="'+7(###) ###-##-##'"
            :rules="[rules.phone]"
            single-line
            dense
            :disabled="isDisabled && edit"
            outlined
            label="Телефон поставщика"
          />
          <v-text-field v-else dense outlined disabled label="Телефон поставщика" />
        </v-col>
        <v-col cols="6" class="py-0">
          <v-switch v-model="noPhone" label="Нет телефона" color="indigo" inset></v-switch>
        </v-col>

        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="site"
            single-line
            dense
            :disabled="isDisabled && edit"
            outlined
            label="Ссылка на сайт"
          />
        </v-col>

        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="product"
            :disabled="isDisabled && edit"
            single-line
            dense
            outlined
            label="Наименование товара"
          />
        </v-col>
      </v-row>

      <div style="float: right">
        <v-btn class="mt-6 mr-4" outlined @click="close">Отмена</v-btn>
        <v-btn v-if="!edit" class="mt-6" color="primary" :disabled="!valid" @click="addCreateSupplier">Добавить</v-btn>
        <v-btn v-else class="mr-4 mt-6" color="primary" @click="isDisabledForm">Редактировать</v-btn>
        <v-btn v-if="edit" class="mt-6" color="primary" :disabled="!valid" @click="editSupplier">Сохранить</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { validateInn, validateAcc, validateOgrn } from '@/utils/helpers';
import BankSearch from '@/components/common/credit/BankSearch';
import { mapActions, mapMutations, mapState } from 'vuex';
import CompanySearch from '@/components/common/credit/CompanySearch.vue';
import * as api from '@/api';

export default {
  name: 'CreditCreateSupplier',
  components: { BankSearch, CompanySearch },
  directives: { mask },

  props: {
    closePanel: {
      type: Boolean,
    },
  },
  data() {
    return {
      isDisabled: true,
      isEdit: false,
      noPhone: false,
      name: '',
      inn: '',
      kpp: '',
      localBank: null,
      localAcc: '',
      ogrn: '',
      phone: '',
      site: '',
      product: '',
      bic: '',
      bank: '',
      edit: false,
      rules: {
        required: (msg) => (v) => !!v || msg,
        inn: (v) => (!!v && !!validateInn(v)) || 'Введите корректный ИНН',
        ogrn: (v) => (!!v && !!validateOgrn(v)) || 'Введите корректный ОГРН',
        kpp: (v) => !v || v.replace(/\D/g, '').length === 9 || 'КПП должен содержать 9 цифр',
        kpp_number: (v) => !v || /^\d+$/.test(v.replace(/\s/g, '')) || 'Только цифры',
        number: (v) => /^\d+$/.test(v.replace(/\s/g, '')) || 'Только цифры',
        phone: (v) => (!!v && v.replace(/\D/g, '').length === 11) || 'Введите номер телефона',
        acc20: (v) =>
          (typeof v === 'string' && v.replace(/\D/g, '').length === 20) || 'Счёт получателя должен содержать 20 цифр',
        /* eslint-disable */
        acc: (v) =>
          !this.localBank
            ? 'Сначала введите БИК'
            : (typeof v === 'string' && validateAcc(v.replace(/\D/g, ''), this.bic)) ||
              'Неверная контрольная цифра. Внимательно проверьте БИК и номер счёта',
      },
      /* eslint-enable */
      valid: false,
      company: null,

      // Хак для применения маски
      phoneInputEnabled: true,
    };
  },

  computed: {
    ...mapState('credit', ['supplier', 'sid']),
    disabled() {
      return this.edit && !this.isEdit;
    },
    disabledBank() {
      return this.edit && this.isDisabled;
    },
  },
  watch: {
    closePanel(v) {
      if (!v) {
        this.close();
      }
    },

    supplier(e) {
      if (e) {
        this.edit = true;
        this.inn = e.inn;
        this.ogrn = e.ogrn;
        this.kpp = e.kpp;
        this.product = e.product;
        this.site = e.site;
        this.localAcc = e.acc;
        this.name = e.name;
        this.phone = e.tel;
        this.bic = e.bic;
        this.bank = e.bank;
        if (!e.tel) {
          this.noPhone = true;
        }

        this.applyPhoneInputMask();

        this.company = { name: e.name, ogrn: e.ogrn, kpp: e.kpp, inn: e.inn };
        this.localBank = { bank: e.bank, bic: e.bic };
        this.$emit('title', 'Редактирование данных');
      }
    },
    company(company) {
      if (company) {
        this.inn = company.inn;
        this.ogrn = company.ogrn;
        this.name = company.name;
        this.kpp = company.kpp;
      } else {
        this.inn = '';
        this.ogrn = '';
        this.name = '';
        this.kpp = '';
      }
    },
    localBank(bank) {
      if (bank) {
        this.bic = bank.bic;
        this.bank = bank.bank;
      } else {
        this.bic = '';
        this.bank = '';
      }
    },
  },

  methods: {
    ...mapMutations('credit', ['showCreditSidePanelSupplier']),
    ...mapActions('credit', ['updateSupplier']),
    isDisabledForm() {
      this.isDisabled = false;
      this.isEdit = false;
      this.$refs.form.validate();
    },

    async editSupplier() {
      const data = {
        site: this.site,
        product: this.product,
        bic: this.bic,
        bank: this.bank,
        acc: this.localAcc,
        sid: this.sid,
      };

      if (!this.noPhone) {
        data.tel = this.phone.replace(/\D/g, '');
      }
      const res = await this.updateSupplier(data);

      if (res) {
        this.close();
      }
    },

    async addCreateSupplier() {
      const data = {
        inn: this.inn,
        ogrn: this.ogrn,
        site: this.site,
        product: this.product,
        bic: this.bic,
        bank: this.bank,
        name: this.name,
        acc: this.localAcc,
        kpp: this.kpp,
      };

      if (!this.noPhone) {
        data.tel = this.phone.replace(/\D/g, '');
      }

      const res = await api.addCredit(data);
      if (res) {
        this.$store.commit('credit/setSuppliersList', res.suppliers);

        this.close();
      }
    },

    resetFields() {
      this.company = null;
      this.localBank = null;
      this.inn = '';
      this.kpp = '';
      this.ogrn = '';
      this.bic = '';
      this.bank = '';
      this.phone = '';
      this.site = '';
      this.product = '';
      this.name = '';
      this.localAcc = '';
      this.noPhone = false;
      this.edit = false;
      this.isDisabled = true;
      this.isEdit = false;
      this.$emit('title', '');
    },

    close() {
      this.showCreditSidePanelSupplier(false);

      setTimeout(() => {
        this.resetFields();
        this.$refs.form.resetValidation();
      }, 200);
    },

    applyPhoneInputMask() {
      this.phoneInputEnabled = false;
      this.$nextTick(() => (this.phoneInputEnabled = true));
    },
  },
};
</script>

<style scoped></style>
