<template>
  <vue-horizontal scroll :button="false" class="horizontal__scroll">
    <div class="table__container">
      <v-simple-table fixed-header class="table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-caption text--secondary history__th">Номер продажи</th>
              <th class="text-caption text--secondary history__th pr-8">Дата продажи</th>
              <th class="text-caption text--secondary history__th">Статус</th>
              <th class="text-caption text--secondary history__th pr-16">Товар</th>
              <th class="text-caption text--secondary history__th">Артикул поставщика</th>
              <th class="text-caption text--secondary history__th">Количество</th>
              <th class="text-caption text--secondary history__th pr-12">Цена</th>
              <th class="text-caption text--secondary history__th pr-10">Цена со скидкой</th>
              <th class="text-caption text--secondary history__th">Скидка</th>
              <th class="text-caption text--secondary history__th">Промокод</th>
              <th class="text-caption text--secondary history__th">СПП</th>
              <th class="text-caption text--secondary history__th">Страна</th>
              <th class="text-caption text--secondary history__th">Макрорегион</th>
              <th class="text-caption text--secondary history__th">Регион</th>
              <th class="text-caption text--secondary history__th">Склад</th>
              <th class="text-caption text--secondary history__th">Тип товара</th>
              <th class="text-caption text--secondary history__th">Категория</th>
              <th class="text-caption text--secondary history__th">Размер</th>
              <th class="text-caption text--secondary history__th">Штрихкод</th>
              <th class="text-caption text--secondary history__th">Артикул МП</th>
            </tr>
          </thead>
          <tbody v-for="item in tapeList" :key="item.id">
            <tr>
              <td>{{ item.gnumber }}</td>
              <td>{{ item.dateSale === null ? '-' : formatDate(item.dateSale) }}</td>
              <td>{{ item.tp }}</td>
              <td class="py-2">{{ item.name }}</td>
              <td>{{ item.article === null ? '-' : item.article }}</td>
              <td>{{ item.quantity === (null || undefined) ? '-' : `${item.quantity} шт` }}</td>
              <td>{{ makeRub(item.totalPrice, 2) }}</td>
              <td>{{ makeRub(item.finishedPrice, 2) }}</td>
              <td>{{ item.discPr === (null || undefined) ? '-' : `${item.discPr.toFixed(2)} %` }}</td>
              <td>{{ item.promocode === (null || undefined) ? '-' : `${item.promocode} %` }}</td>
              <td>{{ item.spp === null ? '-' : `${item.spp.toFixed(2)} %` }}</td>
              <td>{{ item.country === null ? '-' : item.country }}</td>
              <td>{{ item.okrug === null ? '-' : item.okrug }}</td>
              <td>{{ item.region === null ? '-' : item.region }}</td>
              <td>{{ item.warehouse === null ? '-' : item.warehouse }}</td>
              <td>{{ item.subject === null ? '-' : item.subject }}</td>
              <td>{{ item.category === null ? '-' : item.category }}</td>
              <td>{{ item.spp === (null || undefined) ? '-' : `${item.spp} см` }}</td>
              <td>{{ item.barcode === null ? '-' : item.barcode }}</td>
              <td>{{ item.codeWB === null ? '-' : item.codeWB }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </vue-horizontal>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { format } from 'date-fns';
import VueHorizontal from 'vue-horizontal';

export default {
  name: 'TapeExpandTable',
  components: { VueHorizontal },
  mixins: [makeRub],
  props: {
    tapeList: {
      type: Array,
    },
  },
  methods: {
    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },
  },
};
</script>

<style scoped>
/* .history__th {
  height: 30px;
} */
td {
  border-bottom: 1px solid rgb(125, 125, 125, 0.3);
}
</style>
