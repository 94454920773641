<template>
  <OneTimeTable v-if="showServiceTable" :items="list" />
</template>

<script>
import OneTimeTable from '@/components/services/onetime/OneTimeTable';
import { mapGetters } from 'vuex';

export default {
  name: 'OneServiceTab',
  components: { OneTimeTable },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('services', ['showServiceTable']),
  },
};
</script>
