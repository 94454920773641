import { HubConnectionFactory } from '@ssv/signalr-client';
import { IS_PROD, DEV_TOKEN_COMPANY, HUB_URL } from '@/constants.js';

let companyToken = IS_PROD ? localStorage.getItem('Company') : DEV_TOKEN_COMPANY;

const hubFactory = new HubConnectionFactory();
hubFactory.create({
  key: 'whitehub',
  endpointUri: HUB_URL + '/control?login=' + companyToken,
  options: { headers: { Company: companyToken } },
});

const hubConnection = hubFactory.get('whitehub');

hubConnection.connect().subscribe(() => {
  console.log(`connected!`);
});

export { hubConnection };
