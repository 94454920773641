<template>
  <div>
    <div v-if="dataArray.length" class="align-center text-center mx-auto">
      <v-switch class="switch" :label="viewLabel" @click="toggleView"></v-switch>
    </div>
    <IncomeSalesGraph v-if="dataArray.length && isRubView" :graph-income="graphIncome" :graph-sales="graphSales" />
    <IncomeSalesGraphPercent
      v-if="dataArray.length && !isRubView"
      :graph-income="graphIncome"
      :graph-sales="graphSales"
    />
    <SubTable
      :data-array="dataArray"
      :type-id="typeId"
      :name-component="nameComponent"
      :module-name="moduleName"
      :action="action"
      @cat:change="$emit('cat:change', $event)"
    />
  </div>
</template>

<script>
import IncomeSalesGraphPercent from '@/components/common/metric/IncomeSalesGraphPercent.vue';
import IncomeSalesGraph from '@/components/common/metric/IncomeSalesGraph.vue';
import SubTable from '@/components/metric/wb/subTabs/SubTable.vue';

export default {
  name: 'SubCountry',

  inject: ['filterMutations'],
  components: {
    SubTable,
    IncomeSalesGraph,
    IncomeSalesGraphPercent,
  },

  props: {
    id: {
      required: true,
      type: String,
    },
    moduleName: {
      required: true,
      type: String,
    },
    action: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      isRubView: true,
    };
  },

  computed: {
    viewLabel() {
      return this.isRubView ? 'В руб' : 'В процентах';
    },
    dataArray() {
      return this.$store.getters[`${this.moduleName}/dataArray`];
    },
    typeId() {
      return `ctid`;
    },
    nameComponent() {
      return `Country`;
    },
    graphIncome() {
      return this.$store.getters[`${this.moduleName}/graphIncome`];
    },
    graphSales() {
      return this.$store.getters[`${this.moduleName}/graphSales`];
    },
  },

  created() {
    console.log('++++++++++СОЗДАНА Страна created');
    this.getBrandList();
  },

  methods: {
    getBrandList() {
      this.$store.dispatch(`${this.action}`);
    },

    toggleView() {
      this.isRubView = !this.isRubView;
    },
  },
};
</script>

<style lang="scss">
.switch .v-input__slot {
  display: block;
}

.switch.v-input {
  text-align: center;
  display: block;
}
</style>
