<template>
  <div class="font">
    <div class="d-flex flex-wrap justify-space-between align-center">
      <div class="font-weight-medium mr-2 mb-4">Ваши поставщики</div>
      <v-btn
        outlined
        class="text-caption font-weight-bold mb-4"
        style="border: 2px solid"
        color="primary"
        @click="showCreditSidePanelSupplier(true)"
        >Добавить поставщика</v-btn
      >
    </div>

    <CardList :list="suppliers" :supplier="true" />
  </div>
</template>

<script>
import CardList from '@/components/common/CardList';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'CreditSuppliersList',

  components: { CardList },

  data() {
    return {};
  },
  computed: {
    ...mapState('credit', ['suppliers']),
  },
  methods: {
    ...mapMutations('credit', ['showCreditSidePanelSupplier']),
  },
};
</script>

<style scoped></style>
