import * as api from '@/api';
import { INIT_SALES_TYPE } from '@/constants.js';
import { createResetMutation } from '@/store/helpers/reset';
import { downloadFile } from '@/store/helpers/download-file';
import { today, thirtyDaysBefore } from '@/mixins/filterDate';
import Vue from 'vue';

const initState = () => ({
  product: {},
  // SKU
  sdate: thirtyDaysBefore,
  edate: today,
  pid: null,
  tid: null,
  salesType: INIT_SALES_TYPE,
  keywords: '',
  seoTab: 0,
  keywordsResult: {},
  showSlider: false,
});

export const wbProductCard = {
  namespaced: true,
  state: initState,

  getters: {
    sdate: (state) => state.sdate,
    edate: (state) => state.edate,
    pid: (state) => state.pid,
    tid: (state) => state.tid,
    salesType: (state) => state.salesType,
  },

  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setProduct: (state, value) => (state.product = value),
    setKeywords: (state, value) => (state.keywords = value),
    setKeywordResults: (state, value) => (state.keywordsResult = value),
    setSlider: (state, value) => (state.showSlider = value),
    setSeoTab: (state, value) => (state.seoTab = value),
    setStartDate: (state, sdate) => (state.sdate = sdate),
    setEndDate: (state, edate) => (state.edate = edate),
    setPid: (state, pid) => (state.pid = pid),
    setTid: (state, tid) => (state.tid = tid),
    setSalesType: (state, salesType) => (state.salesType = salesType),
    setFileLoading: (state, payload) => Vue.set(state, 'fileLoading', payload),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },

  actions: {
    isCardEditing({ commit }) {
      commit('setProduct', {});
      commit('setPid', null);
      commit('setStartDate', null);
      commit('setEndDate', null);
      commit('setSalesType', INIT_SALES_TYPE);
    },

    async getCard({ commit, getters }) {
      let data = {
        pid: getters.pid,
        sdate: getters.sdate,
        edate: getters.edate,
        salesType: getters.salesType,
        marketplace: 'wb',
      };
      const res = await api.getProduct(data);
      if (res) {
        commit('setProduct', res);
      }
    },

    async checkKeywords({ commit }, data) {
      const res = await api.checkKeywords(data);

      if (res) {
        commit('setKeywordResults', res);
        commit('setTid', res.testedTags.id);
      }
    },

    async getExportListCategories({ commit, getters }) {
      commit('setFileLoading', true);

      let data = {
        pid: getters.pid,
        marketplace: 'wb',
      };

      const res = await api.getExportCategories(data);

      if (res) {
        const fileid = res.fileid;
        // выгрузка файла
        const fileResponse = await api.fileGetHidden(fileid);

        if (fileResponse) {
          fileResponse.text = 'categories_wb_';
          downloadFile(fileResponse);
        }
      }

      // Сброс состояния загрузки файла
      commit('setFileLoading', false);
    },

    async getExportListTags({ commit, getters }) {
      commit('setFileLoading', true);

      let data = {
        pid: getters.pid,
        marketplace: 'wb',
      };

      const res = await api.getExportTags(data);

      if (res) {
        const fileid = res.fileid; // Получение идентификатора файла
        // выгрузка файла
        const fileResponse = await api.fileGetHidden(fileid);

        if (fileResponse) {
          fileResponse.text = 'tags_wb_';
          downloadFile(fileResponse);
        }
      }

      // Сброс состояния загрузки файла
      commit('setFileLoading', false);
    },

    async getExportListSEOTags({ commit, getters }, type) {
      commit('setFileLoading', true);

      let data = {
        type,
        tid: getters.tid,
        marketplace: 'wb',
      };

      const res = await api.getExportSEO(data);

      if (res) {
        const fileid = res.fileid;
        // выгрузка файла
        const fileResponse = await api.fileGetHidden(fileid);

        if (fileResponse) {
          fileResponse.text = 'tags_wb_';
          downloadFile(fileResponse);
        }
      }

      // Сброс состояния загрузки файла
      commit('setFileLoading', false);
    },
  },
};
