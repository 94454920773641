import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  credit: null,
  suppliers: [],
  openSupplier: false,
  openPayment: false,

  smsid: null,
  supplier: null,
  sid: null,
});

export const credit = {
  namespaced: true,
  state: initState,
  getters: {
    limit: (state) => state.credit?.limit,
    suppliersList: (state) => state.suppliers.filter((v) => v.state === 'success').filter((v) => v.req === 0),
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setSuppliersList: (state, payload) => (state.suppliers = payload),
    showCreditSidePanelSupplier: (state, payload) => (state.openSupplier = payload),
    setCredit: (state, payload) => (state.credit = payload),
    showCreditSidePanelPayment: (state, payload) => (state.openPayment = payload),
    setSmsId: (state, payload) => (state.smsid = payload),
    setManagers: (state, value) => (state.supplier = value),
    setSid: (state, value) => (state.sid = value),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async getCredit({ commit }) {
      const res = await api.getCredit();
      if (res) {
        commit('setSuppliersList', res.suppliers);
        commit('setCredit', res);
      }
    },

    async creditPaySupplier({ commit }, data) {
      const res = await api.creditPay(data);
      if (res) {
        commit('setSmsId', res.smsid);
      }
      return res;
    },

    async repeatSmsCode({ commit }, data) {
      const res = await api.repeatSmsCode(data);
      if (res) {
        commit('setSmsId', res.smsid);
      }
      return res;
    },

    async editSupplier({ commit }, id) {
      const res = await api.editSupplier({ sid: id });
      if (res) {
        commit('setManagers', res);

        commit('showCreditSidePanelSupplier', true);
      }
    },

    async updateSupplier({ dispatch }, data) {
      const res = await api.updateSupplier(data);
      if (res) {
        dispatch('getCredit');
      }
      return res;
    },
  },
};
