<template>
  <ProductTable />
</template>

<script>
import ProductTable from '@/components/analitic/ozon/products/ProductTable';

export default {
  name: 'ProductTab',
  components: { ProductTable },
};
</script>
