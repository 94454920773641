<template>
  <div class="pa-6 font">
    <div class="text-h6 font--black1 mb-6">Оплата поставщику</div>
    <v-form ref="form" v-model="valid">
      <v-row>
        <div class="text-body-2 font--lh21 ml-4 mb-3">Выберите поставщика</div>

        <v-col cols="12" class="py-0">
          <v-select
            v-model="supplier"
            label="Поставщик"
            :items="suppliersList"
            no-data-text="Подходящих поставщиков нет"
            :item-text="supplierSelected"
            item-value="id"
            dense
            :rules="[rules.required('Выберите поставщика')]"
            outlined
            :disabled="disabledSms"
          >
          </v-select>
        </v-col>

        <v-col cols="12" class="pt-0">
          <div class="text-body-2 font--lh21">Доступно для перевода</div>
          <div class="font-weight-medium pt-2">{{ makeRub(limit || 0, 0) }}</div></v-col
        >

        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="sum"
            dense
            type="number"
            :rules="[rules.required('Введите сумму'), rules.maxLimit]"
            single-line
            class="inputPrice"
            outlined
            placeholder="Сумма"
            :disabled="disabledSms"
          ></v-text-field
        ></v-col>

        <v-col cols="6" class="py-0">
          <v-select
            v-model="nds"
            :menu-props="{ bottom: true, offsetY: true }"
            single-line
            dense
            :items="ndsItems"
            label="Система налогообложения"
            outlined
            :disabled="disabledSms"
          ></v-select>
        </v-col>

        <v-col cols="6" class="py-0">
          <div class="text-body-2 font--lh21">Назначение платежа</div>
        </v-col>
        <v-col v-if="sum" cols="6" class="py-0">
          <div class="text-body-2 font--lh21">{{ ndsSum }}</div>
        </v-col>

        <v-col cols="12" class="pb-0">
          <v-textarea
            v-model="purpose"
            rows="3"
            placeholder="Назначение платежа"
            :rules="[rules.purposeMin, rules.purposeMax]"
            outlined
            :disabled="disabledSms"
          ></v-textarea>
        </v-col>

        <v-col v-if="smsid" offset="4" cols="4" class="py-0">
          <v-text-field
            v-model="code"
            v-mask="'#####'"
            outlined
            autofocus
            dense
            label="Код из смс"
            type="text"
            :rules="[rules.required('Введите 5 цифр'), rules.code]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="py-0">
          <div v-if="showRepeatSms" class="d-flex text-center">
            <v-card-text class="font--paragraph--xsmall">
              Время ввода кода истекло.
              <a href="#" @click="refreshCode">Выслать код повторно</a>
            </v-card-text>
          </div>
          <v-card-text v-if="timeSec > 0 && !showRepeatSms" class="text-center">
            <span> Выслать код повторно через {{ timeSec }}</span></v-card-text
          >
        </v-col>
      </v-row>
      <div style="float: right">
        <v-btn class="mt-6 mr-4" outlined @click="close">Отмена</v-btn>
        <v-btn v-if="!smsid" class="mt-6" color="primary" :disabled="!valid" @click="creditPay">Оплатить</v-btn>
        <v-btn v-else class="mt-6" color="primary" :disabled="!valid" @click="smsCode">Подтвердить</v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import * as api from '@/api';
import { mask } from 'vue-the-mask';

export default {
  name: 'CreditCreatePaymentSupplier',

  directives: { mask },
  mixins: [makeRub],

  props: {
    closePanel: {
      type: Boolean,
    },
  },
  data() {
    return {
      timeSec: 0,
      timer: null,
      showRepeatSms: false,
      code: '',
      isDisabled: false,
      purpose: '',
      nds: '0',
      ndsItems: [
        { text: 'Без НДС', value: '0' },
        { text: '18% включен в сумму', value: '18' },
        { text: '20% включен в сумму', value: '20' },
        { text: '10% включен в сумму', value: '10' },
      ],
      rules: {
        required: (msg) => (v) => !!v || msg,
        purposeMin: (v) => v.length >= 5 || 'Введите минимум 5 символов',
        code: (v) => v.length >= 5 || '5 цифр',
        purposeMax: (v) => v.length <= 210 || 'Максимум 210 символов',
        maxLimit: (v) => v <= this.limit || 'Сумма недоступна к переводу',
      },
      valid: false,
      sum: '',
      supplier: null,
    };
  },

  destroyed() {
    this.resetFields();
  },

  computed: {
    ...mapGetters('credit', ['suppliersList', 'limit']),
    ...mapState('credit', ['smsid']),
    ndsSum() {
      const sum = (Number(this.sum) * Number(this.nds)) / (100 + Number(this.nds));

      return `В т.ч. НДС ${this.nds}% - ${sum.toFixed(2)}`;
    },
    disabledSms() {
      if (this.smsid) {
        this.isDisabled = true; // eslint-disable-line
      }
      return this.isDisabled;
    },
  },

  watch: {
    closePanel(v) {
      if (!v) {
        this.close();
      }
    },
  },

  methods: {
    ...mapMutations('credit', ['showCreditSidePanelPayment', 'setSmsId']),
    ...mapActions('credit', ['creditPaySupplier', 'repeatSmsCode']),

    supplierSelected(sup) {
      return sup.inn + ' ' + sup.name;
    },

    async creditPay() {
      const res = await this.creditPaySupplier({
        sm: this.sum,
        purpose: this.purpose,
        sid: this.supplier,
        nds: this.nds,
      });
      if (res) {
        this.startTimer();
      }
    },

    async smsCode() {
      const res = api.smsCode({
        smsid: this.smsid,
        accept: this.code,
      });
      if (res) {
        this.close();
        this.$store.dispatch('common/addAlert', { msg: 'Платеж проведен успешно', type: 'success' });
      }
    },

    close() {
      this.showCreditSidePanelPayment(false);

      setTimeout(() => {
        this.resetFields();
        this.$refs.form.resetValidation();
      }, 200);
    },

    resetFields() {
      this.sum = null;
      this.purpose = '';
      this.nds = '0';
      this.sum = '';
      this.supplier = null;
      this.code = '';
      this.isDisabled = false;
      this.setSmsId(null);
      this.timeSec = 0;
      this.showRepeatSms = false;
      clearTimeout(this.timer);
    },

    startTimer() {
      this.timeSec = 60;
      this.showRepeatSms = false;
      this.timer = setInterval(() => {
        this.timeSec--;
        if (this.timeSec < 1) {
          this.showRepeatSms = true;
          clearTimeout(this.timer);
        }
      }, 1000);
    },

    async refreshCode() {
      clearTimeout(this.timer);
      const res = await this.repeatSmsCode({
        smsid: this.smsid,
      });
      if (res) {
        this.startTimer();
        this.code = '';
      }
    },
  },
};
</script>

<style scoped>
.inputPrice >>> input[type='number'] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
