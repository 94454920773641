<template>
  <div class="d-flex flex-wrap">
    <v-col v-for="item in filterObjectsByName" :key="item.id" lg="2" md="3" sm="4" xs="6">
      <v-card class="pa-3 d-flex flex-wrap text-body-2 font--lh16" @click="onBrandClick(item)">
        <div>{{ item.name }}</div>
        <div>
          <v-img v-if="item.logo" max-height="50" max-width="50" :src="`http:${item.logo}`" class="ml-3"></v-img>
        </div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { createCategoryModule } from '@/store/modules/metric/wb/features/category';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BrandTop',

  computed: {
    ...mapGetters('wbMainTabs', ['hasTab']),
    ...mapGetters('wbBrand', ['brands']),

    filterObjectsByName() {
      return this.brands ? this.brands.filter((obj) => obj.name !== undefined && obj.name.trim() !== '') : [];
    },
  },

  created() {
    this.getTopBrand();
  },

  methods: {
    ...mapActions('wbMainTabs', ['addTab']),
    ...mapActions('wbBrand', ['getTopBrand']),

    onBrandClick(item) {
      if (!this.hasTab(item.id)) {
        this.addTab({
          id: item.id,
          name: item.name,
          component: `BrandTabs`,
          action: `wbCategory/${item.id}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.id);
      }
      const moduleName = `wbCategory/${item.id}`;
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'bid', cid: item.id }));
      }
    },
  },
};
</script>

<style lang="scss">
.brand-card {
  box-shadow: 0px 6px 16px 0px rgba(34, 39, 51, 0.12) !important;
}
</style>
