import { makeCurrency } from '@/utils/helpers';
export const makeRub = {
  methods: {
    /**
     * @param {number | string | null} value
     */
    makeRub(value, digits = 0) {
      const rubs = typeof value === 'number' ? value : Number(value);
      return makeCurrency(
        rubs.toLocaleString('ru-RU', {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        })
      );
    },
  },
};
