<template>
  <span class="d-flex align-center">
    <v-btn text min-width="44" class="pa-0" :disabled="counter <= 0" @click="countDecreased()">
      <svg width="44" height="36" style="cursor: pointer">
        <use xlink:href="#button_small_grey_minus"></use>
      </svg>
    </v-btn>
    <v-text-field
      v-model="counter"
      type="number"
      :hide-spin-buttons="true"
      dense
      outlined
      class="text-subtitle-2 rounded-0 center-input inputPrice"
      style="margin-top: 26px"
    />
    <v-btn text min-width="44" class="pa-0" :disabled="counter >= max" @click="countIncreased()">
      <svg width="44" height="36" style="cursor: pointer">
        <use xlink:href="#button_small_grey_plus"></use>
      </svg>
    </v-btn>
  </span>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Counter',
  mixins: [makeRub],
  props: {
    max: {
      type: String,
    },
  },

  data: () => ({
    counter: 0,
    debounce: null,
  }),

  computed: {
    ...mapGetters('services', ['clear']),
  },

  watch: {
    // для принудительного изменения значения в инпуте
    counter(value) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (value > Number(this.max)) {
          this.counter = Number(this.max);
        }
        this.sendCounter(value);
        this.resetTable(false);
      }, 10);
    },

    // сброс инпута после отправки данных
    clear: {
      handler() {
        this.counter = 0;
      },
    },
  },

  methods: {
    ...mapMutations('services', ['resetTable']),
    countDecreased() {
      this.counter--;
      this.sendCounter(this.counter);
    },
    countIncreased() {
      this.counter++;
      this.sendCounter(this.counter);
    },
    sendCounter(value) {
      return this.$emit('counter', value.toString());
    },
  },
};
</script>

<style scoped>
.center-input >>> input {
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  padding: 0 !important;
}

.v-input {
  max-width: 30% !important;
}

.center-input >>> .v-input__slot::before {
  border-style: none !important;
}
.inputPrice >>> input[type='number'] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.v-icon.v-icon::after {
  background-color: white !important;
}

.v-icon.v-icon:hover {
  color: #4971d0ed !important;
}

.center-input >>> .v-input__slot {
  margin-bottom: 2px;
  min-height: 36px !important;
}

.center-input.v-text-field--outlined >>> fieldset {
  border-color: #cbcdd1;
  border-left: none;
  border-right: none;
}
</style>
