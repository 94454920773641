<template>
  <v-card height="100%" class="pa-4">
    <div class="d-flex justify-space-between align-start ma-0">
      <div class="text-subtitle-2 font--black1">Поставщики</div>
      <v-img src="img/shippers.png" min-height="47" max-height="50" max-width="37"></v-img>
    </div>
    <v-img src="img/QR_china.jpeg" max-height="72" max-width="72" class="mx-auto mb-2"></v-img>

    <div class="text-subtitle-2 font-weight-regular font--graphite3 text-center font--lh20 pb-5">
      Поможем найти поставщика товара в России и в Китае
    </div>
    <v-card-actions class="pt-4">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn-multiline"
        ><span class="text-wrap"
          ><a class="text-decoration-none" target="_blank" :href="'https://t.me/Modul_China'">Найти поставщика</a></span
        ></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'WidgetShippers',
};
</script>

<style scoped>
.btn-multiline >>> span {
  width: 100%;
  font-size: 12px;
}
</style>
