import { serverWhite } from '../server-white';

/**
 * Каталог
 * @returns {Promise<CatalogResponse>}
 */
async function catalogList(data) {
  const res = await serverWhite.post('/admin/mp/catalog/list', data);
  return res;
}

async function externalLink() {
  const res = await serverWhite.post('/admin/users/account/external', { link: 'true' });
  return res;
}

export { catalogList, externalLink };
