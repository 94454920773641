<template>
  <vue-horizontal scroll :button="false" class="horizontal__scroll">
    <div class="table__container">
      <v-simple-table fixed-header class="table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-caption text--secondary history__th">Идентификатор заказа</th>
              <th class="text-caption text--secondary history__th">Дата транзакции</th>
              <th class="text-caption text--secondary history__th">Продажа</th>
              <th class="text-caption text--secondary history__th pr-16">Товар</th>
              <th class="text-caption text--secondary history__th">Артикул</th>
              <th class="text-caption text--secondary history__th">Количество</th>
              <th class="text-caption text--secondary history__th pr-12">Цена</th>
              <th class="text-caption text--secondary history__th pr-10">Цена без скидки</th>
              <th class="text-caption text--secondary history__th">Скидка</th>
              <th class="text-caption text--secondary history__th pr-10">Выручка</th>
              <th class="text-caption text--secondary history__th">Комиссия МП (Р)</th>
              <th class="text-caption text--secondary history__th">Комиссия МП (%)</th>
              <th class="text-caption text--secondary history__th">Склад</th>
              <th class="text-caption text--secondary history__th">Тип товара</th>
              <th class="text-caption text--secondary history__th">Размер</th>
              <th class="text-caption text--secondary history__th">SKU</th>
              <th class="text-caption text--secondary history__th">Штрихкод</th>
              <th class="text-caption text--secondary history__th">Дата заказа</th>
              <th class="text-caption text--secondary history__th">Дата продажи</th>
              <th class="text-caption text--secondary history__th">Тип продажи</th>
            </tr>
          </thead>
          <tbody v-for="item in weeklyList" :key="item.id">
            <tr>
              <td>{{ item.srid }}</td>
              <td>{{ item.dtR === null ? '-' : formatDate(item.dtR) }}</td>
              <td>{{ item.oper_type }}</td>
              <td class="py-2">{{ item.name }}</td>
              <td>{{ item.article === null ? '-' : item.article }}</td>
              <td>{{ item.quantity === (null || undefined) ? '-' : `${item.quantity} шт` }}</td>
              <td>{{ makeRub(item.price, 2) }}</td>
              <td>{{ makeRub(item.retail_price, 2) }}</td>
              <td>{{ item.sale_percent === (null || undefined) ? '-' : `${item.sale_percent.toFixed(2)} %` }}</td>
              <td>{{ makeRub(item.for_pay, 2) }}</td>
              <td>{{ makeRub(item.commisMP, 2) }}</td>
              <td>
                {{ item.commission_percent === (null || undefined) ? '-' : `${item.commission_percent.toFixed(2)} %` }}
              </td>
              <td>{{ item.office_name === null ? '-' : item.office_name }}</td>
              <td>{{ item.subject === null ? '-' : item.subject }}</td>
              <td>{{ item.size === (null || undefined) ? '-' : `${item.size} кг` }}</td>
              <td>{{ item.codeWb === null ? '-' : item.codeWb }}</td>
              <td>{{ item.barcode === null ? '-' : item.barcode }}</td>
              <td>{{ item.dtO === null ? '-' : formatDate(item.dtO) }}</td>
              <td>{{ item.dtS === null ? '-' : formatDate(item.dtS) }}</td>
              <td>{{ item.type_sales === null ? '-' : item.type_sales }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </vue-horizontal>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { format } from 'date-fns';
import VueHorizontal from 'vue-horizontal';

export default {
  name: 'WeeklyExpandTable',
  components: { VueHorizontal },
  mixins: [makeRub],
  props: {
    weeklyList: {
      type: Array,
    },
  },
  methods: {
    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },
  },
};
</script>

<style scoped>
td {
  border-bottom: 1px solid rgb(125, 125, 125, 0.3);
}
</style>
