<template>
  <div>
    <v-form v-model="valid" @submit.prevent="init">
      <v-text-field
        v-model="phone"
        v-mask="'+7(9##) ###-##-##'"
        background-color="#ffffff"
        :rules="phoneRules"
        outlined
        autofocus
        label="Номер телефона"
        name="phone"
        type="text"
      ></v-text-field>
      <v-checkbox v-model="checkbox" :rules="checkboxRules" class="mt-0">
        <template v-slot:label>
          <div class="font--p2 font--black1">
            Я соглашаюсь с
            <a class="text-link" href="#" @click.stop.prevent="showModal = true">условиями</a>
            обработки и использования моих персональных данных
          </div>
        </template>
      </v-checkbox>
      <v-btn :disabled="!valid" x-large block color="primary" type="submit" class="mb-8">Зарегистрироваться</v-btn>
    </v-form>

    <v-dialog v-model="showModal" scrollable max-width="800">
      <v-card light style="padding: 12px">
        <v-card-title flat>
          <v-toolbar-title>Соглашение</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          >Я даю согласие ООО «МодульПартнёр» ОГРН 1217700202015, ИНН 9715400317, адрес местонахождения 127015, город
          Москва, улица Новодмитровская, дом 2, корпус 1, пом. XXXV (далее — МодульПартнёр): на проведение проверки, в
          том числе с использованием услуг других операторов, достоверности предоставленных мной сведений, получение
          информации о неисполнении и/или ненадлежащим исполнении договорных обязательств; на обработку, как с
          использованием средств автоматизации, так и без использования таких средств, включая сбор, запись,
          систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, извлечение,
          блокирование, удаление, уничтожение, а также передачу его Партнёрам (АО КБ «Модульбанк» (адрес
          местонахождения: 156005, г. Кострома, пл. Октябрьская, д. 1), ООО «Аванпост» (адрес местонахождения 127015,
          город Москва, улица Новодмитровская, дом 2, корпус 1, пом. XXXV, офис 12) с правом Партнёров на обработку без
          получения дополнительного согласия следующих персональных данных, предоставленных мной лично при заполнении
          Анкеты или поступивших в МодульПартнёр иными законными способами в целях заключения и исполнения любых
          договоров, проверки, в том числе с использованием услуг других операторов, достоверности предоставленных мной
          сведений, проверки информации о неисполнении и/или ненадлежащим исполнении договорных обязательств,
          информирования меня об изменениях в продуктах и услугах МодульПартнёр и его Партнёров: фамилия, имя, отчество,
          данные документа, удостоверяющего личность, адрес регистрации, ИНН, фотографическое изображение, номер
          контактного телефона, адрес электронной почты. Согласие на обработку персональных данных действует с даты
          подписания до даты полного исполнения обязательств по договорам, в том числе, если в результате рассмотрения и
          обсуждения условий проектов договоров договорные отношения установлены не были, а также последующие 5 (пять)
          лет, в соответствии со сроками хранения документов, установленными законодательством Российской Федерации, и
          может быть отозвано путём направления письменного обращения в адрес МодульПартнёр и/или его Партнёров в
          произвольной форме. В случае направления МодульПартнёр персональных данных должностных лиц и/или работников
          гарантирую МодульПартнёр, что получил от таких должностных лиц, работников согласия на передачу их
          персональных данных, а также обязуюсь возместить МодульПартнёр ущерб, причинённый отсутствием такого
          согласия.</v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { required, phone } from '@/utils/rules';
import { clearPhone } from '@/utils/helpers';
import { mapMutations } from 'vuex';

export default {
  name: 'FBRRegistrationInit',

  directives: { mask },
  props: {},

  data() {
    return {
      phoneRules: [required('Введите номер телефона'), phone],
      checkboxRules: [required('Для продолжения необходимо согласие')],
      phone: '',
      checkbox: true,
      showModal: false,
      valid: false,
    };
  },
  computed: {
    phoneDigits() {
      return clearPhone(this.phone);
    },
  },
  methods: {
    ...mapMutations('profile', ['setLogin']),

    init() {
      this.setLogin(this.phoneDigits);
      this.$emit('init', this.phoneDigits);
    },
  },
};
</script>
