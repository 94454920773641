<template>
  <div>
    <v-card min-height="99" class="font mx-auto mb-4 pr-4 pl-xl-15" elevation="0" color="#FCF2CE">
      <div class="d-sm-flex justify-space-between">
        <div class="d-flex">
          <div class="d-flex align-center">
            <v-img src="img/offer.png" max-width="112" max-height="99"></v-img>
          </div>
          <div class="ma-4 my-auto">
            <v-card-text class="text-uppercase font--lh14 font--gray10 font-weight-medium text-caption pa-0 mb-3"
              >Специальное предложение</v-card-text
            >
            <v-card-text class="font--lh20 text-body-2 font--black1 font-weight-medium pa-0"
              >Финансирование для селлеров</v-card-text
            >
          </div>
        </div>
        <v-card-actions class="mx-xs-auto mx-sm-auto mr-md-0">
          <v-btn
            color="primary"
            elevation="0"
            class="text-caption font-weight-bold ml-10 pa-4"
            :href="chat"
            target="_blank"
            >Оставить заявку</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import { OFFER_URL } from '@/constants.js';
export default {
  name: 'SpecialOffer',
  data() {
    return {
      chat: OFFER_URL,
    };
  },
};
</script>
