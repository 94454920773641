import { formatISO, subDays } from 'date-fns';

// 7 дней
// FIXME как будем менять на 30 дней заменить в компоненте  thirtyDaysBefore на  sevenDaysBefore
// const date = new Date();
// const today = formatISO(date, { representation: 'date' });
// const sevenDaysBefore = formatISO(subDays(date, 6), { representation: 'date' });

// 30 дней
const date = new Date();
const today = formatISO(date, { representation: 'date' });
const thirtyDaysBefore = formatISO(subDays(date, 29), { representation: 'date' });

const filter = {
  filter: [
    {
      field: 'dadd',
      filters: [
        { operator: 'gte', value: thirtyDaysBefore },
        { operator: 'lte', value: today },
      ],
      logic: 'and',
    },
  ],
};
export { filter, today, thirtyDaysBefore };
