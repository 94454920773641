<template>
  <v-card color="#EEFAD4" class="pa-4">
    <div v-if="showPackage">
      <div class="d-flex justify-space-between align-center pb-2 font--black1">
        <div class="text-subtitle-2">{{ servicePack.name }}</div>

        <v-chip class="font-weight-medium ag-status-chip" x-small color="#88BD1F" text-color="white">
          <v-icon x-small left> mdi-check </v-icon> {{ servicePack.state === 'enable' ? 'Подключен' : '' }}</v-chip
        >
      </div>
    </div>

    <div v-else>
      <div class="d-flex justify-space-between pb-2 font--black1">
        <div class="text-subtitle-2">Услуги для селлеров</div>

        <div class="text-subtitle-2 ml-0 text-right">от 0 ₽/мес</div>
      </div>

      <div class="text-subtitle-2 font-weight-regular mt-2">Поможем увеличить продажи или выйти на маркетплейсы</div>
      <v-card-actions class="justify-center mt-8"
        ><v-btn class="pa-0 widget__btn" absolute bottom dark depressed color="#88BD1F" @click="setTab()">
          Подключить
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'WidgetSellers',
  components: {},
  mixins: [makeRub],

  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('products', ['info', 'showPackage']),
    ...mapState('products', ['info', 'servicePack']),
  },

  methods: {
    ...mapMutations('common', ['setVerticalTab', 'setMobileMainTab']),

    setTab() {
      this.isMobile ? this.setMobileMainTab('tab-1') : this.setVerticalTab(1);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget__btn.v-btn.v-size--default {
  font-size: 12px !important;
  min-width: 90%;
  height: 30px;
}
.widget__btn >>> span {
  width: 100%;
}
.ag-status-chip.v-chip.v-size--x-small {
  height: 14px;
}
</style>
