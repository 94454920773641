<template>
  <v-navigation-drawer
    :stateless="stateless"
    fixed
    :value="value"
    right
    :width="width"
    enable-resize-watcher
    overlay-opacity="0"
    :touchless="touchless"
    @input="$emit('input', $event)"
    @transitionend="onTransitionEnd"
  >
    <template #prepend>
      <header class="d-sm-flex align-center font">
        <div class="text-right pa-2">
          <v-btn icon @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <slot name="title">
          <h2 class="text-h5 mt-n5 pb-6 mt-sm-0 font font--h1 font--black1 pl-4 pr-12 px-sm-0 py-sm-4">
            {{ title }}
          </h2>
        </slot>
      </header>
      <!-- <v-divider class="d-none d-sm-block"></v-divider> -->
    </template>
    <slot></slot>
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    title: String,
    value: Boolean,
    width: {
      type: Number,
      default: 900,
    },
    stateless: {
      type: Boolean,
      default: false,
    },
    touchless: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onTransitionEnd(e) {
      // transitionend генерится два раза - один нам не нужен
      if (e.propertyName === 'transform') return;

      if (this.value) {
        this.$emit('sidepanelopen');
      } else {
        this.$emit('sidepanelclosed');
      }
    },
  },
};
</script>

<style lang="scss">
.side-panel {
  padding: 32px 32px;
  @media (max-width: 600px) {
    padding: 16px 16px;
  }
}
.side-panel-right-field {
  padding: 32px 256px 32px 52px;
  @media (max-width: 960px) {
    padding-right: 52px;
  }
  @media (max-width: 600px) {
    padding: 16px;
  }
}
.v-navigation-drawer__append {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0869282), 0px 0px 1px rgba(0, 0, 0, 0.239102);
  padding: 10px 32px;
  @media (max-width: 600px) {
    padding: 10px 16px;
  }
}
.v-navigation-drawer--close {
  transition: all 0.3s ease-in !important;
  transform: none !important;
  opacity: 0;
}
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
  transition: all 0.3s ease-in !important;
  transform: none !important;
  opacity: 1;
}
</style>
