<template>
  <div>
    <bar-chart :chart-data="salesGraph" :options="options" :height="70" :width="200"></bar-chart>
  </div>
</template>

<script>
import BarChart from '@/components/common/graph/BarChart.vue';

export default {
  name: 'ProductSalesGraph',
  components: {
    BarChart,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      salesGraph: {},
      options: {
        tooltips: {
          titleFontSize: 10,
          bodyFontSize: 10,
          footerAlign: 'center',
          displayColors: false,
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    valueGraph() {
      const arr = this.value.map((res) => res?.value);
      return arr.reverse();
    },
    dateGraph() {
      const date = this.value.map((res) => new Date(res?.date).toLocaleDateString('ru-RU'));
      return date.reverse();
    },
  },
  watch: {
    value() {
      this.barGraph();
    },
  },
  mounted() {
    this.barGraph();
  },

  methods: {
    barGraph() {
      if (this.value) {
        this.salesGraph = {
          labels: this.dateGraph,
          datasets: [
            {
              data: this.valueGraph,
              backgroundColor: '#A5D6A7',
            },
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
