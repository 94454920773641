<template>
  <div>
    <div class="mr-3 d-flex justify-space-between">
      <div v-show="viewLabel === 'Таблица'">
        <div class="d-flex align-center justify-space-between">
          Выберите период:
          <v-card class="font day-group mx-2" flat color="#FFF">
            <div class="text-center day-group__div">
              <v-btn-toggle
                v-model="days"
                class="text-lowercase ma-0 day-group__toogle"
                group
                color="primary"
                @change="selectedDays($event)"
              >
                <v-btn value="7" class="day-group__toogle--btn ma-0 mr-2" :class="{ 'disable-events': days == '7' }"
                  >7 дн.</v-btn
                >
                <v-btn value="14" class="day-group__toogle--btn ma-0 mr-2" :class="{ 'disable-events': days == '14' }"
                  >14 дн.</v-btn
                >
                <v-btn value="30" class="day-group__toogle--btn ma-0" :class="{ 'disable-events': days == '30' }"
                  >30 дн.</v-btn
                >
              </v-btn-toggle>
            </div>
          </v-card>
          <SalesTypeToogle @change="changeType($event)" />
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-switch
        :label="viewLabel"
        dense
        inset
        hide-details
        class="pr-3 ma-0 flex align-center new-switch"
        @click="toggleView"
      ></v-switch>
    </div>

    <CatalogTable v-if="isTableView" :items="items" @cat:change="$emit('cat:change', $event)" />
    <v-row v-else class="ma-0 mb-5">
      <v-col v-for="(item, i) in items" :key="i" cols="12" md="6" lg="4" class="pa-1">
        <v-expansion-panels :active-class="`grey lighten-${item.lvl}`">
          <CatalogPanel :item="item" @cat:change="$emit('cat:change', $event)" />
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import CatalogPanel from '@/components/metric/wb/catalog/CatalogPanel';
import CatalogTable from '@/components/metric/wb/catalog/CatalogTable';
import SalesTypeToogle from '@/components/common/SalesTypeToogle';
import { INIT_SALES_TYPE } from '@/constants.js';

export default {
  name: 'CatalogMain',
  components: { CatalogPanel, CatalogTable, SalesTypeToogle },
  data() {
    return {
      isTableView: true,
      days: '7',
      types: INIT_SALES_TYPE,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.wbCatalog.catalog,
    }),
    ...mapGetters('wbMainTabs', ['tabs']),
    viewLabel() {
      return this.isTableView ? 'Таблица' : 'Панель';
    },
  },
  mounted() {
    // диспатчим метод каталога
    this.$store.dispatch(this.tabs[0].action);
  },
  methods: {
    ...mapMutations('wbCatalog', ['setPeriod', 'setCatalogSalesType']),
    ...mapActions('wbCatalog', ['catalogList']),
    toggleView() {
      this.isTableView = !this.isTableView;
    },
    selectedDays(e) {
      this.setPeriod(e);
      this.catalogList();
    },
    changeType(e) {
      this.setCatalogSalesType(e);
      this.catalogList();
    },
  },
};
</script>

<style lang="scss">
.day-group {
  border-radius: 4px !important;
  &__div {
    border-radius: 4px;
    background-color: #f5f5f6;
    padding: 4px;
  }

  .v-btn-toggle > .v-btn.v-btn--active {
    color: #4971d0 !important;
    background-color: #4971d0 !important;
  }

  &__toogle {
    &--btn {
      width: 56px !important;
      height: 36px !important;
      border-radius: 4px !important;
      border: none !important;
      opacity: 1 !important;
    }
    &--btn .v-btn__content {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #777c87;

      text-transform: lowercase;
    }
    &--btn.v-btn--active .v-btn__content {
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
