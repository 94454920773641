<template>
  <v-card
    style="background-color: #f2f6ff; border-radius: 2px; height: 100%"
    class="d-flex flex-column justify-space-between pa-4 rounded-lg"
    elevation="0"
  >
    <div>
      <div class="text-subtitle-2 mb-5">Расходы</div>
      <div v-for="(value, name, index) in status" :key="index + name">
        <div v-if="index < limit_by" cols="12" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            {{ name }}
          </div>
          <div class="text-subtitle-2">
            {{ makeRub(value) }}
          </div>
        </div>
      </div>
    </div>
    <v-btn text color="primary" class="mt-4 info__btn" @click="costsClick(default_limit, Object.keys(status).length)"
      >{{ limit_by === 2 ? 'Подробнее' : 'Свернуть' }}
      <v-icon class="ml-1">{{ limit_by === 2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { makeRub } from '@/mixins/makeRub';
import { renameKeys } from '@/utils/helpers';

export default {
  name: 'CostsInfo',
  mixins: [makeRub],
  data: () => ({
    default_limit: 2,
    limit_by: 2,
    keysMap: {
      commission: 'Комиссия МП',
      logistics: 'Логистика и хранение',
      service: 'Cервис',
      finServices: 'Финсервисы',
      comp: 'Компенсации и начисления',
      promotion: 'Продвижение и отзывы',
      other: 'Прочие расходы МП ',
    },
  }),
  computed: {
    ...mapState('ozonAnaliticInfo', ['analitic']),
    status() {
      return renameKeys(this.keysMap, this.analitic.costs);
    },
  },
  methods: {
    costsClick(default_limit, filters_length) {
      this.limit_by = this.limit_by === default_limit ? filters_length : default_limit;
    },
  },
};
</script>

<style scoped>
.info__btn.v-btn:not(.v-btn--round).v-size--default {
  justify-content: flex-start;
  padding: 0;
}
</style>
