import axios from 'axios';
import merge from 'lodash/merge';
import { DEV_TOKEN_COMPANY, WHITE_SERVER_URL, IS_METRIC, IS_DEVELOPER } from '@/constants.js';

const BASE_AXIOS_CONFIG = {
  withCredentials: false,
  proxyHeaders: false,
  headers: {},
};

const axiosConfig = {
  baseURL: WHITE_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};
const fileGetConfig = {
  baseURL: WHITE_SERVER_URL,
  responseType: 'blob',
};

export let serverWhite;
export let serverWhiteHidden;
export let serverWhiteFileGetHidden;

export const createServerWhite = (tokens) => {
  try {
    if (IS_METRIC) {
      // localStorage.clear();
      let stopUtmList = ['opensku', 'extension'];

      let utmList = decodeURIComponent(location.search.substr(1)).split('&');
      utmList.forEach(function (item) {
        let utm = item.split('=');
        let utmName = utm[0];
        let utmValue = utm[1];

        // !! добавим проверку чтобы в хедеры не попало
        if (
          !stopUtmList.includes(utmName.toLowerCase()) &&
          utmValue !== undefined &&
          utmValue !== null &&
          utmValue !== ''
        ) {
          BASE_AXIOS_CONFIG.headers[utmName] = utmValue;
          // localStorage.setItem(utmName, utmValue);
        }

        // сохраняем tnx их ссылки в куку
        // document.cookie = `${utmName} = ${utmValue}; max-age=0`;
        // Сохраняем в куки xToken на 1 час
        // document.cookie = `${utmName} = ${utmValue}; path = /; SameSite = None; Secure`;
      });
    } else if (tokens) {
      const xToken = tokens['X-TOKEN'] || tokens['x-token'];
      const company = tokens['Company'] || tokens['company'];

      if (xToken) BASE_AXIOS_CONFIG.headers['X-TOKEN'] = xToken;
      if (company) BASE_AXIOS_CONFIG.headers['Company'] = company;
    } else {
      // moduldev
      BASE_AXIOS_CONFIG.headers['Company'] = DEV_TOKEN_COMPANY;
    }

    // хедер отдельный для аналитики
    if (IS_DEVELOPER) {
      axiosConfig.headers['Developer'] = 'true';
    }

    const httpConf = merge({}, BASE_AXIOS_CONFIG, axiosConfig);
    const fileGetConf = merge({}, BASE_AXIOS_CONFIG, fileGetConfig);

    serverWhite = axios.create(httpConf);
    serverWhiteHidden = axios.create(httpConf);
    serverWhiteFileGetHidden = axios.create(fileGetConf);
  } catch (e) {
    console.log('Ошибка создания http клиента');
    console.log(e);
  }
};
