<template>
  <div class="chart-container">
    <line-chart ref="chartRef" :chart-data="productGraph" :options="options"></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/common/graph/LinesChart.vue';

export default {
  name: 'MainInfoGraph',
  components: {
    LineChart,
  },
  props: {
    info_graph: {
      // eslint-disable-line
      type: [Object, null],
    },
  },
  data() {
    return {
      productGraph: {},
      legendItems: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  computed: {
    card() {
      const array = this.info_graph?.graph ? this.info_graph.graph : [];
      return array.reverse();
    },

    label() {
      const formattedDates = this.card.map((res) => new Date(res?.date).toLocaleDateString('ru-RU'));
      return formattedDates;
    },
  },
  watch: {
    card() {
      this.lineGraph();
    },
  },

  methods: {
    onLegendClicked(index) {
      const ci = this.$refs.chartRef.$data._chart;

      var meta = ci.getDatasetMeta(index);

      meta.hidden = !this.legendItems[index].display;

      // отображаем/скрываем данные на графике
      if (meta.hidden) {
        ci.options.scales.yAxes[index].display = false;
      } else if (meta.hidden === null && (index === 1 || index === 3)) {
        ci.options.scales.yAxes[index].display = false;
      } else if (meta.hidden === null && (index === 0 || index === 2)) {
        ci.options.scales.yAxes[index].display = true;
      } else {
        ci.options.scales.yAxes[index].display = true;
      }
      ci.update();
    },
    lineGraph() {
      this.productGraph = {
        labels: this.label,
        datasets: [
          {
            label: 'Продажи (руб)',
            data: this.card.map((res) => res?.sumSales),
            fill: false,
            borderColor: '#4971D0',
            type: 'line',
            hidden: true,
          },
          {
            label: 'Продажи (шт)',
            data: this.card.map((res) => res?.quSales),
            fill: false,
            borderColor: '#A7C0FB',
            type: 'line',
          },
          {
            label: 'Заказы (руб)',
            data: this.card.map((res) => res?.sumOrders),
            fill: false,
            borderColor: '#EFD04E',
            type: 'line',
            hidden: true,
          },
          {
            label: 'Заказы (шт)',
            data: this.card.map((res) => res?.quOrders),
            fill: false,
            borderColor: '#A4D73E',
            type: 'line',
          },
          {
            label: 'Возвраты (руб)',
            data: this.card.map((res) => res?.sumReturns),
            fill: false,
            borderColor: '#E36868',
            type: 'line',
            hidden: true,
          },
          {
            label: 'Возвраты (шт)',
            data: this.card.map((res) => res?.quReturns),
            fill: false,
            borderColor: '#F19494',
            type: 'line',
          },
          {
            label: 'Выплата МП',
            data: this.card.map((res) => res?.forpay),
            fill: false,
            borderColor: '#F99631',
            type: 'line',
            hidden: true,
          },
        ],
      };

      const ci = this.$refs.chartRef.$data._chart;
      const isEmpty = ci.config.data.datasets.length === 0;

      if (isEmpty) {
        this.legendItems = this.productGraph.datasets.map((e) => {
          return {
            label: e.label,
            display: !e.hidden,
            backgroundColor: e.borderColor,
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  max-height: 500px;
}
</style>
