<template>
  <v-card class="d-flex justify-space-between flex-column px-6 py-8" min-height="500" elevation="0">
    <div class="d-flex justify-space-between">
      <div class="text-h6 font-weight-regular pa-0 font--lh24" style="width: 50%">Подключение</div>
      <a class="d-flex" @click.stop="showInfoPopup = true"
        ><svg width="18" height="18" class="icon mr-2">
          <use xlink:href="#help"></use></svg
        ><span class="text-uppercase text-caption font-weight-medium font--lh16">Как это работает</span></a
      >
    </div>
    <section>
      <div class="d-flex justify-center">
        <v-img src="img/moment.png" alt="Moment" max-width="145px" max-height="130px"></v-img>
      </div>

      <v-card-text class="pa-0 mb-3 mt-9 text-center text-h6 font-weight-regular font--lh24"
        >Моментально выплатим выручку</v-card-text
      >
      <v-card-text v-if="isDisabled" class="pa-0 text-center text-subtitle-1 font--lh24 font--graphite3 text"
        >Переводим деньги за выкупленные товары сразу – не нужно ждать выплату от маркетплейса. С комиссией всего
        2%</v-card-text
      >
      <div v-if="inProgress">
        <v-card-text class="pa-0 text-center text-subtitle-1 font--lh24 font--graphite3 text">
          <!-- <v-btn elevation="0" icon color="primary" loading class="mr-2"></v-btn> -->
          Пожалуйста, подождите, обработка запроса может занять несколько минут.</v-card-text
        >
      </div>
    </section>

    <v-card-actions class="justify-center">
      <v-btn large :disabled="inProgress" color="primary" @click="sendReg">{{
        inProgress ? 'Заявка отправлена' : 'Подключить'
      }}</v-btn>
    </v-card-actions>
    <InfoMomentPopup v-model="showInfoPopup" />
  </v-card>
</template>

<script>
import InfoMomentPopup from '@/components/moment/InfoMomentPopup';
import { mapGetters } from 'vuex';

export default {
  components: { InfoMomentPopup },

  data: () => ({
    showInfoPopup: false,
  }),
  computed: {
    ...mapGetters('moment', ['inProgress', 'isDisabled']),
  },
  methods: {
    sendReg() {
      this.$store.dispatch('moment/getMomentReg');
    },
  },
};
</script>

<style scoped>
.theme--light.v-card > .v-card__text {
  color: rgba(0, 0, 0, 1);
}

.text {
  color: #777c87 !important;
}

section {
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
