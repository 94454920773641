import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  debts: null,
});

export const moment = {
  namespaced: true,
  state: initState,
  getters: {
    limit: (state) => state.debts?.limit,
    debt: (state) => state.debts?.debt,
    debtCom: (state) => state.debts?.debt_comm,
    debtExComt: (state) => state.debts?.debt_ex_comm,
    isSuccess: (state) => state.debts?.status === 'reg',
    inProgress: (state) => state.debts?.status === 'wait_reg',
    isDisabled: (state) => state.debts?.status === 'unreg',
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setDebts: (state, value) => {
      state.debts = value;
    },
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async getMoment({ commit }) {
      const res = await api.getMoment();
      if (res) {
        commit('setDebts', res);
      }
    },

    async addMomentSum({ commit }, data) {
      let res = await api.addMomentSum(data);
      if (res) {
        commit('setDebts', res);
      }
      return res;
    },

    async getMomentReg({ commit }) {
      const res = await api.getMomentReg();
      if (res) {
        commit('setDebts', res);
      }
    },
  },
};
