import { createResetMutation } from '@/store/helpers/reset';
import { extensionLogOut } from '@/utils/chrome.js';
import { today, thirtyDaysBefore } from '@/mixins/filterDate';
import Vuetify from '@/plugins/vuetify';

const initState = () => ({
  errdesc: '',
  showCardWb: false,
  showCardOzon: false,
  openProductToken: false,
  // openServicesToken: false,
  openContragents: false,
  openOptionToken: false,
  openOptionProductToken: false,
  openAnaliticToken: false,
  alerts: [],
  uid: 0,
  loading: 0,
  isHeaderIntersecting: false,
  sdate: thirtyDaysBefore,
  edate: today,
  tab: 0,
  mobileTab: 'tab-0',
  isAuthorized: false,
});

export const common = {
  namespaced: true,
  state: initState,
  getters: {
    errdesc: (state) => state.errdesc,
    alerts: (state) => state.alerts,
    loading: (state) => Boolean(state.loading),
    sdate: (state) => state.sdate,
    edate: (state) => state.edate,
    isMobile: () => Vuetify.framework.breakpoint.xs,
    isLogged: (state) => {
      let tnx = localStorage.getItem('tnx');
      return tnx || state.isAuthorized === true;
    },
  },

  mutations: {
    setErrdesc: (state, value) => (state.errdesc = value),
    addAlert(state, { type, msg }) {
      state.uid++;
      state.alerts.push({ type, msg, id: state.uid });
    },

    auth(state, tnxValue) {
      state.isAuthorized = true;
      localStorage.setItem('tnx', tnxValue);
    },

    removeAlert(state, id) {
      let i = state.alerts.filter((el) => el.uid === id);
      state.alerts.splice(i, 1);
    },
    startLoading(state) {
      state.loading = state.loading + 1;
    },
    stopLoading(state) {
      state.loading = state.loading - 1;
    },
    setHeaderIntersecting(state, value) {
      state.isHeaderIntersecting = value;
    },
    showCardWbModal: (state, payload) => (state.showCardWb = payload),
    showCardOzonModal: (state, payload) => (state.showCardOzon = payload),
    showProductSidePanel: (state, payload) => (state.openProductToken = payload),
    // showServicesSidePanel: (state, payload) => (state.openServicesToken = payload),
    showGreenSidePanel: (state, payload) => (state.openContragents = payload),
    showOptionSidePanel: (state, payload) => (state.openOptionToken = payload),
    showAnaliticSidePanel: (state, payload) => (state.openAnaliticToken = payload),
    showOptionProductTokenSidePanel: (state, payload) => (state.openOptionProductToken = payload),
    setStartDate: (state, payload) => (state.sdate = payload),
    setEndDate: (state, payload) => (state.edate = payload),
    setVerticalTab: (state, payload) => (state.tab = payload),
    setMobileMainTab: (state, payload) => (state.mobileTab = payload),
    setIsAuthorized: (state, payload) => (state.isAuthorized = payload),

    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    addAlert({ commit }, { type, msg }) {
      commit('addAlert', { type, msg });

      // автоудаление через 3 сек
      setTimeout(() => {
        commit('removeAlert');
      }, 3000);
    },

    clearErrdesc({ commit, state: { errdesc } }) {
      if (errdesc.length > 0) {
        commit('setErrdesc', '');
      }
    },

    logout({ commit }) {
      localStorage.removeItem('tnx');
      localStorage.removeItem('extension');
      commit('setIsAuthorized', false);

      extensionLogOut();

      const currentDomain = window.location.origin;
      window.location.href = currentDomain + '/';
    },
  },
};
