import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';

const initState = () => ({
  optionsList: null,
  token: null,
  type: null,
  clientid: null,
  product_token: null,
});

export const options = {
  namespaced: true,
  state: initState,
  getters: {
    isOptionsList: (state) => !!state.optionsList,
    isLoaded: (state) => state.optionsList !== null,
    isActiveWbKey: (state) => {
      const list = state.optionsList ? state.optionsList : [];
      const wbActiveKeys = list.filter((option) => {
        return option.type == 'wb' && option.act == true;
      });
      return wbActiveKeys.length ? true : false;
    },
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setOptionsList: (state, value) => {
      state.optionsList = value;
    },
    setProductToken: (state, value) => {
      state.product_token = value;
    },
    setToken: (state, value) => {
      state.token = value;
    },
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    // список токентов
    async getOptionList({ commit }) {
      const res = await api.getOptionList();
      if (res) {
        commit('setOptionsList', res.keys);
      }
    },
  },
};
