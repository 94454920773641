// const TOKENS = ['ijnbncppomccffipflibjpklajoikphl', 'kdfmbelapkjfdkbcinmgafjjaigfngkn']; // test
const TOKENS = ['pkfjhhelaiceefbopgjdodpbdlkakigh']; // prod

function extensionLogIn() {
  console.log('++++extensionLogIn++++');
  console.log('============');
  console.log('ТОКЕН ДЛЯ ПЕРЕДАЧИ : ', localStorage.getItem('tnx'));
  console.log('============');

  if (chrome.runtime) {
    chrome.runtime.sendMessage('pkfjhhelaiceefbopgjdodpbdlkakigh', {
      name: 'AUTH_TOKEN_REQUEST',
      data: { token: localStorage.getItem('tnx') },
    });
  } else {
    console.error('___ОБЪЕКТ CHROME.RUNTIME НЕ ДОСТУПЕН___');
  }
}

function extensionLogOut() {
  console.log('++++extensionLogout++++');
  TOKENS.forEach((token) => {
    if (chrome.runtime) {
      chrome.runtime.sendMessage(token, {
        name: 'LOGOUT',
        data: null,
      });
    }
  });
}

export { extensionLogIn, extensionLogOut };
