<template>
  <v-chip class="font-weight-medium ag-status-chip" small :color="color" :text-color="textColor">{{ label }}</v-chip>
</template>

<script>
// import { mapState } from "vuex";

export default {
  name: 'StatusChip',

  props: {
    value: String,
  },

  data() {
    return {
      // Статусы
      statuses: {
        decline: {
          label: 'Отказ',
          bgColor: '#FFE9E9',
          textColor: '#A61D1D',
        },
        rejection: {
          label: 'Отказ',
          bgColor: '#FFE9E9',
          textColor: '#A61D1D',
        },
        approved: {
          label: 'Одобрен',
          bgColor: '#EEFAD4',
          textColor: '#5E8C00',
        },
        success: {
          label: 'Одобрен',
          bgColor: '#EEFAD4',
          textColor: '#5E8C00',
        },
        check: {
          label: 'На проверке',
          bgColor: '#FAE6AA',
          textColor: '#B79310',
        },
        another: {
          label: 'Статус другой',
          bgColor: '#F5F5F6',
          textColor: '#464C59',
        },
        added: {
          label: 'Добавлен',
          bgColor: '#D2DDFA',
          textColor: '#253C77',
        },
      },
    };
  },
  computed: {
    // ...mapState("bid", ["statuses"]),
    status() {
      return this.statuses[this.value];
    },
    label() {
      return this.status ? this.status.label : this.value;
    },
    color() {
      return this.status ? this.status.bgColor : '#eeeeee';
    },
    textColor() {
      return this.status ? this.status.textColor : '#000000';
    },
  },
};
</script>

<style lang="scss" scoped>
.ag-status-chip.v-chip.v-size--small {
  height: 22px;
}
</style>
