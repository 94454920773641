import { serverWhite } from '../server-white';

/**
 * Старна
 * @param {string} search_query
 * @returns {Promise<CountrySearchResponse>}
 */

async function searchCountry(data) {
  let res = await serverWhite.post('/admin/mp/country/get', data);
  return res;
}

export { searchCountry };
