import { stringFilterNeedsValue } from '../_utils/filterConditions';

// type constants

const TYPES = {
  ENUM: 'enum',
  MULTIPLE: 'multiple',
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  // константа для строкового поля Впишите.. без операторов
  EQ_STRING: 'eq_string',
};

const STRING_OPERATOR_KEYS = [
  'CONTAINS',
  'DOES_NOT_CONTAIN',
  'STARTS_WITH',
  'ENDS_WITH',
  'EQUALS',
  'NOT_EQUALS',
  'IS_NULL',
  'IS_NOT_NULL',
  'IS_EMPTY',
  'IS_NOT_EMPTY',
];

const STRING_OPERATOR_NAMES = {
  CONTAINS: 'Содержит',
  DOES_NOT_CONTAIN: 'Не содержит',
  STARTS_WITH: 'Начинается на',
  ENDS_WITH: 'Заканчивается на',
  EQUALS: 'Равно',
  NOT_EQUALS: 'Не равно',
  IS_NULL: 'Не задано значение',
  IS_NOT_NULL: 'Задано значение',
  IS_EMPTY: 'Пустая строка',
  IS_NOT_EMPTY: 'Не пустая строка',
};

const STRING_OPERATOR_VALUES = {
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'doesnotcontain',
  STARTS_WITH: 'startswith',
  ENDS_WITH: 'endswith',
  EQUALS: 'eq',
  NOT_EQUALS: 'neq',
  IS_NULL: 'isnull',
  IS_NOT_NULL: 'isnotnull',
  IS_EMPTY: 'isempty',
  IS_NOT_EMPTY: 'isnotempty',
};

const STRING_OPERATOR_ITEMS = STRING_OPERATOR_KEYS.map((key) => ({
  name: STRING_OPERATOR_NAMES[key],
  value: STRING_OPERATOR_VALUES[key],
}));

const NUMBER_OPERATOR_KEYS = ['GREATER_OR_EQUALS', 'GREATER', 'LESS_OR_EQUALS', 'LESS', 'EQUALS', 'NOT_EQUALS'];

const NUMBER_OPERATOR_NAMES = {
  GREATER_OR_EQUALS: 'Больше или равно',
  GREATER: 'Строго больше',
  LESS_OR_EQUALS: 'Меньше или равно',
  LESS: 'Строго меньше',
  EQUALS: 'Равно',
  NOT_EQUALS: 'Не равно',
};

const NUMBER_OPERATOR_VALUES = {
  GREATER_OR_EQUALS: 'gte',
  GREATER: 'gt',
  LESS_OR_EQUALS: 'lte',
  LESS: 'lt',
  EQUALS: 'eq',
  NOT_EQUALS: 'neq',
};

const NUMBER_OPERATOR_ITEMS = NUMBER_OPERATOR_KEYS.map((key) => ({
  name: NUMBER_OPERATOR_NAMES[key],
  value: NUMBER_OPERATOR_VALUES[key],
}));

const LOGIC_KEYS = ['AND', 'OR'];

const LOGIC_OPERATOR_NAMES = {
  AND: 'И',
  OR: 'Или',
};

const LOGIC_OPERATOR_VALUES = {
  AND: 'and',
  OR: 'or',
};

const LOGIC_OPERATOR_ITEMS = LOGIC_KEYS.map((key) => ({
  name: LOGIC_OPERATOR_NAMES[key],
  value: LOGIC_OPERATOR_VALUES[key],
}));

const DATE_OPERATOR_VALUES = {
  GREATER_OR_EQUALS: 'gte',
  LESS_OR_EQUALS: 'lte',
};

const EQUALITY_OPERATOR_VALUES = {
  EQUALS: 'eq',
  NOT_EQUALS: 'neq',
};

const REQUIRED_TEXT = 'Обязательное поле';
const NEED_NUMBER_TEXT = 'Только числовые значения';

const SIMPLE_REQUIRED_RULE = ({ active }) => [(v) => !active || !!v || REQUIRED_TEXT];
const SIMPLE_RANGE_RULE = ({ active, range }) => [(v) => !active || !range.active || !!v || REQUIRED_TEXT];

const RULES = {
  ENUM: SIMPLE_REQUIRED_RULE,
  EQ_STRING: SIMPLE_REQUIRED_RULE,
  MULTIPLE: ({ active }) => [(v) => !active || (Array.isArray(v) && v.length > 0) || REQUIRED_TEXT],
  STRING: {
    MAIN_OPERATOR: SIMPLE_REQUIRED_RULE,
    MAIN_VALUE: ({ active, operator }) => [(v) => !active || !stringFilterNeedsValue(operator) || !!v || REQUIRED_TEXT],
  },
  NUMBER: {
    MAIN_OPERATOR: SIMPLE_REQUIRED_RULE,
    MAIN_VALUE: ({ active }) => [
      (v) => !active || v === 0 || !!v || REQUIRED_TEXT,
      (v) => !active || !isNaN(parseFloat(v)) || NEED_NUMBER_TEXT,
    ],
    LOGIC_OPERATOR: SIMPLE_RANGE_RULE,
    RANGE_OPERATOR: SIMPLE_RANGE_RULE,
    RANGE_VALUE: SIMPLE_RANGE_RULE,
  },
  DATE: SIMPLE_REQUIRED_RULE,
};

export {
  TYPES,
  STRING_OPERATOR_VALUES,
  STRING_OPERATOR_ITEMS,
  NUMBER_OPERATOR_VALUES,
  NUMBER_OPERATOR_ITEMS,
  LOGIC_OPERATOR_VALUES,
  LOGIC_OPERATOR_ITEMS,
  DATE_OPERATOR_VALUES,
  EQUALITY_OPERATOR_VALUES,
  REQUIRED_TEXT,
  NEED_NUMBER_TEXT,
  SIMPLE_REQUIRED_RULE,
  RULES,
};
