<template>
  <div v-if="isLoaded">
    <GreenLimitEmpty v-if="hideGreenLimit" />
    <GreenLimitMain v-else />

    <SidePanel v-model="showCounterparty" :width="650">
      <GreenLimitCreateContragent :close-panel="showCounterparty" />
    </SidePanel>
  </div>
</template>
<script>
import GreenLimitMain from '@/components/greenLimit/GreenLimitMain';
import GreenLimitEmpty from '@/components/greenLimit/GreenLimitEmpty';
import SidePanel from '@/components/common/SidePanel';
import GreenLimitCreateContragent from '@/components/greenLimit/GreenLimitCreateContragent';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'GreenLimit',

  components: { GreenLimitMain, GreenLimitEmpty, SidePanel, GreenLimitCreateContragent },
  data() {
    return {};
  },
  computed: {
    ...mapState('common', ['openContragents']),
    ...mapState('green', ['isLoaded']),
    ...mapGetters('green', ['hideGreenLimit']),

    showCounterparty: {
      get() {
        return this.openContragents;
      },
      set(value) {
        this.showGreenSidePanel(value);
      },
    },
  },
  mounted() {
    this.getGreen();
  },
  methods: {
    ...mapMutations('common', ['showGreenSidePanel']),
    ...mapActions('green', ['getGreen']),
  },
};
</script>

<style scoped></style>
