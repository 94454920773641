export const BUILD = 'metric'; // metric oldmetric

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_PROD = !IS_DEVELOPMENT;
export const DEV_TOKEN_COMPANY = '532dc640-e3d2-45e9-b135-a8be02499a8f';
export const IS_METRIC = BUILD === 'metric';

const configuration = {
  oldmetric: {
    url: 'https://modulmetric.aml.tech/',
    white_server_url: 'https://modulmetric.aml.tech/',
    developer: false,
  },
  metric: {
    url: 'https://modulmetric.modulbank.ru/',
    white_server_url: 'https://modulmetric.modulbank.ru/',
    developer: false,
  },
};

export const WHITE_SERVER_URL = configuration[BUILD].white_server_url;
export const IS_DEVELOPER = configuration[BUILD].developer;
export const INIT_SALES_TYPE = 'fbo';
