<template>
  <div>
    <div class="d-flex flex-wrap justify-space-between mb-11 mt-6">
      <div class="mb-2">
        <modul-grid-filters
          ref="grid-filters"
          :module-name="`${productModule}`"
          :action="`${action}`"
          :columns="headers"
          :filters="true"
          :footer-props="footerProps"
          :fast-date="true"
        >
        </modul-grid-filters>
      </div>
      <v-text-field
        v-model="search"
        dense
        single-line
        hide-details
        height="44"
        class="mb-2"
        style="max-width: 400px"
        outlined
        placeholder="Введите номер заказа"
        clearable
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>
    <vue-horizontal scroll class="horizontal__scroll" snap="none">
      <div class="table__container">
        <v-data-table
          :mobile-breakpoint="100"
          class="orders-table"
          :headers="headers"
          :items="indexedGrid"
          item-key="iid"
          :no-data-text="noDataText"
          fixed-header
          :items-per-page="50"
          :footer-props="footerProps"
          show-expand
          :server-items-length="total"
          :options.sync="options"
          @update:options="onUpdateOptions($event)"
          @item-expanded="loadDetails"
        >
          <template #[`item.order_number`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.posting_number`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.orderdt`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
          </template>

          <template #[`item.sts`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.quantity`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
          </template>

          <template #[`item.price`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
          </template>

          <template #[`item.region`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>

          <template #[`item.warehouse`]="{ value }">
            <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 0 !important">
              <OrdersExpandTable
                :key="item.id"
                :order-products="item.details.products"
                :order-costs="item.details.costs"
              />
            </td>
          </template>
        </v-data-table>
      </div>
    </vue-horizontal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import { TYPES } from '@/components/common/modul-grid/_utils/filters';
import { analiticMixin } from '@/mixins/analiticMixin';
import { createOzonOrdersModule } from '@/store/modules/analitic/ozon/orders/all';
import { getOzonOrderFiltersData } from '@/api/analitic/order';

import OrdersExpandTable from '@/components/analitic/ozon/orders/all/OrdersExpandTable';
import VueHorizontal from 'vue-horizontal';
import { format } from 'date-fns';
import * as api from '@/api';

import { makeRub } from '@/mixins/makeRub';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'OrdersTable',
  components: { OrdersExpandTable, ModulGridFilters, VueHorizontal },
  inject: ['filterMutations', 'pagingMutations'],
  mixins: [makeRub, analiticMixin],

  data() {
    return {
      productModule: 'ozonAnaliticOrders',
      action: 'ozonAnaliticOrders/ozonOrders',
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        { text: 'Номер заказа', value: 'order_number', width: '126px', type: TYPES.EQ_STRING },
        { text: 'Номер отправления', value: 'posting_number', width: '126px', type: TYPES.EQ_STRING },
        { text: 'Дата заказа', value: 'orderdt', width: '126px' },
        { text: 'Статус', value: 'sts', width: '168px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Количество', value: 'quantity', width: '126px', type: TYPES.NUMBER },
        { text: 'Цена', value: 'price', width: '126px', type: TYPES.NUMBER },
        { text: 'Регион', value: 'region', width: '150px', type: TYPES.MULTIPLE, options: [] },
        { text: 'Склад', value: 'warehouse', width: '150px', type: TYPES.MULTIPLE, options: [] },
      ],
    };
  },

  computed: {
    ...mapState('ozonAnaliticOrders', ['filtersData']),
    indexedGrid() {
      return this.grid.map((item, index) => ({
        iid: index,
        ...item,
      }));
    },
  },

  created() {
    if (!this.$store.hasModule(this.productModule)) {
      this.$store.registerModule(this.productModule, createOzonOrdersModule());
    }
    this.debouncedSearch = debounce(this.getProduct, 800);
  },

  async mounted() {
    const statesAndTypes = await getOzonOrderFiltersData();
    this.setOptions({ field: 'region', options: statesAndTypes?.region });
    this.setOptions({ field: 'sts', options: statesAndTypes?.sts });
    this.setOptions({ field: 'warehouse', options: statesAndTypes?.warehouse });
  },

  methods: {
    ...mapMutations('ozonAnaliticOrders', ['setStartDate', 'setEndDate']),
    getProduct() {
      this.$store.dispatch(`${this.action}`);
    },
    setOptions({ field, options }) {
      this.$store.commit(`${this.productModule}/setOptions`, { field, options });
    },

    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },
    async loadDetails({ item }) {
      if (!Object.keys(item.details).length) {
        const res = await api.ozonOrderDetails({ postingNumber: item.posting_number });
        if (res) {
          item.details = res;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  opacity: 1 !important;
  background-color: transparent;
}

.v-data-table.orders-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.theme--light.v-data-table.orders-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.table__container {
  padding-top: 20px;
}

.horizontal__scroll::v-deep .v-hl-btn {
  top: -40px;
  align-items: start;
}
.horizontal__scroll::v-deep .v-hl-btn-next {
  right: 22px;
}

.horizontal__scroll::v-deep .v-hl-btn-prev {
  left: 22px;
}
</style>
