<template>
  <div class="mt-6">
    <span class="font font-weight-medium font--black1">Результаты проверки</span>
    <div class="d-flex justify-space-between mt-6">
      <v-card elevation="0" color="#F5F5F6" class="pa-4 mr-5" min-width="240" min-height="165">
        <span class="text-subtitle-2 font--black1">Категории и запросы</span>
        <div class="d-flex justify-space-between mt-5 mb-3">
          <span
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
            >Количество категорий</span
          >
          <span
            v-if="keywordsResult.position && keywordsResult.position.category !== null"
            class="text-subtitle-2 font--black1"
            >{{ keywordsResult.position.category }}</span
          >
          <span v-else class="text-subtitle-2 font--black1">0</span>
        </div>
        <div class="d-flex justify-space-between mb-3">
          <span
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
            >Количество ключевиков</span
          >
          <span
            v-if="keywordsResult.position && keywordsResult.position.tags !== null"
            class="text-subtitle-2 font--black1"
            >{{ keywordsResult.position.tags }}</span
          >
          <span v-else class="text-subtitle-2 font--black1">0</span>
        </div>
      </v-card>

      <v-card elevation="0" color="#F5F5F6" class="pa-4 mr-5" min-width="240" min-height="165">
        <span class="text-subtitle-2 font--black1">Проверенные запросы</span>

        <div class="d-flex justify-space-between mt-5 mb-3">
          <span
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
            >Всего</span
          >
          <span
            v-if="keywordsResult.requests && keywordsResult.requests.total !== null"
            class="text-subtitle-2 font--black1"
            >{{ keywordsResult.requests.total.toFixed(0) }} %</span
          >
          <span v-else class="text-subtitle-2 font--black1">0%</span>
        </div>
        <div class="d-flex justify-space-between mb-3">
          <span
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
            >Есть все слова</span
          >
          <span
            v-if="keywordsResult.requests && keywordsResult.requests.all_words !== null"
            class="text-subtitle-2 font--black1"
            >{{ keywordsResult.requests.all_words.toFixed(0) }} %</span
          >
          <span v-else class="text-subtitle-2 font--black1">0%</span>
        </div>
        <div class="d-flex justify-space-between mb-3">
          <span
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
            >Полное вхождение</span
          >
          <span
            v-if="keywordsResult.requests && keywordsResult.requests.full_entry !== null"
            class="text-subtitle-2 font--black1"
            >{{ keywordsResult.requests.full_entry.toFixed(0) }} %</span
          >
          <span v-else class="text-subtitle-2 font--black1">0%</span>
        </div>
      </v-card>

      <v-card elevation="0" color="#F5F5F6" class="pa-4" min-width="240" min-height="165">
        <span class="text-subtitle-2 font--black1">Упущенные запросы</span>
        <div class="d-flex justify-space-between mt-5 mb-3">
          <span
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
            >Всего</span
          >
          <span
            v-if="keywordsResult.requests && keywordsResult.requests.missed_requests !== null"
            class="text-subtitle-2 font--black1"
            >{{ keywordsResult.requests.missed_requests.toFixed(0) }} %</span
          >
          <span v-else class="text-subtitle-2 font--black1">0%</span>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProductSeoCards',

  computed: {
    ...mapState('wbProductCard', ['keywordsResult']),
  },
};
</script>
