<template>
  <PackCards v-if="showPackList" class="px-md-8 pt-8" />
</template>

<script>
import PackCards from '@/components/services/package/PackCards';
import { mapGetters } from 'vuex';
export default {
  name: 'PackServiceTab',
  components: { PackCards },
  computed: {
    ...mapGetters('services', ['showPackList']),
  },
  mounted() {
    this.$store.dispatch('services/getPackage');
  },
};
</script>
