<template>
  <v-card class="pa-4 d-flex flex-column justify-space-between">
    <div>
      <div class="text-subtitle-2 pb-4 font--black1">Аналитика по моим магазинам</div>
      <div v-if="analytics.wb">
        <div v-if="analytics.wb.quOrders || analytics.wb.sumOrders" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Заказы
          </div>
          <div v-if="analytics.wb.quOrders && analytics.wb.sumOrders" class="text-subtitle-2">
            {{ analytics.wb.quOrders.toLocaleString('ru') }} шт / {{ makeRub(analytics.wb.sumOrders) }}
          </div>
          <div v-if="analytics.wb.quOrders && analytics.wb.sumOrders == null" class="text-subtitle-2">
            {{ analytics.wb.quOrders.toLocaleString('ru') }} шт
          </div>
          <div v-if="analytics.wb.quOrders == null && analytics.wb.sumOrders" class="text-subtitle-2">
            {{ makeRub(analytics.wb.sumOrders) }}
          </div>
        </div>
        <div v-if="analytics.wb.quSales || analytics.wb.sumSales" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Выкуплено
          </div>
          <div v-if="analytics.wb.quSales && analytics.wb.sumSales" class="text-subtitle-2">
            {{ analytics.wb.quSales.toLocaleString('ru') }} шт / {{ makeRub(analytics.wb.sumSales) }}
          </div>
          <div v-if="analytics.wb.quSales && analytics.wb.sumSales == null" class="text-subtitle-2">
            {{ analytics.wb.quSales.toLocaleString('ru') }} шт
          </div>
          <div v-if="analytics.wb.quSales == null && analytics.wb.sumSales" class="text-subtitle-2">
            {{ makeRub(analytics.wb.sumSales) }}
          </div>
        </div>
        <div v-if="analytics.wb.ransomPrc" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Процент выкупа
          </div>
          <div class="text-subtitle-2">{{ analytics.wb.ransomPrc.toFixed(0) }} %</div>
        </div>
        <div v-if="analytics.wb.quantity" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Остатки на всех складах
          </div>
          <div class="text-subtitle-2">{{ analytics.wb.quantity.toLocaleString('ru') }} шт</div>
        </div>
      </div>
      <div v-else>
        <div v-if="analytics.ozon.quOrders || analytics.ozon.sumOrders" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Заказы FBO
          </div>
          <div v-if="analytics.ozon.quOrders && analytics.ozon.sumOrders" class="text-subtitle-2">
            {{ analytics.ozon.quOrders.toLocaleString('ru') }} шт / {{ makeRub(analytics.ozon.sumOrders) }}
          </div>
          <div v-if="analytics.ozon.quOrders && analytics.ozon.sumOrders == null" class="text-subtitle-2">
            {{ analytics.ozon.quOrders.toLocaleString('ru') }} шт
          </div>
          <div v-if="analytics.ozon.quOrders == null && analytics.ozon.sumOrders" class="text-subtitle-2">
            {{ makeRub(analytics.ozon.sumOrders) }}
          </div>
        </div>
        <div v-if="analytics.ozon.quOrdersFBS || analytics.ozon.sumOrdersFBS" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Заказы FBS
          </div>
          <div v-if="analytics.ozon.quOrdersFBS && analytics.ozon.sumOrdersFBS" class="text-subtitle-2">
            {{ analytics.ozon.quOrdersFBS.toLocaleString('ru') }} шт / {{ makeRub(analytics.ozon.sumOrdersFBS) }}
          </div>
          <div v-if="analytics.ozon.quOrdersFBS && analytics.ozon.sumOrdersFBS == null" class="text-subtitle-2">
            {{ analytics.ozon.quOrdersFBS.toLocaleString('ru') }} шт
          </div>
          <div v-if="analytics.ozon.quOrdersFBS == null && analytics.ozon.sumOrdersFBS" class="text-subtitle-2">
            {{ makeRub(analytics.ozon.sumOrdersFBS) }}
          </div>
        </div>
        <div v-if="analytics.ozon.quSales || analytics.ozon.sumSales" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Выкуплено
          </div>
          <div v-if="analytics.ozon.quSales && analytics.ozon.sumSales" class="text-subtitle-2">
            {{ analytics.ozon.quSales.toLocaleString('ru') }} шт / {{ makeRub(analytics.ozon.sumSales) }}
          </div>
          <div v-if="analytics.ozon.quSales && analytics.ozon.sumSales == null" class="text-subtitle-2">
            {{ analytics.ozon.quSales.toLocaleString('ru') }} шт
          </div>
          <div v-if="analytics.ozon.quSales == null && analytics.ozon.sumSales" class="text-subtitle-2">
            {{ makeRub(analytics.ozon.sumSales) }}
          </div>
        </div>
        <div v-if="analytics.ozon.pr_ransom" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Процент выкупа
          </div>
          <div class="text-subtitle-2">{{ analytics.ozon.pr_ransom.toFixed(0) }} %</div>
        </div>
        <div v-if="analytics.ozon.quantity" class="d-flex justify-space-between mb-2">
          <div
            class="d-flex align-center text-uppercase font--graphite3"
            style="font-size: 0.625rem !important; letter-spacing: 0.1em"
          >
            Остатки на всех складах
          </div>
          <div class="text-subtitle-2">{{ analytics.ozon.quantity.toLocaleString('ru') }} шт</div>
        </div>
      </div>
    </div>
    <v-card-actions class="pt-4 pb-16">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn-multiline" @click="setTab()"
        ><span class="text-wrap">Перейти к аналитике</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'WidgetAnalytics',
  components: {},
  mixins: [makeRub],
  computed: {
    ...mapState('products', ['analytics']),
    ...mapGetters('common', ['isMobile']),
  },
  methods: {
    ...mapMutations('common', ['setVerticalTab', 'setMobileMainTab']),
    setTab() {
      this.isMobile ? this.setMobileMainTab('tab-0') : this.setVerticalTab(5);
    },
  },
};
</script>
<style scoped>
.btn-multiline >>> span {
  width: 100%;
  font-size: 12px;
}
</style>
