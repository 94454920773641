<template>
  <v-card height="100%" class="pa-4">
    <v-carousel
      hide-delimiters
      show-arrows-on-hover
      next-icon="mdi-chevron-right"
      prev-icon="mdi-chevron-left"
      :cycle="true"
      height="auto"
    >
      <v-carousel-item v-for="manager of managers" :key="manager.id">
        <div class="text-subtitle-2 font--black1">Маркет менеджер</div>
        <div class="pt-6 pb-4 d-flex justify-center">
          <v-avatar size="120" color="gray">
            <img :src="manager.src" alt="Name" />
          </v-avatar>
        </div>
        <!-- <div class="text-subtitle-2 pt-0 text-center font--black1">{{ manager.name }}</div> -->
        <div class="pb-2 text-caption text-center font--graphite4">сертифицированный специалист</div>
        <div class="d-flex flex-row justify-space-between pt-0 pb-6">
          <LogoMarket :type="'ozon'" :height="12" />
          <LogoMarket :type="'yandex'" :width="84" />
          <LogoMarket :type="'wb'" />
        </div>
        <v-card-text class="pa-0">
          <div>
            <div
              v-if="
                manager.name === 'Шушкова Елизавета' ||
                manager.name === 'Каменская Анастасия' ||
                manager.name === 'Айтуганов Артем' ||
                manager.name === 'Кузина Анастасия'
              "
            >
              <span class="d-flex mb-3 services-text">
                <svg width="18" height="18" class="mr-2">
                  <use xlink:href="#call"></use>
                </svg>
                +7 (909) 644-25-60
              </span>

              <a
                class="d-flex pb-4 text-subtitle-2 font-weight-regular font--lh14 services-text"
                target="_blank"
                :href="'https://www.acebot.ru/wa?79311050718'"
                ><svg width="18" height="18" class="mr-2">
                  <use xlink:href="#whatsapp-circle "></use>
                </svg>
                Написать в WhatsApp
              </a>

              <a
                class="d-flex text-subtitle-2 font-weight-regular font--lh14 services-text"
                target="_blank"
                :href="manager.tgId"
              >
                <svg width="18" height="18" class="mr-2">
                  <use xlink:href="#tg-circle"></use>
                </svg>
                Написать в Telegram
              </a>
            </div>

            <div
              v-else-if="
                manager.name === 'Дмитриева Ксения' ||
                manager.name === 'Ноздрина Ирина' ||
                manager.name === 'Мамонтов Игорь'
              "
            >
              <span class="d-flex mb-3 services-text">
                <svg width="18" height="18" class="mr-2">
                  <use xlink:href="#call"></use>
                </svg>
                <span>+7 (909) 644-25-60</span>
              </span>

              <a
                class="d-flex pb-4 text-subtitle-2 font-weight-regular font--lh14 services-text"
                target="_blank"
                :href="'https://www.acebot.ru/wa?79311050721'"
              >
                <svg width="18" height="18" class="mr-2">
                  <use xlink:href="#whatsapp-circle "></use>
                </svg>
                Написать в WhatsApp
              </a>

              <a
                class="d-flex text-subtitle-2 font-weight-regular font--lh14 services-text"
                target="_blank"
                :href="manager.tgId"
              >
                <svg width="18" height="18" class="mr-2">
                  <use xlink:href="#tg-circle"></use>
                </svg>
                @modulbank
              </a>
            </div>

            <div v-else>
              <div>
                <span class="d-flex pb-3 services-text">
                  <svg width="18" height="18" class="mr-2">
                    <use xlink:href="#call"></use>
                  </svg>
                  {{ formatPhone(manager.phone) }}
                </span>
                <a class="d-flex services-text" target="_blank" :href="manager.tgId">
                  <svg width="18" height="18" class="mr-2">
                    <use xlink:href="#tg-circle"></use>
                  </svg>
                  @modulbank
                </a>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-carousel-item>
    </v-carousel>

    <v-card-actions v-if="!isMobile" class="pt-8">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn btn-multiline" :href="chat" target="_blank">
        <span class="text-wrap">Написать в чат банка </span></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import LogoMarket from '@/components/common/LogoMarket';
import { formatPhone } from '@/mixins/formatPhone';
import { mapGetters } from 'vuex';
import { CHAT_URL } from '@/constants.js';

export default {
  name: 'WidgetManagers',
  components: { LogoMarket },
  mixins: [formatPhone],

  data() {
    return {
      chat: CHAT_URL,
    };
  },

  computed: {
    ...mapGetters({ managers: 'products/getManagers' }),
    ...mapGetters('common', ['isMobile']),
  },
};
</script>

<style scoped>
.btn {
  font-size: 12px;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}

.btn-multiline >>> span {
  width: 100%;
}

.services-text {
  font-size: 13px !important;
}
</style>
