<template>
  <div>
    <v-card min-height="400px" class="d-flex flex-column pa-10 font align-center justify-center mt-6" elevation="0">
      <!-- TODO исправить if как поймём, что за данные -->
      <v-img src="img/analitic.png" max-width="198px" max-height="161px" class="mb-1"></v-img>
      <div class="text-h6 my-3 font--lh23 font--black1">Аналитика по магазинам</div>
      <v-card-text class="text-subtitle-1 mb-3 text-center font--graphite3 font--lh24" style="width: 500px">
        Для получения аналитики {{ marketName ? marketName : 'по вашему магазину ' }} необходимо ввести токен. Вы
        получите полную информацию о товарах, продажах, остатках и многом другом
      </v-card-text>
      <div>
        <v-btn class="text-caption font-weight-bold mt-10" color="primary" @click="addAnaliticToken"
          >Добавить магазин</v-btn
        >
      </div>
    </v-card>
    <SidePanel v-model="showAnaliticToken" :width="700">
      <CreateToken :options="{ componentName: 'analitic' }" />
    </SidePanel>
  </div>
</template>

<script>
import SidePanel from '@/components/common/SidePanel';
import CreateToken from '@/components/common/CreateToken';
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'AnaliticEmpty',

  components: { SidePanel, CreateToken },
  props: {
    marketName: {
      type: String,
    },
  },
  computed: {
    ...mapState('common', ['openAnaliticToken']),

    showAnaliticToken: {
      get() {
        return this.openAnaliticToken;
      },
      set(value) {
        this.showAnaliticSidePanel(value);
      },
    },
  },
  methods: {
    ...mapMutations('common', ['showAnaliticSidePanel']),
    addAnaliticToken() {
      this.showAnaliticSidePanel(true);
    },
  },
};
</script>

<style scoped></style>
