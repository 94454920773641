<template>
  <StockTable />
</template>

<script>
import StockTable from '@/components/analitic/wb/stock/StockTable';

export default {
  name: 'StockTab',
  components: { StockTable },
};
</script>
