import { serverWhite } from '../server-white';

async function wbProducts(data) {
  const res = await serverWhite.post('/admin/analytics/wb/products', data);
  return res;
}

async function ozonProducts(data) {
  const res = await serverWhite.post('/admin/analytics/ozon/products', data);
  return res;
}

async function getWbProductFiltersData() {
  return await serverWhite.post('/admin/analytics/wb/products_available', {});
}

async function getOzonProductFiltersData() {
  return await serverWhite.post('/admin/analytics/wb/products_available', {});
}

export { wbProducts, ozonProducts, getWbProductFiltersData, getOzonProductFiltersData };
