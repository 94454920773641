import { serverGate } from '../server-gate';

async function getCredit() {
  const res = await serverGate.get('mp/credit/get');
  return res;
}

async function searchCreditCompany(q) {
  const res = await serverGate.post('mp/credit/search', { q });
  return res;
}

async function searchCreditBank(q) {
  const res = await serverGate.post('mp/credit/bic', { q });
  return res;
}
async function addCredit(data) {
  const res = await serverGate.post('mp/credit/sadd', data);
  return res;
}
async function creditPay(data) {
  const res = await serverGate.post('mp/credit/pay', data);
  return res;
}
async function smsCode(data) {
  const res = await serverGate.post('mp/credit/accept', data);
  return res;
}

async function repeatSmsCode(data) {
  const res = await serverGate.post('mp/credit/sms', data);
  return res;
}

async function editSupplier(id) {
  const res = await serverGate.post('mp/credit/sget', id);
  return res;
}

async function updateSupplier(data) {
  const res = await serverGate.post('mp/credit/sedit', data);
  return res;
}

export {
  getCredit,
  searchCreditCompany,
  searchCreditBank,
  addCredit,
  editSupplier,
  updateSupplier,
  creditPay,
  smsCode,
  repeatSmsCode,
};
