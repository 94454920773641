<template>
  <div>
    <v-row class="d-flex flex-wrap">
      <v-col md="4" lg="3" sm="12">
        <CreditCardInfoMoney />
      </v-col>
      <v-col md="4" lg="6" sm="12"><CreditSuppliersList /> </v-col>

      <v-col md="4" lg="3" sm="12">
        <CreditInfo />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CreditInfo from '@/components/common/cards/CreditInfo';

import CreditSuppliersList from '@/components/credit/CreditSuppliersList';
import CreditCardInfoMoney from '@/components/credit/CreditCardInfoMoney';
import { mapActions } from 'vuex';

export default {
  name: 'CreditMain',

  components: {
    CreditInfo,
    CreditSuppliersList,
    CreditCardInfoMoney,
  },

  mounted() {
    this.getCredit();
  },

  methods: {
    ...mapActions('credit', ['getCredit']),
  },
};
</script>

<style scoped></style>
