<template>
  <div>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="9" :class="isMobile ? 'd-flex flex-column align-center mb-16 pb-10' : ''">
        <SpecialOffer v-if="!isMobile" />
        <ServicesMain />
      </v-col>

      <v-col v-if="!isMobile" xs="12" sm="12" md="12" lg="3">
        <v-row>
          <v-col cols="12" md="6" lg="12">
            <WidgetSupport />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import WidgetSupport from '@/components/products/WidgetSupport';
import SpecialOffer from '@/components/common/SpecialOffer';
import ServicesMain from '@/components/services/ServicesMain';
import { mapGetters } from 'vuex';
export default {
  name: 'Services',
  components: { WidgetSupport, SpecialOffer, ServicesMain },
  computed: {
    ...mapGetters('common', ['isMobile']),
  },
};
</script>
