let makeCurrency = (val, curr = '₽') => `${val} ${curr}`;

const patternEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i;

function formatPhone(phone) {
  const phoneDigits = phone.replace(/\D/g, '');
  if (phoneDigits.length !== 11) return phone;
  return phoneDigits.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
}

const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );

function setEnding(number, variants) {
  let num1 = number % 100;
  let num2 = number % 10;

  if (num1 >= 11 && num1 <= 14) {
    return variants[2];
  }

  switch (num2) {
    case 1:
      return variants[0];

    case 2:
    case 3:
    case 4:
      return variants[1];

    default:
      return variants[2];
  }
}

function validateAcc(acc, bic) {
  let valid = false;
  if (acc && acc.length === 20 && bic) {
    const factors = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
    const bicAcc = bic.slice(-3) + acc;
    const check = factors.reduce((sum, n, i) => sum + n * (bicAcc[i] % 10), 0);
    valid = check % 10 === 0;
  }
  return valid;
}

function makeDataForGraph(data, OPTION) {
  if (!data.length) return [];
  const copy = data.slice();

  const sortFn = (a, b) => (a[OPTION] <= b[OPTION] ? 1 : -1);
  const sortedData = copy.sort(sortFn);
  const graphTop = sortedData.slice(0, 10).map((x) => ({ name: x.name, [OPTION]: x[OPTION] }));

  if (copy.length > 10) {
    const otherObj = { name: 'Остальные', [OPTION]: 0 };
    const graphAll = sortedData.reduce((acc, current, i) => {
      if (i < 10) return acc;
      acc[OPTION] += current[OPTION];
      return acc;
    }, otherObj);
    graphTop.push(graphAll);
  }

  return graphTop;
}

function validateInn(inn) {
  let valid = false;
  if (inn.length === 10) {
    const sum = [2, 4, 10, 3, 5, 9, 4, 6, 8].reduce((res, n, i) => res + n * inn[i], 0);
    valid = parseInt((sum % 11) % 10) === parseInt(inn[9]);
  }
  if (inn.length === 12) {
    const sum11 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8].reduce((res, n, i) => res + n * inn[i], 0);
    const sum12 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8].reduce((res, n, i) => res + n * inn[i], 0);

    valid = parseInt((sum11 % 11) % 10) === parseInt(inn[10]) && parseInt((sum12 % 11) % 10) === parseInt(inn[11]);
  }
  return valid;
}

function validateOgrn(ogrn) {
  let valid = false;
  if (ogrn.length === 13) {
    const control = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
    valid = control === parseInt(ogrn[12]);
  }
  if (ogrn.length === 15) {
    const control = parseInt((parseInt(ogrn.slice(0, -1)) % 13).toString().slice(-1));
    valid = control === parseInt(ogrn[14]);
  }
  return valid;
}

function clearPhone(phone) {
  return phone.replace(/\D/g, '');
}

function clearLocalStorageExceptTNX() {
  const TNX = localStorage.getItem('tnx');
  localStorage.clear();
  if (TNX) localStorage.setItem('tnx', TNX);
}

export {
  makeCurrency,
  patternEmail,
  formatPhone,
  setEnding,
  validateAcc,
  makeDataForGraph,
  validateInn,
  validateOgrn,
  renameKeys,
  clearPhone,
  clearLocalStorageExceptTNX,
};
