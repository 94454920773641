<template>
  <div>
    <EmptyProductTokenPopup v-model="showProductTokenPopup" />

    <div class="d-flex flex-wrap justify-space-between mb-11 mt-6">
      <div class="mb-2">
        <modul-grid-filters
          ref="grid-filters"
          :module-name="`${productModule}`"
          :action="`${action}`"
          :columns="headers"
          :filters="true"
          :footer-props="footerProps"
          :fast-date="true"
        >
        </modul-grid-filters>
      </div>
      <v-text-field
        v-model="search"
        dense
        single-line
        hide-details
        height="44"
        class="mb-2"
        style="max-width: 400px"
        outlined
        placeholder="Введите номер задания"
        clearable
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>

    <v-data-table
      :mobile-breakpoint="100"
      class="assembly-table"
      :headers="headers"
      :items="grid"
      item-key="orderUID"
      :no-data-text="noDataText"
      fixed-header
      :items-per-page="50"
      :footer-props="footerProps"
      show-expand
      :server-items-length="total"
      :options.sync="options"
      @update:options="onUpdateOptions($event)"
      @item-expanded="loadDetails"
    >
      <template #[`item.orderUID`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : value }}</span>
      </template>

      <template #[`item.dt`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : formatDate(value) }}</span>
      </template>

      <template #[`item.quantity`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : `${value} шт` }}</span>
      </template>

      <template #[`item.summ`]="{ value }">
        <span class="text-no-wrap">{{ value === null ? '-' : makeRub(value, 2) }}</span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 0 !important">
          <AssemblyExpandTable :key="item.orderUID" :order-list="item.details" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import { TYPES } from '@/components/common/modul-grid/_utils/filters';
import { analiticMixin } from '@/mixins/analiticMixin';
import { createWbAssemblyTasksModule } from '@/store/modules/analitic/wb/orders/assemblyTasks';

import { format } from 'date-fns';
import { makeRub } from '@/mixins/makeRub';
import EmptyProductTokenPopup from '@/components/common/EmptyProductTokenPopup';
import AssemblyExpandTable from '@/components/analitic/wb/orders/assemblyTasks/AssemblyExpandTable';
import * as api from '@/api';
import { mapState } from 'vuex';

export default {
  name: 'AssemblyTasksTable',
  components: { AssemblyExpandTable, EmptyProductTokenPopup, ModulGridFilters },
  inject: ['filterMutations', 'pagingMutations'],
  mixins: [makeRub, analiticMixin],
  data() {
    return {
      action: 'wbAssemblyOrders/wbAssembly',
      productModule: 'wbAssemblyOrders',
      showProductTokenPopup: false,

      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        { text: 'Номер задания', value: 'orderUID', width: '126px', type: TYPES.EQ_STRING },
        { text: 'Создано', value: 'dt', width: '126px' },
        { text: 'Количество', value: 'quantity', width: '126px', type: TYPES.NUMBER },
        { text: 'Сумма заказа', value: 'summ', width: '126px', type: TYPES.NUMBER },
      ],
    };
  },

  computed: {
    ...mapState('wbAssemblyOrders', ['filtersData', 'productToken']),
  },

  watch: {
    productToken(e) {
      if (e) {
        this.showProductTokenPopup = true;
      }
    },
  },

  created() {
    if (!this.$store.hasModule(this.productModule)) {
      this.$store.registerModule(this.productModule, createWbAssemblyTasksModule());
    }
    this.debouncedSearch = debounce(this.getAssemblyOrders, 800);
  },

  methods: {
    getAssemblyOrders() {
      this.$store.dispatch(`${this.action}`);
    },

    formatDate(data) {
      return format(new Date(data), 'dd.MM.yyyy');
    },

    async loadDetails({ item }) {
      if (!Object.keys(item.details).length) {
        const res = await api.wbAssemblyDetails({ orderUID: item.orderUID });
        if (res) {
          item.details = res;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table.assembly-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.theme--light.v-data-table.assembly-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}
</style>
