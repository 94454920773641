<template>
  <v-card min-height="182px" class="font mx-auto pa-4" color="#F2F6FF">
    <v-list-item three-line class="pa-0">
      <v-list-item-content>
        <svg width="49" height="40">
          <use xlink:href="#token"></use>
        </svg>

        <v-list-item-title class="text-body-2 font-weight-medium mb-3"> Что такое кредит на закупку?</v-list-item-title>
        <div class="text-body-2 font--lh21">
          Вы можете взять деньги у банка, чтобы рассчитаться со своими поставщиками за товары. Введите данные
          поставщиков, и обратитесь к маркет-менеджеру за кредитом
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-btn
      class="text-caption font-weight-bold"
      width="100%"
      outlined
      color="primary"
      style="border: 2px solid"
      :href="'https://modulbank.ru/fs/files/market_seller.pdf'"
      target="_blank"
    >
      Поговорить с поддержкой
    </v-btn>
  </v-card>
</template>
<script>
export default {
  name: 'CreditInfo',
};
</script>
