var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-4",attrs:{"height":"100%"}},[_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows-on-hover":"","next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","cycle":true,"height":"auto"}},_vm._l((_vm.managers),function(manager){return _c('v-carousel-item',{key:manager.id},[_c('div',{staticClass:"text-subtitle-2 font--black1"},[_vm._v("Маркет менеджер")]),_c('div',{staticClass:"pt-6 pb-4 d-flex justify-center"},[_c('v-avatar',{attrs:{"size":"120","color":"gray"}},[_c('img',{attrs:{"src":manager.src,"alt":"Name"}})])],1),_c('div',{staticClass:"pb-2 text-caption text-center font--graphite4"},[_vm._v("сертифицированный специалист")]),_c('div',{staticClass:"d-flex flex-row justify-space-between pt-0 pb-6"},[_c('LogoMarket',{attrs:{"type":'ozon',"height":12}}),_c('LogoMarket',{attrs:{"type":'yandex',"width":84}}),_c('LogoMarket',{attrs:{"type":'wb'}})],1),_c('v-card-text',{staticClass:"pa-0"},[_c('div',[(
              manager.name === 'Шушкова Елизавета' ||
              manager.name === 'Каменская Анастасия' ||
              manager.name === 'Айтуганов Артем' ||
              manager.name === 'Кузина Анастасия'
            )?_c('div',[_c('span',{staticClass:"d-flex mb-3 services-text"},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#call"}})]),_vm._v(" +7 (909) 644-25-60 ")]),_c('a',{staticClass:"d-flex pb-4 text-subtitle-2 font-weight-regular font--lh14 services-text",attrs:{"target":"_blank","href":'https://www.acebot.ru/wa?79311050718'}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#whatsapp-circle "}})]),_vm._v(" Написать в WhatsApp ")]),_c('a',{staticClass:"d-flex text-subtitle-2 font-weight-regular font--lh14 services-text",attrs:{"target":"_blank","href":manager.tgId}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#tg-circle"}})]),_vm._v(" Написать в Telegram ")])]):(
              manager.name === 'Дмитриева Ксения' ||
              manager.name === 'Ноздрина Ирина' ||
              manager.name === 'Мамонтов Игорь'
            )?_c('div',[_c('span',{staticClass:"d-flex mb-3 services-text"},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#call"}})]),_c('span',[_vm._v("+7 (909) 644-25-60")])]),_c('a',{staticClass:"d-flex pb-4 text-subtitle-2 font-weight-regular font--lh14 services-text",attrs:{"target":"_blank","href":'https://www.acebot.ru/wa?79311050721'}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#whatsapp-circle "}})]),_vm._v(" Написать в WhatsApp ")]),_c('a',{staticClass:"d-flex text-subtitle-2 font-weight-regular font--lh14 services-text",attrs:{"target":"_blank","href":manager.tgId}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#tg-circle"}})]),_vm._v(" @modulbank ")])]):_c('div',[_c('div',[_c('span',{staticClass:"d-flex pb-3 services-text"},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#call"}})]),_vm._v(" "+_vm._s(_vm.formatPhone(manager.phone))+" ")]),_c('a',{staticClass:"d-flex services-text",attrs:{"target":"_blank","href":manager.tgId}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#tg-circle"}})]),_vm._v(" @modulbank ")])])])])])],1)}),1),(!_vm.isMobile)?_c('v-card-actions',{staticClass:"pt-8"},[_c('v-btn',{staticClass:"pa-0 btn btn-multiline",attrs:{"absolute":"","bottom":"","left":"","text":"","color":"primary","href":_vm.chat,"target":"_blank"}},[_c('span',{staticClass:"text-wrap"},[_vm._v("Написать в чат банка ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }