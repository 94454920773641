import { serverWhite } from '../server-white';

/**
 * Бренд
 * @param {string} search_query
 * @returns {Promise<BrandSearchResponse>}
 */

async function searchBrand(data) {
  let res = await serverWhite.post('/admin/mp/brand/get', data);
  return res;
}

/**
 * Список товаров
 * @param {Object} data
 * @param {string} data.page
 * @param {string} data.search_query
 * @param {string} data.pageSize
 * @param {Array} data.filter
 * @returns {Promise<BrandResponse>}
 */
async function getBrandSubTab(data) {
  const res = await serverWhite.post('/admin/mp/metrics/brand', data);
  return res;
}

async function getTopBrand(data) {
  const res = await serverWhite.post('/admin/mp/brand/main', data);
  return res;
}

export { searchBrand, getBrandSubTab, getTopBrand };
