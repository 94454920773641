<template>
  <div class="pt-1 d-flex justify-center">
    <v-simple-table ref="prev" v-resize="onResize" height="85vh" style="width: 96% !important" fixed-header>
      <thead v-if="!isMobile">
        <tr>
          <th class="text-left services_tr">Наименование</th>
          <th class="text-left services_tr">Цена</th>
          <th class="text-left services_tr">Количество</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td v-if="isMobile" colspan="3" class="py-4">
            <div>{{ item.name }}</div>

            <div class="d-flex justify-space-between">
              <span class="d-flex align-center font-weight-medium"> {{ makeRub(item.amount) }} </span>
              <span> <Counter class="counter justify-end" :max="item.max" @counter="addCount($event, item)" /> </span>
            </div>

            <div class="text-caption font--graphite3">
              {{ item.description }}
            </div>
          </td>
          <td v-if="!isMobile" style="padding-left: 48px !important">
            <div>{{ item.name }}</div>
            <div class="text-caption font--graphite3">{{ item.description }}</div>
          </td>

          <td v-if="!isMobile" style="width: 20%">
            <span class="font-weight-medium">{{ makeRub(item.amount) }}</span>
          </td>
          <td v-if="!isMobile" style="width: 30%">
            <Counter class="counter" :max="item.max" @counter="addCount($event, item)" />
          </td>
        </tr>
      </tbody>
      <tfoot
        v-if="checkedList.length"
        color="white"
        :style="{ width: tWidth + 'px' }"
        :class="isMobile ? 'mb-16 pb-6' : ''"
      >
        <v-col :class="isMobile ? '' : 'd-flex flex-sm-column flex-md-row justify-space-between align-center'">
          <div class="font--graphite3">
            Выбрано {{ selectItems }}:<span class="font-weight-medium font--black1"> сумма {{ makeRub(sum) }}</span>
          </div>
          <span>
            <span v-if="!isMobile" class="text-caption font--graphite3"
              >Нажимая «Заказать», вы соглашаетесь с
              <a href="https://partner.modulbank.ru/legal" target="_blank">Офертой</a></span
            >
            <v-btn
              elevation="0"
              color="primary"
              :class="isMobile ? 'mt-4' : 'ml-6'"
              :style="isMobile ? 'width: 100%' : ''"
              @click="payServices(checkedList)"
            >
              Заказать</v-btn
            >
          </span>
          <span v-if="isMobile" class="text-caption font--graphite3"
            >Нажимая «Заказать», вы соглашаетесь с
            <a href="https://partner.modulbank.ru/legal" target="_blank">Офертой</a></span
          >
        </v-col>
      </tfoot>
    </v-simple-table>
    <!-- <Snackbar :open.sync="snackbar" :text="'Создан платежный документ'" /> -->
  </div>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations, mapState } from 'vuex';
import Counter from '@/components/services/onetime/Counter';
// import Snackbar from '@/components/common/Snackbar';

import { setEnding } from '@/utils/helpers';
import { saveServices } from '@/api';

export default {
  name: 'OneTimeTable',
  components: {
    Counter,
    // Snackbar,
  },
  mixins: [makeRub],

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // snackbar: false,
      checkedList: [],
      tWidth: 0,
    };
  },

  computed: {
    ...mapGetters('services', ['clear']),
    ...mapState('services', ['oneTimeList']),
    ...mapGetters('common', ['isMobile']),

    sum() {
      return this.checkedList.reduce(
        (sum, currentValue) => sum + Number(currentValue.amount) * (currentValue.counter || 0),
        0
      );
    },

    selectItems() {
      return this.checkedList.length + ' ' + this.formatDays(this.checkedList.length, ['услуга', 'услуги', 'услуг']);
    },
  },

  mounted() {
    this.onResize();
  },

  methods: {
    ...mapMutations('services', ['resetTable']),

    addCount(e, itemRow) {
      if (e != 0) {
        if (this.checkedList.indexOf(itemRow) === -1) {
          this.checkedList.push(itemRow);
        }
      }
      if (e == 0) {
        const index = this.checkedList.indexOf(itemRow);
        if (index >= 0) {
          this.checkedList.splice(index, 1);
        }
      }
      this.checkedList.map((item) => {
        if (item.id == itemRow.id) {
          this.$set(item, 'counter', e);
        }
      });
      return this.checkedList;
    },

    async payServices(arr) {
      let dataArr = arr.map((item) => ({
        id: item.id,
        cnt: item.counter,
      }));
      let data = { onetime: dataArr };
      const ok = await saveServices(data);
      if (ok) {
        // this.snackbar = true;
        this.resetTable(true);
        this.checkedList = [];
        this.$store.dispatch('services/getOneTime');
      }
    },

    // для окончания в "услугах"
    formatDays(value, variants) {
      return setEnding(value, variants);
    },

    // для расчета размера футера
    onResize() {
      this.tWidth = this.$refs.prev.$el.clientWidth;
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  color: #222733;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #777c87;
}
.services_tr {
  height: 37px !important;
  background-color: #f5f5f6 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  padding-left: 16px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

.v-data-table > .v-data-table__wrapper > table > tfoot {
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 0px -1px 2px rgba(36, 48, 54, 0.02), 0px -4px 16px rgba(36, 48, 54, 0.12);
}
</style>
