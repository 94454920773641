import { serverWhite } from './server-white';

/**
 * @typedef {Object} Company
 * @property {string} inn
 * @property {string} dt_create
 * @property {string} ogrn
 * @property {string} okopf
 * @property {string} name
 * @property {string} address
 */

/**
 * @typedef {Object} CompanySearchResponse
 * @property {string} status - 'ok', 'err', 'redirect'
 * @property {Company[]} company - array of companies (fields: inn, dt_create, ogrn, okopf, name, address)
 */

/**
 * @param {string} q search query
 * @returns {Promise<CompanySearchResponse>}
 */
async function searchCompany(query) {
  let res = await serverWhite.post('/landing/app/company/search', { query });
  return res;
}

export { searchCompany };
