import { TYPES } from '@/components/common/modul-grid/_utils/filters';

export const GRID_HEADERS_SUB_TABLE = [
  {
    text: 'Название',
    value: 'name',
    type: TYPES.STRING,
  },
  {
    text: 'Товаров',
    value: 'products',
    type: TYPES.NUMBER,
  },
  {
    text: 'Товаров со скидкой',
    value: 'productsPromo',
    type: TYPES.NUMBER,
  },
  {
    text: 'Комментарии',
    value: 'comments',
    type: TYPES.NUMBER,
    name: 'Количество отзывов',
  },
  {
    text: 'Рейтинг',
    value: 'rating',
    type: TYPES.NUMBER,
  },
  // в старых float

  {
    text: 'Выручка,\xa0₽',
    value: 'income',
    width: '100px',
    type: TYPES.NUMBER,
    name: 'Доход',
  },
  {
    text: 'Продажи',
    value: 'sales',
    type: TYPES.NUMBER,
  },
  {
    text: 'Ср.Цена,\xa0₽',
    value: 'priceAvg',
    subType: true,
  },
  {
    text: 'Cр.Выручка,\xa0₽',
    value: 'incomeAvg',
    subType: true,
  },
  {
    text: 'Кол-во продавцов',
    value: 'sellers',
    subType: true,
    categoryType: true,
  },
  {
    text: 'Кол-во брендов',
    value: 'brands',
    subType: true,
    categoryType: true,
  },
];

export const GRID_HEADERS_SUB_PRODUCT_LIST = [
  {
    text: 'Наименование',
    value: 'name',
    type: TYPES.STRING,
  },
  {
    text: 'Фото',
    value: 'imagePreview',
    sortable: false,
  },
  { text: 'SKU', value: 'extid', type: TYPES.STRING },
  { text: 'Выручка,\xa0₽', value: 'income', type: TYPES.NUMBER },
  {
    text: 'Среднедневная выручка,\xa0₽',
    value: 'avgDailyIncome',
    type: TYPES.NUMBER,
    hideCountry: true,
  },
  {
    text: 'Потенциал выручки,\xa0₽',
    value: 'potenc',
    type: TYPES.NUMBER,
    description: 'Рассчитывается по формуле: (Выручка / Кол-во дней в наличии) * Дней в отчете.',
    hideCountry: true,
  },
  { text: 'Страна', value: 'country', type: TYPES.STRING, hideCountry: true },
  { text: 'Бренд', value: 'brand', type: TYPES.STRING },
  { text: 'Продавец', value: 'seller', type: TYPES.STRING },
  {
    text: 'Наличие,\xa0шт.',
    value: 'quantity',
    type: TYPES.NUMBER,
    description: 'Остаток на складе на последнюю дату анализируемого периода',
  },
  {
    text: 'Дата обнаружения',
    value: 'dadd',
    type: TYPES.DATE,
    description: 'В этот день ваша карточка впервые отобразилась на сайте маркетплейса',
    hideCountry: true,
  },
  {
    text: 'Отзывы,\xa0шт.',
    value: 'comments',
    type: TYPES.NUMBER,
    description: 'Количество отзывов на товар',
  },
  {
    text: 'Рейтинг',
    value: 'rating',
    type: TYPES.NUMBER,
    description: 'Оценка товара на маркетплейсе',
  },
  { text: 'Цена товара до скидки,\xa0₽', value: 'price', type: TYPES.NUMBER },
  {
    text: 'Скидка,\xa0%',
    value: 'discPrc',
    type: TYPES.NUMBER,
    description: 'Размер скидки на последнюю дату анализируемого периода',
    hideCountry: true,
  },
  { text: 'Цена со скидкой,\xa0₽', value: 'priceWithSale', type: TYPES.NUMBER },
  {
    text: 'Средняя цена,\xa0₽',
    value: 'avgPrice',
    type: TYPES.NUMBER,
    description: 'Среднее арифметическое цен товаров из выборки',
    hideCountry: true,
  },
  { text: 'Min цена за период,\xa0₽', value: 'minPrice', type: TYPES.NUMBER, hideCountry: true },
  { text: 'Max цена за период,\xa0₽', value: 'maxPrice', type: TYPES.NUMBER, hideCountry: true },
  {
    text: 'Упущенная выручка,\xa0₽',
    value: 'incomeLost',
    type: TYPES.NUMBER,
    description: 'Выручка, которую можно получить, если покрыть клиентский спрос',
  },
  { text: 'Был в наличии,\xa0дн.', value: 'cntDayInStock', type: TYPES.NUMBER, hideCountry: true },
  {
    text: 'Дней с продажами,\xa0дн',
    value: 'days_with_sales',
    type: TYPES.NUMBER,
    hideCountry: true,
  },
  { text: 'Продажи,\xa0шт.', value: 'sales', type: TYPES.NUMBER },
  {
    text: 'Продаж в день,\xa0шт.',
    value: 'avgSales',
    type: TYPES.NUMBER,
    description: 'Среднее количество продаж',
    hideCountry: true,
  },
  {
    text: 'Среднее при наличии,\xa0шт.',
    value: 'avg_available',
    type: TYPES.NUMBER,
    description: 'Среднее количество продаж в день, при наличии товара на конец дня',
    hideCountry: true,
  },
  {
    text: 'Оборачиваемость,\xa0дн',
    value: 'obor_days',
    type: TYPES.NUMBER,
    description:
      'Столько дней необходимо для продажи среднего товарного остатка — чем ниже этот показатель, тем быстрее окупаются вложения в товар',
    hideCountry: true,
  },
  { text: 'График продаж', value: 'salesSchedule', sortable: false, width: '200px' },
];
