<template>
  <div ref="containter">
    <v-autocomplete
      v-model="company"
      no-filter
      class="mb-2 company-search"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :items="searchResult"
      :loading="isLoading"
      :search-input.sync="query"
      clearable
      hide-details
      item-text="name"
      item-value="ogrn"
      return-object
      outlined
      prepend-inner-icon="mdi-magnify"
      :filter="filterCompany"
      :menu-props="{ maxWidth: '592px' }"
      dense
    >
      <template v-slot:no-data>
        <div v-if="!query || query.length < 2" class="nodata">
          <p class="text-center">Введите минимум два символа для поиска</p>
        </div>
        <div v-else class="nodata">
          <slot name="company-not-found">
            <p class="text-center">Мы старались, но не нашли вашу организацию ☹️</p>
            <!-- <div class="text-center"> -->
            <!-- <v-btn v-if="!forPartnerForm" color="primary" outlined @click.stop="$emit('create-company')"
                >я зарегистрировался недавно</v-btn
              >
              <v-btn v-else color="primary" outlined @click.stop="$emit('create-company')">ввести вручную</v-btn> -->
            <!-- </div> -->
          </slot>
        </div>
      </template>

      <template v-slot:item="{ item }">
        <div class="search-result mt-2">
          <h4 class="font font--h4 mb-2 font-weight-medium font--black1">{{ item.name }}</h4>
          <p class="font font--p2 font--graphite3">
            ИНН: {{ item.inn }}, ОГРН: {{ item.ogrn }}
            <br />
            <template v-if="!forPartnerForm">
              Адрес: {{ item.address }}
              <br />
            </template>
            <template v-if="item.dt_create"> Дата регистрации: {{ item.dt_create }} </template>
          </p>
        </div>
      </template>

      <template v-if="!forPartnerForm" v-slot:append-item>
        <div v-if="hasFilteredResults" class="nodata">
          <div class="text-center">
            <v-btn color="primary" outlined @click.stop="$emit('create-company')">Нет вашей организации?</v-btn>
          </div>
        </div>
      </template>

      <template v-if="forPartnerForm" v-slot:append-outer>
        <slot name="append-outer"></slot>
      </template>
    </v-autocomplete>

    <div v-if="company && !hideDetails" class="choosen pa-4">
      <p class="font font--p">
        <span v-if="company.isNewCompany" class="font-weight-medium">{{ company.name }}</span>
        <span v-if="company.inn">
          <span class="font-weight-medium">ИНН: {{ company.inn }}</span>
        </span>

        <span v-if="company.ogrn">
          <span class="font-weight-medium">ОГРН: {{ company.ogrn }} </span>
        </span>
        <span v-if="company.address">
          <span class="font-weight-medium"> Адрес: {{ company.address }} </span>
        </span>
        <span v-if="company.dt_create">
          <span class="font-weight-medium"> Дата регистрации: {{ company.dt_create }} </span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { searchCompany } from '@/api/search';

export default {
  props: {
    label: String,
    placeholder: String,
    rules: Array,
    value: Object,
    hideDetails: {
      type: Boolean,
      default: false,
    },
    forPartnerForm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    query: null,
    searchResult: [],
  }),

  computed: {
    company: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    hasFilteredResults() {
      return (
        this.searchResult.filter((item) => {
          const query = this.query || '';

          return this.filterCompany(item, query);
        }).length > 0
      );
    },

    isLoading() {
      return !!this.$store.state.common.loading;
    },
  },

  watch: {
    query(current) {
      // TODO: обдумать когда не надо искать
      if (!current || current.length < 2 || (this.company && current === this.company.name)) return;
      this.debouncedSearch(this.query);
    },
    value(current) {
      if (current) {
        this.searchResult = [current];
      }
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchCompany, 500);
  },

  methods: {
    filterCompany(item, queryText) {
      const q = queryText.toLowerCase();
      return (
        item.name.toLowerCase().includes(q) || item.inn.toLowerCase().includes(q) || item.ogrn.toLowerCase().includes(q)
      );
    },

    async searchCompany(query) {
      try {
        const response = await searchCompany(query);
        if (response) {
          if (response.company && Array.isArray(response.company)) {
            this.searchResult = response.company.sort((a, b) => {
              const dateA = new Date(a.dt_create) ?? null;
              if (dateA === null) return 1;

              const dateB = new Date(b.dt_create) ?? null;
              if (dateB === null) return -1;

              return dateA > dateB ? -1 : 1;
            });
          } else {
            this.searchResult = [];
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    clear() {
      this.company = null;
      this.searchResult = [];
      this.query = null;
    },
  },
};
</script>

<style lang="scss">
.nodata {
  padding: 16px;
}
.choosen {
  background-color: #f5f5f6;
}

.company-search > .v-input__append-outer {
  margin-left: 0 !important;
}
</style>
