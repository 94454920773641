<template>
  <v-card min-height="400px" class="d-flex flex-column pa-10 font align-center justify-center">
    <!-- TODO исправить if как поймём, что за данные -->
    <v-img src="img/marketplays.png" max-width="198px" max-height="161px"></v-img>
    <!-- <v-img src="img/setThree.png" max-width="198px" max-height="161px"></v-img> -->
    <div class="text-h6 my-3 font--lh23">Этот сервис еще в разработке</div>
    <div class="text-body-2 mb-3">Мы можем отправить вам уведомление когда сервис будет готов</div>
    <div>
      <v-btn class="text-caption font-weight-bold" color="primary">Прислать уведомление</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'GreenLimitEmpty',

  computed: {},
};
</script>

<style scoped></style>
