var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6"},[_c('v-simple-table',{staticClass:"query_tag__table",attrs:{"height":"240px","fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"min-width":"360px"}},[_vm._v("Запрос")]),_c('th',{staticClass:"text-left"},[_vm._v("Частота")]),_c('th',{staticClass:"text-left"},[_vm._v("Индекс")]),_c('th',{staticClass:"text-left"},[_vm._v("Есть")]),_c('th',{staticClass:"text-left"},[_vm._v("Бренд")]),_c('th',{staticClass:"text-left"},[_vm._v("Название")]),_c('th',{staticClass:"text-left"},[_vm._v("Цвет")]),_c('th',{staticClass:"text-left"},[_vm._v("Состав")]),_c('th',{staticClass:"text-left"},[_vm._v("Описание")]),_c('th',{staticClass:"text-left"},[_vm._v("Параметры")]),_c('th',{staticClass:"text-left"},[_vm._v("Комплектация")])])]),_c('tbody',[(!_vm.list || !_vm.list.length)?_c('td',{staticClass:"text-center pt-16 font--graphite3",attrs:{"colspan":"8"}},[_vm._v(" Не указаны ключевые запросы для поиска ")]):_vm._e(),_vm._l((_vm.list),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.tag) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.tag !== null ? item.tag : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.frequency) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.frequency !== null ? item.frequency : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.index) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.index !== null ? item.index : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.thereIs) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.thereIs !== null ? item.thereIs : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.brand) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.brand !== null ? item.brand : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.name) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.name !== null ? item.name : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.color) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.color !== null ? item.color : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.composit) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.composit !== null ? item.composit : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.descr) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.descr !== null ? item.descr : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.params) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.params !== null ? item.params : '-')+" ")]),_c('td',{staticClass:"font--lh21",style:(item.index !== null
                ? { 'background-color': _vm.itemAllClass(item.contents) }
                : { 'background-color': _vm.itemIndexClass(item.index) })},[_vm._v(" "+_vm._s(item.contents !== null ? item.contents : '-')+" ")])])})],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }