<template>
  <v-card class="d-flex justify-space-between flex-column px-6 py-8" min-height="600" elevation="0">
    <div class="d-flex justify-space-between">
      <div class="text-h6 font-weight-regular pa-0 font--lh24" style="width: 50%">Получить выплату</div>
      <a class="d-flex" @click.stop="showInfoPopup = true"
        ><svg width="18" height="18" class="icon mr-2">
          <use xlink:href="#help"></use></svg
        ><span class="text-uppercase text-caption font-weight-medium font--lh16">Как это работает</span></a
      >
    </div>
    <div class="text-center text-subtitle-1 font--lh24 mt-10">Доступная сумма к выдаче</div>
    <v-sheet color="#EEFAD4" elevation="0" height="92" width="100%" class="d-flex align-center mt-4">
      <v-card-text class="text-center text-h4 font-weight-bold font--lh45 limit__font">{{
        makeRub(limit || 0, 0)
      }}</v-card-text></v-sheet
    >

    <v-form ref="form" v-model="valid">
      <v-card-text class="text-caption font-weight-medium font--graphite3 font--lh14 pa-0 mt-8 mb-1"
        >Сумма к получению</v-card-text
      >
      <div class="d-flex">
        <v-text-field
          v-model="sum"
          height="44"
          dense
          append-icon="mdi-currency-rub"
          single-line
          outlined
          :rules="[rules.required('Введите сумму'), rules.number, maxLimit]"
          class="text-subtitle-2 rules__message"
          placeholder="0,00"
        >
        </v-text-field>
        <v-btn color="primary" large class="ml-5" :disabled="!valid" @click="momentPay">Получить выплату</v-btn>
      </div>
    </v-form>
    <v-row v-if="debt || debtCom || debtExComt" class="mt-12">
      <v-col
        v-if="debt !== undefined && debt !== null"
        cols="4"
        class="d-flex flex-column justify-space-between align-start"
        ><span class="text-caption font--graphite3 font--lh16">Текущий долг:</span>
        <p class="mt-2">
          <span class="text-h6 font--lh24">{{ makeRub(debt, 0) }}</span>
        </p></v-col
      >
      <v-col
        v-if="debtCom !== undefined && debtCom !== null"
        cols="4"
        class="d-flex flex-column justify-space-between align-start"
        ><span class="text-caption font--graphite3 font--lh16">Текущий долг по комиссии:</span>
        <p class="mt-2">
          <span class="text-h6 font--lh24">{{ makeRub(debtCom, 0) }}</span>
        </p></v-col
      >
      <v-col
        v-if="debtExComt !== undefined && debtExComt !== null"
        cols="4"
        class="d-flex flex-column justify-space-between align-start"
        ><span class="text-caption font--graphite3 font--lh16">Текущий долг по просроченным платежам:</span>
        <p class="mt-2">
          <span class="text-h6 font--lh24">{{ makeRub(debtExComt, 0) }}</span>
        </p></v-col
      >
    </v-row>
    <v-responsive width="100%" height="100"></v-responsive>
    <InfoMomentPopup v-model="showInfoPopup" />
  </v-card>
</template>

<script>
import InfoMomentPopup from '@/components/moment/InfoMomentPopup';
import { makeRub } from '@/mixins/makeRub';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { InfoMomentPopup },
  mixins: [makeRub],

  data: () => ({
    showInfoPopup: false,
    connection: false,
    valid: '',
    sum: '',
    rules: {
      required: (msg) => (v) => !!v || msg,
      number: (v) => /^\d+$/.test(v.replace(/\s/g, '')) || 'Только цифры',
    },
  }),

  computed: {
    ...mapGetters('moment', ['limit', 'debt', 'debtCom', 'debtExComt']),
    maxLimit() {
      return (v) => v <= this.limit || 'Запрошенная сумма больше лимита';
    },
  },

  methods: {
    ...mapActions('moment', ['addMomentSum']),
    async momentPay() {
      let res = await this.addMomentSum({
        sum: this.sum,
      });
      if (res) {
        this.sum = '';
        this.$refs.form.resetValidation();
        this.$store.dispatch('common/addAlert', { msg: 'Платеж проведен успешно', type: 'success' });
      }
    },
  },
};
</script>

<style lang="scss">
.rules__message.error--text {
  background-color: #ffe9e9 !important;
}

.rules__message.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}

.rules__message .v-text-field__details {
  position: absolute;
  left: 99%;
  top: 18px;
  padding-bottom: 20px !important;
}

.rules__message .v-messages__message {
  position: relative;
  background: #e36868;
  color: white;
  line-height: 40px;
  padding: 0;
  border-radius: 5px;
  text-align: center;
  width: 241px;
  height: 40px;
  z-index: 10;
}

.rules__message .v-messages__message:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #e36868;
  border-width: 8px 8px 8px 0;
  top: 50%;
  left: -7px;
  margin-top: -8px;
}

.v-icon.v-icon {
  font-size: 16px;
}
</style>
