<template>
  <!-- <div> -->
  <ProductTable />
  <!-- <EmptyProductTokenPopup v-model="showProductTokenPopup" /> -->
  <!-- </div> -->
</template>

<script>
import ProductTable from '@/components/analitic/wb/products/ProductTable';
// import EmptyProductTokenPopup from '@/components/common/EmptyProductTokenPopup';

export default {
  name: 'ProductTab',
  components: { ProductTable },
  // data: () => ({
  //   showProductTokenPopup: false,
  // }),
  // computed: {
  //   ...mapState('wbAnaliticProducts', ['productToken']),
  // },
  // watch: {
  //   productToken(e) {
  //     if (e) {
  //       this.showProductTokenPopup = true;
  //     }
  //   },
  // },
};
</script>
