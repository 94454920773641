<template>
  <v-card class="pa-6 elevation-0">
    <div class="font-weight-medium font--fs20 mb-5">Оставить заявку</div>
    <div class="alert-app pa-4">Наш менеджер поможет оформить все перечисленные документы</div>

    <v-form ref="form" v-model="valid">
      <v-card-text class="text-caption font-weight-medium font--graphite3 font--lh14 pa-0 mt-6 mb-1"
        >Наименование товара и его назначение</v-card-text
      >
      <v-textarea
        v-model="brifGoodName"
        rows="3"
        placeholder=""
        :rules="[rules.required('Введите наименование товара и его назначение')]"
        outlined
      ></v-textarea>

      <v-card-text class="text-caption font-weight-medium font--graphite3 font--lh14 pa-0 mb-1"
        >Наименование продукции</v-card-text
      >
      <v-text-field
        v-model="brifBuyGoodName"
        dense
        :rules="[rules.required('Введите наименование продукции')]"
        outlined
      ></v-text-field>

      <v-card-text class="text-caption font-weight-medium font--graphite3 font--lh14 pa-0 mb-1"
        >Код ТН ВЭД
        <v-tooltip right max-width="251" color="#464C59">
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small v-bind="attrs" class="cursor-pointer" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <div class="font--fs12 font--lh17">ТНВЭД – Товарная номенклатура внешнеэкономической деятельности</div>
        </v-tooltip>
      </v-card-text>
      <v-text-field v-model="brifTnved" type="number" class="inpuCod" dense outlined></v-text-field>

      <v-card-text class="text-caption font-weight-medium font--graphite3 font--lh14 pa-0 mb-1"
        >Из какого материала изготовлен товар</v-card-text
      >
      <v-textarea
        v-model="brifGoodMaterial"
        rows="3"
        :rules="[rules.required('Введите из какого материала изготовлен товар')]"
        single-line
        outlined
      ></v-textarea>

      <v-card-text class="text-caption font-weight-medium font--graphite3 font--lh14 pa-0 mb-1"
        >Краткое описание продукции или сслыка на товар</v-card-text
      >
      <v-textarea
        v-model="brifShortDescLink"
        rows="3"
        :rules="[rules.required('Введите краткое описание продукции или сслыку на товар')]"
        single-line
        outlined
      ></v-textarea>

      <v-btn class="mt-2" color="primary" :disabled="!valid" @click="saveForm">оставить заявку</v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'CertificateForm',

  data() {
    return {
      valid: false,

      rules: {
        required: (msg) => (v) => !!v || msg,
      },
    };
  },

  computed: {
    ...mapState('certificate', [
      'brif_good_name',
      'brif_buy_good_name',
      'brif_tnved',
      'brif_good_material',
      'brif_short_desc_link',
    ]),

    brifGoodName: {
      get() {
        return this.brif_good_name;
      },
      set(value) {
        this.set({ type: 'brif_good_name', value });
      },
    },
    brifBuyGoodName: {
      get() {
        return this.brif_buy_good_name;
      },
      set(value) {
        this.set({ type: 'brif_buy_good_name', value });
      },
    },
    brifTnved: {
      get() {
        return this.brif_tnved;
      },
      set(value) {
        this.set({ type: 'brif_tnved', value });
      },
    },
    brifGoodMaterial: {
      get() {
        return this.brif_good_material;
      },
      set(value) {
        this.set({ type: 'brif_good_material', value });
      },
    },
    brifShortDescLink: {
      get() {
        return this.brif_short_desc_link;
      },
      set(value) {
        this.set({ type: 'brif_short_desc_link', value });
      },
    },
  },
  methods: {
    ...mapMutations('certificate', ['set']),
    ...mapActions('certificate', ['createCertificate']),

    saveForm() {
      this.createCertificate();
    },
  },
};
</script>

<style scoped>
.alert-app {
  background: #d4f296;
  border-radius: 3px;
}
.inpuCod >>> input[type='number'] {
  -moz-appearance: textfield;
}
.inpuCod >>> input::-webkit-outer-spin-button,
.inpuCod >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
