<template>
  <div>
    <v-form @submit.prevent>
      <div style="margin-bottom: 24px">
        Код для входа отправлен на номер {{ maskedPhone }}.
        {{ currentDelay > 0 ? 'Повторно запросить через ' + currentDelay + 'c.' : '' }}
      </div>
      <v-text-field
        v-model="code"
        v-mask="'####'"
        background-color="#ffffff"
        outlined
        autofocus
        label="Код из смс"
        type="text"
      ></v-text-field>
    </v-form>
    <v-btn :disabled="currentDelay > 0" x-large block color="primary" @click="refresh">выслать код повторно</v-btn>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'FBRLoginConfirm',

  directives: { mask },
  props: {
    maskedPhone: {
      type: String,
      required: true,
    },
    resendDelay: {
      type: Number,
      default: 180,
    },
  },
  data() {
    return {
      currentDelay: this.resendDelay,
      timer: null,
      code: '',
    };
  },
  watch: {
    code(code, oldCode) {
      if (code.length === 4 && oldCode.length < 4) {
        this.confirm();
      }
    },

    currentDelay(time) {
      if (time === 0) {
        this.stopTimer();
      }
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.code);
    },
    refresh() {
      this.$emit('refresh');
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.currentDelay--;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>
