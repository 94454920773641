<template>
  <v-card height="100%" class="pa-4">
    <div class="d-flex justify-space-between align-start ma-0">
      <div class="text-subtitle-2 font--black1">Маркет.Студия</div>
      <v-img src="img/studio.png" max-height="46" max-width="38" style="opacity: 0.4"></v-img>
    </div>
    <v-img src="img/QR_studio.jpeg" max-height="72" max-width="72" class="mx-auto mb-2"></v-img>

    <div class="text-subtitle-2 font-weight-regular font--graphite3 text-center font--lh20 pb-5">
      Бесплатный фото и видеоконтент для ваших товаров, Rich-контент, дизайн магазина и логотипов
    </div>
    <v-card-actions class="pt-4">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn-multiline"
        ><span class="text-wrap"
          ><a class="text-decoration-none" target="_blank" :href="'https://t.me/market_studio'"
            >Заказать съемку</a
          ></span
        ></v-btn
      ></v-card-actions
    >
  </v-card>
</template>

<script>
export default {
  name: 'WidgetStudio',
};
</script>

<style scoped>
.btn-multiline >>> span {
  width: 100%;
  font-size: 12px;
}
</style>
