<template>
  <v-row class="align-center font mt-1">
    <v-col v-if="analitic" cols="12" class="d-flex">
      <v-row dense class="py-6">
        <v-col v-if="analitic.sales && analitic.sales != null" cols="12" xs="12" sm="6" md="3" lg="3">
          <SalesInfo />
        </v-col>
        <v-col v-if="analitic.finance && analitic.finance != null" cols="12" xs="12" sm="6" md="3" lg="3">
          <FinanceInfo />
        </v-col>
        <v-col v-if="analitic.costs && analitic.costs != null" cols="12" xs="12" sm="6" md="3" lg="3">
          <CostsInfo />
        </v-col>
        <v-col v-if="analitic.stock" cols="12" xs="12" sm="6" md="3" lg="3">
          <StockInfo />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import SalesInfo from '@/components/analitic/wb/info/cards/SalesInfo';
import FinanceInfo from '@/components/analitic/wb/info/cards/FinanceInfo';
import CostsInfo from '@/components/analitic/wb/info/cards/CostsInfo';
import StockInfo from '@/components/analitic/wb/info/cards/StockInfo';

export default {
  name: 'InfoCards',
  components: { SalesInfo, FinanceInfo, CostsInfo, StockInfo },
  computed: {
    ...mapState('wbAnaliticInfo', ['analitic']),
  },
};
</script>

<style lang="scss" scoped></style>
