<template>
  <v-card height="100%" class="pa-4">
    <div class="d-flex justify-space-between align-start ma-0">
      <div class="text-subtitle-2 font--black1">Маркет.Институт</div>
      <v-img src="img/inst.png" max-height="46" max-width="38"></v-img>
    </div>
    <v-img src="img/QR_inst.jpeg" max-height="72" max-width="72" class="mx-auto mb-2"></v-img>

    <div class="text-subtitle-2 font-weight-regular font--graphite3 text-center font--lh20 pb-5">
      Научим работать с площадками: от регистрации до реальной поставки товара и первой выручки
    </div>
    <v-card-actions class="pt-4">
      <v-btn absolute bottom left text color="primary" class="pa-0 btn-multiline"
        ><span class="text-wrap"
          ><a class="text-decoration-none" target="_blank" :href="'https://t.me/modulbank_marketplace'"
            >Записаться на обучение</a
          ></span
        >
      </v-btn>
    </v-card-actions>

    <!-- <v-card-actions class="justify-center"
      ><v-btn class="pa-0 widget__btn" absolute bottom outlined></v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  name: 'WidgetInstitute',
};
</script>

<style scoped>
.btn-multiline >>> span {
  width: 100%;
  font-size: 12px;
}
</style>
