import * as api from '@/api';
import { createResetMutation } from '@/store/helpers/reset';
import { formatISO, subDays } from 'date-fns';

const date = new Date();
const today = formatISO(date, { representation: 'date' });
const thirtyDaysBefore = formatISO(subDays(date, 29), { representation: 'date' });

const initState = () => ({
  isLoaded: false,
  analitic: {},
  sdate: thirtyDaysBefore,
  edate: today,
});

export const wbAnaliticInfo = {
  namespaced: true,
  state: initState,
  getters: {
    sdate: (state) => state.sdate,
    edate: (state) => state.edate,
    sumQuantity: (state) =>
      state.analitic.stock.map((item) => item.fullQuantity).reduce((prev, curr) => prev + curr, 0),
  },
  mutations: {
    set: (state, payload) => (state[payload.type] = payload.value),
    setAnalitic: (state, value) => (state.analitic = value),
    setStartDate: (state, sdate) => (state.sdate = sdate),
    setEndDate: (state, edate) => (state.edate = edate),
    setLoaded: (state) => {
      state.isLoaded = true;
    },
    // метдод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    async wbAnalitic({ commit, getters }) {
      let data = {
        sdate: getters.sdate,
        edate: getters.edate,
        // marketplace: 'wb',
      };
      const res = await api.wbAnalitic(data);

      if (res) {
        // TODO Проследить что приходит
        commit('setAnalitic', res);
        commit('setLoaded');
      }
    },
  },
};
