<template>
  <v-row class="d-flex font">
    <v-col cols="6" md="6" sm="12">
      <v-card min-height="182px" class="d-flex flex-column pa-6">
        <span class="text-h4 font-weight-bold font--lh37 mb-1">{{ makeRub(0) }}</span>
        <p class="text-caption font--lh24 font--graphite3 mb-1">Доступно для кредитования по всем поставщикам</p>
        <p class="text-caption font--lh24 font--graphite3">
          Добавьте первого поставщика для рассчёта стартовой суммы лимита.
        </p>
        <div>
          <v-btn class="text-caption font-weight-bold" color="primary" @click="showCreditSidePanelSupplier(true)"
            >Добавить поставщика</v-btn
          >
        </div>
        <div class="pic">
          <svg width="90" height="78">
            <use xlink:href="#purse"></use>
          </svg>
        </div>
      </v-card>
    </v-col>
    <v-col cols="6" md="6" sm="12">
      <CreditInfo />
    </v-col>
  </v-row>
</template>
<script>
import { mapMutations } from 'vuex';
import { makeRub } from '@/mixins/makeRub';
import CreditInfo from '@/components/common/cards/CreditInfo';

export default {
  name: 'CreditEmpty',

  components: {
    CreditInfo,
  },
  mixins: [makeRub],
  data() {
    return {};
  },

  methods: {
    ...mapMutations('credit', ['showCreditSidePanelSupplier']),
  },
};
</script>

<style scoped>
.pic {
  float: right; /* Обтекание картинки текстом */
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
