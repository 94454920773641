import { ADD_TAB, REMOVE_TAB, SET_TAB } from '@/store/modules/metric/ozon/mutations/tabs';
import { createResetMutation } from '@/store/helpers/reset';

export function createTabsModule({ state } = {}) {
  const initState = () => state;

  return {
    namespaced: true,
    state: initState,
    getters: {
      tabs: ({ tabs }) => tabs,
      tabsCount: ({ tabs }) => tabs.length,
      tab: ({ tab }) => tab,
      hasTab:
        ({ tabs }) =>
        (id) =>
          tabs.some((tab) => tab.id === id),
    },
    mutations: {
      [ADD_TAB]: (state, tab) => state.tabs.push(tab),
      [REMOVE_TAB]: (state, id) => {
        const index = state.tabs.findIndex((tab) => tab.id === id);
        if (index !== -1) {
          state.tabs.splice(index, 1);
        }
      },
      [SET_TAB]: (state, index) => (state.tab = index),
      reset: createResetMutation(initState),
    },
    actions: {
      addTab({ getters: { tabsCount }, commit }, tab) {
        const count = tabsCount;
        commit(ADD_TAB, tab);
        commit(SET_TAB, count);
      },
    },
  };
}
