<template>
  <div v-if="card">
    <v-data-table
      class="productlist-table"
      :headers="headers"
      :items="card"
      item-key="id"
      :footer-props="footerProps"
      :no-data-text="noDataText"
      sort-by="date"
      :sort-desc="true"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ headers }">
        <v-tooltip v-if="h.description" :key="h.value" top>
          <template v-slot:activator="{ on }">
            <span v-on="on"
              >{{ h.text }}
              <v-icon small color="success">{{ h.icon }}</v-icon>
            </span>
          </template>
          <span>{{ h.description }}</span>
        </v-tooltip>
        <div v-else :key="h.value">{{ h.text }}</div>
      </template>

      <template #[`item.date`]="{ value }">
        <span>{{ new Date(value).toLocaleDateString('ru-RU') }}</span>
      </template>

      <template #[`item.income`]="{ value }">
        <span>{{ value === null ? '-' : makeRub(value) }}</span>
      </template>

      <template #[`item.price`]="{ value }">
        <span>{{ value === null ? '-' : makeRub(value) }}</span>
      </template>

      <template #[`item.sales`]="{ value }">
        <span> {{ value === null ? '-' : value }}</span>
      </template>

      <template #[`item.quantity`]="{ value }">
        <span> {{ value === null ? '-' : value }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';

export default {
  name: 'ProductGraphTable',
  components: {},
  mixins: [makeRub],
  props: {
    product_card: Object, // eslint-disable-line
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: '',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',
        itemsPerPageOptions: [10, 15, 20, 50],
      },
      noDataText: 'Список товаров пуст',
      headers: [
        {
          text: 'Дата',
          value: 'date',
        },
        {
          text: 'Выручка',
          value: 'income',
        },
        {
          text: 'Цена',
          value: 'price',
        },
        {
          text: 'Продажи',
          value: 'sales',
        },
        {
          text: 'Остаток',
          value: 'quantity',
        },
      ],
    };
  },
  computed: {
    card() {
      return this.product_card && this.product_card.sales;
    },
  },
};
</script>

<style lang="scss" scoped></style>
