<template>
  <v-app>
    <!-- так не попадем по ссылке -->
    <!-- https://localhost:8080/?tnx=43762E5E8BADD1505F9BE104CF788114 -->
    <v-main v-if="isLogged">
      <template>
        <MetricVerticalTabs v-if="metric" />
        <MainTabsMobileTabs v-else-if="isMobile" />
        <MainVerticalTabs v-else />
      </template>
    </v-main>

    <v-main v-else>
      <svg class="icon mt-8 ml-8" width="156" height="32">
        <use xlink:href="#logo-product"></use>
      </svg>
      <v-container class="mt-16">
        <FBRRegistration v-if="registration" @registration="setRegist" />
        <FBRLogin v-else @registration="setRegist" />
      </v-container>
    </v-main>

    <Alert />
    <Loading />
    <SvgSprite></SvgSprite>
  </v-app>
</template>

<script>
import FBRRegistration from '@/components/registration/FBRRegistration.vue';
import MetricVerticalTabs from '@/components/MetricVerticalTabs.vue';
import MainTabsMobileTabs from '@/components/MainTabsMobileTabs.vue';
import MainVerticalTabs from '@/components/MainVerticalTabs.vue';
import SvgSprite from '@/components/images/SvgSprite.vue';
import FBRLogin from '@/components/login/FBRLogin.vue';
import Loading from '@/components/common/Loading.vue';
import Alert from '@/components/common/Alert';

// import { hubConnection, mainGetHidden } from '@/api/';

import { today, thirtyDaysBefore } from '@/mixins/filterDate';
import { mapGetters, mapMutations } from 'vuex';
import { getProductSKU } from '@/api/metric/category';
import { IS_METRIC } from '@/constants.js';
import { extensionLogIn } from '@/utils/chrome.js';

export default {
  name: 'App',

  components: {
    MetricVerticalTabs,
    MainTabsMobileTabs,
    MainVerticalTabs,
    FBRRegistration,
    SvgSprite,
    FBRLogin,
    Loading,
    Alert,
  },

  mixins: [today, thirtyDaysBefore],

  data: () => ({
    component: 'MainVerticalTabs',
    metric: IS_METRIC,
    registration: false,
  }),

  computed: {
    ...mapGetters('common', ['isMobile', 'isLogged']),
  },

  mounted() {
    this.handleRoute();
  },

  // removed signalR
  methods: {
    ...mapMutations('wbProductCard', ['setProduct']),
    ...mapMutations('common', ['showCardWbModal']),

    setRegist(e) {
      this.registration = e;
    },

    handleRoute() {
      let url = new URL(location.href);
      const extension = url.searchParams.has('extension');
      const sku = url.searchParams.has('openSku') ? url.searchParams.get('openSku') : false;

      if (extension) {
        this.handleRouteExtension();
      }

      if (sku) {
        this.handleRouteProduct(sku);
      }
    },

    handleRouteProduct(sku) {
      if (this.isLogged) {
        this.searchSKU(sku);
      } else {
        const currentDomain = window.location.origin; // Получаем текущий домен
        window.location.href = currentDomain + '/';
      }
    },

    async searchSKU(query) {
      const res = await getProductSKU({
        sku: query,
        sdate: thirtyDaysBefore,
        edate: today,
        marketplace: 'wb',
      });

      if (res?.name || res?.extid) {
        this.setProduct(res);
        this.showCardWbModal(true);
      }
    },

    handleRouteExtension() {
      if (this.isLogged) {
        extensionLogIn();
        this.$store.dispatch('common/addAlert', { msg: 'Отправка данных в расширение', type: 'success' });
      } else {
        localStorage.setItem('extension', 'Y');
      }

      const newURL = window.location.origin + '/';
      history.pushState(null, '', newURL); // Изменяем URL без перезагрузки страницы
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/main';

.theme--light.v-application {
  background-color: #f5f5f6;
}
</style>
