<template>
  <div class="py-6 px-8">
    <v-form ref="form" v-model="valid">
      <v-row dense align="center" class="mb-3">
        <v-col cols="12" md="2" class="font font--graphite3"
          >Фамилия <span v-if="!second" class="profile-is-not-filled ml-sm-2"></span
        ></v-col>
        <v-col cols="12" md="4">
          <MaskedTextField
            v-model="secondName"
            single-line
            :rules="[rule.required('Пожалуйста представьтесь')]"
            label="Фамилия"
          />
        </v-col>
      </v-row>

      <v-row class="mb-3" dense align="center">
        <v-col cols="12" md="2" class="font font--graphite3"
          >Имя <span v-if="!first" class="profile-is-not-filled ml-sm-2"></span
        ></v-col>
        <v-col cols="12" md="4">
          <MaskedTextField
            v-model="firstName"
            single-line
            :rules="[rule.required('Пожалуйста представьтесь')]"
            label="Имя"
          />
        </v-col>
      </v-row>

      <v-row class="mb-3" dense align="center">
        <v-col cols="12" md="2" class="font font--graphite3"
          >Отчество <span v-if="!middle" class="profile-is-not-filled ml-sm-2"></span
        ></v-col>
        <v-col cols="12" md="4">
          <MaskedTextField
            v-model="middleName"
            single-line
            :rules="[rule.required('Пожалуйста представьтесь')]"
            label="Отчество"
          />
        </v-col>
      </v-row>
      <v-row class="mb-3" dense align="center">
        <v-col cols="12" md="2" class="font font--graphite3"
          >Компания/ОГРН <span v-if="!middle" class="profile-is-not-filled ml-sm-2"></span
        ></v-col>
        <v-col cols="12" md="4">
          <CompanySearch
            v-model="company"
            :placeholder="`Введите наименование, ИНН или ОГРН`"
            :rules="[rule.required('')]"
            :for-partner-form="true"
            hide-details
          >
            <template #append-outer>
              <v-tooltip top>
                <span>Поиск</span>
              </v-tooltip>
            </template>
          </CompanySearch>
        </v-col>
      </v-row>

      <!-- </v-form>

    <v-form ref="newPhoneForm" v-model="newPhoneValid" @submit.prevent> -->
      <v-row class="mb-3" dense align="center">
        <v-col cols="12" md="2" class="font font--graphite3">Телефон</v-col>
        <v-col cols="12" md="4">
          <!-- <v-text-field
            v-show="showChangePhoneForm"
            hide-details
            dense
            label="Новый номер"
            single-line
            :error="errdesc.length > 0"
            :error-messages="errdesc"
            outlined
            autofocus
            required
            :rules="[rule.required('Введите номер телефона'), rule.phone]"
            v-mask="'+7(9##) ###-##-##'"
            v-model="newphone"
            name="phone"
            type="text"
            @input="clearErrdesc"
          ></v-text-field>
          <v-text-field
            v-show="showConfirmCodeForm"
            dense
            hide-details
            :error="errdesc.length > 0"
            :error-messages="errdesc"
            single-line
            required
            outlined
            name="code"
            label="Код из смс"
            v-model="code"
            v-mask="'####'"
            type="text"
          ></v-text-field> -->
          <!-- <div v-show="!showConfirmCodeForm && !showChangePhoneForm">
            <a @click.prevent="openNewPhone" href="#" class="font">{{ formatPhone(phone) }}</a>
          </div> -->
          <MaskedTextField class="mt-n2" :value="formatPhone(phone)" single-line readonly />
          <!-- <span class="font font--black1"> {{ formatPhone(phone) }}</span> -->
        </v-col>
      </v-row>

      <!-- <v-row class="mb-3" dense align="center" v-if="showChangePhoneForm">
        <v-col offset="0" offset-sm="3" cols="12" sm="9">
          <v-text-field
            hide-details
            :rules="[rule.required('Введите пароль')]"
            dense
            label="Текущий пароль"
            single-line
            required
            outlined
            v-model="pass"
            prepend-inner-icon="mdi-lock-outline"
            :type="show ? 'text' : 'password'"
          >
            <template #append>
              <v-icon @click="show = !show" tabindex="-1" v-if="show">mdi-eye</v-icon>
              <v-icon @click="show = !show" tabindex="-1" v-else>mdi-eye-off</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row> -->

      <!-- <v-row class="mb-3" dense align="center" v-if="showChangePhoneForm">
        <v-col offset="0" offset-sm="3" cols="12">
          <v-btn class="mr-2" :disabled="!newPhoneValid" color="primary" @click="send">Изменить</v-btn>
          <v-btn text @click="resetPhone">Отмена</v-btn>
        </v-col>
      </v-row> -->
      <v-row v-if="isFieldChanged" class="mb-3">
        <v-col cols="12" md="2" class="font font--graphite3"></v-col>
        <v-col cols="12" md="4">
          <v-btn class="mr-2" :disabled="!valid" color="primary" @click="updateForm"> Изменить </v-btn>
          <v-btn text @click="resetForm">Отмена</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- <v-form>
      <v-row class="mb-3" v-if="showPass" dense align="center">
        <v-col offset="0" offset-sm="3" cols="12">
          <v-btn :disabled="!valid || newpass !== confirm" color="primary" class="mr-2" @click="sendChangePass"
            >Изменить</v-btn
          >
          <v-btn @click="resetPass" text>Отмена</v-btn>
        </v-col>
      </v-row>
    </v-form> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import MaskedTextField from '@/components/common/MaskedTextField';
import { formatPhone } from '@/mixins/formatPhone';
import CompanySearch from '@/components/common/CompanySearch';

export default {
  components: { MaskedTextField, CompanySearch },
  directives: { mask },
  mixins: [formatPhone],

  data() {
    return {
      valid: false,
      newPhoneValid: false,
      middleName: '',
      secondName: '',
      firstName: '',
      company: null,
      ogrn: '',
      inn: '',
      name: '',
      rule: {
        required: (msg) => (v) => !!v || msg,
        phone: (v) => v.replace(/\D/g, '').length === 11 || 'Введите номер телефона',
      },
    };
  },

  computed: {
    isFirstNameChanged() {
      return this.first !== this.firstName;
    },
    isSecondNameChanged() {
      return this.second !== this.secondName;
    },
    isMiddleNameChanged() {
      return this.middle !== this.middleName;
    },
    isCompanyChanged() {
      return this.companySearch !== this.company;
    },
    isFieldChanged() {
      return this.isFirstNameChanged || this.isSecondNameChanged || this.isMiddleNameChanged || this.isCompanyChanged;
    },
    first: {
      get() {
        return this.$store.state.profile.firstname;
      },
      set(value) {
        this.$store.commit('profile/set', { type: 'firstname', value });
      },
    },
    second: {
      get() {
        return this.$store.state.profile.surname;
      },
      set(value) {
        this.$store.commit('profile/set', { type: 'surname', value });
      },
    },
    middle: {
      get() {
        return this.$store.state.profile.middlename;
      },
      set(value) {
        this.$store.commit('profile/set', { type: 'middlename', value });
      },
    },
    companySearch: {
      get() {
        return this.$store.state.profile.company;
      },
      set(value) {
        this.$store.commit('profile/set', { type: 'company', value });
      },
    },

    phone() {
      return this.$store.state.profile.phone;
    },
  },

  watch: {
    first(v) {
      this.firstName = v;
    },
    second(v) {
      this.secondName = v;
    },
    middle(v) {
      this.middleName = v;
    },
    companySearch(v) {
      this.company = v;
    },
  },

  mounted() {
    this.get();
    this.firstName = this.first;
    this.secondName = this.second;
    this.middleName = this.middle;
    this.company = this.companySearch;
  },

  methods: {
    ...mapActions('profile', ['get']),
    async updateForm() {
      const data = {};
      if (this.isFirstNameChanged) {
        data.firstName = this.firstName;
      }
      if (this.isSecondNameChanged) {
        data.lastName = this.secondName;
      }
      if (this.isMiddleNameChanged) {
        data.patronymic = this.middleName;
      }
      if (this.isCompanyChanged) {
        data.name = this.company.name;
        data.inn = this.company.inn;
        data.ogrn = this.company.ogrn;
      }

      await this.$store.dispatch('profile/updateProfile', data);
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.middleName = this.middle;
      this.secondName = this.second;
      this.firstName = this.first;
      this.company = this.companySearch;
    },
  },
};
</script>
