<template>
  <v-row class="mx-7 my-3 d-flex">
    <v-col cols="7">
      <div class="text-h6 font--lh21 mb-6">Добавить токены</div>
      <v-form ref="form" v-model="valid">
        <v-select
          v-model="type"
          label="Выберите маркетплейс"
          :items="typeItems"
          item-value="value"
          item-text="text"
          clearable
          outlined
          dense
          :rules="[rules.required('Выберите маркетплейс')]"
        />
        <v-text-field
          v-if="type != 'yandex'"
          v-model="key"
          dense
          :rules="[rules.required('Введите токен')]"
          single-line
          hide-details
          outlined
          class="mb-6"
          placeholder="Токен"
        ></v-text-field>
        <v-text-field
          v-if="type == 'ozon'"
          v-model="clientid"
          dense
          :rules="[rules.required('Введите клиент id')]"
          single-line
          hide-details
          outlined
          class="mb-6"
          placeholder="Клиент id"
        ></v-text-field>
        <template v-if="type == 'yandex'">
          <v-text-field
            v-model="compId"
            dense
            :rules="[rules.required('Введите id компании')]"
            single-line
            hide-details
            outlined
            class="mb-6"
            placeholder="Id компании"
          ></v-text-field>
          <v-btn
            color="primary"
            :disabled="valid"
            href="https://oauth.yandex.ru/authorize?response_type=code&client_id=c154143d83f544e3b45f0f53210a8dea"
            target="_blank"
            class="mb-6"
            >Получить код подтверждения</v-btn
          >
          <v-text-field
            v-model="ya_code"
            dense
            :rules="[rules.required('Введите код подтверждения')]"
            single-line
            hide-details
            outlined
            class="mb-6"
            placeholder="Код подтверждения"
          ></v-text-field>
        </template>
        <v-btn color="primary" :disabled="!valid" @click="addToken">Добавить и подключить</v-btn>
      </v-form>
    </v-col>

    <v-col cols="5">
      <TokenInfo v-if="!type" />
      <div v-if="type == 'yandex'">
        <YandexTokenInfo />
      </div>
      <div v-if="type == 'ozon'">
        <OzonTokenInfo />
      </div>
      <div v-if="type == 'wb'">
        <WbTokenInfo />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import TokenInfo from '@/components/common/cards/TokenInfo.vue';
import YandexTokenInfo from '@/components/common/cards/YandexTokenInfo.vue';
import OzonTokenInfo from '@/components/common/cards/OzonTokenInfo.vue';
import WbTokenInfo from '@/components/common/cards/WbTokenInfo.vue';

import { mapMutations } from 'vuex';
import { addOption, addProduct } from '@/api';

export default {
  name: 'CreateToken',
  components: {
    TokenInfo,
    YandexTokenInfo,
    OzonTokenInfo,
    WbTokenInfo,
  },

  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      key: null,
      clientid: null,
      type: null,
      compId: null,
      ya_code: null,
      rules: {
        required: (msg) => (v) => !!v || msg,
      },
      valid: false,
      typeItems: [
        {
          text: 'Ozon',
          value: 'ozon',
        },
        {
          text: 'Wildberries',
          value: 'wb',
        },
        {
          text: 'Яндекс маркет',
          value: 'yandex',
        },
      ],
    };
  },
  methods: {
    ...mapMutations('common', ['showOptionSidePanel', 'showProductSidePanel']),
    clearFormOption() {
      this.key = null;
      this.clientid = null;
      this.type = null;
      this.compId = null;
      this.ya_code = null;
    },

    async addToken() {
      let data = {
        key: this.key,
        type: this.type,
      };
      if (this.type == 'ozon') {
        data = {
          key: this.key,
          type: this.type,
          clientid: this.clientid,
        };
      }
      if (this.type == 'yandex') {
        data = {
          compId: this.compId,
          ya_code: this.ya_code,
          type: this.type,
        };
      }

      if (this.options.componentName == 'options') {
        const ok = await addOption(data);
        if (ok) {
          this.$store.dispatch('options/getOptionList');
          this.$store.commit('common/showOptionSidePanel', false);
          this.clearFormOption();
        }
      }

      if (this.options.componentName == 'products') {
        const ok = await addProduct(data);
        if (ok) {
          this.clearFormOption();
          this.$store.commit('common/showProductSidePanel', false);
          this.$store.dispatch('products/mainGet');
        }
      }

      if (this.options.componentName == 'analitic') {
        const ok = await addOption(data);
        if (ok) {
          this.$store.commit('common/showAnaliticSidePanel', false);
          this.$store.dispatch('analitic/getAnalitic');
          this.$store.dispatch('options/getOptionList');
          this.clearFormOption();
        }
      }
    },
  },
};
</script>

<style scoped></style>
