<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="value"
    :no-data-text="nodataText"
    dense
    :items="tags"
    :loading="isLoading"
    :search-input.sync="search"
    hide-details
    :item-text="itemText"
    label="Введите ключевой запрос"
    outlined
    single-line
    return-object
    no-filter
    class="tag-search"
    @input="$emit('input', $event)"
  >
    <template v-slot:item="{ item }">
      <v-btn color="#f5f5f500" @click="$emit('query', item)">
        <div class="mr-2">{{ item.text }}</div>
        <v-icon color="success" size="20">mdi-arrow-up-bold</v-icon>
        <div>{{ item.frequency }}</div>
      </v-btn>
    </template>
    <template v-slot:prepend-inner="">
      <svg width="20" height="20" fill="#717D83" class="mr-1 mt-1">
        <use xlink:href="#search"></use>
      </svg>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce';
import { searchTag } from '@/api/metric/tag';

export default {
  name: 'TagSearch',

  data: () => ({
    search: null,
    tags: [],
    value: '',
  }),

  computed: {
    isLoading() {
      return !!this.$store.state.common.loading;
    },

    nodataText() {
      return this.search && this.search.length >= 3
        ? 'Не указаны ключевые запросы для анализа'
        : 'Введите минимум 3 символа для поиска';
    },
  },

  watch: {
    search(current) {
      if (!current || current.length < 3 || (!!this.value && this.itemText(this.value) === current)) return;
      this.debouncedSearch(this.search);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.tagSearch, 500);
  },

  methods: {
    itemText(item) {
      return item.text !== undefined || item.text !== null ? item.text : '';
    },

    async tagSearch(query) {
      const res = await searchTag({ search_query: query, marketplace: 'wb' });
      // если пришел массив
      if (res.length) {
        this.tags = res;
      }
    },
  },
};
</script>

<style lang="scss">
.tag-search .v-label {
  top: 12px !important;
}

.tag-search .v-input__slot {
  height: 44px;
}
</style>
