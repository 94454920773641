import { serverWhite, serverWhiteHidden } from '../server-white';

/**
 * Список товаров
 * @param {Object} data
 * @param {string} data.page
 * @param {string} data.search_query
 * @param {string} data.pageSize
 * @param {Array} data.filter
 * @returns {Promise<ProductsResponse>}
 */
async function getProductList(data) {
  const res = await serverWhite.post('/admin/mp/product/list_test', data);
  return res;
}
// hidden
async function getExportListHidden(data) {
  const res = await serverWhiteHidden.post('/admin/mp/export/list', data);
  return res;
}
async function getProduct(data) {
  const res = await serverWhite.post('/admin/mp/product/get', data);
  return res;
}
async function getCategorySubTab(data) {
  const res = await serverWhite.post('/admin/mp/metrics/catalog', data);
  return res;
}

async function getProductSKU(data) {
  const res = await serverWhite.post('/admin/mp/product/get', data);
  return res;
}

async function getExportCategories(data) {
  const res = await serverWhite.post('/admin/mp/export/categories', data);
  return res;
}
async function getExportTags(data) {
  const res = await serverWhite.post('/admin/mp/export/tags', data);
  return res;
}

async function checkKeywords(data) {
  const res = await serverWhite.post('/admin/mp/product/seo', data);
  return res;
}

async function getExportSEO(data) {
  const res = await serverWhite.post('/admin/mp/export/tested_tags', data);
  return res;
}

export {
  getProductList,
  getProduct,
  getCategorySubTab,
  getProductSKU,
  getExportListHidden,
  getExportCategories,
  getExportTags,
  checkKeywords,
  getExportSEO,
};
