<template>
  <div class="font my-10 d-flex justify-space-around">
    <div style="width: 50%">
      <div class="graph text-body-2 font-weight-medium">По выручке</div>
      <pie-chart class="graph__pie" :chart-data="incomePercent" :options="options"></pie-chart>
    </div>

    <div style="width: 50%">
      <div class="graph text-body-2 font-weight-medium">По продажам</div>
      <pie-chart class="graph__pie" :chart-data="salesPercent" :options="options"></pie-chart>
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/common/graph/PieChart.vue';

export default {
  name: 'IncomeSalesGraph',
  components: {
    PieChart,
  },
  props: {
    graphIncome: {
      type: Array,
      default: () => [],
    },
    graphSales: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    incomePercent() {
      const labels = this.graphIncome.map((res) => res.name);
      const sumall = this.graphIncome.map((res) => res.income).reduce((prev, curr) => prev + curr, 0);
      const data = this.graphIncome.map((res) => ((res.income / sumall) * 100).toFixed() || 0);
      return this.makeDataSet(labels, data);
    },
    salesPercent() {
      const labels = this.graphSales.map((res) => res.name);
      const sumall = this.graphSales.map((res) => res.sales).reduce((prev, curr) => prev + curr, 0);
      const data = this.graphSales.map((res) => ((res.sales / sumall) * 100).toFixed() || 0);
      return this.makeDataSet(labels, data);
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'right',
          labels: {
            fontSize: 10,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              let currentValue = dataset.data[tooltipItem.index];
              return ' ' + currentValue + '%';
            },
          },
        },
      };
    },
  },
  methods: {
    makeDataSet(labels, data) {
      return {
        labels: labels,
        datasets: [
          {
            label: 'Data One',
            data: data,
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
              'rgb(116, 82, 171)',
              'rgb(84, 135, 157)',
              'rgb(146, 201, 97)',
              'rgb(206, 17, 141)',
              'rgb(141, 137, 197)',
              'rgb(250, 98, 30)',
              'rgb(204, 153, 67)',
            ],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.graph {
  &__pie {
    height: 230px;
  }
}
</style>
