<template>
  <div>
    <v-data-table
      :mobile-breakpoint="100"
      :headers="headersFiltered"
      :items="grid"
      :server-items-length="total"
      :footer-props="footerProps"
      :no-data-text="noDataText"
      height="70vh"
      fixed-header
      class="productlist-table"
      @update:options="onUpdateOptions($event)"
    >
      <template v-for="(header, index) in headersFiltered" v-slot:[`header.${header.value}`]="{ headersFiltered }">
        <Headers :key="header.value + index" :header="header" />
      </template>

      <template #top>
        <div class="d-flex flex-wrap align-center mb-3">
          <v-text-field
            v-model="search"
            dense
            single-line
            hide-details
            class="mr-2"
            style="max-width: 300px; border-color: #cbcdd1"
            outlined
            placeholder="Введите название товара или SKU"
            clearable
          >
            <template v-slot:prepend-inner="">
              <svg width="20" height="20" fill="#717D83" class="mr-1 mt-1">
                <use xlink:href="#search"></use>
              </svg>
            </template>
          </v-text-field>

          <modul-grid-filters
            ref="grid-filters"
            :module-name="`${productModule}`"
            :action="`${action}`"
            :columns="headers"
            :filters="true"
            :footer-props="footerProps"
            :fast-date="true"
            :height="44"
          >
          </modul-grid-filters>

          <v-spacer></v-spacer>
          <SalesTypeToogle @change="changeType($event)" />
          <v-btn width="99" height="44" class="ml-2 fb-btn" outlined @click="exportFile()">
            <v-progress-circular v-if="fileLoading" indeterminate color="primary"></v-progress-circular>
            <div v-else>EXCEL</div>
          </v-btn>
        </div>
      </template>

      <template #[`item.name`]="{ value, item }">
        <span>
          <a style="cursor: pointer" @click="openCard({ pid: item.id, item, dateFilter })">{{ value }}</a>
        </span>
      </template>

      <template #[`item.imagePreview`]="{ value, item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-img width="50" :src="value" style="cursor: pointer" v-bind="attrs" v-on="on"></v-img>
          </template>
          <span><img style="height: 40vh" :src="item.imageZoom" /></span>
        </v-tooltip>
      </template>

      <template #[`item.extid`]="{ value, item }">
        <span class="d-flex align-center">
          <v-icon class="mr-2">mdi-source-branch</v-icon>
          <a target="_blank" :href="`${item.extlink}`" class="text-decoration-none">{{ value }}</a>
        </span>
      </template>

      <template #[`item.brand`]="{ value, item }">
        <a style="cursor: pointer" @click="onBrandClick(item)">{{ value }}</a>
      </template>
      <template #[`item.country`]="{ value, item }">
        <a style="cursor: pointer" @click="onCountryClick(item)">{{ value }}</a>
      </template>

      <template #[`item.seller`]="{ value, item }">
        <a style="cursor: pointer" @click="onSellerClick(item)">{{ value }}</a>
      </template>

      <template #[`item.comments`]="{ value }">
        <span class="text-no-wrap">{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.quantity`]="{ value }">
        <span class="text-no-wrap">{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.sales`]="{ value }">
        <span class="text-no-wrap">{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.price`]="{ value }">
        <span class="text-no-wrap">{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.priceWithSale`]="{ value }">
        <span class="text-no-wrap">{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.income`]="{ value }">
        <span class="text-no-wrap">{{ numberFormat(value) }}</span>
      </template>

      <template #[`item.incomeLost`]="{ value }">
        <div style="min-width: 100px" class="text-no-wrap">{{ numberFormat(value) }}</div>
      </template>

      <template #[`item.salesSchedule`]="{ value }">
        <SalesGraph :value="value" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ModulGridFilters from '@/components/common/modul-grid/modul-grid-filters';
import SalesGraph from '@/components/common/metric/SalesGraph.vue';
import SalesTypeToogle from '@/components/common/SalesTypeToogle';
import Headers from '@/components/common/Headers.vue';

import { createCategoryModule } from '@/store/modules/metric/wb/features/category';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import { GRID_HEADERS_SUB_PRODUCT_LIST } from './constants';
import { numberFormat } from '@/mixins/numberFormat';
import { INIT_SALES_TYPE } from '@/constants.js';
import { sortMixin } from '@/mixins/sortMixin';
import debounce from 'lodash/debounce';

export default {
  name: 'SubProductList',

  components: {
    Headers,
    SalesGraph,
    SalesTypeToogle,
    ModulGridFilters,
  },

  mixins: [numberFormat, sortMixin],
  inject: ['filterMutations', 'pagingMutations'],
  props: {
    productModule: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    typeId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      types: INIT_SALES_TYPE,
      footerProps: {
        itemsPerPageText: 'Позиций на странице:',
        itemsPerPageAllText: 'Все',
        pageText: '{0}-{1} из {2}',

        itemsPerPageOptions: [10, 50, 100, 200],
      },
      noDataText: 'Список товаров пуст',
      headers: GRID_HEADERS_SUB_PRODUCT_LIST,
    };
  },

  computed: {
    ...mapState('common', ['loading']),
    ...mapState('wbCategory', ['fileLoading']),
    ...mapGetters('wbMainTabs', ['hasTab']),

    headersFiltered() {
      let filtered = this.headers.filter((h) => {
        // колонки в стране показываем не все
        return this.typeId === 'ctid' ? !h.hideCountry : h;
      });

      console.log('headersFiltered', filtered);
      return filtered;
    },

    grid() {
      return this.$store.getters[`${this.productModule}/grid`];
    },

    total() {
      return this.$store.getters[`${this.productModule}/total`];
    },

    dateFilter() {
      return this.$store.getters[`${this.productModule}/dateFilter`];
    },

    search: {
      get() {
        return this.$store.getters[`${this.productModule}/search_query`];
      },
      set(query) {
        this.$store.commit(`${this.productModule}/${this.filterMutations.SET_SEARCH_QUERY}`, query);
      },
    },
  },

  watch: {
    search(v) {
      // сброс
      this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE}`, 1);
      this.$store.commit(`${this.productModule}/${this.pagingMutations.SET_PAGE_SIZE}`, 10);
      this.$store.commit(`${this.productModule}/${this.filterMutations.RESET_SORT}`);
      // TODO сбрасывать ли фильтр, там же и фильтр даты
      // this.$store.commit(`${this.productModule}/${this.filterMutations.RESET_FILTER}`);
      this.debouncedSearch(v);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.getList, 800);
  },

  methods: {
    ...mapActions('wbMainTabs', ['addTab']),
    ...mapActions('wbProductCard', ['getCard']),
    ...mapMutations('common', ['showCardWbModal']),
    ...mapMutations('wbCategory', ['setFilterExport']),
    ...mapActions('wbCategory', ['getExportList']),

    openCard(item) {
      this.$store.commit('wbProductCard/setStartDate', item?.dateFilter?.start);
      this.$store.commit('wbProductCard/setEndDate', item?.dateFilter?.end);
      this.$store.commit('wbProductCard/setPid', item?.pid);
      this.$store.commit('wbProductCard/setSalesType', INIT_SALES_TYPE);
      this.getCard();
      this.showCardWbModal(true);
    },

    findProduct() {
      this.$store.dispatch(`${this.productModule}/findProduct`);
    },

    getList() {
      this.$store.dispatch(`${this.action}`);
    },

    onBrandClick(item) {
      if (!this.hasTab(item.brandid)) {
        this.addTab({
          id: item.brandid,
          name: item.brand,
          component: `BrandTabs`,
          action: `wbCategory/${item.brandid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.brandid);
      }
      const moduleName = `wbCategory/${item.brandid}`;
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'bid', cid: item.brandid }));
      }
    },

    // регистрация/создание нового модуля. таб продавцы
    onSellerClick(item) {
      if (!this.hasTab(item.sellerid)) {
        this.addTab({
          id: item.sellerid,
          name: item.seller,
          component: `SellerTabs`,
          action: `wnCategory/${item.sellerid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.sellerid);
      }
      const moduleName = `wbCategory/${item.sellerid}`;
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'sid', cid: item.sellerid }));
      }
    },

    onCatalogClick(item) {
      if (!this.hasTab(item.cid)) {
        this.addTab({
          id: item.cid,
          name: item.catalog,
          component: `Category`,
          action: `wbCategory/${item.cid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.cid);
      }

      const moduleName = `wbCategory/${item.cid}`;
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'cid', cid: item.cid }));
      }
    },

    // Страна
    onCountryClick(item) {
      if (!this.hasTab(item.countryid)) {
        this.addTab({
          id: item.countryid,
          name: item.country,
          component: `Country`,
          action: `wbCategory/${item.countryid}/getProductList`,
        });
      } else {
        this.$emit('cat:change', item.countryid);
      }

      const moduleName = `wbCategory/${item.countryid}`;
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, createCategoryModule({ typeId: 'ctid', cid: item.countryid }));
      }
    },

    exportFile() {
      this.setFilterExport(this.$store._modules.root.state[`wbCategory/${this.id}`]);
      this.getExportList({
        typeId: this.typeId,
        cid: this.id,
      });
    },
    changeType(e) {
      this.$store.commit(`${this.productModule}/${this.filterMutations.SET_SALES}`, e);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-btn.v-btn--outlined {
  border: 1px solid #464c59 !important;
  color: #464c59 !important;
}
.v-tooltip__content {
  opacity: 1 !important;
  background-color: transparent;
}
.v-btn-toggle > .v-btn.v-btn {
  color: black !important;
  background: white;
  opacity: 1;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: inherit !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border: 1px solid black !important;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:nth-child(1) {
  border-right-color: transparent !important;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:nth-child(2) {
  border-right-color: transparent !important;
}
</style>
