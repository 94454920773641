<template>
  <div>
    <v-tabs v-model="tab" class="tabs tab_act">
      <v-tabs-slider color="transparent"></v-tabs-slider>
      <v-tab v-for="{ name, img } of tabs" :key="name" :ripple="false" class="text-none tabs_tab">
        <svg height="35" width="80" viewBox="0 0 529.333 142.667">
          <use :xlink:href="`${img}`"></use>
        </svg>
      </v-tab>
    </v-tabs>
    <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light" />
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ component, name, prop } of tabs" :key="name">
        <component :is="component" v-bind="prop"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Wb from '@/components/metric/Wb.vue';
// import Ozon from '@/components/metric/Ozon.vue';

export default {
  name: 'Metric',

  components: {
    // Ozon,
    Wb,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'Wb',
          component: 'Wb',
          img: '#wb-logo',
        },
        // {
        //   name: 'Ozon',
        //   component: 'Ozon',
        //   img: '#ozon-logo',
        // },
      ];
    },
  },
  mounted() {
    document.title = 'МаркетМетрика';
  },

  methods: {},
};
</script>
<style>
.tab_act .v-tab--active {
  background-color: #d2ddfa !important;
}
.tabs .tabs_tab:nth-child(2) {
  margin-left: 0px;
}
</style>
