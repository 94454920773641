<template>
  <div>
    <div v-if="dataArray.length">
      <v-switch class="switch" :label="viewLabel" @click="toggleView"></v-switch>
    </div>
    <IncomeSalesGraph v-if="dataArray.length && isRubView" :graph-income="graphIncome" :graph-sales="graphSales" />
    <IncomeSalesGraphPercent
      v-if="dataArray.length && !isRubView"
      :graph-income="graphIncome"
      :graph-sales="graphSales"
    />
    <SubTable
      :sub-type="'подкатегория'"
      :category-type="categoryType"
      :name-component="nameComponent"
      :module-name="moduleName"
      :data-array="dataArray"
      :action="action"
      :type-id="typeId"
      @cat:change="$emit('cat:change', $event)"
    />
  </div>
</template>

<script>
import SubTable from '@/components/metric/wb/subTabs/SubTable.vue';
import IncomeSalesGraph from '@/components/common/metric/IncomeSalesGraph.vue';
import IncomeSalesGraphPercent from '@/components/common/metric/IncomeSalesGraphPercent.vue';

export default {
  name: 'SubCategory',

  inject: ['filterMutations'],
  components: {
    SubTable,
    IncomeSalesGraph,
    IncomeSalesGraphPercent,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    moduleName: {
      required: true,
      type: String,
    },
    action: {
      required: true,
      type: String,
    },
    categoryType: {
      type: String,
    },
  },

  data() {
    return {
      isRubView: true,
    };
  },

  computed: {
    viewLabel() {
      return this.isRubView ? 'В руб' : 'В процентах';
    },
    typeId() {
      return `cid`;
    },
    dataArray() {
      return this.$store.getters[`${this.moduleName}/dataArray`];
    },
    graphIncome() {
      return this.$store.getters[`${this.moduleName}/graphIncome`];
    },
    graphSales() {
      return this.$store.getters[`${this.moduleName}/graphSales`];
    },
    nameComponent() {
      return `Category`;
    },
  },

  created() {
    console.log(this.id);
    console.log('this.moduleName - ', this.moduleName);
    console.log('++++++++++СОЗДАНА ПОДКАТЕКОРИИ created');
    this.getCategoryList();
  },

  methods: {
    getCategoryList() {
      this.$store.dispatch(`${this.action}`);
    },

    toggleView() {
      this.isRubView = !this.isRubView;
    },
  },
};
</script>

<style lang="scss">
.switch {
  .v-input__slot {
    display: flex;
    justify-content: end;
    width: fit-content;
  }

  .v-input__control {
    width: fit-content;
    margin-left: auto;
  }
}
</style>
