import Vue from 'vue';
import App from './App.vue';
import store, { applyBasicGateInterceptors } from './store';
import vuetify from './plugins/vuetify';
import { createServerGate, createServerWhite } from './api';

Vue.config.productionTip = false;

export default {
  async mount(tokens) {
    try {
      createServerGate(tokens);
      createServerWhite(tokens);
      applyBasicGateInterceptors();

      new Vue({
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    } catch (error) {
      console.log('Ошибка запуска приложения');
      console.log(error);
    }
  },
};
