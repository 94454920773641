<template>
  <v-row dense>
    <v-col v-for="pack in colorPackageList" :key="pack.name" cols="12">
      <v-chip small color="#F27922" class="promo" dark>Акция</v-chip>
      <v-card
        class="pa-6 mx-2 mx-md-0 mb-6 elevation-0"
        min-height="138"
        style="border: 1px solid #e6e7e9"
        :style="{ 'border-left': '8px solid' + pack.color }"
      >
        <v-row>
          <v-col cols="12" sm="8">
            <div class="text-h6">{{ pack.name }}</div>
            <div class="mt-3 text-subtitle-2 font--lh20 font-weight-regular font--gray10">{{ pack.description }}</div>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            :align="isMobile ? 'start' : 'end'"
            :class="isMobile ? 'd-flex align-end justify-space-between pr-0' : ''"
          >
            <div :class="isMobile ? 'd-flex flex-column' : 'd-flex align-center justify-end'">
              <span v-if="pack.name == 'Стандарт'" class="mr-4" style="white-space: nowrap">
                <span class="font--lh23 text-subtitle-1 font--graphite4 text-decoration-line-through"
                  >10 000 ₽/мес
                </span>
              </span>
              <div style="white-space: nowrap">
                <span class="font-weight-bold font--black1" :class="isMobile ? 'font--fs24' : 'font--fs28'"
                  >{{ makeRub(pack.amount) }}
                </span>
                <span class="text-subtitle-1 font-weight-regular font--graphite3">/мес </span>
              </div>
            </div>
            <v-btn
              elevation="0"
              small
              :disabled="pack.state == 'enable'"
              :dark="pack.state != 'enabled'"
              :color="pack.state == 'enabled' ? '#f5f5f6' : '#5932CB'"
              class="mt-2"
              :class="pack.state != 'enabled' ? 'btn_enabled' : ''"
              @click="packActivate(pack)"
              >{{ btnText(pack.state) }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <span class="text-caption font--graphite3 ml-4 mb-6"
      >Нажимая «Подключить», вы соглашаетесь с
      <a href="https://partner.modulbank.ru/legal" target="_blank">Офертой</a></span
    >
    <Snackbar :open.sync="snackbar" :text="snackbarText" />
  </v-row>
</template>

<script>
import { makeRub } from '@/mixins/makeRub';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { saveServices } from '@/api';
import Snackbar from '@/components/common/Snackbar';

export default {
  name: 'PackCards',
  components: { Snackbar },
  mixins: [makeRub],
  data() {
    return {
      snackbar: false,
      snackbarText: '',
    };
  },
  computed: {
    ...mapState('services', ['packageList']),
    ...mapGetters('common', ['isMobile']),

    // хардом добавляем цвета для карточек
    colorPackageList() {
      this.packageList.map((pack) => {
        if (pack.name === 'Выводим на маркетплейсы') {
          return (pack.color = '#CBCDD1');
        }
        if (pack.name === 'Стандарт') {
          return (pack.color = '#24C4BC');
        }
        if (pack.name === 'Эксперт') {
          return (pack.color = '#E64D8D');
        }
        if (pack.name === 'VIP') {
          return (pack.color = '#222733');
        } else {
          return (pack.color = '#FCF2CE');
        }
      });

      return this.packageList;
    },
  },
  methods: {
    ...mapMutations('common', ['setVerticalTab', 'setMobileMainTab']),

    setTab() {
      this.isMobile ? this.setMobileMainTab('tab-0') : this.setVerticalTab(0);
    },

    btnText(state) {
      if (state === 'disabled') {
        return 'Подключить';
      }
      if (state === 'enable') {
        return 'Заявка отправлена';
      }
      if (state === 'enabled') {
        return 'Отключить';
      } else {
        return 'Подключить';
      }
    },
    async packActivate(pack) {
      // если нажали на Подключить отправляем disable, если на Отключить - enable
      if (pack.state != 'enable') {
        let data = {};
        if (pack.state == 'disabled') {
          data = { package: [{ id: pack.id, state: 'enable' }] };
        }
        if (pack.state == 'enabled') {
          data = { package: [{ id: pack.id, state: 'disable' }] };
        }
        const ok = await saveServices(data);
        if (ok) {
          if (pack.state === 'disabled') {
            this.snackbarText = 'Ваша заявка на подключение отправлена, скоро с вами свяжется наш сотрудник';
          }
          if (pack.state === 'enabled') {
            this.snackbarText = 'Ваша заявка на отключение отправлена, скоро с вами свяжется наш сотрудник';
          }
          this.snackbar = true;
          this.$store.dispatch('services/getPackage');
        }
      }
    },
  },
};
</script>

<style lang="scss">
.promo {
  position: absolute;
  top: 20px;
  left: 40px;
}

.btn_enabled:hover {
  background-color: #714fd6 !important;
}

.btn_enabled:active {
  background-color: #4626a4 !important;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #5932cb !important;
  opacity: 0.24;
}
.theme--dark.v-btn.v-btn--disabled {
  color: white !important;
}
</style>
