<template>
  <div v-if="isLoaded">
    <div class="d-flex">
      <h2 class="text-h5 font--lh28 mt-2">Аналитика</h2>
      <v-tabs v-model="tab" class="tabs tab_act d-flex justify-end mb-2" background-color="transparent">
        <v-tabs-slider color="transparent" class="tab"></v-tabs-slider>

        <v-tab v-for="{ name } of tabs" :key="name" :ripple="false" rounded class="text-none tabs_tab">
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ component, name, prop } of tabs" :key="name">
        <component :is="component" v-bind="prop"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import AnaliticWb from '@/components/analitic/AnaliticWb';
import AnaliticOzon from '@/components/analitic/AnaliticOzon';

import AnaliticEmpty from '@/components/analitic/AnaliticEmpty';
import { mapGetters } from 'vuex';

export default {
  name: 'Analitic',

  components: { AnaliticEmpty, AnaliticWb, AnaliticOzon },
  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    ...mapGetters('analitic', ['isLoaded']),
    tabs() {
      return [
        {
          name: 'Wildberries',
          component: 'AnaliticWb',
        },
        {
          name: 'Ozon',
          component: 'AnaliticOzon',
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch('analitic/getAnalitic');

    // открывать озон, если вб без данных
    let wbTab = this.$store.getters['analitic/isSuccessWb'];
    if (wbTab) {
      this.tab = 1;
    } else {
      this.tab = 0;
    }
  },
};
</script>

<style scoped>
.tab_act .v-tab--active {
  background-color: #d2ddfa !important;
  border: 2px solid #4971d0;
  color: #4971d0 !important;
}
.tabs .tabs_tab:nth-child(2) {
  margin-left: 40px;
}

.tabs_tab {
  background-color: white;
  border: 1px solid #cbcdd1;
  width: 142px;
  height: 44px;
  font-size: 16px;
  color: #222733 !important;
  font-weight: 400;
}
</style>
