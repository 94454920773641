<template>
  <div>
    <div style="width: fit-content">
      <v-tabs v-model="tab" class="mb-4 tabs" background-color="#F5F5F6" height="44px">
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab
          v-for="({ name, pid, selectedType, icon }, index) of tabs"
          :key="pid || name"
          :ripple="false"
          class="text-none tabs__link"
          @click.stop="createSubTab(selectedType, index, pid, name)"
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ component, pid, name, prop, selectedType } of tabs" :key="pid || name">
        <component
          :is="component"
          :id="id"
          :selected-type="selectedType"
          v-bind="prop"
          :type-id="'tid'"
          @cat:change="$emit('cat:change', $event)"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { createSubTabModule } from '@/store/modules/metric/wb/features/subTabs';
import SubProductList from '@/components/metric/wb/subTabs/SubProductList';

export default {
  name: 'TagTabs',
  components: { SubProductList },

  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      productModule: this.id ? `wbCategory/${this.id}` : 'wbCategory',
      tab: 0,
    };
  },
  computed: {
    ...mapGetters('wbCategorySubTabs', ['hasTab']),

    tabs() {
      return [
        {
          pid: 0,
          name: 'Все товары',
          component: 'SubProductList',
          prop: {
            productModule: this.productModule,
            action: `${this.productModule}/getProductList`,
          },
          icon: 'mdi-basket-outline',
        },
      ];
    },
  },
  watch: {
    // если открыли новый таб делаем его активным. делам this.tab последним елементов в массиве
    tabs(current, prev) {
      if (current.length > prev.length) {
        this.tab = current.length - 1;
      }
    },
  },

  methods: {
    ...mapMutations('wbCategorySubTabs', ['addTab']),

    // регистрация модуля
    createSubTab(selectedType, index, pid, name) {
      // FIXME DELETE
      console.log('*********createSubTab selectedType', selectedType);
      console.log(' index ТАБА - ', index);
      console.log(' pid ТАБА - ', pid);
      console.log(' name ТАБА - ', name);
      console.log('есть такой таб hasTab ? :', this.hasTab(this.id + '-' + selectedType));
      console.log('Выходим: ', !selectedType || selectedType == 'card' || this.hasTab(this.id + '-' + selectedType));

      if (!selectedType || selectedType == 'card' || this.hasTab(this.id + '-' + selectedType)) return;

      // FIXME нужен ли нам modulName?
      const moduleName = `wbCategorySubTabs/${this.id}-${selectedType}/${selectedType}`;

      this.addTab({
        id: this.id + '-' + selectedType,
        type: selectedType,
        modulName: moduleName,
        categoryId: this.id,
      });

      if (!this.$store.hasModule(moduleName)) {
        const subTabModule = createSubTabModule({
          id: this.id,
          type: selectedType,
        });
        this.$store.registerModule(moduleName, subTabModule);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Табы начинаются с index 2
.tabs {
  border-radius: 4px;
  height: 44px !important;

  &__link {
    height: 36px;
    font-size: 14px;
    color: #777c87 !important;
    font-weight: 400;
    margin: 4px 4px 0px !important;
    padding: 0 12px;
    letter-spacing: 0 !important;
  }

  &__link.v-tab--active {
    background-color: #4971d0 !important;
    color: #ffffff !important;
    border-radius: 4px;
    border: none;
    height: 36px;
  }
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #777c87 !important;
}
</style>
