<template>
  <v-menu v-model="open" :close-on-content-click="false">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" :height="height" class="mr-2 date-filter__button fb-btn" outlined v-on="on">
        <svg class="mr-2" width="20" height="20">
          <use xlink:href="#calendar"></use>
        </svg>
        <span class="d-none d-md-inline font-weight-regular font--black1 font--fs16 font--lh18">{{ buttonText }}</span>
      </v-btn>
    </template>
    <v-card width="350">
      <v-list>
        <template v-for="period in periods">
          <v-list-item :key="period" link @click="setDatePeriod(period)">
            <v-list-item-title>За последние {{ period }} дней</v-list-item-title>
          </v-list-item>
          <v-divider :key="`${period}_divider`" />
        </template>
        <v-list-item link>
          <v-list-item-title>
            <v-row dense>
              <v-menu
                transition="scale-transition"
                :return-value="filter.value"
                min-width="auto"
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-col cols="8">
                    <v-text-field
                      :value="prettyDate(filter.value)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      :rules="RULES.DATE(filter)"
                      hide-details
                      placeholder="Выберите даты"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </v-col>
                </template>
                <v-date-picker v-model="filter.value" range no-title scrollable />
              </v-menu>
              <v-col class="d-flex justify-end">
                <v-btn class="primary" @click="setDateFilter(filter)">ОК</v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { prettyDate, rawFilterFactory, RULES, TYPES } from '../_utils/filters';
import { format, sub } from 'date-fns';

const dateFormat = 'yyyy-MM-dd';
const defaultDateFilter = () =>
  rawFilterFactory({
    text: 'Дата',
    type: TYPES.DATE,
    value: 'dadd',
  });

export default {
  name: 'ModulDateFilter',
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    height: {
      type: [String, Number],
      default: '36',
    },
  },
  data: () => ({
    RULES,
    open: false,
    periods: [30, 90, 180, 360],
    filter: defaultDateFilter(),
  }),
  computed: {
    dateFilter: {
      get() {
        return this.$store.getters[`${this.moduleName}/localDateFilter`];
      },
      set(newVal) {
        this.filter = { ...newVal };
      },
    },
    buttonText() {
      const dates = this.filter.value;
      if (!dates || dates.length === 0) return 'Выберите даты';
      if (dates[0] && dates[1]) return `С ${prettyDate(dates).replace(' - ', ' по ')}`;
      if (dates[0]) return prettyDate(dates);
      return 'Выберите даты';
    },
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        if (newVal && newVal.active) this.filter = { ...newVal };
        else {
          this.filter = defaultDateFilter();
          this.setDatePeriod(30);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    prettyDate,
    setDateFilter() {
      this.$emit('update:date-filter', this.filter);
      this.open = false;
    },
    setDatePeriod(period) {
      const today = new Date();
      const start = sub(today, { days: period - 1 });
      console.log(today, start);
      const value = [format(start, dateFormat), format(today, dateFormat)];
      this.filter.active = true;
      this.filter.value = value;
      this.setDateFilter();
    },
  },
};
</script>

<style scoped lang="scss"></style>
