export const GRID_HEADERS = [
  { value: 'name', text: 'Каталог', sortable: false, width: '20rem' },
  { value: 'products', text: 'Товаров,\xa0шт', sortable: false, class: 'text-center', divider: true },
  { value: 'brands', text: 'Брендов,\xa0шт', sortable: false, class: 'text-center', divider: true },
  { value: 'sellers', text: 'Продавцов,\xa0шт', sortable: false, class: 'text-center', divider: true },
  { value: 'averageSm', text: 'Средний чек,\xa0₽', sortable: false, class: 'text-center', divider: true },
  { value: 'sales', text: 'Продаж,\xa0шт', sortable: false, class: 'text-center', divider: true },
  { value: 'income', text: 'Выручка,\xa0₽', sortable: false, width: '7.5rem', class: 'text-center', divider: true },
  {
    value: 'incomeLost',
    text: 'Упущенная выручка,\xa0₽',
    sortable: false,
    width: '160px',
    class: 'text-center',
    divider: true,
    description: 'LP',
  },
  {
    value: 'sps',
    text: 'Продаж на 1 продавца,\xa0шт',
    sortable: false,
    class: 'text-center',
    divider: true,
    description: 'SpS',
  },
  {
    value: 'spg',
    text: 'Продаж на 1 товар,\xa0шт',
    sortable: false,
    class: 'text-center',
    divider: true,
    description: 'SpG',
  },
];
